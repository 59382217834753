import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import "../styles/UpdateMomentStyle.css";

const VideoPlayer = ({ source = null, autoPlay = false }) => {
  const [isYouTube, setIsYouTube] = useState(false);

  useEffect(() => {
    if (source) {
      const ytRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)/;
      if (source.match(ytRegex)) {
        setIsYouTube(true);
      }
    }
  }, [source]);

  return (
    <>
      {isYouTube ? (
        <div className="videoFrame">
          <ReactPlayer
            url={source}
            playing={autoPlay}
            controls={true}
            className="videoFrame"
          />
        </div>
      ) : (
        <iframe
          src={source}
          scrolling="no"
          allowFullScreen={true}
          className="videoFrame"
        />
      )}
    </>
  );
};

export default VideoPlayer;
