import React from "react";
import { images } from "../../utils/image";
import "../../styles/LandingPage/InfoStyle.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const Info = () => {
  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 6000,
  };

  return (
    <div className="info-main">
      <div className="card-item d-flex justify-content-between align-items-center">
        <div className="content">
          <img src={images.moofLogoTran} />
          <div className="text">
            A Digital Platform for All Your Life Moments
          </div>
          <div className="d-flex align-items-center justify-content-start input-set">
            <div className="input-box">Log in to engage with Life Moments.</div>
            <Link to="/login">
              <div className="icon">
                <i class="bi bi-arrow-right" style={{ color: "white" }}></i>
              </div>
            </Link>
          </div>
        </div>

        <div className="image-part">
          <Slider {...settings}>
            <div className="image-div">
              <img src={images.info1} />
            </div>
            <div className="image-div">
              <img src={images.info5} />
            </div>
            <div className="image-div">
              <img src={images.info2} />
            </div>
            <div className="image-div">
              <img src={images.info4} />
            </div>
            <div className="image-div">
              <img src={images.info6} />
            </div>
            <div className="image-div">
              <img src={images.info3} />
            </div>
            <div className="image-div">
              <img src={images.info7} />
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Info;
