import React, { useEffect, useRef, useState } from "react";
import "../../styles/TopMomentTileStyle.css";
import "../../styles/ShareIconStyle.css";
import NavigationBar from "../../components/NavigationBar";
import InfiniteScroll from "react-infinite-scroller";
import { getTopMomentsTile } from "../../store/action/TopMomentsAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Card } from "react-bootstrap";
import VideoPlayer from "../../components/VideoPlayer";
import { Link } from "react-router-dom";
import { images } from "../../utils/image";
import Dropdown from "react-bootstrap/Dropdown";
import copy from "copy-to-clipboard";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import {
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
  XIcon,
} from "react-share";
import { getMyMomentsTile } from "../../store/action/MomentAction";
import { getFromSessionStorage } from "../../utils/storageHandler";

const MyMomentsTile = () => {
  const dispatch = useDispatch();
  const MyMoments = useSelector((state) => state.MyMoments.MyMoments);

  const limit = 9;
  const limitReached = useSelector((state) => state.MyMoments.limitReached);
  const Page = useSelector((state) => state.MyMoments.Page);
  const isLimitReached = limitReached;

  const [shareBoxVisible, setShareBoxVisible] = useState();
  const [shareMoment, setShareMoment] = useState("");
  const textRef = useRef();
  const shareBoxRef = useRef();
  const [tooltipState, setTooltipState] = useState({});

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (shareBoxRef.current && !shareBoxRef.current.contains(event.target)) {
        setShareBoxVisible(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const [email, setEmail] = useState();

  useEffect(() => {
    const userDetails = getFromSessionStorage("userDetails", true);
    const sessionStorageEmail = userDetails ? userDetails.email : "";
    setEmail(sessionStorageEmail);

    dispatch(
      getMyMomentsTile(
        {
          page: 1,
          limit: 9,
          user_email: sessionStorageEmail,
        },
        1
      )
    );
  }, []);

  const loadItems = async () => {
    dispatch(
      getMyMomentsTile(
        {
          page: Page,
          limit: limit,
          user_email: email,
        },
        Page
      )
    );
  };

  const currentUrl =
    window.location.protocol +
    "//" +
    window.location.hostname +
    (window.location.port ? ":" + window.location.port : "");

  const isLargeTile = (index) => {
    // pattern - 1, 6, 11, 16, 21
    // 6 / 5 remaining 1
    if (MyMoments[index]?.images[0]?.url && index % 5 === 1) {
      return true;
    } else if (
      index % 5 === 2 &&
      !MyMoments[index - 1]?.images[0]?.url &&
      MyMoments[index]?.images[0]?.url
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleShareClick = (momentId) => {
    setShareMoment(momentId);
    setShareBoxVisible(true);
  };

  const copyToClipboard = (id, myMoments) => {
    let copyText = textRef.current.value;
    let isCopy = copy(copyText);
    if (id === myMoments._id && isCopy) {
      toggleTooltip(id, true);

      setTimeout(() => {
        toggleTooltip(id, false);
      }, 3000);
    }
  };

  const toggleTooltip = (momentId, value) => {
    setTooltipState((prevState) => ({
      ...prevState,
      [momentId]: value,
    }));
  };

  return (
    <div>
      <div
        id="scrollerDiv"
        className="dummy-scroll"
        style={{
          height: "100vh",
          overflow: "auto",
        }}
        tabIndex={0}
      >
        <NavigationBar />

        <InfiniteScroll
          pageStart={1}
          loadMore={loadItems}
          hasMore={!isLimitReached}
          loader={
            MyMoments.length >= limit && !isLimitReached ? (
              <p
                className="loading-word-landing"
                style={{ marginLeft: "20px" }}
              >
                Loading...
              </p>
            ) : null
          }
          useWindow={false}
          initialLoad={false}
        >
        
          <div className="tile-view-main">
            {email ? (
              <div className="grid">
                <div className="tile date-tile">
                  <div className="date-card">
                    <div className="img-container">
                      <Card className="info-card">
                        <Card.Text
                          className="topic"
                          style={{ color: "#FFC000", fontSize: "40px" }}
                        >
                          My Moments
                        </Card.Text>
                      </Card>
                    </div>
                  </div>
                </div>
                {MyMoments.map((myMoments, index) => (
                  <div
                    key={myMoments._id}
                    className={`tile ${isLargeTile(index) ? "large" : ""}`}
                  >
                    <Dropdown>
                      <Dropdown.Toggle>
                        <div className="moof-icon">
                          <img src={images.MoofIcon} className="moof-icon"/>
                        </div>
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="dropdown-menu">
                        <Dropdown.Item
                          onClick={() => handleShareClick(myMoments._id)}
                        >
                          <img src={images.ShareIcon}></img>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                    {tooltipState[myMoments._id] && (
                      <div className="copy-message ">Link copied</div>
                    )}
                    <div className="icons share-icon">
                      <div class="share-feed">
                        <a
                          class="share-btn"
                          onClick={() => handleShareClick(myMoments._id)}
                        >
                          {shareBoxVisible && shareMoment === myMoments._id && (
                            <div class="share-box" ref={shareBoxRef}>
                              <div class="share-icon">
                                <label>Telegram</label>
                                <TelegramShareButton
                                  url={`${currentUrl}/${myMoments.life.verified_name?myMoments.life.verified_name:myMoments.life.life_id}/${myMoments._id}`}
                                  title={moment.moment_title}
                                >
                                  <TelegramIcon
                                    size={32}
                                    round
                                    className="icon"
                                  />
                                </TelegramShareButton>
                              </div>
                              <div class="share-icon">
                                <label>Linkedin</label>
                                <LinkedinShareButton
                                  url={`${currentUrl}/${myMoments.life.verified_name?myMoments.life.verified_name:myMoments.life.life_id}/${myMoments._id}`}
                                  title={moment.moment_title}
                                  source="MoofLife"
                                >
                                  <LinkedinIcon
                                    size={32}
                                    round
                                    className="icon"
                                  />
                                </LinkedinShareButton>
                              </div>
                              <div class="share-icon">
                                <label>Whatsapp</label>
                                <WhatsappShareButton
                                  url={`${currentUrl}/${myMoments.life.verified_name?myMoments.life.verified_name:myMoments.life.life_id}/${myMoments._id}`}
                                  title={moment.moment_title}
                                  separator=":: "
                                >
                                  <WhatsappIcon
                                    size={32}
                                    round
                                    className="icon"
                                  />
                                </WhatsappShareButton>
                              </div>
                              <div class="share-icon">
                                <label>Facebook</label>
                                <FacebookShareButton
                                  url={`${currentUrl}/${myMoments.life.verified_name?myMoments.life.verified_name:myMoments.life.life_id}/${myMoments._id}`}
                                >
                                  <FacebookIcon
                                    size={32}
                                    round
                                    className="icon"
                                  />
                                </FacebookShareButton>
                              </div>
                              <div class="share-icon">
                                <label>Mask</label>
                                <TwitterShareButton
                                  url={`${currentUrl}/${myMoments.life.verified_name?myMoments.life.verified_name:myMoments.life.life_id}/${myMoments._id}`}
                                  title={moment.moment_title}
                                >
                                  <XIcon size={32} round className="icon" />
                                </TwitterShareButton>
                              </div>
                              <div class="share-icon icon-copy">
                                <div class="hover-text">
                                  {/* <!-- Copy Text --> */}
                                  <div className="copy-text d-flex">
                                    <input
                                      value={`${currentUrl}/${myMoments.life.verified_name?myMoments.life.verified_name:myMoments.life.life_id}/${myMoments._id}`}
                                      disabled
                                      type="text"
                                      ref={textRef}
                                    />
                                  </div>
                                </div>
                                <img
                                  src={images.CopyIcon}
                                  onClick={() =>
                                    copyToClipboard(myMoments._id, myMoments)
                                  }
                                  className="icon"
                                ></img>
                              </div>
                            </div>
                          )}
                        </a>
                      </div>
                    </div>

                    <Link
                      to={`/${myMoments.life.verified_name?myMoments.life.verified_name:myMoments?.life?.life_id}/${myMoments._id}`}
                      target="_blank"
                    >
                      {myMoments.images.length > 0 &&
                        (myMoments.images[0]?.media_type === "image" ? (
                          <img src={myMoments.images[0].url} className="moment-img" />
                        ) : (
                          <div>
                            <VideoPlayer
                              className="videoFrame"
                              source={myMoments.images[0].url}
                            />
                          </div>
                        ))}

                      <div className="title">
                        <div className="p">
                          <div className="life-logo-name">
                            <img
                              src={myMoments.life?.life_logo}
                              className="img"
                            ></img>
                            <div className="moment-date">
                              {myMoments.moment_date}
                            </div>
                          </div>
                          {myMoments.moment_title.length > 90
                            ? myMoments.moment_title.slice(0, 90) + "..."
                            : myMoments.moment_title}
                        </div>
                      </div>

                      {myMoments.images.length < 1 && (
                        <div className="para">
                          <div>
                            {myMoments.moment_details.length > 150
                              ? myMoments.moment_details.slice(0, 150) + "..."
                              : myMoments.moment_details}
                          </div>
                        </div>
                      )}
                    </Link>
                  </div>
                ))}
              </div>
              ):(
              <div className="no-moments-line">
                <div className="title">Log in and follow Lives to see your favorite moments !</div> 
              <div className="link">
                <Link to="/login"><span>Login</span></Link>&nbsp; |  &nbsp;
                <Link to="/sign-up"><span>Sign Up</span></Link>
              </div>
              </div>
            )}
          </div>
      
          
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default MyMomentsTile;
