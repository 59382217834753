import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { images } from "../../utils/image";
import "../../styles/CooperatePagesStyle/CooperateFooterStyle.css";

const CooperateFooter = () => {
  const now = new Date();
  const currentYear = (now.getFullYear());

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div>
      <Container className="footer-main">
        <Row>
          <Col sm={4}>
            <div className="footer-col1">
              <img className="footer-logo" src={images.moofLogoTran}></img>
              <div className="logo-title">Creating History for the Future</div>
              <div className="logo-title">MoofLife Corporation © {currentYear}</div>
            </div>
          </Col>
          <Col sm={2}>
            <ul>
              <p className="title">MoofLife Corporation</p>
              <Link to="/about-us">
                <div className="sub-title" onClick={handleClick}>About Us</div>
              </Link>
              <Link to="/mooflife-concept">
                <div className="sub-title" onClick={handleClick}>MoofLife Concept</div>
              </Link>
              <Link to="/contact-us">
                <div className="sub-title" onClick={handleClick}>Contact Us</div>
              </Link>
            </ul>
          </Col>
          <Col sm={2}>
            <ul>
              <p className="title">Legal</p>
              <Link to="/privacy-policy">
                <div className="sub-title" onClick={handleClick}>Terms & Conditions</div>
              </Link>
              <Link to="/privacy-policy">
                <div className="sub-title" onClick={handleClick}>Privacy Policy</div>
              </Link>
            </ul>
          </Col>
          <Col sm={2}>
            <div>
              <p className="title">Contact Us</p>

              <div className="list-item">
                <p className="sub-title">
                  <span>
                    <img
                      className="contact-icons"
                      src={images.ContactUsCall}
                    ></img>
                  </span>
                  +1 628 225 3261
                </p>
              </div>

              <div className="list-item">
                <a href="mailto:contact@mooflife.com">
                <p className="sub-title email-section">
                  <span>
                    <img
                      className="contact-icons"
                      src={images.ContactUsMail}
                    ></img>
                  </span>
                  contact@mooflife.com
                </p>
                </a>
              </div>

              <div className="list-item">
                <p className="sub-title">
                  <span>
                    <img
                      className="contact-icons"
                      src={images.ContactUsLocation}
                    ></img>
                  </span>{" "}
                  San Francisco, CA
                </p>
              </div>
            </div>
          </Col>
          <Col sm={2}>
            <div>
              <p className="title follow-title">Follow Us</p>

              <div className="footer-socialIcons">
                <a href="https://www.facebook.com/MoofLife" target="_blank">
                  <img
                    className="social-icons"
                    src={images.ContactUsFacebook}
                  ></img>
                </a>
                <a href="https://www.linkedin.com/company/mooflife" target="_blank">
                  <img
                    className="social-icons"
                    src={images.ContactUsLinkedin}
                  ></img>
                </a>
                <a href="https://www.youtube.com/@MoofLife" target="_blank">
                  <img
                    className="social-icons"
                    src={images.ContactUsYoutube}
                  ></img>
                </a>
                <a href="https://www.tiktok.com/@mooflife_moments" target="_blank">
                  <img
                    className="social-icons"
                    src={images.ContactUsTiktok}
                  ></img>
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CooperateFooter;
