import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import {images} from "../utils/image"
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { sendEmailForGetOTP, setForgotPasswordDetails } from "../store/action/userInteractionsAction";
import { getFromSessionStorage } from "../utils/storageHandler";
import "../styles/ForgetPasswordStyle.css";
import Spinner from "react-bootstrap/Spinner";
const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const ForgetPassword = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailPatternErrorMessage, setEmailPatternErrorMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state;
  const routeAction = locationState && locationState.action ? locationState.action : null;
  const forgotPasswordStatus = {
    forgetPassword: true,
    otp: false,
  };
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    const accessToken = getFromSessionStorage('accessToken');
    if(accessToken && routeAction !== 'passwordChange'){
      navigate("/");
    }
  }, []);

  const handleEmailChange = (event) => {
    if (!emailPattern.test(event.target.value)) {
      setEmailPatternErrorMessage("Type valid email address");
    } else {
      setEmailPatternErrorMessage("");
    }
    setEmail(event.target.value);
  };

  const handleButton = async () => {
    setLoading(true);
    dispatch(await sendEmailForGetOTP({ email: email, forgotPassword: true }))
      .then((res) => {
        if (res) {

          dispatch(setForgotPasswordDetails(forgotPasswordStatus))
          .then(() => {
            setLoading(false);
            navigate(`/otp-verification`, {
              state: { email: email, warning: false },
            });
          })
          .catch((error) => {
            setLoading(false);
            console.error("Error in setForgotPasswordDetails:", error);
          });
        } else {

          setLoading(false);
          setErrorMessage("User registred with another sign-in method")
       
          // dispatch(setForgotPasswordDetails(forgotPasswordStatus))
          // .then(() => {
          //   navigate(`/otp-verification`, {
          //     state: { email: email, warning: false },
          //   });
          // })
          // .catch((error) => {
          //   console.error("Error in setForgotPasswordDetails:", error);
          // });
          
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error in verification:", error);
      });
  };

  return (
    <div>
      <div className="forget-password-main">
        <Row className="col1">
          <Col sm={5}>
            <img className="forget-password-img" src={images.forgetPasswordBg}></img>
          </Col>

          <Col sm={7}>
            <p className="go-back" onClick={() => navigate(-1)}>
              <span>
                <i class="bi bi-arrow-left"></i>
              </span>
              Back
            </p>
            <Card className="text-center card">
              <Card.Body>
                <section className="forget-password-logo">
                  <img className="logo" src={images.moofLifeLogo}></img>
                </section>
                <div className="forget-password-container">
                  <div className="title-set">
                    <p className="title-1">Forgot Password ? </p>
                    <p className="title-2">
                      Please enter the e-mail adders associated with your
                      account.
                    </p>
                  </div>
                  <div className="forget-password-form">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="form-control"
                      placeholder="Enter the e-mail address"
                      value={email}
                      onChange={handleEmailChange}
                    />
                    {emailPatternErrorMessage && (
                      <small>
                        <div className="error-message">
                          <span>
                            <i class="bi bi-exclamation-circle"></i>
                          </span>
                          {emailPatternErrorMessage}
                        </div>
                      </small>
                    )}
                      {errorMessage && (
                    <small>
                      <div className="error-message">
                        <span>
                          <i class="bi bi-exclamation-circle"></i>
                        </span>
                        {errorMessage}
                      </div>
                    </small>
                  )}
                    <button
                      type="button"
                      className="btn-verify"
                      onClick={handleButton}
                      disabled={email === "" || emailPatternErrorMessage || loading === true}
                    >
                      {loading ? (
                      <Spinner
                        animation="border"
                        variant="light"
                        style={{ width: "15px", height: "15px" }}
                      />
                    ) : (
                      "Send OTP (One Time Password)"
                    )}
                    </button>
                  </div>
                </div>

                <p className="title-3">
                  Don’t have an account ?
                  <span>
                    <Link to="/sign-up">Sign Up</Link>
                  </span>
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ForgetPassword;
