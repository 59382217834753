import React, { useRef, useState, useEffect } from "react";
import NavigationBar from "../views/LandingPage/NavigationBar.jsx";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import MomentJs from "moment";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { images } from "../utils/image";
import "../styles/UpdateLifeStyle.css";
import Footer from "../components/Footer";
import { Spinner } from "react-bootstrap";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import {
  storeLifeImages,
  createNewLife,
  fetchLife,
  getManagers,
  updateLife,
  completeSubscription,
  getSubscriptionLimits,
} from "../store/action/LifeAction";
import { getAuthorizedEmails } from "../store/action/MomentAction";
import { getFromSessionStorage } from "../utils/storageHandler";
import userImage from "../assets/User_cicrle.png";
import Calendar from "react-calendar";
import PopUpModal from "./PopUpModal";

const UpdateLifePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const subscribed = queryParams.has("subscribed");
  const URLState = location.state;
  const lifeToCreate = getFromSessionStorage("lifeToCreate", true);
  const isCreateLife =
    location && location.pathname && location.pathname === "/create-life"
      ? true
      : false;
  let life_Id = URLState && URLState.lifeId ? URLState.lifeId : "";
  let lifeType = URLState && URLState.lifeType ? URLState.lifeType : "";
  let life_name = URLState && URLState.lifeName ? URLState.lifeName : "";
  let plan = URLState && URLState.plan ? URLState.plan : "";

  if (isCreateLife && lifeToCreate) {
    if (life_Id === "") {
      life_Id = lifeToCreate.lifeId;
    }

    if (lifeType === "") {
      lifeType = lifeToCreate.lifeType;
    }

    if (life_name === "") {
      life_name = lifeToCreate.lifeName;
    }

    if (plan === "") {
      plan = lifeToCreate.plan;
    }
  }

  const [life_type, setlifeTypeValue] = useState(lifeType);
  const userDetails = getFromSessionStorage("userDetails", true);
  const inputRefProfile = useRef(null);
  const inputRefCover = useRef(null);
  const [profileImage, setProfileImage] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [currentProfImg, setCurrentProfImg] = useState(null);
  const [currentCovImg, setCurrentCovImg] = useState(null);
  const [founders, setFounders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState("public");
  const [cloudBucket, setCloudBucket] = useState("DO");
  const [selectedTags, setSelectedTags] = useState([]);
  const [mooflifeAdmin, setMooflifeAdmin] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [showCalendar, setShowCalendar] = useState(false);
  const calendarRef = useRef(null);
  const [clickCheckbox, setClickCheckbox] = useState(false);
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [fullDate, setFullDate] = useState("");
  const [show, setShow] = useState(false);
  const [isValidVerifiedName, setIsValidVerifiedName] = useState(true);
  const [message, setMessage] = useState("");
  const [accountManager, setAccountManager] = useState({
    first_name: "",
    last_name: "",
    image_url: "",
  });
  const [lifeManagers, setLifeManagers] = useState([]);
  const [lifeValues, setLifeValues] = useState({
    life_name: life_name,
    life_title: "",
    account_manager_email: "",
    current_subscription: plan,
    life_tag: "",
    founders: [],
    founded_in: "",
    life_id: life_Id,
    life_type: life_type,
    life_privacy: "public",
    life_description: "",
    web_link: "",
    life_logo: "",
    cover_image: "",
    since: "",
    is_operating: true,
    verified_name: "",
    bday_display: "show",
    setSelectedSubCategory:[]
  });
  const [mooflifeSubscription, setMooflifeSubscription] = useState([]);
  const [waitingModalShow, setWaitingModalShow] =  useState(false);
  const [subCategory, setSelectedSubCategory] = useState([]);
  const [subCategoryInputValue, setSubCategoryInputValue] = useState("");
  const fullLifeTypes = [
    {
      type: "OL",
      description: "Organization Life",
    },
    {
      type: "PL",
      description: "Person Life",
    },
    {
      type: "EL",
      description: "Entity Life",
    },
  ];

  useEffect(() => {
    const accessToken = getFromSessionStorage("accessToken");

    if (!accessToken) {
      navigate("/login");
    }

    if (
      isCreateLife &&
      (life_Id === "" || life_type === "" || life_name === "")
    ) {
      navigate("/");
    }
    checkAuthorizedEmails();
    requestSubscriptions();
    if (!isCreateLife) {
      setLifeData();
    }

    if (isCreateLife && subscribed) {
      handleSubscriptionComplete();
    }
  }, []);

  const requestSubscriptions = async () => {
    const subscriptions = await dispatch(getSubscriptionLimits());
    if (subscriptions.success && subscriptions.data) {
      const freeSub = subscriptions.data
        .filter((subscription) => subscription.type === "mooflife")
        .map((subscription) => subscription.plan);

      setMooflifeSubscription(["Free", ...freeSub]);
    }
  };

  const handleSubscriptionComplete = async () => {
    await dispatch(
      completeSubscription({
        lifeId: life_Id,
        userEmail: userDetails.email,
        isNew: true,
        life_name: life_name,
        life_type: life_type
      })
    );
  };

  const getManagerData = async (AMEmail, LMEmails) => {
    dispatch(
      await getManagers({
        AMEmail: AMEmail,
        LMEmails: LMEmails,
      })
    ).then((managerRes) => {
      if ((managerRes && managerRes.success, managerRes.data)) {
        if (managerRes.data) {
          setAccountManager(managerRes.data.accountManager);
          setLifeManagers(managerRes.data.lifeManagers);
        }
      }
    });
  };

  const setLifeData = async () => {
    if (life_Id && life_Id !== "") {
      dispatch(await fetchLife(life_Id, "update")).then((res) => {
        if (res.data && res.data.success) {
          const lifeRes = res.data.data;
          setlifeTypeValue(lifeRes.life_type);
          setCurrentProfImg(lifeRes.life_logo);
          setCurrentCovImg(lifeRes.cover_image);
          if (
            (lifeRes.life_tag && lifeRes.life_tag !== "") ||
            lifeRes.life_tag !== null
          ) {
            let tagSet = lifeRes.life_tag.split("#");
            tagSet.shift();
            const resultArray = tagSet.map((tag) => ({
              value: "#" + tag.trim(),
              label: "#" + tag.trim(),
            }));
            setSelectedTags(resultArray);
          }

          if (
            lifeRes.life_sub_category.length>0
          ) {
            const resultArray = lifeRes.life_sub_category.map((sub) => ({
              value: sub,
              label: sub,
            }));
            setSelectedSubCategory(resultArray);
          }

          setLifeValues({
            life_name: lifeRes.life_name,
            life_title: lifeRes.life_title,
            account_manager_email: lifeRes.account_manager_email,
            current_subscription: lifeRes.current_subscription,
            life_tag: lifeRes.life_tag,
            founders: lifeRes.founders,
            founded_in: lifeRes.founded_in,
            life_id: lifeRes.life_Id,
            life_type: lifeRes.life_type,
            life_privacy: lifeRes.life_privacy,
            life_description: lifeRes.life_description,
            web_link: lifeRes.web_link,
            life_logo: lifeRes.life_logo,
            cover_image: lifeRes.cover_image,
            since: "",
            is_operating: lifeRes.is_operating.toString(),
            verified_name: lifeRes.verified_name,
            bday_display: lifeRes.bday_display ? lifeRes.bday_display : "show",
            setSelectedSubCategory: lifeRes.setSelectedSubCategory
          });

          if (lifeRes.since) {
            const dateParts = lifeRes.since.split("-");

            if (dateParts.length === 3) {
              const fullDate = MomentJs(lifeRes.since).format("DD-MM-YYYY");
              setFullDate(fullDate);
              setDay(MomentJs(lifeRes.since).format("DD"));
              setMonth(MomentJs(lifeRes.since).format("MM"));
              setYear(MomentJs(lifeRes.since).format("YYYY"));
            } else if (dateParts.length === 2) {
              setFullDate(MomentJs(lifeRes.since).format("MM-YYYY"));
              setDay("");
              setMonth(MomentJs(lifeRes.since).format("MM"));
              setYear(MomentJs(lifeRes.since).format("YYYY"));
            } else if (dateParts.length === 1) {
              setFullDate(MomentJs(lifeRes.since).format("YYYY"));
              setDay("");
              setMonth("");
              setYear(MomentJs(lifeRes.since).format("YYYY"));
            }
          }

          getManagerData(
            lifeRes.account_manager_email,
            lifeRes.life_manager_email
          );
        }
      });
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        calendarRef.current &&
        !calendarRef.current.contains(event.target) &&
        !event.target.classList.contains("bi-calendar-week-fill")
      ) {
        setShowCalendar(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const checkAuthorizedEmails = async () => {
    if (userDetails && userDetails.email) {
      dispatch(
        await getAuthorizedEmails({ userEmail: userDetails.email })
      ).then((res) => {
        if (res && res.success && res.data) {
          setMooflifeAdmin(res.data.result);
        }
      });
    }
  };

  const handleCoverImageClick = () => {
    inputRefCover.current.click();
  };

  const handleCoverImageChange = (event) => {
    const file = event.target.files[0];
    const isValid = isValidImage(file);
    if (isValid) {
      setCoverImage(file);
    }
  };

  const handleProfileImageClick = () => {
    inputRefProfile.current.click();
  };

  const handleProfileImageChange = (event) => {
    const file = event.target.files[0];
    const isValid = isValidImage(file);
    if (isValid) {
      setProfileImage(file);
    }
  };

  const isValidImage = (file) => {
    if (file && file.type) {
      let validTypes = ["image/jpeg", "image/png", "image/webp", "image/jpg"];

      if (validTypes.includes(file.type)) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleLifeValueChange = (evt) => {
    const { id, value } = evt.target;

    if (id === "website_link" || id === "website_link_org") {
      setLifeValues((prevValues) => ({
        ...prevValues,
        web_link: value,
      }));
    } else if (id === "is_operating") {
      setLifeValues((prevValues) => ({
        ...prevValues,
        is_operating: value === "true" ? true : false,
      }));
    } else {
      if (id === "verified_name") {
        checkVerifiedNameValidity(value);
      }
      setLifeValues((prevValues) => ({
        ...prevValues,
        [id]: value,
      }));
    }
  };

  const handleFounders = (evt) => {
    const { value } = evt.target;
    const foundersArray = [value];
    setFounders(foundersArray);
  };

  const handleAddKeyword = () => {
    const words = inputValue.split(/\s+/);

    const newValue = words
      .map((word) => {
        // Check if word already starts with "#"
        if (word && !word.startsWith("#")) {
          return "#" + word;
        } else {
          return word;
        }
      })
      .join(" ");

    setInputValue(newValue);
    setSelectedTags([...selectedTags, { value: newValue, label: newValue }]);
    setInputValue("");
  };

  const handleRemoveKeyword = (keywordRemove) => {
    setSelectedTags(selectedTags.filter((tag) => tag.label !== keywordRemove));
  };

  const handleChange = (selectedOptions) => {
    setSelectedTags(selectedOptions);
  };

  const handleInputChange = (inputValue) => {
    const words = inputValue.split(/\s+/);

    const newValue = words
      .map((word) => {
        // Check if word already starts with "#"
        if (word && !word.startsWith("#")) {
          return "#" + word;
        } else {
          return word;
        }
      })
      .join(" ");
    setInputValue(newValue);
  };

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setSelectedTags([
          ...selectedTags,
          { value: inputValue, label: inputValue },
        ]);
        setInputValue("");
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  const handleChangeSubCategory = (selectedOptions) => {
    setSelectedSubCategory(selectedOptions);
  };

  const handleSubCategoryInputChange = (subCategoryInputValue) => {
    setSubCategoryInputValue(subCategoryInputValue);
  };

  const handleSubCategoryKeyDown = (event) => {
    if (!subCategoryInputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setSelectedSubCategory([
          ...subCategory,
          { value: subCategoryInputValue, label: subCategoryInputValue },
        ]);
        setSubCategoryInputValue("");
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  const handleAddSubCategoryKeyword = () => {
    setSubCategoryInputValue(subCategoryInputValue);
    setSelectedSubCategory([...subCategory, { value: subCategoryInputValue, label: subCategoryInputValue }]);
    setSubCategoryInputValue("");
  };

  const handleRemoveSubCategoryKeyword = (keywordRemove) => {
    setSelectedSubCategory(subCategory.filter((tag) => tag.label !== keywordRemove));
  };

  const handleImageUpload = async (update = false) => {
    if (profileImage || coverImage) {
      const files = {
        profileImage: profileImage,
        coverImage: coverImage,
      };

      let existingImages = null;
      const cloudImgRegEx = /\/life\/images\/\S+/;

      if (update) {
        let oldProfPicKey = null;
        let oldCovPicKey = null;

        if (profileImage && currentProfImg) {
          let isValidCloudPrfImg = cloudImgRegEx.test(currentProfImg)
            ? currentProfImg
            : null;

          if (isValidCloudPrfImg) {
            oldProfPicKey = `life${isValidCloudPrfImg.split("life")[1]}`;
          }
        }

        if (coverImage && currentCovImg) {
          let isValidCloudCovImg = cloudImgRegEx.test(currentCovImg)
            ? currentCovImg
            : null;

          if (isValidCloudCovImg) {
            oldCovPicKey = `life${isValidCloudCovImg.split("life")[1]}`;
          }
        }

        existingImages = {
          oldProfImgKey: oldProfPicKey,
          oldCovImgKey: oldCovPicKey,
        };
      }

      const imgRes = await dispatch(
        storeLifeImages(files, cloudBucket, life_type, life_Id, existingImages)
      );
      if (imgRes && imgRes.success && imgRes.data) {
        if (update) {
          const profImg =
            imgRes.data.profileImageUrl !== null
              ? imgRes.data.profileImageUrl
              : currentProfImg;
          const covImg =
            imgRes.data.coverImageUrl !== null
              ? imgRes.data.coverImageUrl
              : currentCovImg;
          return {
            profileImageUrl: profImg,
            coverImageUrl: covImg,
          };
        } else {
          return imgRes.data;
        }
      }
    } else {
      if (update) {
        return {
          profileImageUrl: currentProfImg,
          coverImageUrl: currentCovImg,
        };
      } else {
        return {
          profileImageUrl: null,
          coverImageUrl: null,
        };
      }
    }
  };

  const prepareDataForLife = (imgRes = null) => {
    let lifeData = lifeValues;
    const subs = lifeValues.current_subscription.split("-")[1];
    lifeData.life_logo = imgRes.profileImageUrl;
    lifeData.cover_image = imgRes.coverImageUrl;
    lifeData.account_manager_email = userDetails.email;
    lifeData.founders = founders;
    lifeData.life_tag = selectedTags.map((item) => item.value).join(" ");
    lifeData.life_sub_category = subCategory.map((item) => item.value);
    //lifeData.current_subscription = subs === "Free" ? "Free" : lifeValues.current_subscription
    lifeData.current_subscription = mooflifeSubscription.includes(subs)
      ? subs
      : lifeValues.current_subscription;

    let lifeDate = "";
    if (day !== "" && month !== "" && year !== "") {
      lifeDate = `${year}-${month}-${day}`;
    } else if (month === "") {
      lifeDate = `${year}`;
    }

    lifeData.since = lifeDate;
    return lifeData;
  };

  const createLife = async () => {
    setLoading(true);
    setWaitingModalShow(true);
    const imgRes = await handleImageUpload(false);

    if (imgRes && isValidVerifiedName) {
      const newLifeData = prepareDataForLife(imgRes);
      const createdLife = await dispatch(createNewLife(newLifeData));
      if (createdLife && createdLife.success && createdLife.data) {
        setMessage("Life created successfully");
        setTimeout(() => {
          navigate(`/${createdLife.data.life_id}`);
          setWaitingModalShow(false);
          setShow(false);
          setLoading(false);
          localStorage.removeItem("lifeToCreate");
        }, 20000);
      } else {
        let errorMsg = null;
        if (createdLife.error) {
          errorMsg = createdLife.error;
        }
        setLoading(false);
        setShow(true);
        setMessage(errorMsg ? `Error: ${errorMsg}` : "Life creation is failed");
      }
    } else {
      setLoading(false);
      setShow(true);
      setMessage("Please add valid data for create a new Life!");
    }
  };

  const handleDateSelection = () => {
    setShowCalendar((prevState) => !prevState);
    setClickCheckbox(false);
  };

  const handlePermission = () => {
    setClickCheckbox((prevState) => !prevState);
  };

  const selectBucket = (evt) => {
    setCloudBucket(evt.target.value);
  };

  const handleDateValues = (dateVal) => {
    const formattedDate = MomentJs(dateVal).format("DD-MM-YYYY");

    setFullDate(formattedDate);
    setYear(MomentJs(dateVal).format("YYYY"));
    setMonth(MomentJs(dateVal).format("MM"));
    setDay(MomentJs(dateVal).format("DD"));
  };

  const setDateValues = (evt) => {
    const { id, value } = evt.target;

    if (id === "lifeDay" || id === "lifeDayPL") {
      setDay(value);
    } else if (id === "lifeMonth" || id === "lifeMonthPL") {
      setMonth(value);
    } else if (id === "lifeYear" || id === "lifeYearPL") {
      setYear(value);
    }
  };

  const updateExistingLife = async () => {
    setLoading(true);
    const imgRes = await handleImageUpload(true);

    if (imgRes && isValidVerifiedName) {
      const lifeDataToUpdate = prepareDataForLife(imgRes);

      delete lifeDataToUpdate.account_manager_email;
      delete lifeDataToUpdate.life_id;
      delete lifeDataToUpdate.life_type;

      const updatedLife = await dispatch(
        updateLife({ lifeId: life_Id, updates: lifeDataToUpdate })
      );
      if (updatedLife && updatedLife.success && updatedLife.data) {
        setShow(true);
        setMessage(
          <>
            <span style={{ fontWeight: 'bold' }}>Life updated !<br/></span>
            <br />
            <span>
            You will be redirected to the updated Life. Please wait...
            </span>
          </>
        );
        setTimeout(() => {
          navigate(
            `/${
              updatedLife.data.verified_name
                ? updatedLife.data.verified_name
                : updatedLife.data.life_id
            }`
          );
          setShow(false);
          setLoading(false);
        }, 5000);
      } else {
        let errorMsg = null;
        if (updatedLife.error) {
          errorMsg = updatedLife.error;
        }
        setLoading(false);
        setShow(true);
        setMessage(errorMsg ? `Error: ${errorMsg}` : "Life create failed");
      }
    } else {
      setLoading(false);
      setShow(true);
      setMessage("Please add valid data for update Life!");
    }
  };

  const displayLifeTypeInFull = (type) => {
    const fullLifeType = fullLifeTypes.find((item) => item.type === type);
    if (fullLifeType) {
      return fullLifeType.description;
    } else {
      return type;
    }
  };

  const checkVerifiedNameValidity = (verifiedName) => {
    const invalidCharacters = /[#$\s@%&^*()\\+/,*!=\[\].]/;
    if (verifiedName) {
      if (invalidCharacters.test(verifiedName)) {
        setIsValidVerifiedName(false);
      } else {
        setIsValidVerifiedName(true);
      }
    }
  };

  return (
    <div>
      <div className="update-life">
        <NavigationBar></NavigationBar>

        <PopUpModal
          title={message}
          show={show}
          setShow={(bool) => setShow(bool)}
        ></PopUpModal>

<div>
        <Modal
          className="popup-modal confirmation-modal"
          backdrop="static"
          centered
          show={waitingModalShow}
        >
          <div className="popup-header">
            <div className="logo-icon">
              <img src={images.MoofIcon} />
            </div>
          </div>

          <Modal.Body className="modal-body p-5">
          
              <div className="title">
              ‘We are creating your new Life !
              </div>
              <div className="subtitle1">
              This usually takes less than a minute. You will be 
              automatically taken into the newly created life page. Please wait…
              </div>
          
            <Spinner
              animation="border"
              size="sm"
              style={{
                width: "20px",
                height: "20px",
                marginLeft: "2px",
                marginTop: "20px",
                color: "#FFC000",
              }}
            />
          </Modal.Body>
        </Modal>
      </div>

        <div className="update-life-main">
          <Row className="topic-row">
            <Col sm={5} style={{ textAlign: "left", paddingLeft: "100px" }}>
              <div className="back" onClick={() => navigate(-1)}>
                <span>
                  <i
                    class="bi bi-caret-left-fill"
                    style={{ color: "#CACACA", marginRight: "5px" }}
                  ></i>
                </span>
                Back
              </div>
            </Col>
            <Col>
              <div
                className="d-flex"
                style={{
                  justifyContent: "space-between",
                  paddingRight: "50px",
                  paddingLeft: "50px",
                }}
              >
                <div className="topic">
                  {isCreateLife ? "Create Life" : "Update Life"}
                </div>
              </div>
            </Col>
          </Row>
          <div className="life-form-main">
            <div className="life-header">
              <Card className="header-card">
                <Card.Body className="header-content">
                  <div>
                    <div onClick={handleCoverImageClick}>
                      {coverImage === null && currentCovImg ? (
                        <img
                          src={currentCovImg}
                          className="img-display-after cover-image"
                        ></img>
                      ) : coverImage ? (
                        <img
                          src={URL.createObjectURL(coverImage)}
                          className="img-display-after cover-image"
                        ></img>
                      ) : (
                        <>
                          <div className="img-display-before cover-image"></div>
                          <div class="top-right">
                            <div className="cover-icon">
                              <i className="bi bi-image"></i>
                            </div>
                          </div>
                        </>
                      )}
                      <input
                        type="file"
                        ref={inputRefCover}
                        onChange={handleCoverImageChange}
                        style={{ display: "none" }}
                        accept="image/png, image/jpeg, image/jpg, image/webp"
                      ></input>
                    </div>
                  </div>
                  <div className="header-row">
                    <div
                      className="profile-image-name-row"
                      style={{
                        alignItems: isValidVerifiedName
                          ? "center"
                          : "flex-start",
                      }}
                    >
                      {profileImage === null && currentProfImg ? (
                        <img
                          src={currentProfImg}
                          className="img-display-after profile-image"
                          onClick={handleProfileImageClick}
                        ></img>
                      ) : profileImage ? (
                        <img
                          src={URL.createObjectURL(profileImage)}
                          className="img-display-after profile-image"
                          onClick={handleProfileImageClick}
                        ></img>
                      ) : (
                        <div className="d-flex">
                          <img
                            src={userImage}
                            className="img-display-before profile-image"
                            onClick={handleProfileImageClick}
                          ></img>
                          <i
                            class="bi bi-camera-fill"
                            onClick={handleProfileImageClick}
                          ></i>
                        </div>
                      )}
                      <input
                        type="file"
                        ref={inputRefProfile}
                        onChange={handleProfileImageChange}
                        style={{ display: "none" }}
                        accept="image/png, image/jpeg, image/jpg, image/webp"
                      ></input>

                      <div className="bucket-verified-name">
                        {mooflifeAdmin && (
                          <select onChange={selectBucket} value={cloudBucket}>
                            <option value="DO">DigitalOcean</option>
                            <option value="CF">Cloudflare</option>
                          </select>
                        )}

                        <div className="profile-name">
                          <label className="title-2">Verified Name</label>
                          {mooflifeAdmin ? (
                            <input
                              className="form-input"
                              type="text"
                              placeholder="Verified Name"
                              id="verified_name"
                              onChange={handleLifeValueChange}
                              value={lifeValues.verified_name}
                              style={{ width: "150px" }}
                            ></input>
                          ) : (
                            <>
                              :{" "}
                              <span style={{ color: "#3787FF" }}>
                                {lifeValues.verified_name}
                              </span>
                            </>
                          )}
                          {isValidVerifiedName === false && (
                            <div className="error-message">
                              <span>
                                <i class="bi bi-exclamation-circle"></i>
                              </span>
                              Please add a valid verified name
                            </div>
                          )}
                        </div>
                      </div>

                      <div
                        className="title-3 subscription-word"
                        style={{ marginLeft: "30px" }}
                      >
                        Subscription :{" "}
                        <span>{lifeValues.current_subscription}</span>
                      </div>
                      {mooflifeAdmin &&(<div>
                        <div className="title-2 life-tag">
                        <label
                          className="title-2"
                          style={{ width: "65px", color: "black" }}
                        >
                          Sub Category
                        </label>
                        <Select
                          isMulti
                          onChange={handleChangeSubCategory}
                          value={subCategory}
                          onInputChange={handleSubCategoryInputChange}
                          inputValue={subCategoryInputValue}
                          onKeyDown={handleSubCategoryKeyDown}
                          placeholder="0000"
                          className="select"
                        />
                      </div>

                      <div className="input-keyword-row">
                        <div className="keyword-container">
                          <input
                            className="form-input"
                            type="text"
                            value={subCategoryInputValue}
                            onChange={(e) => setSubCategoryInputValue(e.target.value)}
                            placeholder="0000"
                          />
                          <button
                            className="yellow-btn"
                            onClick={handleAddSubCategoryKeyword}
                          >
                            Add
                          </button>
                        </div>
                        <div className="keywords">
                          {subCategory.map((tag, index) => (
                            <span key={index} style={{ marginRight: "5px" }}>
                              {tag.label}{" "}
                              <span
                                className="close-icon"
                                onClick={() => handleRemoveSubCategoryKeyword(tag.label)}
                              >
                                &#x2716;
                              </span>
                            </span>
                          ))}
                        </div>
                      </div>
                      </div>)}
                    </div>

                    <div className="profile-tag-row">
                      <div className="profile-name">
                        <label className="title-2">Life Name</label>
                        <input
                          className="form-input"
                          type="text"
                          placeholder="Life Name"
                          id="life_name"
                          value={lifeValues.life_name}
                          onChange={handleLifeValueChange}
                          maxLength="30"
                        ></input>
                      </div>
                      <div className="life-title">
                        <label className="title-2">Life Title</label>
                        {life_type === "EL" ? (
                          <span>
                            <input
                              className="form-input"
                              type="text"
                              placeholder="Ex: Automotive Manufacturer"
                              id="life_title"
                              value={lifeValues.life_title}
                              onChange={handleLifeValueChange}
                              maxLength="40"
                            ></input>
                          </span>
                        ) : life_type === "PL" ? (
                          <span>
                            <input
                              className="form-input"
                              type="text"
                              placeholder="Ex: Vocalist / Dancer / Actor"
                              id="life_title"
                              value={lifeValues.life_title}
                              onChange={handleLifeValueChange}
                              maxLength="40"
                            ></input>
                          </span>
                        ) : (
                          <span>
                            <input
                              className="form-input"
                              type="text"
                              placeholder="Ex: County"
                              id="life_title"
                              value={lifeValues.life_title}
                              onChange={handleLifeValueChange}
                              maxLength="40"
                            ></input>
                          </span>
                        )}
                      </div>
                      <div className="title-2 life-tag">
                        <label
                          className="title-2"
                          style={{ width: "65px", color: "black" }}
                        >
                          HashTags
                        </label>
                        <Select
                          isMulti
                          onChange={handleChange}
                          value={selectedTags}
                          onInputChange={handleInputChange}
                          inputValue={inputValue}
                          onKeyDown={handleKeyDown}
                          placeholder="#Graduation #Family Moment"
                          className="select"
                        />
                      </div>

                      <div className="input-keyword-row">
                        <div className="keyword-container">
                          <input
                            className="form-input"
                            type="text"
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            placeholder="#Graduation #Family Moment"
                          />
                          <button
                            className="yellow-btn"
                            onClick={handleAddKeyword}
                          >
                            Add
                          </button>
                        </div>
                        <div className="keywords">
                          {selectedTags.map((tag, index) => (
                            <span key={index} style={{ marginRight: "5px" }}>
                              {tag.label}{" "}
                              <span
                                className="close-icon"
                                onClick={() => handleRemoveKeyword(tag.label)}
                              >
                                &#x2716;
                              </span>
                            </span>
                          ))}
                        </div>
                      </div>

                      <div className="title-3">
                        Life Type :{" "}
                        <span>{displayLifeTypeInFull(life_type)}</span>
                      </div>

                      <div className="title-3">
                        Life ID : <span>{life_Id}</span>
                      </div>

                      <div className="title-4">
                        <label for="cars">
                          <i
                            class="bi bi-people-fill"
                            style={{ fontSize: "20px" }}
                          ></i>
                        </label>

                        <select
                          className="public-private-select"
                          id="life_privacy"
                          value={lifeValues.life_privacy}
                          onChange={handleLifeValueChange}
                        >
                          <option value="public">Public</option>
                          <option value="private">Private</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
            <hr></hr>
            <div className="life-update-form">
              <Card className="card">
                <Row>
                  <Col sm={4} className="col-1">
                    <div>
                      <p>Profile description</p>
                      <textarea
                        className="form-text-area"
                        type="text"
                        placeholder="Ex: Michael Jackson was an American singer, songwriter, and dancer who was the most popular entertainer in the world in the early and mid-1980s. Even today he is widely regarded as the “King of Pop.”"
                        maxLength="2000"
                        value={lifeValues.life_description}
                        id="life_description"
                        onChange={handleLifeValueChange}
                      ></textarea>
                    </div>
                  </Col>
                  <Col className="col-2" sm={8}>
                    <div className="form-part">
                      <Form>
                        {life_type === "PL" ? (
                          <div className="form">
                            <div className="form-field">
                              <div>
                                <p>Web</p>
                                <input
                                  className="form-input"
                                  type="text"
                                  placeholder="Web link"
                                  id="web_link"
                                  value={lifeValues.web_link}
                                  onChange={handleLifeValueChange}
                                ></input>
                              </div>
                            </div>
                            <div className="form-field">
                              <div>
                                <p className="d-flex">
                                  Date of Birth
                                  <span onClick={handleDateSelection}>
                                    <i
                                      class="bi bi-calendar-week-fill"
                                      style={{
                                        color: "#CCCCCC",
                                        fontSize: "15px",
                                        marginLeft: "10px",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </span>
                                  {mooflifeAdmin && (
                                    <>
                                      <div style={{ marginLeft: "5px" }}>
                                        <Form.Check
                                          type="radio"
                                          className="yellow-checkbox"
                                          id="shareable"
                                          checked={clickCheckbox}
                                          onClick={handlePermission}
                                        />
                                      </div>
                                    </>
                                  )}
                                </p>
                                {showCalendar && (
                                  <div
                                    ref={calendarRef}
                                    className="calendar"
                                    style={{
                                      position: "absolute",
                                      zIndex: "1",
                                      marginTop: "50px",
                                    }}
                                  >
                                    <Calendar
                                      className="calendar"
                                      onChange={handleDateValues}
                                    />
                                  </div>
                                )}
                                {clickCheckbox && mooflifeAdmin && (
                                  <Col className="d-flex date-picker">
                                    <input
                                      className="date"
                                      type="number"
                                      placeholder="DD"
                                      maxLength={2}
                                      style={{
                                        borderRadius: "5px 0px 0px 5px",
                                      }}
                                      id="lifeDayPL"
                                      onChange={setDateValues}
                                      value={day}
                                    ></input>
                                    <input
                                      className="date"
                                      type="number"
                                      placeholder="MM"
                                      maxLength={2}
                                      id="lifeMonthPL"
                                      onChange={setDateValues}
                                      value={month}
                                    ></input>
                                    <input
                                      className="date"
                                      type="number"
                                      placeholder="YYYY"
                                      maxLength={4}
                                      style={{
                                        borderRadius: "0px 5px 5px 0px",
                                      }}
                                      id="lifeYearPL"
                                      onChange={setDateValues}
                                      value={year}
                                    ></input>
                                  </Col>
                                )}

                                {!clickCheckbox && (
                                  <Col className="d-flex date-picker">
                                    <input
                                      className="date"
                                      type="text"
                                      placeholder="DD/MM/YYYY"
                                      style={{
                                        borderRadius: "5px 0px 0px 5px",
                                      }}
                                      value={fullDate}
                                      disabled={true}
                                    ></input>
                                  </Col>
                                )}

                                <select
                                  className="form-input"
                                  style={{ marginTop: "20px" }}
                                  value={lifeValues.bday_display}
                                  id="bday_display"
                                  onChange={handleLifeValueChange}
                                >
                                  <option value="show">Show Birthday</option>
                                  <option value="no-year">Hide Year</option>
                                  <option value="hide">Hide Birthday</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        ) : life_type === "OL" ? (
                          <>
                            <div className="form">
                              <div className="form-field">
                                <div>
                                  <p>Founder(s)</p>
                                  <input
                                    className="form-input"
                                    type="text"
                                    placeholder="John Smith"
                                    value={lifeValues.founders[0]}
                                    onChange={handleFounders}
                                  ></input>
                                </div>
                              </div>
                              <div className="form-field">
                                <div>
                                  <p>Founded In</p>
                                  <input
                                    className="form-input"
                                    type="text"
                                    placeholder="Indiana"
                                    id="founded_in"
                                    value={lifeValues.founded_in}
                                    onChange={handleLifeValueChange}
                                  ></input>
                                </div>
                              </div>
                              <div className="form-field">
                                <p className="d-flex">
                                  Since
                                  <span onClick={handleDateSelection}>
                                    <i
                                      class="bi bi-calendar-week-fill"
                                      style={{
                                        color: "#CCCCCC",
                                        fontSize: "15px",
                                        marginLeft: "10px",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </span>
                                  {mooflifeAdmin && (
                                    <>
                                      <div style={{ marginLeft: "5px" }}>
                                        <Form.Check
                                          type="radio"
                                          className="yellow-checkbox"
                                          id="shareable"
                                          checked={clickCheckbox}
                                          onClick={handlePermission}
                                        />
                                      </div>
                                    </>
                                  )}
                                </p>
                                {showCalendar && (
                                  <div
                                    ref={calendarRef}
                                    className="calendar"
                                    style={{
                                      position: "absolute",
                                      zIndex: "1",
                                      marginTop: "50px",
                                    }}
                                  >
                                    <Calendar
                                      className="calendar"
                                      onChange={handleDateValues}
                                    />
                                  </div>
                                )}
                                {clickCheckbox && mooflifeAdmin && (
                                  <div className="d-flex date-picker">
                                    <input
                                      className="date"
                                      type="number"
                                      placeholder="DD"
                                      maxLength={2}
                                      style={{
                                        borderRadius: "5px 0px 0px 5px",
                                      }}
                                      id="lifeDay"
                                      onChange={setDateValues}
                                      value={day}
                                    ></input>
                                    <input
                                      className="date"
                                      type="number"
                                      placeholder="MM"
                                      maxLength={2}
                                      id="lifeMonth"
                                      onChange={setDateValues}
                                      value={month}
                                    ></input>
                                    <input
                                      className="date"
                                      type="number"
                                      placeholder="YYYY"
                                      maxLength={4}
                                      style={{
                                        borderRadius: "0px 5px 5px 0px",
                                      }}
                                      id="lifeYear"
                                      onChange={setDateValues}
                                      value={year}
                                    ></input>
                                  </div>
                                )}

                                {!clickCheckbox && (
                                  <Col className="d-flex date-picker">
                                    <input
                                      className="date"
                                      type="text"
                                      placeholder="DD-MM-YYYY"
                                      style={{
                                        borderRadius: "5px 0px 0px 5px",
                                      }}
                                      value={fullDate}
                                      disabled={true}
                                    ></input>
                                  </Col>
                                )}
                              </div>
                              <div className="form-field">
                                <div>
                                  <p>Web</p>
                                  <input
                                    className="form-input"
                                    type="text"
                                    placeholder="Your organization website link"
                                    id="website_link"
                                    value={lifeValues.web_link}
                                    onChange={handleLifeValueChange}
                                  ></input>
                                </div>
                              </div>
                              <div className="form-field">
                                <div>
                                  <p>Status</p>
                                  <select
                                    className="form-input"
                                    style={{ color: "#B8B5B5" }}
                                    id="is_operating"
                                    value={lifeValues.is_operating}
                                    onChange={handleLifeValueChange}
                                  >
                                    <option value="true">Operating</option>
                                    <option value="false">Not Operating</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div>
                            <div style={{ marginRight: "100px" }}>
                              <div>
                                <p>Web</p>
                                <input
                                  className="form-input"
                                  type="text"
                                  id="website_link_org"
                                  placeholder="Your organization website link"
                                  value={lifeValues.web_link}
                                  onChange={handleLifeValueChange}
                                ></input>
                              </div>
                            </div>
                            <Col></Col>
                          </div>
                        )}
                      </Form>
                    </div>

                    {!isCreateLife && (
                      <Row>
                        <div className="person-list">
                          <div className="person-1">
                            <div className="admin">
                              <h6>Account Administrator</h6>
                              <img
                                src={
                                  accountManager.image_url !== ""
                                    ? accountManager.image_url
                                    : images.UserImage
                                }
                              ></img>
                              <p>
                                {accountManager.first_name}{" "}
                                {accountManager.last_name}
                              </p>
                            </div>
                          </div>
                          <div class="vl"></div>
                          <div className="person-1">
                            <h6>Life Administrators</h6>
                            {lifeManagers.length > 0 && (
                              <div className="life-admin-set">
                                {lifeManagers.map((user, index) => (
                                  <div key={index} className="admin">
                                    <img
                                      src={
                                        user.image_url !== ""
                                          ? user.image_url
                                          : images.UserImage
                                      }
                                    ></img>
                                    <p>
                                      {user.first_name} {user.last_name}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </Row>
                    )}
                  </Col>
                </Row>

                <div className="button-part">
                  {isCreateLife ? (
                    <button
                      type="submit"
                      className="publish-btn yellow-btn"
                      onClick={() => createLife()}
                      disabled={
                        lifeValues.life_name === "" ||
                        lifeValues.current_subscription === "" ||
                        lifeValues.life_type === "" ||
                        lifeValues.life_id === "" ||
                        loading ||
                        isValidVerifiedName === false
                      }
                    >
                      {loading ? (
                        <Spinner
                          animation="border"
                          variant="light"
                          style={{ width: "15px", height: "15px" }}
                        />
                      ) : (
                        "Create"
                      )}
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="publish-btn yellow-btn"
                      onClick={() => updateExistingLife()}
                      disabled={
                        lifeValues.life_name === "" ||
                        lifeValues.life_type === "" ||
                        lifeValues.life_id === "" ||
                        loading ||
                        isValidVerifiedName === false
                      }
                    >
                      {loading ? (
                        <Spinner
                          animation="border"
                          variant="light"
                          style={{ width: "15px", height: "15px" }}
                        />
                      ) : (
                        "Save Updates"
                      )}
                    </button>
                  )}

                  <button
                    type="submit"
                    className="cancel-btn"
                    disabled={loading}
                    onClick={() => navigate(-1)}
                  >
                    {/* // navigate(-1) */}
                    Cancel
                  </button>
                </div>
              </Card>
            </div>
          </div>
        </div>
        <div className="d-flex footer-and-button">
          <div className="footer-part">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateLifePage;
