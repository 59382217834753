import React, { useState, useEffect, useRef } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import "../../styles/LifeLeftStyle.css";
import "../../styles/AdvanceSearchStyle.css";
import "../../styles/CommonStyle.css";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSearchResults,
  resetLifeAndMomentSearchData,
  resetLifeAndMomentSearchPage,
} from "../../store/action/SearchAction";
import { getSearchSuggestion } from "../../store/action/LifeAction";
import { getSelectedMoments } from "../../store/action/MomentAction";
import { getFromSessionStorage } from "../../utils/storageHandler";

const AdvanceSearch = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [fromYear, setFromYear] = useState("");
  const [toYear, setToYear] = useState("");
  const [words, setWords] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showFromYearDropdown, setFromYearDropdown] = useState(false);
  const [showToYearDropdown, setToYearDropdown] = useState(false);
  const searchSuggetion = useSelector((state) => state.Life.searchSuggetion);
  const [userLogin, setUserLogin] = useState("");
  const [fromYearEra, setFromYearEra] = useState("CE");
  const [toYearEra, setToYearEra] = useState("CE");

  const inputDropdownRef = useRef(null);
  const fromYearDropdownRef = useRef(null);
  const toYearDropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        inputDropdownRef.current &&
        !inputDropdownRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
      if (
        fromYearDropdownRef.current &&
        !fromYearDropdownRef.current.contains(event.target)
      ) {
        setFromYearDropdown(false);
      }
      if (
        toYearDropdownRef.current &&
        !toYearDropdownRef.current.contains(event.target)
      ) {
        setToYearDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const userDetails = getFromSessionStorage("userDetails", true);
    setUserLogin(userDetails);
  }, []);

  const handleSearch = async () => {
    let from = fromYear;
    let to = toYear;

    // if (fromYear > toYear) {
    //   from = toYear;
    //   to = fromYear;
    // }

    handleAddKeywords();

    dispatch(resetLifeAndMomentSearchData());
    dispatch(resetLifeAndMomentSearchPage());

    dispatch(
      await fetchSearchResults({
        keyword: words,
        fromYear: handleYears(from),
        fromYearEra: fromYearEra,
        toYear: handleYears(to),
        toYearEra: toYearEra,
        page: 1,
        user_email: userLogin?.email? userLogin.email:"",
        sorting: -1
      })
    )
      .then((res) => {
        if (res) {
          navigate(`/search`, {
            state: { keyword: words, fromYear: fromYear, fromYearEra: fromYearEra, 
              toYearEra: toYearEra, toYear: toYear },
          });
        } else {
          console.log("not found");
        }
      })
      .catch((error) => {
        console.error("Error in search:", error);
      });
  };

  const handleAddKeywords = () => {
    // From Year - To Year
    if (fromYear != "" && toYear != "") {
      const keywordText = `${fromYear} - ${toYear}`;
      setSearchKeywords("fromAndToYear", keywordText);
    } else {
      // From - Present
      if (fromYear !== "") {
        const keywordText = `${fromYear} - Present`;
        setSearchKeywords("fromYear", keywordText);
      }

      // Past - ToYear
      if (toYear !== "") {
        const keywordText = `Past - ${toYear}`;
        setSearchKeywords("toYear", keywordText);
      }
    }

    // Word
    if (words !== "") {
      const keywordText = `${words}`;
      setSearchKeywords("keyword", keywordText);
    }
    
    startMomentSearch();
  };

  const setSearchKeywords = (itemName, keywordText) => {
    setKeywords((prevKeywords) => {
      const keywordIndex = prevKeywords.findIndex(
        (item) => item.type === itemName
      );
      let updatedKeywords = [...prevKeywords];

      if (keywordText === "") {
        updatedKeywords = updatedKeywords.filter(
          (item) => item.type !== itemName
        );
      }

      if (itemName === "fromAndToYear") {
        updatedKeywords = updatedKeywords.filter(
          (item) => item.type !== "fromYear" && item.type !== "toYear"
        );
      } else if (itemName === "fromYear") {
        updatedKeywords = updatedKeywords.filter(
          (item) => item.type !== "fromAndToYear" && item.type !== "toYear"
        );
      } else if (itemName === "toYear") {
        updatedKeywords = updatedKeywords.filter(
          (item) => item.type !== "fromAndToYear" && item.type !== "fromYear"
        );
      }

      if (keywordIndex !== -1) {
        // If 'keyword' already exists, update its value
        updatedKeywords[keywordIndex] = { type: itemName, value: keywordText };
        return updatedKeywords;
      } else {
        // if 'keyword' not exists
        return [...updatedKeywords, { type: itemName, value: keywordText }];
      }
    });
  };

  const handleRemoveKeyword = (type) => {
    setShowDropdown(false);
    setFromYearDropdown(false);
    setToYearDropdown(false);

    if (type === "keyword") {
      setWords("");
    } else if (type === "fromYear") {
      setFromYear("");
      setFromYearEra("CE");
    } else if (type === "toYear") {
      setToYear("");
      setToYearEra("CE");
    } else if (type === "fromAndToYear") {
      setFromYear("");
      setToYear("");
      setFromYearEra("CE");
      setToYearEra("CE");
    }
    setKeywords((prevKeywords) =>
      prevKeywords.filter((item) => item.type !== type)
    );
   
    startMomentSearch(type);
  };

  const startMomentSearch = (avoidType) => {
    const searchQuery = {
      selector: {},
      page: 1,
      limit: 10,
      user_email: userLogin?.email ? userLogin.email : ""
    };

    if (words && words !== "" && avoidType !== "keyword") {
      searchQuery.selector.search_keyword = words;
    }

    if ( fromYear && fromYear !== "" && avoidType !== "fromYear" && avoidType !== "fromAndToYear" ) {
      searchQuery.selector.fromYear = fromYear;
    }

    if ( toYear && toYear !== "" && avoidType !== "toYear" && avoidType !== "fromAndToYear" ) {
      searchQuery.selector.toYear = toYear;
    }

   // dispatch(getSelectedMoments(searchQuery, 1, "search"));
  };

  const handleDropdownItem = (lifeId) => {
    navigate(`/${lifeId}`);
  };

  const handleSearchSuggestion = (input) => {
    dispatch(getSearchSuggestion({ input: input, user_email: userLogin?.email?userLogin.email: ""  }));
  };

  const handleFromYearItem = (selectedYear) => {
    setFromYear(selectedYear);
  };

  const handleToYearItem = (selectedToYear) => {
    setToYear(selectedToYear);
  };

  const pressEnterButton = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  }

  const fromYearList = [
    {
      id: 1,
      year: "1750",
    },
    {
      id: 2,
      year: "1800",
    },
    {
      id: 3,
      year: "1850",
    },
    {
      id: 4,
      year: "1950",
    },
    {
      id: 5,
      year: "1975",
    },
  ];

  const toYearList = [
    {
      id: 1,
      year: "1800",
    },
    {
      id: 2,
      year: "1900",
    },
    {
      id: 3,
      year: "1990",
    },
    {
      id: 4,
      year: "2000",
    },
    {
      id: 5,
      year: "2020",
    },
  ];

  const getSuggestionWhenClick = () => {
    if (showDropdown === false && !words.length > 0) {
      dispatch(getSearchSuggestion({ count: 0, user_email: userLogin?.email?userLogin.email: ""  }));
    }
    setShowDropdown((prevState) => !prevState);
  };

  const handleYears = (year) => {
    let yearVal = year;
    if (year && year !== "") {
      if (year < 10) {
        yearVal = `000${year}`;
      } else if (year < 100) {
        yearVal = `00${year}`;
      } else if (year < 1000) {
        yearVal = `0${year}`;
      } else {
        yearVal = year.toString();
      }
    } else {
      yearVal = year;
    }
    return yearVal;
  };

  const handleYearEra = (evt, type) => {
    if(type === 'from'){
      setFromYearEra(evt.target.value);
    }else {
      setToYearEra(evt.target.value);
    }
  }

  return (
    <div className="advance-search-main">
      <div className="quick-search">
        <Card className="card">
          <Row className="search-row">
            <Col className="search-col" ref={inputDropdownRef}>
              <div>
                <input
                  id="searchText"
                  className={`search-moment ${
                    showDropdown && !words.length > 0
                      ? "expanded"
                      : "search-moment"
                  }`}
                  type="text"
                  placeholder="Search Lives and Moments"
                  value={words}
                  onChange={(e) => {
                    setShowDropdown(true);
                    setWords(e.target.value);
                    handleSearchSuggestion(e.target.value);
                  }}
                  onClick={() => {
                    getSuggestionWhenClick();
                  }}
                  onKeyPress={pressEnterButton}
                />
              </div>
              {showDropdown === true &&
                keywords.length === 0 &&
                searchSuggetion &&
                searchSuggetion.length > 0 && (
                  <div className="search-dropdown-content">
                    {searchSuggetion.map((searchLife, index) => (
                      <div className="d-flex dropdown-item menu-items">
                        <div className="item">
                          <Row
                            onClick={() =>
                              handleDropdownItem(searchLife.verified_name?searchLife.verified_name:searchLife.life_id)
                            }
                          >
                            <Col>
                              <img
                                src={searchLife.life_logo}
                                className="search-dropdown-user"
                              ></img>
                            </Col>
                            <Col className="search-dropdown-name">
                              <div>{searchLife.life_name}</div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
            </Col>
          </Row>
          <Row className="year-row">
            <Col className="fromYear" sm={6} ref={fromYearDropdownRef}>
              <div>
                <input
                  type="number"
                  className={`fromYear ${
                    showFromYearDropdown && fromYear === ""
                      ? "expanded"
                      : "fromYear"
                  }`}
                  placeholder="From Year"
                  style={{ marginRight: "5px" }}
                  value={fromYear}
                  maxLength="4"
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 4);
                    setFromYear(e.target.value);
                  }}
                  onClick={() => {
                    setFromYearDropdown((prevState) => !prevState);
                  }}
                  onKeyPress={pressEnterButton}
                />
                {showFromYearDropdown === true && fromYear === "" && (
                  <div className="from-year-dropdown-content">
                    {fromYearList.map((fromyear, index) => (
                      <div
                        key={index}
                        className="d-flex dropdown-item menu-items"
                        onClick={() => handleFromYearItem(fromyear.year)}
                      >
                        <div className="item">
                          <Row>
                            <div
                              className="search-dropdown-name"
                              onClick={() => setFromYearDropdown(false)}
                            >
                              {fromyear.year}
                            </div>
                          </Row>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {/* <select
                className="search-input"
                onChange={(evt) => handleYearEra(evt, "from")}
                value={fromYearEra}
              >
                <option value="CE">CE</option>
                <option value="BCE">BCE</option>
              </select> */}
            </Col>
            <Col className="toYear" sm={6} ref={toYearDropdownRef}>
              <div>
                <input
                  type="number"
                  className={`toYear ${
                    showToYearDropdown && toYear === "" ? "expanded" : "toYear"
                  }`}
                  placeholder="To Year"
                  value={toYear}
                  maxlength="4"
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 4);
                    setToYear(e.target.value);
                  }}
                  onClick={() => {
                    setToYearDropdown((prevState) => !prevState);
                  }}
                  onKeyPress={pressEnterButton}
                />

                {showToYearDropdown === true && toYear === "" && (
                  <div className="from-year-dropdown-content">
                    {toYearList.map((toyear, index) => (
                      <div
                        key={index}
                        className="d-flex dropdown-item menu-items"
                        onClick={() => handleToYearItem(toyear.year)}
                      >
                        <div className="item">
                          <Row>
                            <Col
                              className="search-dropdown-name"
                              onClick={() => setToYearDropdown(false)}
                            >
                              {toyear.year}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {/* <select
                className="search-input"
                onChange={(evt) => handleYearEra(evt, "to")}
                value={toYearEra}
              >
                <option value="CE">CE</option>
                <option value="BCE">BCE</option>
              </select> */}
            </Col>
          </Row>
          <Row>
            <Button
              className="search-button blue-btn"
              onClick={handleSearch}
              disabled={!words.length > 0 && fromYear === "" && toYear === ""}
              style={{ marginTop: "10px" }}
            >
              Search
            </Button>
          </Row>

          <Row>
            <div className="keywords-container">
              {keywords.map((keyword, index) => (
                <button key={index} className="keyword-button">
                  {keyword.value}
                  <span
                    className="close-icon"
                    onClick={() => handleRemoveKeyword(keyword.type)}
                  >
                    &#x2716;
                  </span>
                </button>
              ))}
            </div>
          </Row>
        </Card>
      </div>
    </div>
  );
};

export default AdvanceSearch;
