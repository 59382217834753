import React, { useState, useRef } from "react";
import moment from "moment";
import Card from "react-bootstrap/Card";
import "../../styles/TopMomentsStyle.css";

import { useEffect } from "react";
import { fetchTopMoments } from "../../store/action/TopMomentsAction";
import { useDispatch, useSelector } from "react-redux";
import { images } from "../../utils/image";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import VideoPlayer from "../../components/VideoPlayer.jsx";

const TopMoments = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.toLocaleString("en-US", { month: "long" });
  const currentDay = currentDate.toLocaleString("en-US", { day: "2-digit" });

  //Get details from Redux - MostViewdMomentSlice
  const dispatch = useDispatch();
  const TopMoments = useSelector((state) => state.TopMoments.TopMoments);
  const isInfinite = TopMoments.length < 2;

  useEffect(() => {
    const momentDate = moment(currentDate).format("YYYY-MM-DD");
    dispatch(fetchTopMoments(momentDate));
  }, []);

  const sliderRef = useRef(null);

  const handlePrevClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const handleNextClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const settings = {
    dots: false,
    speed: 5000,
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: isInfinite ? false : true,
    autoplay: true,
    autoplaySpeed: 10000,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],

    prevArrow: TopMoments.length > 1 && (
      <button onClick={handlePrevClick}>
        <div className="rotate-180"></div>
      </button>
    ),

    nextArrow: TopMoments.length > 1 && (
      <button onClick={handleNextClick}>
        <div className="next-btn"></div>
      </button>
    ),
  };

  return (
    <>
      {TopMoments.length > 0 && (
        <div className="top-moments-main">
          <div className="content">
            <div className="date-card">
              <div className="img-container">
                <Link to="/top-moment-grid">
                  <Card className="info-card">
                    <Card.Body>
                      <div className="top-date-month">
                        <Card.Text className="date">{currentDay}</Card.Text>
                        <Card.Text className="month">{currentMonth}</Card.Text>
                      </div>

                      <Card.Text className="topic">
                        {" "}
                        Moments in History{" "}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Link>
              </div>
            </div>

            <div className="slider">
              <Slider {...settings} ref={sliderRef}>
                {TopMoments?.map(
                  (topmoment, index) =>
                    topmoment.images?.length > 0 && (
                      <div class="full-image" key={index}>
                        <Link
                          to={`/${
                            topmoment.life.verified_name
                              ? topmoment.life.verified_name
                              : topmoment.life?.life_id
                          }/${topmoment._id}`}
                          target="_blank"
                        >
                          {topmoment.images[0]?.media_type === "image" ? (
                            <img
                              className="topmoment-image"
                              src={
                                topmoment.images?.length > 0
                                  ? topmoment.images[0].url
                                  : null
                              }
                              alt="topmomentImage"
                            />
                          ) : (
                            <div>
                              <VideoPlayer
                                className="videoFrame"
                                source={topmoment.images[0].url}
                              />
                            </div>
                          )}
                          <div class="bottom-left">
                            <p className="title-1">
                              {topmoment?.moment_date?.split("-")[2]} :{" "}
                              {topmoment.moment_title}
                            </p>
                            <p className="title-2">
                              {topmoment.moment_date} : {topmoment.moment_title}
                            </p>
                          </div>
                        </Link>
                        <div class="overlay"></div>
                      </div>
                    )
                )}
              </Slider>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TopMoments;
