import moofLifeLogo from '../assets/moof-life.webp';

import moofLifeLogoTran from '../assets/mooflife-logo-tran.webp';
import eye from '../assets/eye.webp';
import eyeSlash from '../assets/eyeSlash.webp';

//Navbar
import search1 from "../assets/search1.webp";
import search2 from "../assets/search2.webp";
import login from "../assets/login.webp";
import logout from "../assets/logout.webp";

//Middle Landing Page
import topNext from "../assets/top-next.webp";
import sortAsc from "../assets/sort.png";
import sortDec from "../assets/sort2.png";
import CopyIcon from "../assets/copy.webp";
import UserImage from "../assets/userimage.webp";
import VerifiedIcon from "../assets/verified.webp";
import CountryMoment from "../assets/countryMoment.webp";
import TopMoment from "../assets/top.webp";
import MoofIcon from "../assets/moof.webp";
import like from "../assets/like.webp";
import great from "../assets/great.webp";
import emotional from "../assets/emotional.webp";
import nostalgic from "../assets/nostalgic.webp";
import wish from "../assets/wish.webp";
import part from "../assets/part.webp";
import witnessed from "../assets/witnessed.webp";
import CommentIcon from "../assets/comment.webp";
import share from "../assets/share.webp";
import shareIcon from "../assets/share-icon.webp"
import telegram from "../assets/Telegram.webp";
import ImpressionsIcon from "../assets/impressions.webp";
import SustainabilityMoment from "../assets/sustainabilityMoment.webp";
import whatsapp from "../assets/whatsapp.webp";
import facebookShare from "../assets/facebook-share.webp";
import linkdin from "../assets/linkdin.webp";
import mask from "../assets/mask.webp";
import GlobalMoment from "../assets/global.webp";
import commentLike from '../assets/commentlike.webp';
import commentDislike from '../assets/commentdislike.webp';
import closeIcon from "../assets/closeIcon.webp";

//Contact Us
import ContactUsFacebook from "../assets/c-facebook .webp";
import ContactUsInstagram from "../assets/c-insta.webp";
import ContactUsLinkedin from "../assets/c-linkdin.webp";
import ContactUsTiktok from "../assets/c-tiktok.webp";
import ContactUsYoutube from "../assets/c-youtube.webp";
import ContactUsMask from "../assets/Mask group.webp";
import ContactUsSnapchat from "../assets/Snapchat.webp";
import ContactUsMail from "../assets/mail.webp";
import ContactUsCall from "../assets/call.webp";
import ContactUsLocation from "../assets/place.webp";

// Mooflife Concept
import lifeTogether from "../assets/life-together.webp";

//User settings
import fav from "../assets/fav.png";
import access from "../assets/access.png";
import subscription from "../assets/subscription.png";
import admin from "../assets/admin.png";

//Update Moment
import uploadImg from "../assets/upload-img.png";
import close from "../assets/close.png";
import back from "../assets/previous.webp";
import privateIcon from "../assets/private.png";
import publicIcon from "../assets/public.png";

//User Settings
import addAdmin from "../assets/addAdmin.png";

//New Landing Page
//logo
import moofLogoTran from "../assets/LandingPage/moof-logo.png";
import moofLogo from "../assets/mooflife-logo-tran.webp";
//Top Moment
import nextImage from "../assets/LandingPage/next.png";
//subscription page
import check from "../assets/check.png";
import cancel from "../assets/cancel.png";

import successImage from "../assets/success.webp";

const mooflife_image_url = "https://mls3.mooflife.com";


export const images = {

    moofLifeLogo: moofLifeLogo,
    moofLifeLogoTran: moofLifeLogoTran,
    googleAdd1: `${mooflife_image_url}/Ads/Explor_past.webp`,
    googleAdd2: `${mooflife_image_url}/Ads/history_travel.webp`,
    googleAdd3: `${mooflife_image_url}/Ads/write_history.webp`,
    eye:eye,
    eyeSlash:eyeSlash,

    //Navbar
    search1 : search1,
    search2 : search2,
    login : login,
    logout : logout,

    //Middle Page
    topNext:topNext,
    sortAsc: sortAsc,
    sortDec: sortDec,
    CopyIcon: CopyIcon,
    UserImage: UserImage,
    VerifiedIcon: VerifiedIcon,
    CountryMoment: CountryMoment,
    TopMoment: TopMoment,
    GlobalMoment: GlobalMoment,
    MoofIcon: MoofIcon,
    ImpressionsIcon: ImpressionsIcon,
    SustainabilityMoment: SustainabilityMoment,
    CommentIcon: CommentIcon,
    like: like,
    like: commentLike,
    dislike: commentDislike,
    closeIcon: closeIcon,

    //Impression list
    LikeIcon: like,
    GreatIcon: great,
    EmotionalIcon: emotional,
    NostalgicIcon: nostalgic,
    WishIcon: wish,
    PartOfTheMomentIcon: part,
    WitnessedIcon: witnessed,

    //Share list
    ShareIcon: shareIcon,
    TelegramIcon: telegram,
    WhatsappIcon: whatsapp,
    FacebookIcon: facebookShare,
    LinkedinIcon: linkdin,
    MaskIcon: mask,

    //Contact Us
    ContactUsBg: `${mooflife_image_url}/Corporate/contactus.webp`,
    ContactUsFacebook: ContactUsFacebook,
    ContactUsInstagram: ContactUsInstagram,
    ContactUsLinkedin: ContactUsLinkedin,
    ContactUsTiktok: ContactUsTiktok,
    ContactUsYoutube: ContactUsYoutube,
    ContactUsMask: ContactUsMask,
    ContactUsSnapchat: ContactUsSnapchat,
    ContactUsMail: ContactUsMail,
    ContactUsCall: ContactUsCall,
    ContactUsLocation: ContactUsLocation,

    //About Us
    AboutUsHeader: `${mooflife_image_url}/Corporate/banner.webp`,
    AboutUsTimeline: `${mooflife_image_url}/Corporate/moments.webp`,
    video: `${mooflife_image_url}/Corporate%2FComing%20Soon.mp4`,

    //Mooflife Concept
    mooflifeConcept: `${mooflife_image_url}/Corporate/mooflifeconcept.webp`,
    headerRow: `${mooflife_image_url}/Corporate/historynow.webp`,
    card1: `${mooflife_image_url}/Corporate/share.webp`,
    card2: `${mooflife_image_url}/Corporate/report.webp`,
    card3: `${mooflife_image_url}/Corporate/learn.webp`,
    lifeTogether: lifeTogether,

    //Register
    registerBg: `${mooflife_image_url}/Corporate/login_2.webp`,

    //Forget Password
    forgetPasswordBg: `${mooflife_image_url}/Corporate/login_5.webp`,

    //Email Verification
    emailVerificationBg: `${mooflife_image_url}/Corporate/login_3.webp`,

    //OTP Verification
    otpVerificationBg: `${mooflife_image_url}/Corporate/login_3.webp`,

    //New Password
    newPasswordBg: `${mooflife_image_url}/Corporate/login_5.webp`,
    successImage: successImage,

    //Your Interest
    img1: `${mooflife_image_url}/Corporate/sport.webp`,
    img2: `${mooflife_image_url}/Corporate/business.webp`,
    img3: `${mooflife_image_url}/Corporate/politics.webp`,
    img4: `${mooflife_image_url}/Corporate/stem.webp`,
    img5: `${mooflife_image_url}/Corporate/people.webp`,
    img6: `${mooflife_image_url}/Corporate/sustainability.webp`,
    img7: `${mooflife_image_url}/Corporate/countries.webp`,
    congratulation: `${mooflife_image_url}/Corporate/login_4.webp`,

    //Error Page
    ErrorImage: `${mooflife_image_url}/Corporate/error.webp`,

    //User Settings
    fav:fav,
    access:access,
    subscription:subscription,
    admin:admin,

    //Upload Img
    uploadImg:uploadImg,
    close:close,
    back:back,
    privateIcon:privateIcon,
    publicIcon:publicIcon,

    //user settings
    addAdmin:addAdmin,

    //New Landing Page
    //logo
    moofLogo:moofLogo,
    moofLogoTran:moofLogoTran,
    ad1:`${mooflife_image_url}/Corporate/ad1.png`,
    ad2:`${mooflife_image_url}/Corporate/ad2.png`,
    ad3:`${mooflife_image_url}/Corporate/ad3.png`,
    //Top Moment
    TopMoment1:`${mooflife_image_url}/Corporate/TopMoment1.webp`,
    TopMoment2:`${mooflife_image_url}/Corporate/TopMoment2.webp`,
    TopMoment3:`${mooflife_image_url}/Corporate/TopMoment3.webp`,
    TopMoment4:`${mooflife_image_url}/Corporate/TopMoment4.webp`,
    TopMoment5:`${mooflife_image_url}/Corporate/TopMoment5.webp`,
    TopMoment6:`${mooflife_image_url}/Corporate/TopMoment6.webp`,
    TopMoment7:`${mooflife_image_url}/Corporate/TopMoment7.webp`,
    TopMoment8:`${mooflife_image_url}/Corporate/TopMoment8.webp`,
    TopMoment9:`${mooflife_image_url}/Corporate/TopMoment9.webp`,
    TopMoment10:`${mooflife_image_url}/Corporate/TopMoment10.webp`,
    nextImage:nextImage,
    info1:`${mooflife_image_url}/Corporate/i1.webp`,
    info2:`${mooflife_image_url}/Corporate/i2.webp`,
    info3:`${mooflife_image_url}/Corporate/i3.webp`,
    info4:`${mooflife_image_url}/Corporate/i4.webp`,
    info5:`${mooflife_image_url}/Corporate/i5.webp`,
    info6:`${mooflife_image_url}/Corporate/i6.webp`,
    info7:`${mooflife_image_url}/Corporate/i7.webp`,
    //subscription page
    check:check,
    cancel:cancel,
}