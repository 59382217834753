import { createRequest } from '../../utils/requestHandler';
import { setLife, clearLifeMoments, setLifeMoments, setLimitReached, 
  setPage, setLifeMomentsFromSearch, setLifeMomentSearchCriteria, setLifeMomentLoading,
  setCurrentPage, setSearchSuggestion, setMomentsSorting, getNewLives } from '../slices/LifeSlice';
import { getFromSessionStorage } from '../../utils/storageHandler';
const mooflife_backend = process.env.REACT_APP_BACK_END_URL;

export const fetchLife = (life, action = null) => {
  return async (dispatch, getState) => {
    try {
      const userDetails = await getFromSessionStorage("userDetails", true);
      
      const configs = {
        headers: {
            'Content-Type': 'application/json',
          },
      }
      let body;
      if (life.startsWith('PL') || life.startsWith('EL') || life.startsWith('OL')) {
        body = {
          selector: {life_id: life}, user_email:userDetails?.email?userDetails.email:"", action: action
        }
       
      } else {
        body = {
          selector: {verified_name: life}, user_email:userDetails?.email?userDetails.email:"", action: action
        }
      }

      const response = await createRequest('post', `${mooflife_backend}/life/get-life`, body, configs);
      if(response && response.data && response.data.success && response.data.data){
        dispatch(setLife(response.data.data));
      }

      return response;
       
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
};

export const getSelectedLifeMoments = (data, page) => {
  return async (dispatch, getState) => {
    try { 

      const isLoading = getState().Life.lifeMomentsLoading;
      const searchValues = getState().Life.lifeMomentSearchValues;

      if (isLoading) {
        return;
      }

      if(data.sorting){
        dispatch(setMomentsSorting(data.sorting))
      }

      if(searchValues.search_keyword && searchValues.search_keyword !== ''){
        data.selector.search_keyword = searchValues.search_keyword; 
      }

      if(searchValues.fromYear && searchValues.fromYear !== ''){
        data.selector.fromYear = searchValues.fromYear; 
      }

      if(searchValues.fromYearEra && searchValues.fromYearEra !== ''){
        data.selector.fromYearEra = searchValues.fromYearEra; 
      }

      if(searchValues.toYear && searchValues.toYear !== ''){
        data.selector.toYear = searchValues.toYear; 
      }

      if(searchValues.toYearEra && searchValues.toYearEra !== ''){
        data.selector.toYearEra = searchValues.toYearEra; 
      }

      const configs = {
          headers: {
              'Content-Type': 'application/json',
          },
      }

      
      dispatch(setLifeMomentLoading(true));
      dispatch(addLifeMomentSearchCriteria(data.selector));

      await createRequest('post', `${mooflife_backend}/moment/multiple-selected`, data, configs)
      .then((response) => {
        if(response.data) {
          if(response.data.length === 0){
            dispatch(setLimitReached(true));
          }
          dispatch(setLifeMoments(response.data))
          
          if(page){
            dispatch(setPage(page+1)); 
          }
        }
        dispatch(setLifeMomentLoading(false));
      })
    } catch (error) {
      console.error('Error while getting moments data:', error);
    }
  };
};

export const getLifeMomemntsForSearch = (data, page) => {
  return async (dispatch, getState) => {
    try { 
      const isLoading = getState().Life.lifeMomentsLoading;

      if (isLoading) {
        return;
      }

      const configs = {
          headers: {
              'Content-Type': 'application/json',
            },
      }
  
      dispatch(setLifeMomentLoading(true));
      dispatch(addLifeMomentSearchCriteria(data.selector));

      await createRequest('post', `${mooflife_backend}/moment/multiple-selected`, data, configs)
      .then((response) => {
        if(response.data){
          dispatch(resetLifeMoments());
          dispatch(resetLimitReached());
          if(page){
            dispatch(setPage(page+1));
          }
          
          dispatch(setLifeMomentsFromSearch(response.data));
          dispatch(setLifeMomentLoading(false));
        }
        
      })
    } catch (error) {
      console.error('Error while getting moments data:', error);
    }
  };
}

export const resetLifeMoments = () => {
  return async (dispatch) => {
    try {
      dispatch(clearLifeMoments([]));
    } catch (error) {
      console.error('Error resetting Life Moments:', error);
    }
  };
};

export const resetLife = () => {
  return async (dispatch) => {
    try {
      dispatch(setLife({}));
    } catch (error) {
      console.error('Error resetting Life Moments:', error);
    }
  };
};

export const resetLimitReached = () => {
  return async (dispatch) => {
    try {
      dispatch(setLimitReached(false));
    } catch (error) {
      console.error('Error resetting limit reached:', error);
    }
  };
};

export const resetPage = () => {
  return async (dispatch) => {
    try {
      dispatch(setPage(1));
    } catch (error) {
      console.error('Error resetting page value:', error);
    }
  };
};

export const addLifeMomentSearchCriteria = (data) => {
  return async (dispatch) => {
    try {
      dispatch(setLifeMomentSearchCriteria(data));
    } catch (error) {
      console.error('Error while store life search values:', error);
    }
  };
};

export const getMomentsStartingFromSpecificId = (data) => {
  return async (dispatch, getState) => {
    try {
      const isLoading = getState().Life.lifeMomentsLoading;
      const lifePage = getState().Life.Page;

      data.page = lifePage;

      if (isLoading) {
        return;
      }

      const configs = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      dispatch(setLifeMomentLoading(true));

      await createRequest(
        "post",
        `${mooflife_backend}/moment/shared-moments`,
        data,
        configs
      ).then((response) => {
        if (response.data && response.data.data && response.data.success) {
          if (response.data.data.length === 0) {
            dispatch(setLimitReached(true));
          }
          dispatch(setLifeMoments(response.data.data));

          if (lifePage) {
            dispatch(setPage(lifePage + 1));
          }
        }
        dispatch(setLifeMomentLoading(false));
      });
    } catch (error) {
      console.error("Error while getting moments data:", error);
    }
  };
};

export const getLifeMomentsForSearch = (data, page) => {
  return async (dispatch, getState) => {
    try { 
      const isLoading = getState().Life.lifeMomentsLoading;

      if (isLoading) {
        return;
      }

      if(data.sorting){
        dispatch(setMomentsSorting(data.sorting))
      }

      const configs = {
          headers: {
              'Content-Type': 'application/json',
            },
      }

      dispatch(setLifeMomentLoading(true));
      dispatch(setLifeMomentSearchCriteria(data.selector));

      await createRequest('post', `${mooflife_backend}/moment/multiple-selected`, data, configs)
      .then((response) => {
        if(response.data){
          dispatch(resetLifeMoments());
          dispatch(resetLimitReached());
          if(page){
            dispatch(setPage(page+1));
          }
          dispatch(setLifeMoments(response.data));
           
        }
        dispatch(setLifeMomentLoading(false)); 
      });
    } catch (error) {
      console.error('Error while getting moments data for search:', error);
    }
  };
};

export const getSearchSuggestion = (data, page) => {
  return async (dispatch, getState) => {
    try { 

      const configs = {
          headers: {
              'Content-Type': 'application/json',
            },
      }

      await createRequest('post', `${mooflife_backend}/life/search-suggestion`, data, configs)
      .then((response) => {
        if(response.data){
          dispatch(setSearchSuggestion(response.data.data));
        }
        
      })
    } catch (error) {
      console.error('Error while getting search suggestion:', error);
    }
  };
};

export const getLivesForUser = (data) => {
  return async (dispatch) => {
    try { 
      const accessToken = await getFromSessionStorage("accessToken");
      const configs = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
      }

      const response = await createRequest('post', `${mooflife_backend}/life/get-for-user`, data, configs)

      if (response && response.data.success && response.data.data) {
        return response.data;
      }

    } catch (error) {
      console.error('Error while getting Lives by user suggestion:', error);
    }
  };
}

export const storeLifeImages = (files, bucket, lifeType, lifeId, existingImages) => {
  return async () => {
    try {
     
      const accessToken = await getFromSessionStorage("accessToken");
     
      let formData = new FormData();

      if(files.profileImage) {
        formData.append('profileImage', files.profileImage);
      }

      if(files.coverImage) {
        formData.append('coverImage', files.coverImage);
      }

      if(existingImages) {
        formData.append('existingImages', JSON.stringify(existingImages));
      }
      
      formData.append('bucket', bucket);
      formData.append('lifeType', lifeType);
      formData.append('lifeId', lifeId);
  
        const configs = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${accessToken}`
              },
        }
  
        const response = await createRequest('post', `${mooflife_backend}/life/life-images`, formData, configs);
        if (response && response.data.success && response.data.data) {
          return response.data;
        }
    } catch (error) {
      console.error('Error storing Life Image:', error);
    }
  };
};

export const createLifeId = (data) => {
  return async () => {
    try { 
      const accessToken = await getFromSessionStorage("accessToken");
      const configs = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
      }

      const response = await createRequest('post', `${mooflife_backend}/life/life-id`, data, configs)

      if (response && response.data.success && response.data.data) {
        return response.data;
      }

    } catch (error) {
      console.error('Error while creating lifeId:', error);
    }
  };
};

export const createNewLife = (data) => {
  return async () => {
    try { 
      const accessToken = await getFromSessionStorage("accessToken");
      const configs = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
      }

      const response = await createRequest('post', `${mooflife_backend}/life/create`, data, configs)

      if (response && response.data) {
        return response.data;
      }

    } catch (error) {
      console.error('Error while creating lifeId:', error);
    }
  };
};

export const getManagers = (data) => {
  return async () => {
    try { 
      const accessToken = await getFromSessionStorage("accessToken");
      const configs = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
      }

      const response = await createRequest('post', `${mooflife_backend}/life/get-managers`, data, configs)

      if (response && response.data.success && response.data.data) {
        return response.data;
      }

    } catch (error) {
      console.error('Error while getting managers:', error);
    }
  };
};

export const updateLife = (data) => {
  return async () => {
    try { 
      const accessToken = await getFromSessionStorage("accessToken");
      const configs = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
      }

      const response = await createRequest('post', `${mooflife_backend}/life/update`, data, configs)

      if (response && response.data) {
        return response.data;
      }

    } catch (error) {
      console.error('Error while updating life:', error);
    }
  };
};

export const momentBulkUpload = (file, lifeId, lifeName) => {
  return async () => {
    try {
     
      const accessToken = await getFromSessionStorage("accessToken");
     
      let formData = new FormData();
      formData.append('doc', file);
      formData.append('life_id', lifeId);
      formData.append('life_name', lifeName);
      
      const configs = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${accessToken}`
          },
      }
  
      const response = await createRequest('post', `${mooflife_backend}/bulk-upload/moments`, formData, configs);
        if (response && response.data) {
          return response.data;
        }
    } catch (error) {
      console.error('Error storing Life Image:', error);
    }
  };
};

export const connectLife = (data) => {
  return async () => {
    try { 
      const accessToken = await getFromSessionStorage("accessToken");
      const configs = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
      }

      const response = await createRequest('post', `${mooflife_backend}/life/connect`, data, configs)

      if (response && response.data) {
        return response.data;
      }

    } catch (error) {
      console.error('Error while connecting life:', error);
    }
  };
};

export const disconnectLife = (data) => {
  return async () => {
    try { 
      const accessToken = await getFromSessionStorage("accessToken");
      const configs = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
      }

      const response = await createRequest('post', `${mooflife_backend}/life/disconnect`, data, configs)

      if (response && response.data) {
        return response.data;
      }

    } catch (error) {
      console.error('Error while disconnecting life:', error);
    }
  };
};

export const getConnectedLives = (data) => {
  return async () => {
    try { 
      const accessToken = await getFromSessionStorage("accessToken");
      const configs = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
      }

      const response = await createRequest('post', `${mooflife_backend}/life/connected`, data, configs)

      if (response && response.data) {
        return response.data;
      }

    } catch (error) {
      console.error('Error while disconnecting life:', error);
    }
  };
};

export const searchLivesToConnect = (data) => {
  return async () => {
    try { 
      const accessToken = await getFromSessionStorage("accessToken");
      const configs = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
      }

      const response = await createRequest('post', `${mooflife_backend}/life/life-list`, data, configs)

      if (response && response.data) {
        return response.data;
      }

    } catch (error) {
      console.error('Error while searchin lives to connect:', error);
    }
  };
};

export const fetchNewLives = (data) => {
  
  return async (dispatch) => {
    try { 
      const configs = {
          headers: {
              'Content-Type': 'application/json',
            },
      }

      const response = await createRequest('post', `${mooflife_backend}/life/new-lives`, {}, configs)
      if (response && response.data && response.data.success) {
        
        dispatch(getNewLives(response.data.data));
      }
      
    } catch (error) {
      console.error('Error while getting most view moments data:', error);
    }
  };
};
export const createSubscription = (data) => {
  return async () => {
    try { 
      const accessToken = await getFromSessionStorage("accessToken");
      const configs = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
      }

      const response = await createRequest('post', `${mooflife_backend}/life/create-subscription`, data, configs)

      if (response && response.data) {
        return response.data;
      }

    } catch (error) {
      console.error('Error while creating subscription:', error);
    }
  };
};

export const completeSubscription = (data) => {
  return async () => {
    try { 
      const accessToken = await getFromSessionStorage("accessToken");
      const configs = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
      }

      const response = await createRequest('post', `${mooflife_backend}/life/subscription-complete`, data, configs)

      if (response && response.data) {
        return response.data;
      }

    } catch (error) {
      console.error('Error while complete subscription:', error);
    }
  };
};

export const getSubscriptionData = (data) => {
  return async () => {
    try { 
      const accessToken = await getFromSessionStorage("accessToken");
      const configs = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
      }

      const response = await createRequest('post', `${mooflife_backend}/life/get-subscription`, data, configs)

      if (response && response.data) {
        return response.data;
      }

    } catch (error) {
      console.error('Error while get subscription data:', error);
    }
  };
};

export const upgradeSubscription = (data) => {
  return async () => {
    try { 
      const accessToken = await getFromSessionStorage("accessToken");
      const configs = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
      }

      const response = await createRequest('post', `${mooflife_backend}/life/upgrade-subscription`, data, configs)

      if (response && response.data) {
        return response.data;
      }

    } catch (error) {
      console.error('Error while upgrade subscription data:', error);
    }
  };
};

export const downgradeSubscription = (data) => {
  return async () => {
    try { 
      const accessToken = await getFromSessionStorage("accessToken");
      const configs = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
      }

      const response = await createRequest('post', `${mooflife_backend}/life/downgrade-subscription`, data, configs)

      if (response && response.data) {
        return response.data;
      }

    } catch (error) {
      console.error('Error while downgrade subscription data:', error);
    }
  };
};

export const validateCoupon = (data) => {
  return async () => {
    try { 
      const accessToken = await getFromSessionStorage("accessToken");
      const configs = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
      }

      const response = await createRequest('post', `${mooflife_backend}/life/validate-coupon`, data, configs)

      if (response && response.data) {
        return response.data;
      }

    } catch (error) {
      console.error('Error while validating coupon:', error);
    }
  };
};

export const renewSubscription = (data) => {
  return async () => {
    try { 
      const accessToken = await getFromSessionStorage("accessToken");
      const configs = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
      }

      const response = await createRequest('post', `${mooflife_backend}/life/renew-subscription`, data, configs)

      if (response && response.data) {
        return response.data;
      }

    } catch (error) {
      console.error('Error while downgrade subscription data:', error);
    }
  };
};

export const getSubscriptionLimits = () => {
  return async () => {
    try { 
      const accessToken = await getFromSessionStorage("accessToken");
      const configs = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
      }

      const response = await createRequest('post', `${mooflife_backend}/life/get-subscription-limits`, {}, configs)

      if (response && response.data) {
        return response.data;
      }

    } catch (error) {
      console.error('Error while downgrade subscription data:', error);
    }
  };
};

export const updateMomentsSorting = (sort) => {
  return async (dispatch, getState) => {
    try { 
      if(sort){
        dispatch(setMomentsSorting(sort))
      }
    } catch (error) {
      console.error('Error while getting moments sorting:', error);
    }
  };
};
