import React, { useEffect } from "react";
import CooperateHeader from "./CooperateHeader";
import CooperateFooter from "./CooperateFooter";

import "../../styles/CooperatePagesStyle/AboutUsStyle.css";
import { images } from "../../utils/image";

const AboutUs = () => {
  useEffect(() => {
    const video = document.getElementById("myVideo");
    if (video) {
      video.disablePictureInPicture = true;
    }
  }, []);

  return (
    <div>
      <CooperateHeader />
      <div className="about-header-part">
        <img src={images.AboutUsHeader}></img>
        <div className="header-contain">
          <div className="title-set">
            <div className="title-1">
              <span>Never Miss Anything</span> Track World & Personal Moments
            </div>
            <br />
            <div className="title-2">
              With MoofLife, you’ll stay connected with the significant world
              moments and treasure your personal moments, all in one place.
            </div>
          </div>
        </div>
      </div>

      <div className="video-part">
        <img src={images.MoofIcon} width="100px"></img>
        <div className="title">
          Our <span>Mission</span>
        </div>
        <div className="details">
          To build an engaging platform accessible for everyone, and bringing
          the past moments to life through interactive and immersive digital
          experience. We are building an uninterrupted timeline, capturing
          essential moments in the lives of individuals, organizations, and
          communities.
        </div>
        <div>
          <video
            id="myVideo"
            width="100%"
            autoplay="true"
            muted
            loop
            playsinline
            disablePictureInPicture
          >
            <source src={images.video} type="video/mp4" />
          </video>
        </div>
      </div>

      <div className="gallery-main">
        <img src={images.MoofIcon} width="100px"></img>
        <div className="title">
          Unique <span>Timeline</span>
        </div>
        <div className="para-flip-part">
          <div className="para">
            Walking down the memory lane is more than just revisiting the past;
            it’s heartfelt journey where moments come alive, evoking emotions,
            rekindling relationships, and reminding us of the tapestry of
            experiences that have shaped who we are today. MoofLife is dedicated
            to capturing and celebrating these precious memories, providing a
            platform to the stories and moments that define each unique life
            journey.{" "}
          </div>

          <img className="flip" src={images.AboutUsTimeline}></img>
        </div>
      </div>
      <CooperateFooter />
    </div>
  );
};

export default AboutUs;
