import { setMoments, setPage, setLimitReached, setMomentsLoading,
  updateCommentsCount, setMomentSearchCriteria, resetMoments, setMomentsSorting, removeMoment, getNewMoments} from '../slices/MomentSlice';
import { createRequest } from '../../utils/requestHandler';
import { getFromSessionStorage } from '../../utils/storageHandler';
import { removeLifeMoment } from '../slices/LifeSlice';

import {setMyMoments, setMyMomentPage, setMyMomentLimitReached} from "../slices/MyMomentsTileSlice"
// import { getNewMoments } from '../slices/NewMomentsSlice';
const mooflife_backend = process.env.REACT_APP_BACK_END_URL;


export const getSelectedMoments = (data, page) => {
  return async (dispatch, getState) => {
    try { 
      const isLoading = getState().Moments.momentsLoading;
      const searchValues = getState().Moments.momentSearchValues;

      if (isLoading) {
        return;
      }

      if(data.sorting){
        dispatch(setMomentsSorting(data.sorting))
      }

      if(searchValues.search_keyword && searchValues.search_keyword !== ''){
        data.selector.search_keyword = searchValues.search_keyword; 
      }

      if(searchValues.fromYear && searchValues.fromYear !== ''){
        data.selector.fromYear = searchValues.fromYear; 
      }

      if(searchValues.fromYearEra && searchValues.fromYearEra !== ''){
        data.selector.fromYearEra = searchValues.fromYearEra; 
      }

      if(searchValues.toYear && searchValues.toYear !== ''){
        data.selector.toYear = searchValues.toYear; 
      }

      if(searchValues.toYearEra && searchValues.toYearEra !== ''){
        data.selector.toYearEra = searchValues.toYearEra; 
      }

      const configs = {
          headers: {
              'Content-Type': 'application/json',
          },
      }

      dispatch(setMomentsLoading(true));
      dispatch(setMomentSearchCriteria(data.selector));

      await createRequest('post', `${mooflife_backend}/moment/multiple-selected`, data, configs)
      .then((response) => {
        if(response.data){
          if(response.data.length === 0){
            dispatch(setLimitReached(true));
          }
          
          dispatch(setMoments(response.data));
          
          if(page){
            dispatch(setPage(page+1));
          }
        }
        
        dispatch(setMomentsLoading(false));
      });
    } catch (error) {
      console.error('Error while getting moments data:', error);
    }
  };
};

export const getMomentsForSearch = (data, page) => {
  return async (dispatch, getState) => {
    try { 
      const isLoading = getState().Moments.momentsLoading;

      if (isLoading) {
        return;
      }

      if(data.sorting){
        dispatch(setMomentsSorting(data.sorting))
      }

      const configs = {
          headers: {
              'Content-Type': 'application/json',
            },
      }

      dispatch(setMomentsLoading(true));
      dispatch(setMomentSearchCriteria(data.selector));

      await createRequest('post', `${mooflife_backend}/moment/multiple-selected`, data, configs)
      .then((response) => {
        if(response.data){
          dispatch(resetMoments());
          dispatch(setLimitReached(false));
          if(page){
            dispatch(setPage(page+1));
          }
          dispatch(setMoments(response.data));
           
        }
        dispatch(setMomentsLoading(false)); 
      });
    } catch (error) {
      console.error('Error while getting moments data for search:', error);
    }
  };
};

export const getPermissionsToUpdate = (data) => {
  return async () => {
    try {
      const accessToken = await getFromSessionStorage("accessToken");
        const configs = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
              },
        }
  
        const response = await createRequest('post', `${mooflife_backend}/moment/get-permissions-to-update`, data, configs);
        if (response && response.data.success && response.data.data) {
          return  response.data.data;
        }
    } catch (error) {
      console.error('Error getting permissions to update Moment:', error);
    }
  };
};

export const updateMoment = (data) => {
  return async (dispatch) => {
    try {
      const accessToken = await getFromSessionStorage("accessToken");
        const configs = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
              },
        }
  
        const response = await createRequest('put', `${mooflife_backend}/moment/`, data, configs);
        if (response && response.data.success && response.data.data) {
          return response.data;
        }
    } catch (error) {
      console.error('Error updating Moment:', error);
    }
  };
};

export const getSingleMoment = (data) => {
  return async () => {
    try {
      const accessToken = await getFromSessionStorage("accessToken");
        const configs = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
              },
        }
  
        const response = await createRequest('post', `${mooflife_backend}/moment/single-selected`, data, configs);
        if (response && response.data.success && response.data.data) {
          return  response.data.data;
        }
    } catch (error) {
      console.error('Error getting Moment:', error);
    }
  };
};

export const storeImagesInCloud = (file, lifeId, bucket) => {
  return async () => {
    try {
      const fileType = file && file.name && file.name.split(".")[1] ? file.name.split(".")[1] : 'jpg';
      const accessToken = await getFromSessionStorage("accessToken");
      const key = `moments/images/${lifeId}/${new Date().getTime()}.${fileType}`;
      let formData = new FormData();
      formData.append('image', file);
      formData.append('key', key);
      formData.append('bucket', bucket);
  
        const configs = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${accessToken}`
              },
        }
  
        const response = await createRequest('post', `${mooflife_backend}/moment/upload-image`, formData, configs);
        if (response && response.data.success && response.data.data) {
          return { 
            imageUrl : response.data.data.imageUrl,
            key : key,
            mediaType: response.data.data.mediaType
          }
        }
    } catch (error) {
      console.error('Error getting Moment:', error);
    }
  };
};

export const removeMomentImage = (data) => {
  return async (dispatch) => {
    try {
      const accessToken = await getFromSessionStorage("accessToken");
        const configs = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
              },
        }
  
        const response = await createRequest('delete', `${mooflife_backend}/moment/image`, data, configs);
        if (response && response.data.success && response.data.data) {
          return response.data;
        }
    } catch (error) {
      console.error('Error updating Moment:', error);
    }
  };
};

export const createMoment = (data) => {
  return async (dispatch) => {
    try {
      const accessToken = await getFromSessionStorage("accessToken");
        const configs = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
              },
        }
  
        const response = await createRequest('post', `${mooflife_backend}/moment/`, data, configs);
        if (response && response.data.success && response.data.data) {
          return response.data;
        }
    } catch (error) {
      console.error('Error creating Moment:', error);
    }
  };
};

export const getAuthorizedEmails = (data) => {
  return async () => {
    try {
      const accessToken = await getFromSessionStorage("accessToken");
        const configs = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
              },
        }
  
        const response = await createRequest('post', `${mooflife_backend}/moment/authorized-emails`, data, configs);
        if (response && response.data.success && response.data.data) {
          return response.data;
        }
    } catch (error) {
      console.error('Error getting Authorized emails:', error);
    }
  };
};

export const storeVideoInCloud = (file, lifeId, bucket) => {
  return async () => {
    try {
      const fileType = file && file.name && file.name.split(".")[1] ? file.name.split(".")[1] : 'mp4';
      const accessToken = await getFromSessionStorage("accessToken");
      const key = `moments/videos/${lifeId}/${new Date().getTime()}.${fileType}`;
      let formData = new FormData();
      formData.append('video', file);
      formData.append('key', key);
      formData.append('bucket', bucket);

        const configs = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${accessToken}`
              },
        }
  
        const response = await createRequest('post', `${mooflife_backend}/moment/upload-video`, formData, configs);
        if (response && response.data.success && response.data.data) {
          return { 
            videoUrl : response.data.data.videoUrl,
            key : key,
            mediaType: response.data.data.mediaType
          }
        }
    } catch (error) {
      console.error('Error storing moment video:', error);
    }
  };
};

export const deleteMoment = (data) => {
  return async (dispatch) => {
    try {
      const accessToken = await getFromSessionStorage("accessToken");
        const configs = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
              },
        }
  
        const response = await createRequest('delete', `${mooflife_backend}/moment`, data, configs);
      
        if (response && response.data.success && response.data.data) {
          dispatch(removeMoment(response.data.data))
          dispatch(removeLifeMoment(response.data.data))
          return response.data;
        }
        return response.data;
    } catch (error) {
      console.error('Error updating Moment:', error);
      return false;

    }
  };
};

export const linkMoment = (data) => {
  return async () => {
    try {
      const accessToken = await getFromSessionStorage("accessToken");
        const configs = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
        }
  
        const response = await createRequest('post', `${mooflife_backend}/moment/link`, data, configs);
      
        if (response && response.data) {
          return response.data;
        }
        
    } catch (error) {
      console.error('Error linking Moment:', error);
      return false;

    }
  };
};

export const checkIfAlreadyConnected = (data) => {
  return async () => {
    try {
      const accessToken = await getFromSessionStorage("accessToken");
        const configs = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
        }
  
        const response = await createRequest('post', `${mooflife_backend}/moment/connected`, data, configs);
      
        if (response && response.data) {
          return response.data;
        }
        
    } catch (error) {
      console.error('Error checking connected Moment:', error);
      return false;

    }
  };
};

export const unlinkMoment = (data) => {
  return async () => {
    try {
      const accessToken = await getFromSessionStorage("accessToken");
        const configs = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
        }
  
        const response = await createRequest('post', `${mooflife_backend}/moment/unlink`, data, configs);
      
        if (response && response.data) {
          return response.data;
        }
        
    } catch (error) {
      console.error('Error checking connected Moment:', error);
      return false;

    }
  };
};

export const getMyMomentsTile = (data, page) => {
  return async (dispatch) => {
    try {
      const accessToken = await getFromSessionStorage("accessToken");
        const configs = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
              },
        }
  
        const response = await createRequest('post', `${mooflife_backend}/moment/my-moments`, data, configs);
        if (response && response.data && response.data.success && response.data.data) {
          if (response.data.data.length === 0) {
            dispatch(setMyMomentLimitReached(true));
          }
          dispatch(setMyMoments(response.data.data));
  
          if (page) {
            dispatch(setMyMomentPage(page + 1));
          }
        }
    } catch (error) {
      console.error('Error getting permissions to update Moment:', error);
    }
  };
};

export const fetchNewMoments = (data) => {
  
  return async (dispatch) => {
    try { 
      const configs = {
          headers: {
              'Content-Type': 'application/json',
            },
      }

      const response = await createRequest('post', `${mooflife_backend}/moment/new-moments`, {}, configs)
      if (response && response.data && response.data.success) {
        
        dispatch(getNewMoments(response.data.data));
      }
      
    } catch (error) {
      console.error('Error while getting most view moments data:', error);
    }
  };
};