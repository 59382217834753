import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { images } from "../utils/image";
import "../styles/EmailVerificationStyle.css";
import { verifyOTP, sendOTP, setUserDetails } from "../store/action/userInteractionsAction";
import Countdown from "react-countdown";
import { getFromSessionStorage } from "../utils/storageHandler";

const EmailVerification = () => {
  const now = new Date();
  const currentYear = now.getFullYear();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [inputValues, setInputValues] = useState(["", "", "", "", "", ""]);
  const [verificationError, setVerificationError] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [counterkey, setCounterKey] = useState(0);
  const [targetDate, setTargetDate] = useState(Date.now() + 5 * 60 * 1000);

  //OTP expired
  useEffect(() => {
    const accessToken = getFromSessionStorage('accessToken');
    if(accessToken){
      // Redirect to the home page if the user already loggedIn
      navigate("/");
    }else if(!userDetails.email){
      // Redirect to the loggin page if the user hasn't completed the sign-up process
      navigate("/login");
    }
  }, []);

  const userDetails = useSelector(
    (state) => state.UserInteractions.newUserDetails
  );

  const OTPVerified = useSelector(
    (state) => state.UserInteractions.otpVerified
  );

  const handleTermsAndConditions = () => {
    navigate("/terms-and-conditions");
  };

  const handleInputChange = (index, value) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);

    // Move to the previous input field if the current input field is empty and backspace is pressed
    if (value === "" && index > 0) {
      const prevInput = document.getElementById(`opt${index}`);
      prevInput.focus();
    }

    // Move to the next input field if the current input field is not the last one
    if (index < inputValues.length - 1 && value !== "") {
      const nextInput = document.getElementById(`opt${index + 2}`);
      nextInput.focus();
    }
  };

  const verifyOTPCode = async () => {
    const otpString = inputValues.join("");
    dispatch(await verifyOTP({ email: userDetails.email, otp: otpString }))
      .then((res) => {
        if (res) {
          let dataToRegister = { ...userDetails };
          dataToRegister.otp = true;
          dispatch(setUserDetails(dataToRegister))
          .then(() => {
            handleTermsAndConditions();
          })
          .catch((error) => {
            console.error("Error in setUserDetails:", error);
          });
          setVerificationError(false);
        } else {
          setVerificationError(true);
        }
      })
      .catch((error) => {
        console.error("Error in verification:", error);
      });
  };

  const handleComplete = () => {
    setIsExpired(true);
  }

  const resendOTP = () => {
    setIsExpired(false);
    setCounterKey(counterkey+1);
    setTargetDate(Date.now() + 5 * 60 * 1000);
    dispatch(sendOTP({ email: userDetails.email }));
  };

  return (
    <div>
      <div className="email-verification-main">
        <Row className="col1">
          <Col sm={7}>
            <Card className="text-center email-verify-card">
              <div className="email-verification-container">
                <section className="email-logo">
                  <img className="logo" src={images.moofLifeLogo}></img>
                </section>
                <div className="title-set">
                  <p className="title-1">E-mail Verification</p>
                  <p className="title-2">
                    Please enter the verification code that we’ve sent to your
                    email address.
                  </p>
                  <p className="title-3">
                    OTP expires in{" "}
                    <span>
                      <Countdown
                        key={counterkey}
                        date={targetDate}
                        onComplete={handleComplete}
                        renderer={({ minutes, seconds }) => (
                          isExpired === true ? (
                            <div>00:00</div>
                          ):(
                            <div>
                            0{minutes} : {seconds}
                          </div>
                          )
                        )}
                      />
                    </span>
                  </p>
                  {isExpired === true && (
                    <div className="otp-error-message error-message">
                      OTP expired. Please request to resend it.
                    </div>
                  )}
                </div>
                <div className="input-set">
                  <Row>
                    <Col>
                      <input
                        type="number"
                        id="opt1"
                        maxLength="1"
                        name="opt1"
                        value={inputValues[0]}
                        onChange={(e) => handleInputChange(0, e.target.value)}
                      />
                    </Col>
                    <Col>
                      <input
                        type="number"
                        id="opt2"
                        maxLength="1"
                        name="opt2"
                        value={inputValues[1]}
                        onChange={(e) => handleInputChange(1, e.target.value)}
                      />
                    </Col>
                    <Col>
                      <input
                        type="number"
                        id="opt3"
                        maxLength="1"
                        name="opt3"
                        value={inputValues[2]}
                        onChange={(e) => handleInputChange(2, e.target.value)}
                      />
                    </Col>
                    <Col>
                      <input
                        type="number"
                        id="opt4"
                        maxLength="1"
                        name="opt4"
                        value={inputValues[3]}
                        onChange={(e) => handleInputChange(3, e.target.value)}
                      />
                    </Col>
                    <Col>
                      <input
                        type="text"
                        id="opt5"
                        maxLength="1"
                        name="opt5"
                        value={inputValues[4]}
                        onChange={(e) => handleInputChange(4, e.target.value)}
                      />
                    </Col>
                    <Col>
                      <input
                        type="text"
                        id="opt6"
                        maxLength="1"
                        name="opt6"
                        value={inputValues[5]}
                        onChange={(e) => handleInputChange(5, e.target.value)}
                      />
                    </Col>
                  </Row>
                </div>
                <p className="title-4">
                Please ensure that the OTP email has not been directed to your Spam folder.</p>
                {verificationError === true && (
                  <small>
                    <div className="error-message">
                      <span>
                        <i class="bi bi-exclamation-circle"></i>
                      </span>
                      Invalid OTP
                    </div>
                  </small>
                )}
              </div>

              <button
                type="button"
                className="btn-verify yellow-btn"
                onClick={() => verifyOTPCode()}
                disabled={
                  isExpired === true ||
                  inputValues.some((value) => value === "")
                }
              >
                Verify
              </button>

              <Link to="/termsAndServices"> </Link>

              <p className="title-3">
                Didn’t Receive the code?
                <span>
                  <Link onClick={() => resendOTP()}>Resend</Link>
                </span>
              </p>
              <p className="all-right">MoofLife © {currentYear}</p>
            </Card>
          </Col>
          <Col sm={5} style={{ textAlign: "right" }}>
            <img src={images.otpVerificationBg}></img>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EmailVerification;
