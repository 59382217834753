import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { images } from "../../utils/image";
import "../../styles/AccessStyle.css";
import "../../styles/CommonStyle.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";
import Countdown from "react-countdown";
import {
  sendOTP,
  verifyOTP,
  changePassword,
} from "../../store/action/userInteractionsAction";
import { getFromSessionStorage } from "../../utils/storageHandler";

const Access = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const targetDate = Date.now() + 5 * 60 * 1000;
  const [modalShow, setModalShow] = useState(false);
  const [inputValues, setInputValues] = useState(["", "", "", "", "", ""]);
  const [isExpired, setIsExpired] = useState(false);
  const [userData, setUserData] = useState({});
  const [registerType, setRegisterType] = useState({});
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [update, setUpdates] = useState({
    oldPassword: "",
    newPassword: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [oldPasswordType, setOldPasswordType] = useState("password");
  const [newPasswordType, setNewPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [themeColor, setThemeColor] = useState("");
  const [initials, setInitials] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);
  const handlePopupClose = () => setShowPopUp(false);

  //const registerType = "google"

  useEffect(() => {
    const userDetails = getFromSessionStorage("userDetails", true);
    const registerTypeName = getFromSessionStorage("register_type");
    setRegisterType(registerTypeName);
    setUserData(userDetails);

    const themeColor = userDetails ? userDetails.theme_colour : "";
    setThemeColor(themeColor);
    const firstInitial = userDetails ? userDetails.first_name.charAt(0) : "";
    const lastInitial = userDetails && userDetails.last_name ? userDetails.last_name.charAt(0) : "";
    var initials = firstInitial + lastInitial;
    setInitials(initials);
    const imageUrl = userDetails ? userDetails.image_url : "";
    setImageUrl(imageUrl);
  }, []);

  const handleChangePassword = () => {
    handleShow();
  };

  const handleInputChange = (index, value) => {
    if (!isNaN(value)) {
      const newInputValues = [...inputValues];
      newInputValues[index] = value;
      setInputValues(newInputValues);

      if (value === "" && index > 0) {
        const prevInput = document.getElementById(`opt${index}`);
        prevInput.focus();
      }

      if (index < inputValues.length - 1 && value !== "") {
        const nextInput = document.getElementById(`opt${index + 2}`);
        nextInput.focus();
      }
    }
  };

  const resendOTP = () => {
    setIsExpired(false);
  };

  const setConfirmPassword = (evt) => {
    setPasswordConfirm(evt.target.value);
  };

  const handleValueChanges = (evt) => {
    const { id, value } = evt.target;
    setUpdates((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
  };

  const sendOTPEmail = () => {
    dispatch(
      sendOTP({
        email: userData.email,
        action: "password-change",
      })
    );
  };

  const verifyAddedOTP = async () => {
    const otpValue = inputValues.join("");
    dispatch(await verifyOTP({ email: userData.email, otp: otpValue })).then(
      async (result) => {
        if (result) {
          dispatch(
            await changePassword({
              oldPassword: update.oldPassword,
              newPassword: update.newPassword,
              email: userData.email,
            })
          ).then((result) => {
            if (result) {
              handleClose();
              setUpdates({
                oldPassword: "",
                newPassword: "",
              });
              setPasswordConfirm("");
              setInputValues(["", "", "", "", "", ""]);
              setErrorMessage("password update successfully");
              setShowPopUp(true);

              setTimeout(() => {
                setShowPopUp(false);
              }, 5000);
            } else {
              // show error message (pasword update failed)
              setErrorMessage("password update failed");

              setTimeout(() => {
                setErrorMessage("");
              }, 5000);
            }
          });
        } else {
          // show error message (otp verification is failed)
          setErrorMessage("otp verification is failed");

          setTimeout(() => {
            setErrorMessage("");
          }, 5000);
        }
      }
    );
  };

  const redirectForgetPassword = () => {
    navigate(`/forget-password`, {
      state: { action: "passwordChange" },
    });
  };

  //Password validation
  const validatePassword = () => {
    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;
    const numberRegex = /[0-9]/g;
    const specialCharRegex = /[-!@#?<>()&/\\'"]/g; // Add your special characters here
    const noSpaceOrRestrictedCharRegex = /^[^\s]+$/; // Regex to check for spaces and restricted characters
    const minLength = update.newPassword.length >= 8;

    return {
      lowerUppercase:
        update.newPassword.match(lowercaseRegex) &&
        update.newPassword.match(uppercaseRegex),
      number: update.newPassword.match(numberRegex),
      specialChar: update.newPassword.match(specialCharRegex),
      noSpaceOrRestrictedChar: noSpaceOrRestrictedCharRegex.test(
        update.newPassword
      ),
      length: minLength,
    };
  };

  const validationResults = validatePassword();

  //Toggle Eye for password visible

  const toggleOldPassword = () => {
    setOldPasswordType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };

  const toggleNewPassword = () => {
    setNewPasswordType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };

  const toggleConfirmPassword = () => {
    setConfirmPasswordType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };


  const [showPopup, setShowPopUp] = useState(false);

  return (
    <>
      <div className="access-main">

        
                {/* Pop up */}
                <Modal
                  className="popup-modal"
                  show={showPopup}
                  onHide={handleClose}
                >
                  <div className="popup-header">
                    <div className="close-icon" onClick={handlePopupClose}>
                      <img src={images.closeIcon}></img>
                    </div>
                    <div className="logo-icon">
                      <img src={images.MoofIcon} />
                    </div>
                  </div>

                  <Modal.Body className="modal-body">
                    {errorMessage}
                    <br></br>
                  </Modal.Body>
                </Modal>

        <div className="title">Change Password</div>

        <div className="main-content">
          <div className="user-image-name">
            {/* <img
              src={
                userData.image_url && userData.image_url !== ""
                  ? userData.image_url
                  : images.UserImage
              }
            /> */}
            {userData.image_url && userData.image_url !== "" ? (
              <div>
                <img src={imageUrl} className="user-image" alt="imageUrl"></img>
              </div>
            ) : (
              <div
                className="user-image-letter"
                id="name"
                style={{ background: themeColor || "#E0E0E0" }}
              >
                {initials}
              </div>
            )}
            <span>{userData.email}</span>
            {registerType !== "mooflife" && (
              <span className="error-message google-user-error">
                You have logged in with your Google account. To change your
                password visit www.google.com.
              </span>
            )}
          </div>

          <div className="form-conditions">
            <div className="form" disabled={registerType !== "mooflife"}>
              <div>
                <div className="label">Old Password</div>
                <div className="password-input-container">
                  <input
                    type={oldPasswordType}
                    placeholder="Password"
                    name="password"
                    id="oldPassword"
                    value={update.oldPassword}
                    onChange={handleValueChanges}
                    disabled={registerType !== "mooflife"}
                  />
                  <button
                    type="button"
                    className="toggle-password"
                    onClick={toggleOldPassword}
                  >
                    {oldPasswordType === "password" ? (
                      <img
                        src={images.eyeSlash}
                        alt="Show Password"
                        width="20px"
                      />
                    ) : (
                      <img src={images.eye} alt="Hide Password" width="20px" />
                    )}
                  </button>
                </div>
              </div>

              <div>
                <div className="label">New Password</div>
                <div className="password-input-container">
                  <input
                    type={newPasswordType}
                    placeholder="New Password"
                    name="newPassword"
                    id="newPassword"
                    value={update.newPassword}
                    onChange={handleValueChanges}
                    disabled={registerType !== "mooflife"}
                    maxLength={25}
                  />
                  <button
                    type="button"
                    className="toggle-password"
                    onClick={toggleNewPassword}
                  >
                    {newPasswordType === "password" ? (
                      <img
                        src={images.eyeSlash}
                        alt="Show Password"
                        width="20px"
                      />
                    ) : (
                      <img src={images.eye} alt="Hide Password" width="20px" />
                    )}
                  </button>
                </div>
              </div>

              <div>
                <div className="label">Confirm Password</div>
                <div className="password-input-container">
                  <input
                    type={confirmPasswordType}
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    value={passwordConfirm}
                    onChange={setConfirmPassword}
                    disabled={registerType !== "mooflife"}
                    maxLength={25}
                  />
                  <button
                    type="button"
                    className="toggle-password"
                    onClick={toggleConfirmPassword}
                  >
                    {confirmPasswordType === "password" ? (
                      <img
                        src={images.eyeSlash}
                        alt="Show Password"
                        width="20px"
                      />
                    ) : (
                      <img src={images.eye} alt="Hide Password" width="20px" />
                    )}
                  </button>
                </div>

                {update.newPassword !== passwordConfirm && (
                  <small>
                    <div className="error-message password-mismatch">
                      <span>
                        <i class="bi bi-exclamation-circle"></i>
                      </span>
                      Password mismatch
                    </div>
                  </small>
                )}
              </div>

              <div className="button-link">
                <button
                  className="yellow-btn"
                  disabled={
                    update.oldPassword === "" ||
                    update.newPassword === "" ||
                    passwordConfirm === "" ||
                    update.newPassword !== passwordConfirm ||
                    !validationResults.length ||
                            !validationResults.lowerUppercase ||
                            !validationResults.number ||
                            !validationResults.specialChar ||
                            !validationResults.noSpaceOrRestrictedChar 
                  }
                  onClick={handleChangePassword}
                >
                  Change Password
                </button>

              </div>
            </div>
            {registerType !== "mooflife" && <div className="overlay"></div>}
            <div>
              <div className="condition-box">
                <div id="message" className="message-list">
                  <h3 className="message-topic">Create a password that:</h3>
                  <p className={validationResults.length ? "valid" : "invalid"}>
                    Contains 8 - 25 characters
                  </p>
                  <p
                    className={
                      validationResults.lowerUppercase ? "valid" : "invalid"
                    }
                  >
                    Contains both lower (a-z) and upper case letters (A-Z)
                  </p>
                  <p className={validationResults.number ? "valid" : "invalid"}>
                    Contains at least one number (0-9)
                  </p>
                  <p
                    className={
                      validationResults.specialChar ? "valid" : "invalid"
                    }
                  >
                    Contains at least one special character e.g.{" "}
                    <span style={{ fontWeight: "bold" }}> -!@#?()&/\\'" </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="verify-modal"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={handleClose}
        onShow={sendOTPEmail}
        animation={true}
        backdrop="static"
      >
        <Modal.Header closeButton style={{ border: "none" }}></Modal.Header>

        <Modal.Body className="modal-body">
          <div className="title-1">
            Please verify your request to Change password Mooflife
          </div>

          <div className="title-2">
            Please enter the verification code that we’ve sent to your email
            address.
          </div>

          <p className="otp-expire">
            OTP expires in{" "}
            <span>
              <Countdown
                date={targetDate}
                renderer={({ minutes, seconds }) =>
                  isExpired === true ? (
                    <div>00:00</div>
                  ) : (
                    <div>
                      0{minutes} : {seconds}
                    </div>
                  )
                }
              />
            </span>
          </p>
          {isExpired === true && (
            <div className="otp-error-message error-message">
              OTP expired. Please request to resend it.
            </div>
          )}

          <div className="input-set">
            <Row>
              <Col>
                <input
                  type="text"
                  id="opt1"
                  maxLength={1}
                  name="opt1"
                  value={inputValues[0]}
                  onChange={(e) => handleInputChange(0, e.target.value)}
                />
              </Col>
              <Col>
                <input
                  type="text"
                  id="opt2"
                  maxLength={1}
                  name="opt2"
                  value={inputValues[1]}
                  onChange={(e) => handleInputChange(1, e.target.value)}
                />
              </Col>
              <Col>
                <input
                  type="text"
                  id="opt3"
                  maxLength={1}
                  name="opt3"
                  value={inputValues[2]}
                  onChange={(e) => handleInputChange(2, e.target.value)}
                />
              </Col>
              <Col>
                <input
                  type="text"
                  id="opt4"
                  maxLength={1}
                  name="opt4"
                  value={inputValues[3]}
                  onChange={(e) => handleInputChange(3, e.target.value)}
                />
              </Col>
              <Col>
                <input
                  type="text"
                  id="opt5"
                  maxLength={1}
                  name="opt5"
                  value={inputValues[4]}
                  onChange={(e) => handleInputChange(4, e.target.value)}
                />
              </Col>
              <Col>
                <input
                  type="text"
                  id="opt6"
                  maxLength={1}
                  name="opt6"
                  value={inputValues[5]}
                  onChange={(e) => handleInputChange(5, e.target.value)}
                />
              </Col>
            </Row>
          </div>

          <button
            className="yellow-btn"
            disabled={
              isExpired === true || inputValues.some((value) => value === "")
            }
            onClick={() => verifyAddedOTP()}
          >
            Verify
          </button>
          {errorMessage && (
                  <small>
                    <div className="error-message">
                      <span>
                        <i class="bi bi-exclamation-circle"></i>
                      </span>
                      {errorMessage}
                    </div>
                  </small>
                )}
          <div className="resend-link">
            Didn’t Receive the code?{" "}
            <Link onClick={() => resendOTP()}>
              <span>Resend</span>
            </Link>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Access;
