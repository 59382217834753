import React, { useEffect, useRef, useState } from "react";
import "../../styles/TopMomentTileStyle.css";
import NavigationBar from "../../components/NavigationBar";
import InfiniteScroll from "react-infinite-scroller";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import VideoPlayer from "../../components/VideoPlayer";
import { Link } from "react-router-dom";
import { getMostViewedTile } from "../../store/action/MostViewedMomentsAction";
import { images } from "../../utils/image";
import copy from "copy-to-clipboard";
import Dropdown from "react-bootstrap/Dropdown";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import {
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
  XIcon,
} from "react-share";

const MostViewTile = () => {
  const dispatch = useDispatch();
  const MostViewedMoments = useSelector(
    (state) => state.MostViewedMoments.MostViewedMomentsTile
  );

  const limit = 9;
  const limitReached = useSelector(
    (state) => state.MostViewedMoments.limitReached
  );
  const Page = useSelector((state) => state.MostViewedMoments.Page);
  const isLimitReached = limitReached;

  const [shareBoxVisible, setShareBoxVisible] = useState();
  const [shareMoment, setShareMoment] = useState("");
  const textRef = useRef();
  const shareBoxRef = useRef();
  const [tooltipState, setTooltipState] = useState({});

  useEffect(() => {
    dispatch(
      getMostViewedTile(
        {
          page: 1,
          limit: 9,
        },
        1
      )
    );
  }, []);

  const currentUrl =
    window.location.protocol +
    "//" +
    window.location.hostname +
    (window.location.port ? ":" + window.location.port : "");

  const loadItems = async () => {
    dispatch(
      getMostViewedTile(
        {
          selector: {},
          page: Page,
          limit: limit,
        },
        Page
      )
    );
  };

  const isLargeTile = (index) => {
    // pattern - 1, 6, 11, 16, 21
    // 6 / 5 remaining 1
    if (MostViewedMoments[index]?.images[0]?.url && index % 5 === 1) {
      return true;
    } else if (
      index % 5 === 2 &&
      !MostViewedMoments[index - 1]?.images[0]?.url &&
      MostViewedMoments[index]?.images[0]?.url
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleShareClick = (momentId) => {
    setShareMoment(momentId);
    setShareBoxVisible(true);
  };

  const copyToClipboard = (id, mostViewed) => {
    let copyText = textRef.current.value;
    let isCopy = copy(copyText);
    if (id === mostViewed._id && isCopy) {
      toggleTooltip(id, true);

      setTimeout(() => {
        toggleTooltip(id, false);
      }, 3000);
    }
  };

  const toggleTooltip = (momentId, value) => {
    setTooltipState((prevState) => ({
      ...prevState,
      [momentId]: value,
    }));
  };

  return (
    <div>
      <div
        id="scrollerDiv"
        className="dummy-scroll"
        style={{
          height: "100vh",
          overflow: "auto",
        }}
        tabIndex={0}
      >
        <NavigationBar />

        <InfiniteScroll
          pageStart={1}
          loadMore={loadItems}
          hasMore={!isLimitReached}
          loader={
            MostViewedMoments.length >= limit && !isLimitReached ? (
              <p
                className="loading-word-landing"
                style={{ marginLeft: "20px" }}
              >
                Loading...
              </p>
            ) : null
          }
          useWindow={false}
          initialLoad={false}
        >
          <div className="tile-view-main">
            <div className="grid">
              <div className="tile date-tile">
                <div className="date-card">
                  <div className="img-container">
                    <Card className="info-card">
                      <Card.Text
                        className="topic"
                        style={{ color: "#FFC000", fontSize: "40px" }}
                      >
                        Most Viewed
                      </Card.Text>
                      <Card.Text className="topic">
                        Moments of the day
                      </Card.Text>
                    </Card>
                  </div>
                </div>
              </div>
              {MostViewedMoments.map((mostViewed, index) => (
                <div
                  key={mostViewed._id}
                  className={`tile ${isLargeTile(index) ? "large" : ""}`}
                >
                  <Dropdown>
                    <Dropdown.Toggle>
                      <div className="moof-icon">
                        <img src={images.MoofIcon} className="moof-icon"/>
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown-menu">
                      <Dropdown.Item
                        onClick={() => handleShareClick(mostViewed._id)}
                      >
                        <img src={images.ShareIcon}></img>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  {tooltipState[mostViewed._id] && (
                    <div className="copy-message">Link copied</div>
                  )}
                  <div className="icons share-icon">
                    <div class="share-feed">
                      <a
                        class="share-btn"
                        onClick={() => handleShareClick(mostViewed._id)}
                      >
                        {shareBoxVisible && shareMoment === mostViewed._id && (
                          <div class="share-box" ref={shareBoxRef}>
                            <div class="share-icon">
                              <label>Telegram</label>
                              <TelegramShareButton
                                url={`${currentUrl}/${mostViewed.life.verified_name?mostViewed.life.verified_name:mostViewed.life.life_id}/${mostViewed._id}`}
                                title={mostViewed.moment_title}
                              >
                                <TelegramIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </TelegramShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Linkedin</label>
                              <LinkedinShareButton
                                url={`${currentUrl}/${mostViewed.life.verified_name?mostViewed.life.verified_name:mostViewed.life.life_id}/${mostViewed._id}`}
                                title={mostViewed.moment_title}
                                source="MoofLife"
                              >
                                <LinkedinIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </LinkedinShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Whatsapp</label>
                              <WhatsappShareButton
                                url={`${currentUrl}/${mostViewed.life.verified_name?mostViewed.life.verified_name:mostViewed.life.life_id}/${mostViewed._id}`}
                                title={mostViewed.moment_title}
                                separator=":: "
                              >
                                <WhatsappIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </WhatsappShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Facebook</label>
                              <FacebookShareButton
                                url={`${currentUrl}/${mostViewed.life.verified_name?mostViewed.life.verified_name:mostViewed.life.life_id}/${mostViewed._id}`}
                              >
                                <FacebookIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </FacebookShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Mask</label>
                              <TwitterShareButton
                                url={`${currentUrl}/${mostViewed.life.verified_name?mostViewed.life.verified_name:mostViewed.life.life_id}/${mostViewed._id}`}
                                title={mostViewed.moment_title}
                              >
                                <XIcon size={32} round className="icon" />
                              </TwitterShareButton>
                            </div>
                            <div class="share-icon icon-copy">
                              <div class="hover-text">
                                {/* <!-- Copy Text --> */}
                                <div className="copy-text d-flex">
                                  <input
                                    value={`${currentUrl}/${mostViewed.life.verified_name?mostViewed.life.verified_name:mostViewed.life.life_id}/${mostViewed._id}`}
                                    disabled
                                    type="text"
                                    ref={textRef}
                                  />
                                </div>
                              </div>
                              <img
                                src={images.CopyIcon}
                                onClick={() =>
                                  copyToClipboard(mostViewed._id, mostViewed)
                                }
                                className="icon"
                              ></img>
                            </div>
                          </div>
                        )}
                      </a>
                    </div>
                  </div>

                  <Link
                    to={`/${mostViewed.life.verified_name?mostViewed.life.verified_name:mostViewed.life.life_id}/${mostViewed._id}`}
                    target="_blank"
                  >
                    {mostViewed.images.length !== 0 &&
                      (mostViewed.images[0]?.media_type === "image" ? (
                        <img src={mostViewed.images[0].url} className="moment-img"/>
                      ) : (
                        <div>
                          <VideoPlayer
                            className="videoFrame"
                            source={mostViewed.images[0].url}
                          />
                        </div>
                      ))}

                    <div className="title">
                      <div className="p">
                        <div className="life-logo-name">
                          <img src={mostViewed.life.life_logo} className="img"></img>
                          <div className="moment-date">
                            {mostViewed.moment_date}
                          </div>
                        </div>
                        {mostViewed.moment_title.length > 90
                          ? mostViewed.moment_title.slice(0, 90) + "..."
                          : mostViewed.moment_title}
                      </div>
                    </div>

                    {mostViewed.images.length < 1 && (
                      <div className="para">
                        <div>
                          {mostViewed.moment_details.length > 180
                            ? mostViewed.moment_details.slice(0, 180) + "..."
                            : mostViewed.moment_details}
                        </div>
                      </div>
                    )}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default MostViewTile;
