import "../styles/UpdateMomentStyle.css";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import MomentJs from "moment";
import NavigationBar from "../views/LandingPage/NavigationBar.jsx";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import { images } from "../utils/image";
import Footer from "../components/Footer";
import Spinner from "react-bootstrap/Spinner";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import {
  getSingleMoment,
  storeImagesInCloud,
  updateMoment,
  removeMomentImage,
  createMoment,
  getAuthorizedEmails,
  storeVideoInCloud
} from "../store/action/MomentAction";
import { getSubscriptionData } from "../store/action/LifeAction.js";
import { countryList } from "../utils/CountryList.js";
import { options } from "../utils/MomentTypes.js";
import Select from "react-select";
import { MultiSelect, SelectItem } from "react-multi-select-component";
import { getFromSessionStorage } from "../utils/storageHandler.js";
import PopUpModal from "./PopUpModal.jsx";
import ConfirmationPopup from "./ConfirmationPopup.jsx";
import { shortMonths } from "../utils/monthsManager.js";
import VideoPlayer from "../components/VideoPlayer.jsx";
import ReactPlayer from "react-player";
import { updateMomentsSorting } from "../store/action/LifeAction.js";

const UpdateMomentPage = () => {
  const navigate = useNavigate();
  const urlParams = useParams();
  const calendarRef = useRef(null);
  const momentId = urlParams.moment_id ? urlParams.moment_id : null;
  const dispatch = useDispatch();
  const location = useLocation();
  const URLState = location.state;
  const isCreateMoment = URLState && URLState.lifeData ? true : false;
  const userDetails = getFromSessionStorage("userDetails", true);
  const [files, setFiles] = useState([]);
  const [isDragOver, setIsDragOver] = useState(false);
  const [exceedsMax, setExceedsMax] = useState(false);
  const [exceedsVideoMax, setVideoExceedsMax] = useState(false);
  const [exceedImageMessage, setExceedImageMessage] = useState(false);
  const [exceedsVideoMaxMessage, setVideoExceedsMaxMessage] = useState(false);
  const [showLinkUpload, setShowLinkUpload] = useState(false);
  const [showImageUpload, setShowImageUpload] = useState(true);
  const [showLinkVideoUpload, setShowLinkVideoUpload] = useState(false);
  const [showVideoUpload, setShowVideoUpload] = useState(true);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedMoment, setSelectedMoment] = useState({
    id: "",
    moment_date: "",
    moment_details: "",
    moment_title: "",
    moment_type: [],
    shareable: true,
    commentable: true,
    top_moment: false,
    world_moment: false,
    national_moment: [],
    external_link: "",
    external_link_title: "",
    images: [],
    moment_privacy: ["public"],
    impressionable: true,
  });
  const [momentLife, setMomentLife] = useState({
    life_name: "",
  });
  const [selectedTags, setSelectedTags] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedNations, setSelectedNations] = useState([]);
  const [momentImages, setMomentImages] = useState([]);
  const [newImagesValues, setNewImageValues] = useState({
    imageTitle: "",
    imageSource: "",
    imageCredit: "",
  });
  const [newInputImagesValues, setNewInputImageValues] = useState({
    imageUrl: "",
    imageTitle: "",
    imageSource: "",
    imageCredit: "",
    mediaType: "image"
  });
  const [popupMessages, setPopupMessages] = useState({
    title: "",
    subtitle1: "",
    subtitle2: "",
    button1: "",
    button2: "",
  });
  const [topMomentCheck, setTopMomentCheck] = useState(false);
  const [worldMomentCheck, setWorldMomentCheck] = useState(false);
  const [sustainabilityMomentCheck, setSustainabilityMomentCheck] = useState(false);
  const [featuredMomentCheck, setFeaturedMomentCheck] = useState(false);
  const [commentableValue, setCommentableValue] = useState(true);
  const [shareableValue, setShareableValue] = useState(true);
  const [connectableValue, setConnectbleValue] = useState(true);
  const [impressionableValue, setImpressionableValue] = useState(true);
  const [momentYear, setMomentYear] = useState("");
  const [momentMonth, setMomentMonth] = useState("");
  const [momentDay, setMomentDay] = useState("");
  const [momentFullDate, setMomentFullDate] = useState("DD-MM-YYYY");
  const [cloudImagesToDelete, setCloudImagesToDelete] = useState([]);
  const [confirmationPopupShow, setConfirmationPopupShow] = useState(false);
  const [imageIndex, setImageIndex] = useState("");
  const [loading, setLoading] = useState(false);
  const [noInfoMessage, setNoInfoMessage] = useState("");
  const [showDateAdding, setDateAdding] = useState(false);
  const [manualDateValues, setManualDateValues] = useState({
    day: "",
    month: "",
    year: "",
  });
  const [mooflifeAdmin, setMooflifeAdmin] = useState(false);
  const [cloudBucket, setCloudBucket] = useState("DO");
  const [era, setEra] = useState("CE");
  const [impressionTypes, setImpressionTypes] = useState({
    like: true,
    great: true,
    emotional: true,
    nostalgic: true,
    wish: true,
    part: true,
    witnessed: true,
  });
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [momentCountExceeded, setMomentCountExceeded] = useState(false);
  const maxLength = 5000;
  const [maxImages, setmaxImages] = useState(1);
  const [maxVideos, setMaxVideos] = useState(1);

  let lifeLogo = images.UserImage;
  let lifeName = momentLife.life_name
    ? momentLife.life_name
    : URLState && URLState.lifeData
    ? URLState.lifeData.life_name
    : "";

  if (URLState && URLState.lifeData && URLState.lifeData.life_logo) {
    lifeLogo = URLState.lifeData.life_logo;
  } else if (momentLife && momentLife.life_logo) {
    lifeLogo = momentLife.life_logo;
  }

  // Define maxLength for images here based on userType
  //let maxImages;
  //let maxVideos;
  // const userType = "LifeBasic";

  // if (userType === "LifeBasic") {
  //   maxImages = 2;
  // } else if (userType === "LifePlus") {
  //   maxImages = 2;
  // } else if (userType === "LifePremium") {
  //   maxImages = 4;
  // } else if (userType === "LIfeElite") {
  //   maxImages = 4;
  // } else {
  // }

  useEffect(() => {
    const accessToken = getFromSessionStorage("accessToken");
    const updateRouteRegex = /^\/update-moment\/[a-zA-Z0-9-]+$/;
    if (!accessToken) {
      navigate("/login");
    }

    if (location && location.pathname === "/create-moment") {
      if (!isCreateMoment) {
        navigate("/");
      }
    }

    if (momentId && updateRouteRegex.test(location.pathname)) {
      requestMoment();
    }

    checkAuthorizedEmails();

    if(isCreateMoment){
      requestSubscriptionData(URLState.lifeData);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        calendarRef.current &&
        !calendarRef.current.contains(event.target) &&
        !event.target.classList.contains("bi-calendar-week-fill")
      ) {
        setShowCalendar(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const checkAuthorizedEmails = async () => {
    if(userDetails && userDetails.email){
      dispatch(await getAuthorizedEmails({userEmail: userDetails.email}))
      .then((res) => {
        if (res && res.success && res.data) {
          setMooflifeAdmin(res.data.result);
        } 
      });
    }
  };

  const requestSubscriptionData = async (lifeData) => {
    if(lifeData && lifeData.life_id){
      const subscriptionData = await dispatch(getSubscriptionData({lifeId: lifeData.life_id}))
      if(subscriptionData && subscriptionData.success){
        setSubscriptionData(subscriptionData.data);
        if(subscriptionData.data && subscriptionData.data.subscription){
          const subcriptionDetails = subscriptionData.data.subscription;
          setmaxImages(subcriptionDetails.images_per_moment) ;
          setMaxVideos(subcriptionDetails.videos_per_moment) 
          if(subcriptionDetails.moments_per_year === 0){
            setMomentCountExceeded(true);
            // show a message
          }
          
        }
      }
    }
  }

  const requestMoment = async () => {
    dispatch(
      await getSingleMoment({
        selector: {
          _id: momentId,
        },
        action:'update'
      })
    ).then((momentData) => {
      if (momentData) {
        
        setSelectedMoment({
          id: momentData._id,
          moment_date: momentData.moment_date,
          moment_details: momentData.moment_details,
          moment_title: momentData.moment_title,
          moment_type: momentData.moment_type,
          shareable: momentData.shareable,
          commentable: momentData.commentable,
          top_moment: momentData.top_moment,
          world_moment: momentData.world_moment,
          national_moment: momentData.national_moment,
          external_link: momentData.external_link,
          external_link_title: momentData.external_link_title,
          images: momentData.images,
          moment_privacy:
            momentData.moment_privacy && momentData.moment_privacy.length > 0
              ? momentData.moment_privacy
              : ["public"],
          impressionable: momentData.impressionable,
        });
        requestSubscriptionData(momentData.life);
        setMomentLife(momentData.life);
        displayMomentTypes(momentData.moment_type);
        setMomentImages(momentData.images);
        if (
          momentData.national_moment &&
          momentData.national_moment.length > 0
        ) {
          const selectedCountries = [];
          momentData.national_moment.forEach((country) => {
            selectedCountries.push({ label: country, value: country });
          });
          setSelectedNations(selectedCountries);
        }
        setTopMomentCheck(momentData.top_moment);
        setWorldMomentCheck(momentData.world_moment);
        setSustainabilityMomentCheck(momentData.sustainability_moment);
        setFeaturedMomentCheck(momentData.featured);
        setCommentableValue(momentData.commentable);
        setShareableValue(momentData.shareable);
        setConnectbleValue(momentData.connectable && momentData.connectable === "no" ? false : true);
        setImpressionableValue(momentData.impressionable);
        setEra(momentData.era ? momentData.era : "CE");
        if (momentData.moment_date) {
          const date = new Date(momentData.moment_date);
          const dateParts = momentData.moment_date.split("-");

          if (dateParts.length === 3) {
            setMomentDay(MomentJs(date).format("DD"));
            setMomentMonth(MomentJs(date).format("MMM"));
            setMomentYear(MomentJs(date).format("YYYY"));
            setMomentFullDate(
              `${MomentJs(date).format("DD")}-${MomentJs(date).format(
                "MM"
              )}-${MomentJs(date).format("YYYY")}`
            );
          } else if (dateParts.length === 2) {
            setMomentDay("");
            setMomentMonth(MomentJs(date).format("MMM"));
            setMomentYear(MomentJs(date).format("YYYY"));
            setMomentFullDate(
              `${MomentJs(date).format("MM")}-${MomentJs(date).format("YYYY")}`
            );
          } else if (dateParts.length === 1) {
            setMomentDay("");
            setMomentMonth("");
            setMomentYear(MomentJs(date).format("YYYY"));
            setMomentFullDate(`${MomentJs(date).format("YYYY")}`);
          }
        }
        if(momentData.impression_types){
          setImpressionTypes(momentData.impression_types);
        }
      } else {
        // no momemnt data found for update
        setNoInfoMessage("no moments found for this update");
        setPopupMessages({
          title: "No info",
          subtitle1: "No info for update",
          button2: "Ok",
        });
        setConfirmationPopupShow(true);
        console.log("no info");
      }
    });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const onChangeCheckBoxes = (evt) => {
    const { id, checked } = evt.target;

    if (id === "topMoment") {
      setTopMomentCheck(checked);
    }

    if (id === "worldMoment") {
      setWorldMomentCheck(checked);
    }

    if (id === "sustainabilityMoment") {
      setSustainabilityMomentCheck(checked);
    }

    if (id === "featuredMoment") {
      setFeaturedMomentCheck(checked);
    }
  };

  const onChangeSwitches = (evt) => {
    const { id, checked } = evt.target;

    if (id === "commentable") {
      setCommentableValue(checked);
    }

    if (id === "shareable") {
      setShareableValue(checked);
    }

    if (id === "impressionable") {
      setImpressionableValue(checked);
    }

    if (id === "connectable") {
      setConnectbleValue(checked);
    }
  };

  //Drag & Drop Images

  const handleDrop = (e, mediaType) => {
    e.preventDefault();
    setIsDragOver(false);
    let fileInput = document.getElementById("fileInput");
    if(mediaType === "video"){
      fileInput = document.getElementById("videoFileInput");
    }
    const newFiles = Array.from(e.dataTransfer.files);

    const validImage = isValidMedia(newFiles[0], mediaType ? mediaType : "image");
    if (validImage) {
      if (mediaType === "image") {
        const uploadedImageCount = momentImages.filter(image => image.media_type === "image").length;
        if (uploadedImageCount >= maxImages) {
          setExceedsMax(true);
          setExceedImageMessage(true);

          setTimeout(() => {
            setExceedImageMessage(false);
          }, 5000);
        } else {
          setExceedsMax(false);
          setFiles([...files, ...newFiles]);
        }
      } else if (mediaType === "video") {
        const uploadedVideosCount = momentImages.filter(image => image.media_type === "video").length;
        if (uploadedVideosCount >= maxVideos) {
          setVideoExceedsMax(true);
          setVideoExceedsMaxMessage(true);

          setTimeout(() => {
            setVideoExceedsMaxMessage(false);
          }, 5000);
        } else {
          setFiles([...files, ...newFiles]);
          setVideoExceedsMax(false);
        }
      }
    } else {
      // invalid image. Show message
    }
    fileInput.value = "";
  };

  //Select Images and upload

  const handleFileChange = async (e) => {
    setFiles([]);
    setNewImageValues({
      imageTitle: "",
      imageSource: "",
      imageCredit: "",
      mediaType: "image"
    });
    const newFiles = Array.from(e.target.files);
    const validImage = isValidMedia(newFiles[0], "image");
    const fileInput = document.getElementById("fileInput");
    if (validImage) {
      const uploadedImageCount = momentImages.filter(image => image.media_type === "image").length;
      if (uploadedImageCount >= maxImages) {
        setExceedsMax(true);
        setExceedImageMessage(true);

        setTimeout(() => {
          setExceedImageMessage(false);
        }, 5000);
      } else {
        setExceedsMax(false);
        setFiles([...files, ...newFiles]);
      }
    } 
    fileInput.value = "";
  };

  const isValidMedia = (file, mediaType) => {
    if (file && file.type) {
      let validTypes = ["image/jpeg", "image/png", "image/webp", "image/jpg"];
      if(mediaType === "video"){
        validTypes = ["video/mp4", "video/m4v", "video/mpg", "video/mpeg", "video/wmv", "video/mov", "video/webm", "video/flv", "video/avi"];
      }
  
      if (validTypes.includes(file.type)) {
        return true;
      } else {
        setErrorMessage("Incorrect file type");

        setTimeout(() => {
          setErrorMessage("");
        }, 5000);
        return false;
      }
    }
  };

  const deleteImage = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    // setFiles(newFiles);
    setFiles([]);
    setExceedsMax(false); // Reset the exceedsMax state when an image is deleted
  };

  //Video Upload

  const handleVideoFileChange = (e) => {
    setFiles([]);
    setNewImageValues({
      imageTitle: "",
      imageSource: "",
      imageCredit: "",
    });
    const fileInput = document.getElementById("videoFileInput");
    const newFiles = Array.from(e.target.files);
    // Count already uploaded videos
    const uploadedVideosCount = momentImages.filter(image => image.media_type === "video").length;
    if (uploadedVideosCount >= maxVideos) {
      setVideoExceedsMax(true);
      setVideoExceedsMaxMessage(true);

      setTimeout(() => {
        setVideoExceedsMaxMessage(false);
      }, 5000);
      return;
  }else{
    setVideoExceedsMax(false);
  }
    if (newFiles.length > 0) {
      const selectedFile = newFiles[0];
      if (selectedFile.size <= 10 * 1024 * 1024) { // 10MB limit
        //alert('File uploaded successfully!');
        const validVideo = isValidMedia(newFiles[0], "video");
       
        if (validVideo) {
          setFiles([selectedFile]);
        }
        
      } else {
        setErrorMessage('Please select a file less than 10MB.');
        setFiles([]);
      }
    }
    fileInput.value = "";
  };
  

  //Existing data in database
  const [existData, setExistData] = useState([
    {
      id: 1,
      url: "https://digicult.it/wp-content/uploads/2018/02/Free-Desktop-Wallpaper-feature.jpg",
      title: "this is title 1",
      source: "this is source 1",
      credit: "this is credit 1",
    },
    {
      id: 2,
      url: "https://e1.pxfuel.com/desktop-wallpaper/358/670/desktop-wallpaper-sea-tropical-beach-island-summer-tropical-island-the-azure-ocean-section-%D0%BF%D0%B5%D0%B9%D0%B7%D0%B0%D0%B6%D0%B8-tropical-beach-summer-ocean.jpg",
      title: "this is title 2",
      source: "this is source 2",
      credit: "this is credit 2",
    },
  ]);

  const handleValueChange = (event, index) => {
    const {value, id} = event.target
    const newData = [...momentImages];

    if(id === "imageTitle"){
      newData[index].title = value;
    }else if(id === "imageSource"){
      newData[index].source = value;
    }else {
      newData[index].credit = value;
    }
    
    setMomentImages(newData);
  };

  const handleShow = (index) => {
    setPopupMessages({
      title: "Warning!",
      subtitle1: "Are you sure you want to delete this media? ",
      subtitle2:
        "This action is irreversible and the image cannot be recovered once deleted.",
      button1: "Delete",
      button2: "Cancel",
    });
    setConfirmationPopupShow(true);
    setImageIndex(index);
  };

  const deleteExistImage = async (index) => {
    const imagesList = [...momentImages];

    const imageToRemove = imagesList[index];

    if (imageToRemove.key && imageToRemove.key !== "" && imageToRemove.bucket ) {
      const imgKeyArray = cloudImagesToDelete;
      imgKeyArray.push({key: imageToRemove.key, bucket: imageToRemove.bucket});
      setCloudImagesToDelete(imgKeyArray);
    }

    imagesList.splice(index, 1);
    setMomentImages(imagesList);
    setMessage("Media deleted successfully");

    setTimeout(() => {
      setMessage("");
    }, 3000);
    // setExistData(newFiles);
    if(imageToRemove.media_type === "image"){
      setExceedsMax(false);
    }else if (imageToRemove.media_type === "video"){
      setVideoExceedsMax(false);
    }
    
  };

  const displayMomentTypes = (types) => {
    if (types && types.length > 0) {
      let existingTypes = [];
      types.map((type) => {
        existingTypes.push({ value: type, label: type });
      });

      handleChange(existingTypes);
    }
  };

  const handleChange = (selectedOptions) => {
    setSelectedTags(selectedOptions);
  };

  const handleInputChange = (inputValue) => {
    setInputValue(inputValue);
  };

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setSelectedTags([
          ...selectedTags,
          { value: inputValue, label: inputValue },
        ]);
        setInputValue("");
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  const uploadMedia = async (mediaType) => {
    setLoading(true);
    let lifeId = null;
    if (momentLife && momentLife.life_id) {
      lifeId = momentLife.life_id;
    } else if (URLState && URLState.lifeData && URLState.lifeData.life_id) {
      lifeId = URLState.lifeData.life_id;
    }

    if(lifeId){
      if(mediaType === "video"){
        await uploadVideo(lifeId)
      }else {
        await uploadImage(lifeId);
      }
    }else {
      setLoading(false);
    }
  };

  const uploadImage = async (lifeId) => {
    dispatch(await storeImagesInCloud(files[0], lifeId, cloudBucket)).then((res) => {
      setMomentImages([
        {
          url: res.imageUrl,
          title: newImagesValues.imageTitle,
          source: newImagesValues.imageSource,
          credit: newImagesValues.imageCredit,
          media_type: res.mediaType,
          key: res.key,
          bucket: cloudBucket
        },
        ...momentImages,
      ]);
      setFiles([]);
      setNewImageValues({
        imageTitle: "",
        imageSource: "",
        imageCredit: "",
      });

      setLoading(false);
      setMessage("Image uploaded successfully");
      setTimeout(() => {
        setMessage("");
      }, 3000);
    });
  };

  const uploadVideo = async (lifeId) => {
    dispatch(await storeVideoInCloud(files[0], lifeId, cloudBucket)).then((res) => {
      setMomentImages([
        {
          url: res.videoUrl,
          title: newImagesValues.imageTitle,
          source: newImagesValues.imageSource,
          credit: newImagesValues.imageCredit,
          media_type: res.mediaType,
          key: res.key,
          bucket: cloudBucket
        },
        ...momentImages,
      ]);
      setFiles([]);
      setNewImageValues({
        imageTitle: "",
        imageSource: "",
        imageCredit: "",
      });

      setLoading(false);
      setMessage("Video uploaded successfully");
      setTimeout(() => {
        setMessage("");
      }, 3000);
    });
  }

  const onChangeImageValues = (evt) => {
    const { id, value } = evt.target;
    setNewImageValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
  };

  const handleTextValueChange = (evt) => {
    const { id, value } = evt.target;

    if (id === "moment_details") {
      if (value.length <= maxLength) {
        setSelectedMoment((prevValues) => ({
          ...prevValues,
          moment_details: value,
        }));
      }
    } else {
      setSelectedMoment((prevValues) => ({
        ...prevValues,
        [id]: value,
      }));
    }
  };

  const setPrivacy = (evt) => {
    const privacyVal = evt.target.value;
    setSelectedMoment((prevValues) => ({
      ...prevValues,
      moment_privacy: [privacyVal],
    }));
  };

  const onChangeMomentDate = (dateVal) => {
    const formattedDate = MomentJs(dateVal).format("DD-MM-YYYY");

    setMomentFullDate(formattedDate);
    setMomentYear(MomentJs(dateVal).format("YYYY"));
    setMomentMonth(MomentJs(dateVal).format("MMM"));
    setMomentDay(MomentJs(dateVal).format("DD"));

    setShowCalendar(false);
  };

  const handleMomentDate = (evt) => {
    const { id, value } = evt.target;

    setManualDateValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
  };

  const updateSelectedMoment = async () => {
    let momentData = selectedMoment;
    let momentDate = constructMomentDate(momentData.moment_date);

    momentData.moment_date = momentDate;
    momentData.shareable = shareableValue;
    momentData.commentable = commentableValue;
    momentData.connectable = connectableValue ? "yes" : "no";
    momentData.moment_type = selectedTags.map((obj) => obj.value);
    momentData.top_moment = topMomentCheck;
    momentData.world_moment = worldMomentCheck;
    momentData.sustainability_moment = sustainabilityMomentCheck;
    momentData.featured = featuredMomentCheck;
    momentData.national_moment = selectedNations.map((obj) => obj.value);
    momentData.images = momentImages;
    momentData.impressionable = impressionableValue;
    momentData.impression_types = impressionTypes;

    dispatch(
      await updateMoment({ id: momentData.id, updates: momentData })
    ).then(async (res) => {
      let updateCompleted = false;
      if (res && res.success) {
        if (cloudImagesToDelete.length > 0) {
          updateCompleted = await removeImagesFromCloud();
        } else {
          updateCompleted = res.success;
        }
        if (res.data) {
          let moment = res.data;
          moment.id = res.data._id;
          setSelectedMoment(moment);
        }

        if (updateCompleted) {
          setCloudImagesToDelete([]);
          setLoading(true);
          setShow(true);
          dispatch(await updateMomentsSorting(-1))
          setMessage(
            <>
              <span style={{ fontWeight: 'bold' }}>Moment updated !<br/></span>
              <br />
              <span>
                You will be redirected to the updated Moment. Please wait...
              </span>
            </>
          );
          
          setTimeout(() => {
            navigate(`/${momentLife.verified_name?momentLife.verified_name:momentLife.life_id}/${momentId}`);
          }, 5000);
        }
      } else {
        // error update
        setShow(true);
        setMessage("Error in Updating");

        setTimeout(() => {
          setShow(false);
        }, 3000);
      }
    });
  };

  const onChangeInputMediaValues = (evt, mediaType) => {
    const { id, value } = evt.target;
    setNewInputImageValues((prevValues) => ({
      ...prevValues,
      [id]: value,
      mediaType: mediaType? mediaType : "image"
    }));
  };

  const addNewImage = () => {
    setMomentImages([
      {
        url: newInputImagesValues.imageUrl,
        title: newInputImagesValues.imageTitle,
        source: newInputImagesValues.imageSource,
        credit: newInputImagesValues.imageCredit,
        media_type: newInputImagesValues.mediaType
      },
      ...momentImages,
    ]);

    setNewInputImageValues({
      imageUrl: "",
      imageTitle: "",
      imageSource: "",
      imageCredit: "",
      mediaType: "image"
    });
  };

  const clearImageValues = () => {
    setNewInputImageValues({
      imageUrl: "",
      imageTitle: "",
      imageSource: "",
      imageCredit: "",
      mediaType: "image"
    });
  };

  const removeImagesFromCloud = async () => {
    const res = await dispatch(
      removeMomentImage({ imagesToDelete: cloudImagesToDelete })
    );
    return res.success;
  };

  const handleImageUploads = () => {
    setShowLinkUpload(false);
    setShowImageUpload(true);
  };

  const handleLinkUploads = () => {
    setShowLinkUpload(true);
    setShowImageUpload(false);
  };

  const createNewMoment = async () => {
    if (URLState && URLState.lifeData && URLState.lifeData.life_id) {
      let momentData = selectedMoment;
      let momentDate = constructMomentDate(momentData.moment_date);

      let lifeId = null;
    if (momentLife && momentLife.life_id) {
      lifeId = momentLife.life_id;
    } else if (URLState && URLState.lifeData && URLState.lifeData.life_id) {
      lifeId = URLState.lifeData.life_id;
    }

      delete momentData.id;
      momentData.moment_date = momentDate;
      momentData.shareable = shareableValue;
      momentData.commentable = commentableValue;
      momentData.connectable = connectableValue ? "yes" : "no";
      momentData.moment_type = selectedTags.map((obj) => obj.value);
      momentData.top_moment = topMomentCheck;
      momentData.world_moment = worldMomentCheck;
      momentData.sustainability_moment = sustainabilityMomentCheck;
      momentData.featured = featuredMomentCheck;
      momentData.national_moment = selectedNations.map((obj) => obj.value);
      momentData.images = momentImages;
      momentData.impressionable = impressionableValue;
      momentData.life = URLState.lifeData._id;
      momentData.era = era;
      momentData.impression_types = impressionTypes;
      momentData.life_id = lifeId;

      dispatch(await createMoment(momentData)).then(async (res) => {
        if (res && res.success && res.data) {
          const moment = res.data;
          if (cloudImagesToDelete.length > 0) {
            await removeImagesFromCloud();
          }
          setShow(true);
          setMessage("Created Successfully");
          dispatch(await updateMomentsSorting(-1))
          setTimeout(() => {
            navigate(`/${URLState.lifeData.verified_name?URLState.lifeData.verified_name:URLState.lifeData.life_id}/${moment._id}`);
          }, 3000);
        } else {
          setShow(true);
          setMessage("Error in creating moment");

          setTimeout(() => {
            setShow(false);
          }, 3000);
        }
      });
    }
  };

  const constructMomentDate = (initialDate) => {
    let momentDate = initialDate;

    if (showDateAdding) {
      if (manualDateValues.day === "" && manualDateValues.month === "") {
        momentDate = manualDateValues.year;
      } else if (manualDateValues.day === "") {
        const monthVal = shortMonths[Number(manualDateValues.month) - 1];
        momentDate = `${monthVal}-${manualDateValues.year}`;
      } else {
        const monthVal = shortMonths[Number(manualDateValues.month) - 1];
        momentDate = `${manualDateValues.day}-${monthVal}-${manualDateValues.year}`;
      }
    } else {
      if (momentDay === "" && momentMonth === "") {
        momentDate = momentYear;
      } else if (momentDay === "") {
        momentDate = `${momentMonth}-${momentYear}`;
      } else {
        momentDate = `${momentDay}-${momentMonth}-${momentYear}`;
      }
    }
    return momentDate;
  };

  const handleDateSelection = () => {
    setShowCalendar((prevState) => !prevState);
  };

  const [cancel, setCancel] = useState(false);

  const handleCancelBtn = () => {
    setCancel(true);
    setPopupMessages({
      title: "Are you sure you want to leave without updating? ",
      subtitle1: "Changes you made will not be saved if you continue.",
      button1: "Yes",
      button2: "Cancel",
    });
    setConfirmationPopupShow(true);
  };

  const handleDateAdding = () => {
    setDateAdding((prevState) => !prevState);
  };

  const setctionButtonDisability = () => {
    let isDisabled = false;
    if (showDateAdding) {
      isDisabled = manualDateValues.year === "";
    } else {
      isDisabled =
        momentYear === "" ||
        selectedMoment.moment_title === "" ||
        selectedMoment.moment_details === "" ||
        momentCountExceeded;
    }

    return isDisabled;
  };

  const [selectImage, setSelectImage] = useState(false);
  const [selectVideo, setSelectVideo] = useState(false);

  const handleSelectImage = () => {
    setSelectImage(true);
    setSelectVideo(false);
    setShowLinkVideoUpload(false);
    setShowVideoUpload(true);
  };

  const handleSelectVideo = () => {
    setSelectVideo(true);
    setSelectImage(false);
    setShowLinkUpload(false);
    setShowImageUpload(true);
  };

  const handleVideoUploads = () => {
    setShowLinkVideoUpload(false);
    setShowVideoUpload(true);
  };

  const handleVideoLinkUploads = () => {
    setShowLinkVideoUpload(true);
    setShowVideoUpload(false);
  };

  const selectBucket = (evt) => {
    setCloudBucket(evt.target.value);
  };

  const handleEra = (evt) => {
    setEra(evt.target.value);
  }
  const handleImpressions = (evt, type) => {
    // handling impression values
    if(type){
      setImpressionTypes((prevValues) => ({
        ...prevValues,
        [type]: evt.target.checked,
      }));
    }
  };

  return (
    <div>
      <NavigationBar></NavigationBar>

      <PopUpModal
        title={message}
        show={show}
        setShow={(bool) => setShow(bool)}
      ></PopUpModal>

      <ConfirmationPopup
        title={popupMessages.title}
        subtitle1={popupMessages.subtitle1}
        subtitle2={popupMessages.subtitle2}
        button1={popupMessages.button1}
        button2={popupMessages.button2}
        confirmationPopupShow={confirmationPopupShow}
        setConfirmationPopupShow={(bool) => setConfirmationPopupShow(bool)}
        deleteExistImage={deleteExistImage}
        imageIndex={imageIndex}
        noInfoMessage={noInfoMessage}
        cancel={cancel}
        setCancel={setCancel}
        setPopupMessages={setPopupMessages}
      ></ConfirmationPopup>

      <div className="update-moment-main">
        <Row className="topic-row">
          <Col sm={1}>
            <div className="back" onClick={() => navigate(-1)}>
              <span>
                <img
                  src={images.back}
                  alt="back-image"
                  style={{
                    width: "15px",
                    marginRight: "10px",
                    marginTop: "-4px",
                  }}
                />
              </span>
              Back
            </div>
          </Col>
          <Col>
            <div className="topic">
              {isCreateMoment ? "Create" : "Update"} Moment
            </div>
          </Col>
        </Row>
        <div className="profile-row">
          <div className="profile-image-name">
            <div>
              <img className="profile-image" src={lifeLogo}></img>
            </div>
            <div>
              <div className="profile-name">{lifeName}</div>
              <div className="detail-row">
                <div className="d-flex date-picker">
                  {!showDateAdding && (
                    <>
                      <input
                        className="month-name"
                        type="text"
                        id="day"
                        placeholder="DD-MM-YYYY"
                        maxLength="10"
                        value={momentFullDate}
                        readOnly
                      />
                      <div onClick={handleDateSelection}>
                        <i class="bi bi-calendar-week-fill"></i>
                      </div>
                    </>
                  )}

                  {showDateAdding && (
                    <>
                      <input
                        className="date-day"
                        type="number"
                        id="day"
                        placeholder="DD"
                        maxLength="2"
                        value={manualDateValues.day}
                        onChange={handleMomentDate}
                      />
                      <input
                        className="date-month"
                        type="number"
                        id="month"
                        placeholder="MM"
                        maxLength="2"
                        value={manualDateValues.month}
                        onChange={handleMomentDate}
                      ></input>
                      <input
                        className="date-year"
                        type="number"
                        id="year"
                        placeholder="YYYY"
                        maxLength="4"
                        value={manualDateValues.year}
                        onChange={handleMomentDate}
                      />
                      <select
                        onChange={handleEra}
                        value={era}
                        className="select"
                        style={{ width: "100%" }}
                      >
                        <option value="CE">CE</option>
                        <option value="BCE">BCE</option>
                      </select>
                    </>
                  )}

                  {showCalendar && (
                    <div
                      ref={calendarRef}
                      className="calendar"
                      style={{
                        position: "absolute",
                        zIndex: "1",
                      }}
                    >
                      <Calendar
                        onChange={onChangeMomentDate}
                        className="calendar"
                      />
                    </div>
                  )}

                  {mooflifeAdmin && (
                    <div style={{ marginLeft: "5px" }}>
                      <Form.Check
                        type="radio"
                        className="yellow-switch"
                        id="shareable"
                        onClick={handleDateAdding}
                        checked={showDateAdding}
                      />
                    </div>
                  )}
                </div>
                <Col className="d-flex select-option">
                  <div>Privacy</div>

                  <select
                    onChange={setPrivacy}
                    value={selectedMoment.moment_privacy[0]}
                    className="select"
                    style={{ width: "100%" }}
                  >
                    <option value="private">Private</option>
                    <option value="public">Public</option>
                  </select>
                </Col>
                <div className="checkbox-option d-flex">
                  <div className="title-1">Top Moment</div>
                  <span></span>
                  <Form.Check
                    className="yellow-checkbox"
                    type="checkbox"
                    id="topMoment"
                    checked={topMomentCheck}
                    onChange={onChangeCheckBoxes}
                  />
                </div>

                {mooflifeAdmin && (
                  <div className="checkbox-option d-flex">
                    <div className="title-2">World Moment</div>
                    <Form.Check
                      className="yellow-checkbox"
                      type="checkbox"
                      id="worldMoment"
                      checked={worldMomentCheck}
                      onChange={onChangeCheckBoxes}
                    />
                  </div>
                )}

                {mooflifeAdmin && (
                  <div className="checkbox-option d-flex">
                    <div className="title-2">Sustainability Moment</div>
                    <Form.Check
                      className="yellow-checkbox"
                      type="checkbox"
                      id="sustainabilityMoment"
                      checked={sustainabilityMomentCheck}
                      onChange={onChangeCheckBoxes}
                    />
                  </div>
                )}

                {mooflifeAdmin && (
                  <div className="checkbox-option d-flex">
                    <div className="title-2">Featured Moment</div>
                    <Form.Check
                      className="yellow-checkbox"
                      type="checkbox"
                      id="featuredMoment"
                      checked={featuredMomentCheck}
                      onChange={onChangeCheckBoxes}
                    />
                  </div>
                )}

                {mooflifeAdmin && (
                  <div className="country-select-row d-flex">
                    <div className="title-1">National Moment</div>
                    <MultiSelect
                      className="country-select-box"
                      options={countryList.map((countryItem) => ({
                        label: countryItem.country,
                        value: countryItem.country,
                      }))}
                      value={selectedNations}
                      onChange={setSelectedNations}
                      labelledBy="Select"
                      valueRenderer={(selected) =>
                        selected.length > 242
                          ? "All countries selected"
                          : `${selected.length} countries selected`
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="main-form">
          <Card className="card">
            <Card.Body>
              <div className="left-right-form">
                <Col>
                  <div className="left-side-form">
                    <div>
                      <p>
                        Moment title
                        <OverlayTrigger
                          className="tooltip-description"
                          key="right"
                          placement="right"
                          overlay={
                            <Tooltip id="right">
                              Enter a concise and descriptive title for your
                              moment. This will be the first thing others see
                              about your entry
                            </Tooltip>
                          }
                        >
                          <i
                            class="bi bi-exclamation-circle"
                            style={{
                              marginLeft: "10px",
                              fontSize: "15px",
                              fontSize: "15px",
                            }}
                          ></i>
                        </OverlayTrigger>{" "}
                      </p>
                      <input
                        className="form-input"
                        type="text"
                        id="moment_title"
                        value={selectedMoment.moment_title}
                        onChange={handleTextValueChange}
                        placeholder="How do you title this moment?"
                      ></input>
                    </div>
                    <div>
                      <p>
                        Moment type
                        <OverlayTrigger
                          className="tooltip-description"
                          key="right"
                          placement="right"
                          overlay={
                            <Tooltip id="right">
                              Select a category that best describes the nature
                              of your moment. This helps others understand the
                              context at a glance.
                            </Tooltip>
                          }
                        >
                          <i
                            class="bi bi-exclamation-circle"
                            style={{ marginLeft: "10px", fontSize: "15px" }}
                          ></i>
                        </OverlayTrigger>{" "}
                      </p>
                      <div className="select-types">
                        <Select
                          options={options}
                          isMulti
                          onChange={handleChange}
                          value={selectedTags}
                          onInputChange={handleInputChange}
                          inputValue={inputValue}
                          onKeyDown={handleKeyDown}
                          placeholder="e.g. Graduation, Family Moment, Sports Event"
                          noOptionsMessage={() =>
                            "Press Enter to add Moment Type"
                          }
                        />
                      </div>
                    </div>
                    <div>
                      <p>
                        Moment details
                        <OverlayTrigger
                          className="tooltip-description"
                          key="right"
                          placement="right"
                          overlay={
                            <Tooltip id="right">
                              Provide a detailed description of your moment.
                              Include key details that will convey the
                              significance and impact of this moment.
                            </Tooltip>
                          }
                        >
                          <i
                            class="bi bi-exclamation-circle"
                            style={{ marginLeft: "10px", fontSize: "15px" }}
                          ></i>
                        </OverlayTrigger>{" "}
                      </p>
                      <textarea
                        className="form-text-area"
                        as="textarea"
                        name="details"
                        id="moment_details"
                        value={selectedMoment.moment_details}
                        placeholder="Enter details about this moment. For your privacy, avoid including  highly  sensitive or personal information that you wouldn't want to  be publicly visible."
                        maxLength={maxLength}
                        onChange={handleTextValueChange}
                      ></textarea>
                      <div
                        style={{
                          marginTop: "0.25rem",
                          textAlign: "right",
                          fontWeight: "500",
                          color: "#C0C0C0",
                          fontSize: "13px",
                        }}
                        id="counter"
                      >
                        {selectedMoment.moment_details.length}/{maxLength}
                      </div>
                    </div>

                    {mooflifeAdmin && (
                      <div>
                        <p>External web link title</p>
                        <input
                          className="form-input"
                          type="text"
                          id="external_link_title"
                          onChange={handleTextValueChange}
                          value={selectedMoment.external_link_title}
                          placeholder="Web link title"
                        ></input>
                      </div>
                    )}

                    {mooflifeAdmin && (
                      <div>
                        <p>External web link</p>
                        <input
                          className="form-input"
                          type="text"
                          id="external_link"
                          onChange={handleTextValueChange}
                          value={selectedMoment.external_link}
                          placeholder="Are there any references to this moment in the web or news? "
                        ></input>
                      </div>
                    )}
                  </div>
                  <div>
                    <Accordion className="accordion-main">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header className="accordion-header">
                          Advance Moment settings
                        </Accordion.Header>

                        <Accordion.Body className="accordion-body">
                          <Row>
                            <div className="d-flex switch-option">
                              <div
                                className="checkbox-option d-flex"
                                style={{ marginRight: "30px" }}
                              >
                                <div className="title-1">Comments</div>
                                <Form.Check
                                  type="switch"
                                  className="yellow-switch"
                                  id="commentable"
                                  checked={commentableValue}
                                  onChange={onChangeSwitches}
                                />
                              </div>
                              <div
                                className="checkbox-option d-flex"
                                style={{ marginRight: "30px" }}
                              >
                                <div className="title-1">Sharing</div>
                                <Form.Check
                                  type="switch"
                                  className="yellow-switch"
                                  id="shareable"
                                  checked={shareableValue}
                                  onChange={onChangeSwitches}
                                />
                              </div>
                              <div
                                className="checkbox-option d-flex"
                                style={{ marginRight: "30px" }}
                              >
                                <div className="title-1">Connecting</div>
                                <Form.Check
                                  type="switch"
                                  className="yellow-switch"
                                  id="connectable"
                                  checked={connectableValue}
                                  onChange={onChangeSwitches}
                                />
                              </div>
                       
                            </div>
                            <div className="switch-option d-flex">
                                <div className="title-1">Impression</div>
                                {/* <Form.Check
                                  type="switch"
                                  className="yellow-switch"
                                  id="impressionable"
                                  checked={impressionableValue}
                                  onChange={onChangeSwitches}
                                /> */}
                                <div className="icon-set">
                                  <div className="checkbox-option d-flex">
                                    <span></span>
                                   
                                      <div className="title-1">
                                        <img
                                          src={images.LikeIcon}
                                          width="30px"
                                        ></img>
                                      </div>
                                   
                                    <Form.Check
                                      className="yellow-checkbox"
                                      type="checkbox"
                                      checked={impressionTypes.like}
                                      onChange={(evt) => {handleImpressions(evt, "like")}}
                                    />
                                  </div>
                                  <div className="checkbox-option d-flex">
                                    <span></span>
                                    
                                      <div className="title-1">
                                        <img
                                          src={images.GreatIcon}
                                          width="30px"
                                        ></img>
                                      </div>
                                    <Form.Check
                                      className="yellow-checkbox"
                                      type="checkbox"
                                      checked={impressionTypes.great}
                                      onChange={(evt) => {handleImpressions(evt, "great")}}
                                    />
                                  </div>
                                  <div className="checkbox-option d-flex">
                                    <span></span>
                                    
                                      <div className="title-1">
                                        <img
                                          src={images.EmotionalIcon}
                                          width="30px"
                                        ></img>
                                      </div>
                                    <Form.Check
                                      className="yellow-checkbox"
                                      type="checkbox"
                                      checked={impressionTypes.emotional}
                                      onChange={(evt) => {handleImpressions(evt, "emotional")}}
                                    />
                                  </div>
                                  <div className="checkbox-option d-flex">
                                    <span></span>
                                  
                                      <div className="title-1">
                                        <img
                                          src={images.NostalgicIcon}
                                          width="30px"
                                        ></img>
                                      </div>
                                    <Form.Check
                                      className="yellow-checkbox"
                                      type="checkbox"
                                      checked={impressionTypes.nostalgic}
                                      onChange={(evt) => {handleImpressions(evt, "nostalgic")}}
                                    />
                                  </div>
                                  <div className="checkbox-option d-flex">
                                    <span></span>
                                      <div className="title-1">
                                        <img
                                          src={images.WishIcon}
                                          width="30px"
                                        ></img>
                                      </div>
                                    <Form.Check
                                      className="yellow-checkbox"
                                      type="checkbox"
                                      checked={impressionTypes.wish}
                                      onChange={(evt) => {handleImpressions(evt, "wish")}}
                                    />
                                  </div>
                                  <div className="checkbox-option d-flex">
                                    <span></span>
                                      <div className="title-1">
                                        <img
                                          src={images.PartOfTheMomentIcon}
                                          width="30px"
                                        ></img>
                                      </div>
                                    <Form.Check
                                      className="yellow-checkbox"
                                      type="checkbox"
                                      checked={impressionTypes.part}
                                      onChange={(evt) => {handleImpressions(evt, "part")}}
                                    />
                                  </div>
                                  <div className="checkbox-option d-flex">
                                    <span></span>
                                      <div className="title-1">
                                        <img
                                          src={images.WitnessedIcon}
                                          width="30px"
                                        ></img>
                                      </div>
                                    <Form.Check
                                      className="yellow-checkbox"
                                      type="checkbox"
                                      checked={impressionTypes.witnessed}
                                      onChange={(evt) => {handleImpressions(evt, "witnessed")}}
                                    />
                                  </div>
                                </div>
                              </div>
                          </Row>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </Col>
                <Col>
                  <div className="right-side-form">
                    <div className="topic-dropdown-row">
                      <p>Moment Media</p>
                      {mooflifeAdmin && (
                        <select onChange={selectBucket}>
                          <option value="DO">DigitalOcean</option>
                          <option value="CF">Cloudflare</option>
                        </select>
                      )}
                    </div>
                    <div className="choose-upload-type">
                      <div className="image-upload" onClick={handleSelectImage}>
                        <Form.Check
                          type="radio"
                          className="yellow-switch"
                          id="shareable"
                          checked={selectImage}
                        />
                        <div>Image</div>
                      </div>

                      <div className="link-upload" onClick={handleSelectVideo}>
                        <Form.Check
                          type="radio"
                          className="yellow-switch"
                          id="shareable"
                          checked={selectVideo}
                        />
                        <div>Video</div>
                      </div>
                    </div>

                    <div className="choose-upload-type">
                      {mooflifeAdmin && selectImage && (
                        <>
                          <div
                            className="image-upload"
                            onClick={handleImageUploads}
                          >
                            <Form.Check
                              type="radio"
                              className="yellow-switch"
                              id="shareable"
                              checked={showImageUpload}
                            />
                            <div>Upload File</div>
                          </div>

                          <div
                            className="link-upload"
                            onClick={handleLinkUploads}
                          >
                            <Form.Check
                              type="radio"
                              className="yellow-switch"
                              id="shareable"
                              checked={showLinkUpload}
                            />
                            <div>Add through a link</div>
                          </div>
                        </>
                      )}

                      {mooflifeAdmin && selectVideo && (
                        <>
                          <div
                            className="image-upload"
                            onClick={handleVideoUploads}
                          >
                            <Form.Check
                              type="radio"
                              className="yellow-switch"
                              id="shareable"
                              checked={showVideoUpload}
                            />
                            <div>Upload Video</div>
                          </div>

                          <div
                            className="link-upload"
                            onClick={handleVideoLinkUploads}
                          >
                            <Form.Check
                              type="radio"
                              className="yellow-switch"
                              id="shareable"
                              checked={showLinkVideoUpload}
                            />
                            <div>Add Video through a link</div>
                          </div>
                        </>
                      )}
                    </div>

                    <div className="image-uploader">
                      <div
                        className={`img-card ${isDragOver ? "dragover" : ""}`}
                      >
                        <div className="top"></div>
                        {/* image upload */}
                        {showImageUpload && selectImage && (
                          <div
                            className="dragover form"
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}
                          >
                            {files.length > 0 && (
                              <div className="image-uploader-overlay"></div>
                            )}

                            <div>
                              <img src={images.uploadImg} width="40px"></img>
                            </div>
                            <span className="inner">
                              Drag and drop jpg, jpeg ,png, webp or
                            </span>
                            <label
                              htmlFor="fileInput"
                              className="select"
                              style={{
                                background:
                                  files.length > 0 ? "#9E9797" : "#3787FF",
                              }}
                            >
                              Select Image
                            </label>
                            <input
                              id="fileInput"
                              name="file"
                              type="file"
                              className="file"
                              onChange={handleFileChange}
                              accept="image/*"
                            />
                          </div>
                        )}

                        {mooflifeAdmin && showLinkUpload && selectImage && (
                          <div className="image-link-section">
                            <div className="label-input">
                              <div className="label">Image link</div>
                              <input
                                type="text"
                                className="form-input"
                                placeholder="Image link"
                                value={newInputImagesValues.imageUrl}
                                id="imageUrl"
                                onChange={onChangeInputMediaValues}
                              />
                            </div>
                            <div className="label-input">
                              <div className="label">Title</div>
                              <input
                                type="text"
                                className="form-input"
                                placeholder="Image title"
                                value={newInputImagesValues.imageTitle}
                                id="imageTitle"
                                onChange={onChangeInputMediaValues}
                              />
                            </div>
                            <div className="label-input">
                              <div className="label">Source</div>
                              <input
                                type="text"
                                className="form-input"
                                placeholder="Image source"
                                value={newInputImagesValues.imageSource}
                                id="imageSource"
                                onChange={onChangeInputMediaValues}
                              />
                            </div>
                            <div className="label-input">
                              <div className="label">Credit</div>
                              <input
                                type="text"
                                className="form-input"
                                placeholder="Image credit"
                                value={newInputImagesValues.imageCredit}
                                id="imageCredit"
                                onChange={onChangeInputMediaValues}
                              />
                            </div>
                            <button
                              className="add-img-btn yellow-btn"
                              onClick={() => addNewImage()}
                              disabled={newInputImagesValues.imageUrl === ""}
                            >
                              Add image
                            </button>
                            <button
                              className="clear-btn yellow-btn"
                              onClick={() => clearImageValues()}
                            >
                              Clear
                            </button>
                          </div>
                        )}

                        {/* video upload */}
                        {showVideoUpload && selectVideo && (
                          <div
                            className="dragover form"
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={(e) => handleDrop(e, "video")}
                          >
                            {files.length > 0 && (
                              <div className="image-uploader-overlay"></div>
                            )}

                            <div>
                              <img src={images.uploadImg} width="40px"></img>
                            </div>
                            <span className="inner">Drag and drop mp4 or</span>
                            <label
                              htmlFor="videoFileInput"
                              className="select"
                              style={{
                                background:
                                  files.length > 0 ? "#9E9797" : "#3787FF",
                              }}
                            >
                              Select Video
                            </label>
                            <input
                              id="videoFileInput"
                              name="videoFile"
                              type="file"
                              className="file"
                              onChange={handleVideoFileChange}
                              accept="video/*"
                            />
                          </div>
                        )}

                        {mooflifeAdmin &&
                          showLinkVideoUpload &&
                          selectVideo && (
                            <div className="image-link-section">
                              <div className="label-input">
                                <div className="label">Video link</div>
                                <input
                                  type="text"
                                  className="form-input"
                                  placeholder="Video link"
                                  value={newInputImagesValues.imageUrl}
                                  id="imageUrl"
                                  onChange={(evt) =>
                                    onChangeInputMediaValues(evt, "video")
                                  }
                                />
                              </div>
                              <div className="label-input">
                                <div className="label">Title</div>
                                <input
                                  type="text"
                                  className="form-input"
                                  placeholder="Video title"
                                  value={newInputImagesValues.imageTitle}
                                  id="imageTitle"
                                  onChange={(evt) =>
                                    onChangeInputMediaValues(evt, "video")
                                  }
                                />
                              </div>
                              <div className="label-input">
                                <div className="label">Source</div>
                                <input
                                  type="text"
                                  className="form-input"
                                  placeholder="Video source"
                                  value={newInputImagesValues.imageSource}
                                  id="imageSource"
                                  onChange={(evt) =>
                                    onChangeInputMediaValues(evt, "video")
                                  }
                                />
                              </div>
                              <div className="label-input">
                                <div className="label">Credit</div>
                                <input
                                  type="text"
                                  className="form-input"
                                  placeholder="Video credit"
                                  value={newInputImagesValues.imageCredit}
                                  id="imageCredit"
                                  onChange={(evt) =>
                                    onChangeInputMediaValues(evt, "video")
                                  }
                                />
                              </div>
                              <button
                                className="add-img-btn yellow-btn"
                                onClick={addNewImage}
                                disabled={newInputImagesValues.imageUrl === ""}
                              >
                                Add Video
                              </button>
                              <button
                                className="clear-btn yellow-btn"
                                onClick={clearImageValues}
                              >
                                Clear
                              </button>
                            </div>
                          )}

                        {loading ? (
                          <Spinner
                            animation="border"
                            variant="dark"
                            style={{
                              width: "15px",
                              height: "15px",
                              textAlign: "center",
                              margin: "auto",
                              display: "flex",
                              marginTop: "20px",
                            }}
                          />
                        ) : (
                          ""
                        )}

                        <div className="success-message">{message}</div>
                        <div className="error-message">{errorMessage}</div>

                        {exceedImageMessage && (
                          <div
                            className="error-message"
                            style={{ marginBottom: "20px" }}
                          >
                            You've reached the maximum number of images.
                          </div>
                        )}

                        {exceedsVideoMaxMessage && (
                          <div
                            className="error-message"
                            style={{ marginBottom: "20px" }}
                          >
                            You've reached the maximum number of videos.
                          </div>
                        )}

                        {/* Newly added Images */}
                        {files.length > 0 && selectImage && (
                          selectImage && (
                            <div className="im-container new-images">
                              {files.map((file, index) => (
                                <>
                                  <div className="input-row">
                                    <div className="input-details">
                                      <div>
                                        <div className="image" key={index}>
                                          <img
                                            src={URL.createObjectURL(file)}
                                            alt={`Image ${index}`}
                                          />
                                        </div>
                                      </div>

                                      <div style={{ width: "100%" }}>
                                        <div className="title-row">
                                          <div>Title</div>{" "}
                                          <span>
                                            <input
                                              id="imageTitle"
                                              name="imageTitle"
                                              className="form-input"
                                              value={newImagesValues.imageTitle}
                                              onChange={onChangeImageValues}
                                              placeholder="Image title"
                                            ></input>
                                          </span>
                                        </div>
                                        <div>
                                          <div className="title-row">
                                            Source / Author
                                          </div>
                                          <span>
                                            <input
                                              type="text"
                                              id="imageSource"
                                              name="imageSource"
                                              className="form-input"
                                              value={newImagesValues.imageSource}
                                              onChange={onChangeImageValues}
                                              placeholder="Image source"
                                            ></input>
                                          </span>
                                        </div>
                                        <div>
                                          <div className="title-row">Credit</div>
                                          <span>
                                            <input
                                              type="text"
                                              id="imageCredit"
                                              name="imageCredit"
                                              className="form-input"
                                              value={newImagesValues.imageCredit}
                                              onChange={onChangeImageValues}
                                              placeholder="Image credit"
                                            ></input>
                                          </span>
                                          <div className="title-row d-flex align-items-center gap-2">
                                            <div>Featured Moment</div>
                                            <span>
                                              <Form.Check
                                                className="yellow-checkbox"
                                                type="checkbox"
                                                id="topMoment"
                                                // checked={featuredMomentCheck}
                                                style={{ marginTop: "2px" }}
                                                
                                              />
                                            </span>
                                          </div>
                                          <button
                                            type="submit"
                                            className="add-img-btn yellow-btn"
                                            onClick={() => uploadMedia("image")}
                                            disabled={loading}
                                          >
                                            Upload Image
                                          </button>
                                          <button
                                            className="clear-btn yellow-btn"
                                            onClick={() => deleteImage(index)}
                                            disabled={loading}
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {files.length > 1 && <hr />}
                                </>
                              ))}
                            </div>
                          )
                        )}

                        {/* Newly added Videos */}
                        {files.length > 0 && selectVideo && (
                          selectVideo && (
                            <div className="im-container new-images">
                              {files.map((file, index) => (
                                <>
                                  <div className="input-row">
                                    <div className="input-details">
                                      <div>
                                        <div className="image" key={index}>
                                          <ReactPlayer
                                            url={URL.createObjectURL(file)}
                                            alt={`Image ${index}`}
                                            className="videoFrame"
                                            controls={true}
                                          />
                                        </div>
                                      </div>

                                      <div style={{ width: "100%" }}>
                                        <div className="title-row">
                                          <div>Title</div>{" "}
                                          <span>
                                            <input
                                              id="imageTitle"
                                              name="imageTitle"
                                              className="form-input"
                                              value={newImagesValues.imageTitle}
                                              onChange={onChangeImageValues}
                                              placeholder="Video title"
                                            ></input>
                                          </span>
                                        </div>
                                        <div>
                                          <div className="title-row">
                                            Source / Author
                                          </div>
                                          <span>
                                            <input
                                              type="text"
                                              id="imageSource"
                                              name="imageSource"
                                              className="form-input"
                                              value={newImagesValues.imageSource}
                                              onChange={onChangeImageValues}
                                              placeholder="Video source"
                                            ></input>
                                          </span>
                                        </div>
                                        <div>
                                          <div className="title-row">Credit</div>
                                          <span>
                                            <input
                                              type="text"
                                              id="imageCredit"
                                              name="imageCredit"
                                              className="form-input"
                                              value={newImagesValues.imageCredit}
                                              onChange={onChangeImageValues}
                                              placeholder="Video credit"
                                            ></input>
                                          </span>
                                          <button
                                            type="submit"
                                            className="add-img-btn yellow-btn"
                                            onClick={() => uploadMedia("video")}
                                            disabled={loading}
                                          >
                                            Upload Video
                                          </button>
                                          <button
                                            className="clear-btn yellow-btn"
                                            onClick={() => deleteImage(index)}
                                            disabled={loading}
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {files.length > 1 && <hr />}
                                </>
                              ))}
                            </div>
                          )
                        )}

                        {/* Existing Images */}
                        {momentImages.length > 0 && (
                          <div className="im-container">
                            {momentImages.map((image, index) => (
                              <>
                                <div key={index} className="input-row">
                                  <div className="input-details">
                                    <div>
                                      <div className="image">
                                        {!image.media_type ||
                                        image.media_type === "image" ? (
                                          <img src={image.url} alt="image" />
                                        ) : (
                                          // <iframe src={image.url}></iframe>
                                          <VideoPlayer
                                            className="videoFrame"
                                            source={image.url}
                                          />
                                        )}
                                      </div>
                                    </div>

                                    <div style={{ width: "100%" }}>
                                      <div className="title-row">
                                        <div>Title</div>{" "}
                                        <span>
                                          <input
                                            id="imageTitle"
                                            name="imageTitle"
                                            value={image.title}
                                            className="form-input"
                                            placeholder="Media title"
                                            onChange={(e) =>
                                              handleValueChange(e, index)
                                            }
                                          ></input>
                                        </span>
                                      </div>
                                      <div>
                                        <div className="title-row">
                                          Source / Author
                                        </div>
                                        <span>
                                          <input
                                            type="text"
                                            id="imageSource"
                                            name="imageSource"
                                            value={image.source}
                                            className="form-input"
                                            placeholder="Media source"
                                            onChange={(e) =>
                                              handleValueChange(e, index)
                                            }
                                          ></input>
                                        </span>
                                      </div>
                                      <div>
                                        <div className="title-row">Credit</div>
                                        <span>
                                          <input
                                            type="text"
                                            id="imageCredit"
                                            name="imageCredit"
                                            value={image.credit}
                                            className="form-input"
                                            placeholder="Media credit"
                                            onChange={(e) =>
                                              handleValueChange(e, index)
                                            }
                                          ></input>
                                        </span>
                                      </div>
                                      <div className="d-flex gap-2 align-items-center">
                                        <div className="title-row" style={{width: "fit-content"}}>Featured Moment</div>
                                          <span>
                                            <Form.Check
                                              className="yellow-checkbox"
                                              type="checkbox"
                                              id="topMoment"
                                              // checked={featuredMomentCheck}
                                              style={{marginTop: "1px"}}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                  </div>
                                  <div>
                                    <span
                                      // onClick={() => deleteExistImage(index)}
                                      onClick={() => handleShow(index)}
                                    >
                                      <i class="bi bi-trash3-fill"></i>
                                    </span>
                                  </div>
                                </div>
                                {existData.length > 1 && <hr />}
                              </>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              </div>

              <div className="button-part">
                {isCreateMoment ? (
                  <button
                    type="submit"
                    className="publish-btn yellow-btn"
                    onClick={() => createNewMoment()}
                    disabled={setctionButtonDisability()}
                  >
                    Create
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="publish-btn yellow-btn"
                    onClick={updateSelectedMoment}
                    disabled={setctionButtonDisability()}
                  >
                    {loading ? (
                      <Spinner
                        animation="border"
                        variant="light"
                        style={{ width: "15px", height: "15px" }}
                      />
                    ) : (
                      "Save Updates"
                    )}
                  </button>
                )}

                <button
                  type="submit"
                  className="cancel-btn"
                  onClick={handleCancelBtn}
                >
                  {/* // navigate(-1) */}
                  Cancel
                </button>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className="d-flex footer-and-button">
        <div className="footer-part">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default UpdateMomentPage;
