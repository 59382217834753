import { getMostViewedMoments, setMostViewedMoments, setPage, setLimitReached } from '../slices/MostViewedMomentsSlice';
import { createRequest } from '../../utils/requestHandler';
const mooflife_backend = process.env.REACT_APP_BACK_END_URL;

export const updateMomentInteractionCount = (data) => {
    
    return async (dispatch) => {
      try { 
        
        const configs = {
            headers: {
                'Content-Type': 'application/json',
                
              },
        }
  
        await createRequest('post', `${mooflife_backend}/moment-interaction`, data, configs)
        
      } catch (error) {
        return error;
      }
    };
  };

  export const getMostViewMoments = (data) => {
  
    return async (dispatch) => {
      try { 
        const configs = {
            headers: {
                'Content-Type': 'application/json',
              },
        }
  
        const response = await createRequest('get', `${mooflife_backend}/moment-interaction`, data, configs)
        if (response && response.data && response.data.success) {
          
          dispatch(getMostViewedMoments(response.data.data));
        }
        
      } catch (error) {
        console.error('Error while getting most view moments data:', error);
      }
    };
  };

  export const getMostViewedTile = (data, page) => {

    return async (dispatch) => {
      try {
        const configs = {
          headers: {
            'Content-Type': 'application/json',
          },
        }
  
        const response = await createRequest('post', `${mooflife_backend}/moment-interaction/tile`, data, configs);
        if (response && response.data && response.data.success && response.data.data) {
          if (response.data.data.length === 0) {
            dispatch(setLimitReached(true));
          }
          dispatch(setMostViewedMoments(response.data.data));
  
          if (page) {
            dispatch(setPage(page + 1));
          }
        }
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  };

  
