import "../../styles/CooperatePagesStyle/MooflifeConceptStyle.css";
import CooperateHeader from "./CooperateHeader";
import CooperateFooter from "./CooperateFooter";
import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Carousel from "react-bootstrap/Carousel";
import { images } from "../../utils/image";

const MooflifeConcept = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <CooperateHeader />

      <div className="header-layout">
        <div className="header-part">
          <div className="header-title">
            Creating History for the <span>Future</span>
          </div>
          <div className="header-text">
            Imagine scrolling through the greatest moments of our past through a
            user friendly timeline. MoofLife offers a unique blend of history
            and modern technology, bringing historical events to your screen in
            a way that's engaging, interactive, and, most of all, fun!
            <br />
            <br />
            How Does It Work?
            <br></br>
            We combine automated workflow management with advanced AI language
            models to reconstruct historical moments. While these technologies
            offer a starting point, we acknowledge that AI-generated content may
            not always achieve full accuracy or precision. This is where the
            invaluable contributions of our MoofLife community become essential.
            Our platform is not just a presentation of history but an ongoing
            research exercise, constantly evolving and improving through
            community engagement and insights. Each contribution from our
            members plays a critical role in refining and validating our
            content, making it a collective journey in exploring and
            understanding the past.
          </div>
        </div>

        <div>
          <img className="header-img" src={images.headerRow}></img>
        </div>
      </div>

      <div className="layoutOne">
        <p className="title-1">
          Life full of <b>MOMENTS</b>
        </p>
        <Row className="details-row">
          <Col sm={6} style={{ textAlign: "center" }}>
            <img src={images.mooflifeConcept}></img>
          </Col>
          <Col sm={6} style={{ textAlign: "center" }}>
            <div className="title-2" style={{ fontWeight: "600" }}>
              Be a part of History
            </div>
            <div className="title-3" style={{ fontWeight: "500" }}>
              Dive into the world of your favorite historical moments. Whether
              it's witnessing the signing of the Declaration of Independence or
              being part of the crowd during Martin Luther King Jr.'s 'I Have a
              Dream' speech, you're not just learning history; you're
              experiencing it.
              <br /> <br /> Like, comment, and share these historical moments
              just as you would on any social media platform. Engage with other
              history enthusiasts, debate outcomes, and maybe even change
              perspectives. <br /> <br /> MoofLife make reading history
              accessible and enjoyable for everyone. Our platform is perfect for
              history buffs, or just the curious minds. We are working on
              continuous improvement of our language models to enhance the
              quality and accuracy of the content. <br /> <br /> MoofLife is
              constantly evolving. With new content added regularly, the history
              on our platform grows and becomes more vibrant each day.
            </div>
          </Col>
        </Row>
      </div>

      <div className="platform-main">
        <p className="main-topic">
          One-stop platform for sharing world and personal moments
        </p>
        <Row className="row platform-row">
          <Col sm={4}>
            <Card className="card">
              <Card.Body>
                <Card.Text>
                  <img className="img" src={images.card3}></img>
                  <p className="title-1">
                    <span>
                      <img className="img" src={images.MoofIcon}></img>
                    </span>
                    Journey through History
                  </p>
                  <p className="title-2">
                  It is a timeline going back to the start of our existence. Get onboard in a digital journey of important moments in the past. 
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={4}>
            <Card className="card">
              <Card.Body>
                <Card.Text>
                  <img className="img" src={images.card1}></img>
                  <p className="title-1">
                    <span>
                      <img className="img" src={images.MoofIcon}></img>
                    </span>
                    Engage and Share
                  </p>
                  <p className="title-2">
                  Share moments with your family, friends,  and with the larger community. 
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={4}>
            <Card className="card">
              <Card.Body>
                <Card.Text>
                  <img className="img" src={images.card2}></img>
                  <p className="title-1">
                    <span>
                      <img className="img" src={images.MoofIcon}></img>
                    </span>
                    Report and Improve
                  </p>
                  <p className="title-2">
                  Your inputs are  essential to improve the content. If you think the content is inaccurate, please report. We take your inputs seriously!
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>

      <CooperateFooter />
    </div>
  );
};

export default MooflifeConcept;
