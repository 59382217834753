import React, { useState, useEffect } from "react";
import "../../styles/SearchStyle.css";
import "../../styles/CommonStyle.css";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useDispatch, useSelector } from "react-redux";
import { getMoreSearchlives } from "../../store/action/SearchAction";
import { useNavigate } from "react-router-dom";
import { getFromSessionStorage } from "../../utils/storageHandler.js";

const SearchLife = (props) => {
  const data = props.searchData;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchResults = useSelector((state) => state.searchResults.searchData);
  const searchLifePage = useSelector((state) => state.searchResults.searchLifePage);
  const [currentPage, setCurrentPage] = useState(1);
  const [userLogin, setUserLogin] = useState("");
  const itemsPerPage = 5;
  const totalItems = searchResults.lives.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  useEffect(() => {
    const userDetails = getFromSessionStorage("userDetails", true);
    setUserLogin(userDetails);
  }, []);

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
    getNextPageLives();
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getNextPageLives =async () => {

    dispatch(await getMoreSearchlives({
      keyword: data.keyword, fromYear: data.fromYear, toYear: data.toYear,
      page: searchLifePage,
      limit: itemsPerPage,
      user_email: userLogin?.email ? userLogin.email: ""
    },searchLifePage))
  };

  const goToLife = (life_id) => {
    navigate(`/${life_id}`);
  };

  return (
    <div>
      <div className="search-life-main">
        <h2 className="title">Life</h2>
        {searchResults.lives
          .slice(startIndex, endIndex)
          .map((searchLife, index) => (
            <Card
              key={index}
              className="search-life-card"
              onClick={() => goToLife(searchLife.verified_name?searchLife.verified_name:searchLife.life_id)}
            >
              <Row>
                <Col sm={1}>
                  <img className="life-logo" src={searchLife.life_logo}></img>
                </Col>
                <Col>
                  <div className="life-title title-highlight">{searchLife.life_name}</div>
                  <div className="life-tag">{searchLife.life_title}</div>
                </Col>
              </Row>
            </Card>
          ))}
        <div className="pagination-buttons">
          <button
            className="pagination-btn"
            onClick={handlePrev}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <button
            className="pagination-btn"
            onClick={handleNext}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchLife;
