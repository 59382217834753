export const getFromSessionStorage = (key, isObject = false) => {
    try {
        if (!key) {
            throw new Error('Key is required to get the item from storage');
        }

        let item = null;
        item = localStorage.getItem(key);

        if(!item){
            item = sessionStorage.getItem(key)
        }

        if (isObject) {
            return JSON.parse(item);
        } else {
            return item;
        }
    } catch (err) {
        console.error('Error while getting item from storage:', err);
        return null;
    }
};

export const setIntoSessionStorage = (key, data, isObject = false) => {
    try {
        if(key && data){
            if(isObject){
                const item = JSON.stringify(data);
                sessionStorage.setItem(key, item);
                return true;
            }else {
                return sessionStorage.setItem(key, data);
            }
        }
    } catch(err){
        console.error('Error while setting item to session storage')
    }
};

export const setIntoLocalStorage = (key, data, isObject = false) => {
    try {
        if(key && data){
            if(isObject){
                const item = JSON.stringify(data);
                localStorage.setItem(key, item);
                return true;
            }else {
                return localStorage.setItem(key, data);
            }
        }
    } catch(err){
        console.error('Error while setting item to local storage')
    }
};

export const removeLoginDataFromStorage = () => {
    try {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userDetails");
        localStorage.removeItem("tokenExpireTime");
        localStorage.removeItem("interest");
        localStorage.removeItem("selected_countries");
        localStorage.removeItem("register_type");

        sessionStorage.removeItem("accessToken");
        sessionStorage.removeItem("userDetails");
        sessionStorage.removeItem("tokenExpireTime");
        sessionStorage.removeItem("interest");
        sessionStorage.removeItem("selected_countries");
        sessionStorage.removeItem("register_type");

    } catch (err) {
        console.error('Error while removing items from storage:', err);
    }
}