import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import CommentForm from "./CommentForm";
import Comment from "./Comment";
import {
  createComment,
  getCommentsForMoment,
  resetMomentComments,
  handlePageCount,
  handleLimitReached,
  editComment,
  deleteComments,
} from "../../store/action/CommentAction";
import "../../styles/CommentStyle.css";
import Modal from "react-bootstrap/Modal";

const Comments = ({ currentUserId, momentId, area }) => {
  const [backendComments, setBackendComments] = useState([]);
  const [activeComment, setActiveComment] = useState(null);
  const [showAllComments, setShowAllComments] = useState(false);
  const [totalComments, setTotalComments] = useState(0);

  const containerRef = useRef(null); // Reference to the comments container div
  const dispatch = useDispatch();
  const rootComments = useSelector(
    (state) => state.Comments.commentsForMoment,
    shallowEqual
  );

  useEffect(() => {
    dispatch(resetMomentComments());
    dispatch(getCommentsForMoment(momentId, area));
  }, []);

  const addComment = async (commentText, parentId) => {
    if (currentUserId) {
      let commentData = {
        user: currentUserId,
        moment_id: momentId,
        content: commentText,
        date: new Date(),
      };

      if (parentId && parentId !== "") {
        commentData.parent_comment = parentId;
      }

      dispatch(createComment(commentData, area));
      setActiveComment(null);
      setTotalComments(totalComments + 0);
    } else {
      console.error("user data now found!");
    }
  };

  const updateComment = (content, commentId) => {
    dispatch(
      editComment({
        commentId: commentId,
        content: content,
        momentId: momentId,
      })
    );

    setActiveComment(null);
  };

  const [show, setShow] = useState(false);
  const [commentIdToDelete, setCommentIdToDelete] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = (commentId) => {
    setCommentIdToDelete(commentId);
    setShow(true);
  };

  const deleteComment = (commentId) => {
    handleShow(commentId);
  };

  const handleDelete = () => {
    dispatch(
      deleteComments({
        momentId: momentId,
        commentId: commentIdToDelete,
        area: area
      })
    );
    handleClose();
  };

  const handleViewMoreComments = () => {
    setShowAllComments(true);
  };

  return (
    <div className="comments">
      <hr></hr>
      <div className="comments-container-main" ref={containerRef}>
        {showAllComments
          ? rootComments.map((rootComment) => (
              <Comment
                key={rootComment._id}
                comment={rootComment}
                replies={rootComment.replies ? rootComment.replies : []}
                activeComment={activeComment}
                setActiveComment={setActiveComment}
                addComment={addComment}
                deleteComment={deleteComment}
                updateComment={updateComment}
                currentUserId={currentUserId}
                parentId={rootComment._id}
              />
            ))
          : rootComments.length > 0 && (
              <Comment
                key={rootComments[0].id}
                comment={rootComments[0]}
                replies={rootComments[0].replies ? rootComments[0].replies : []}
                activeComment={activeComment}
                setActiveComment={setActiveComment}
                addComment={addComment}
                deleteComment={deleteComment}
                updateComment={updateComment}
                currentUserId={currentUserId}
                parentId={rootComments[0]._id}
              />
            )}
      </div>
      {!showAllComments && rootComments.length > 1 &&(
        <div
          className="view-more-topic"
          onClick={() => handleViewMoreComments()}
        >
          View more comments
        </div>
      )}
      <CommentForm handleSubmit={addComment} />

      <Modal className="delete-modal" show={show} onHide={handleClose}>
        <Modal.Body className="modal-body">Are you sure you want to delete the comment ?</Modal.Body>
        <Modal.Footer className="modal-footer">
        <button className="yellow-btn" onClick={handleDelete}>
            Yes
          </button>
          <button className="yellow-btn" onClick={handleClose}>
            No
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Comments;
