import { createSlice } from "@reduxjs/toolkit";

const TopMomentsSlice = createSlice({
    name: "TopMoments",
    initialState: {
        TopMoments: [],
        TopMomentsTile: [],
        Page: 1,
        limitReached: false,
    },

    reducers: {
        getTopMoments: (state, action) => {
            state.TopMoments = action.payload;
        },

        setTopMoments: (state, action) => {
            action.payload.map(topMoment => {
                if (!state.TopMomentsTile.some(item => item._id === topMoment._id)) {
                    state.TopMomentsTile.push(topMoment);
                }
            })
        },

        setPage: (state, action) => {
            state.Page = action.payload
        },

        setLimitReached: (state, action) => {
            state.limitReached = action.payload
        },

    }
})

export const { getTopMoments, setLimitReached, setPage, setTopMoments } = TopMomentsSlice.actions;

export default TopMomentsSlice.reducer;