import React from "react";
import { images } from "../utils/image";
import "../styles/ErrorPageStyle.css";
import Card from "react-bootstrap/Card";

const ErrorPage = () => {
  return (
    <div className="error-page">
      <div className="error-main">
        <img src={images.ErrorImage} className="error-img"></img>
        <div className="title">Page Not Found</div>
        <div className="sub-title">
          We can't find the page you're looking for.
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
