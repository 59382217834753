import React, { useEffect, useRef, useState } from "react";
import "../../styles/LandingPage/OnThisDayStyle.css";
import { images } from "../../utils/image";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { fetchTopMoments } from "../../store/action/TopMomentsAction";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Comments from "../Comments/Comments";
import { updateMomentInteractionCount } from "../../store/action/MostViewedMomentsAction";
import { countryList } from "../../utils/CountryList";
import Flag from "react-world-flags";
import {
  createImpression,
  fetchImpressionSet,
} from "../../store/action/ImpressionAction";
import { getFromSessionStorage } from "../../utils/storageHandler";
import VideoPlayer from "../../components/VideoPlayer";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import {
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
  XIcon,
} from "react-share";
import copy from "copy-to-clipboard";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const OnThisDay = () => {
  const currentDate = new Date();
  const dispatch = useDispatch();

  const userData = getFromSessionStorage("userDetails", true);
  const [userLogin, setUserLogin] = useState("");
  const [commentMoment, setCommentMoment] = useState("");
  const [showPopup, setShowPopUp] = useState(false);
  const [commentOpen, setCommentOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [currentMoment, setCurrentMoment] = useState("");
  const [shareMoment, setShareMoment] = useState("");
  const [shareBoxVisible, setShareBoxVisible] = useState();
  const [shareBoxShow, setShareBoxShow] = useState();
  const [show, setShow] = useState(false);
  const [commentMomentId, setCommentMomentId] = useState("");
  const textRef = useRef();
  const [tooltipState, setTooltipState] = useState({});
  const [showAllImages, setShowAllImages] = useState(false);
  const [impressionBoxVisible, setImpressionBoxVisible] = useState(false);
  const [showImpressionSet, setShowImpressionSet] = useState("");
  const [showTooltip, setTooltipShow] = useState(false);

  const shareBoxRef = useRef();

  const TopMoments = useSelector((state) => state.TopMoments.TopMoments);

  useEffect(() => {
    const momentDate = moment(currentDate).format("YYYY-MM-DD");
    dispatch(fetchTopMoments(momentDate));
  }, []);

  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      ></div>
    );
  };

  const shareRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (shareRef.current && !shareRef.current.contains(event.target)) {
        setShareBoxVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      ></div>
    );
  };

  const isInfinite = TopMoments.length <= 2;

  const settings = {
    dots: false,
    infinite: isInfinite ? false : true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 5000,
    prevArrow: <button onClick={SamplePrevArrow}></button>,

    nextArrow: <button onClick={SampleNextArrow}></button>,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 578,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleShareClick = (topMomentId) => {
    setShareMoment(topMomentId);
    setShareBoxVisible(true);
  };

  const handleShare = (topMomentId) => {
    setShareMoment(topMomentId);
    setShareBoxShow((prevState) => !prevState);
  };

  const copyToClipboard = (id, topMoment) => {
    let copyText = textRef.current.value;
    let isCopy = copy(copyText);
    if (id === topMoment._id && isCopy) {
      toggleTooltip(id, true);

      setTimeout(() => {
        toggleTooltip(id, false);
      }, 3000);
    }
  };

  const copyCommentModal = (id, selectedCommentMoment) => {
    let copyText = textRef.current.value;
    let isCopy = copy(copyText);
    if (id === selectedCommentMoment._id && isCopy) {
      toggleTooltip(id, true);

      setTimeout(() => {
        toggleTooltip(id, false);
      }, 3000);
    }
  };

  const toggleTooltip = (momentId, value) => {
    setTooltipState((prevState) => ({
      ...prevState,
      [momentId]: value,
    }));
  };

  const handleClose = () => {
    setShowPopUp(false);
    setShow(false);
  };

  const handleShow = (id) => {
    setCommentMomentId(id);
    setShow(true);
  };

  const selectedMoments = TopMoments;

  const selectedCommentMoment = selectedMoments.find(
    (moment) => moment._id === commentMomentId
  );

  useEffect(() => {
    const userDetails = getFromSessionStorage("userDetails", true);
    setUserLogin(userDetails);
  }, []);

  const handleCommentOpen = (momentId) => {
    setCommentMoment(momentId);
    if (!userLogin) {
      setShowPopUp(true);
    }

    if (commentMoment === "" || commentMoment === momentId) {
      setCommentOpen(!commentOpen);
    }
  };

  const getEmojiAsset = (type) => {
    switch (type) {
      case "like":
        return images.LikeIcon;
      case "great":
        return images.GreatIcon;
      case "emotional":
        return images.EmotionalIcon;
      case "nostalgic":
        return images.NostalgicIcon;
      case "wish":
        return images.WishIcon;
      case "part":
        return images.PartOfTheMomentIcon;
      case "witnessed":
        return images.WitnessedIcon;
      default:
        return null;
    }
  };

  const toggleShow = (id, action) => {
    setCurrentMoment(id);
    setShowMore(action === "less" ? false : true);
    dispatch(updateMomentInteractionCount({ moment_id: id }));
  };

  //handle impressions
  const handleEmojiClick = (type, momentId) => {
    dispatch(
      createImpression({
        moment_id: momentId,
        loggedInUserId: userData?.id,
        impression_type: type,
        page: "landing",
      })
    );
  };

  const totalFeedbacks = (reactionCounts) => {
    if (reactionCounts) {
      return Object.values(reactionCounts).reduce(
        (sum, count) => sum + count,
        0
      );
    }
  };

  const handleImpressionClick = () => {
    if (!userLogin) {
      setShowPopUp(true);
    } else setImpressionBoxVisible(!impressionBoxVisible);
  };

  const getTopReactions = (reactionCounts) => {
    // Create an array of objects with reaction type and count
    if (reactionCounts) {
      const reactionArray = Object.entries(reactionCounts).map(
        ([type, count]) => ({ type, count })
      );

      // Sort the array based on count in descending order
      reactionArray.sort((a, b) => b.count - a.count);

      // Get the top 3 reaction types
      const topReactions = reactionArray
        .filter((reaction) => reaction.count > 0)
        .slice(0, 3);

      // Return an array containing only the reaction types
      return topReactions.map((reaction) => reaction.type);
    }
  };

  const handleImpressionSet = (id) => {
    setShowImpressionSet(true);
    dispatch(fetchImpressionSet(id));
  };

  const showPopupModal = () => {
    if (!userLogin) {
      setShowPopUp(true);
    } else {
      setShowPopUp(false);
    }
  };

  const currentUrl =
    window.location.protocol +
    "//" +
    window.location.hostname +
    (window.location.port ? ":" + window.location.port : "");

  return (
    <>
      {/* Pop up */}
      <Modal
        className="popup-modal"
        show={showPopup}
        onHide={handleClose}
        centered
      >
        <div className="popup-header">
          <div className="close-icon" onClick={handleClose}>
            <img src={images.closeIcon}></img>
          </div>
          <div className="logo-icon">
            <img src={images.MoofIcon} />
          </div>
        </div>

        <Modal.Body className="modal-body">
          Please Login / Sign-up to comment or like this moment <br></br>
          <Link to="/login">
            <button className="yellow-btn">Login</button>
          </Link>
          <Link to="/sign-up">
            <button className="yellow-btn">Sign Up </button>
          </Link>
        </Modal.Body>
      </Modal>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="comment-moment-modal"
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedCommentMoment && (
            <div className="moments-container">
              <Card className="card">
                <Card.Body>
                  <Row className="life">
                    <Col className="life-logo" sm={1}>
                      <Link>
                        <img
                          className="life-logo"
                          src={selectedCommentMoment.life.life_logo}
                        ></img>
                      </Link>
                    </Col>
                    <Col className="life-content">
                      <h6 className="title-1">
                        <Link>
                          {selectedCommentMoment.life.life_name}
                          <span>
                            <img
                              className="verified-icon"
                              src={images.VerifiedIcon}
                            ></img>
                          </span>
                        </Link>
                      </h6>
                      <h6 className="title-2">
                        {selectedCommentMoment.moment_date}{" "}
                        {selectedCommentMoment.era &&
                        selectedCommentMoment.era === "BCE"
                          ? "BCE"
                          : ""}
                      </h6>
                    </Col>
                    <Col className="moment-category">
                      {selectedCommentMoment?.world_moment === true ? (
                        <span
                          className="category-word"
                          style={{ background: "#FFF1C6" }}
                        >
                          World Moment
                        </span>
                      ) : selectedCommentMoment.national_moment.length > 0 ? (
                        <span
                          className="category-word"
                          style={{ background: "#D6E4FF" }}
                        >
                          National Moment
                        </span>
                      ) : selectedCommentMoment.top_moment === true ? (
                        <span
                          className="category-word"
                          style={{ background: "#D6E4FF" }}
                        >
                          Top Moment
                        </span>
                      ) : selectedCommentMoment.sustainability_moment ===
                        true ? (
                        <span
                          className="category-word"
                          style={{ background: "#DBFFDE" }}
                        >
                          Sustainability
                        </span>
                      ) : null}
                      {selectedCommentMoment.world_moment === true ? (
                        <img src={images.GlobalMoment} alt="World Moment" />
                      ) : selectedCommentMoment.national_moment.length > 0 ? (
                        selectedCommentMoment.national_moment
                          .slice(0, 5)
                          .map((countryName, index) => {
                            const countryCode = countryList.find(
                              (country) => country.country === countryName
                            );

                            if (countryCode) {
                              return (
                                <Flag key={index} code={countryCode.code} />
                              );
                            }
                          })
                      ) : (
                        selectedCommentMoment.top_moment === true && (
                          <img src={images.TopMoment} alt="Top Moment" />
                        )
                      )}
                      {selectedCommentMoment.sustainability_moment === true && (
                        <img
                          src={images.SustainabilityMoment}
                          alt="Sustainability Moment"
                        />
                      )}
                    </Col>
                  </Row>
                  <Row className="moment">
                    <Col className="moment-logo" sm={1}>
                      {userLogin ? (
                        <Dropdown>
                          <Dropdown.Toggle id="dropdown-basic">
                            <img src={images.MoofIcon}></img>
                          </Dropdown.Toggle>
                        </Dropdown>
                      ) : (
                        <img
                          src={images.MoofIcon}
                          onClick={showPopupModal}
                        ></img>
                      )}
                    </Col>
                    <Col className="moment-title">
                      <Row>
                        <Col>
                          <Link
                            to={`${currentUrl}/${
                              selectedCommentMoment.life.verified_name
                                ? selectedCommentMoment.life.verified_name
                                : selectedCommentMoment.life.life_id
                            }/${selectedCommentMoment._id}`}
                          >
                            <h4 className="title-1">
                              {selectedCommentMoment.moment_title}
                            </h4>
                          </Link>
                        </Col>
                        <Col sm={1} style={{ textAlign: "right" }}>
                          {selectedCommentMoment.userImpression && (
                            <div>
                              <img
                                src={getEmojiAsset(
                                  selectedCommentMoment.userImpression
                                )}
                                width="20px"
                              />
                            </div>
                          )}
                        </Col>
                      </Row>
                      <div className="title-2">
                        {selectedCommentMoment.moment_type.map(
                          (type, index) => (
                            <>| {type} </>
                          )
                        )}
                        {selectedCommentMoment.moment_type}
                        {selectedCommentMoment.moment_type.length > 0 && (
                          <span>|</span>
                        )}
                      </div>
                      <h4 className="title-2"></h4>
                    </Col>
                  </Row>
                  <Card.Text>
                    <div class="moment-content">
                      <p>
                        {showMore && currentMoment === selectedCommentMoment._id
                          ? selectedCommentMoment.moment_details
                          : selectedCommentMoment.moment_details.slice(0, 1500)}
                        {selectedCommentMoment.link_descriptions}
                        {selectedCommentMoment.moment_details.length > 1500 &&
                          (showMore &&
                          currentMoment === selectedCommentMoment._id ? (
                            <span
                              className="seemore"
                              onClick={() =>
                                toggleShow(selectedCommentMoment._id, "less")
                              }
                            >
                              see less...
                            </span>
                          ) : (
                            <span
                              className="seemore"
                              onClick={() =>
                                toggleShow(selectedCommentMoment._id, "more")
                              }
                            >
                              see more...
                            </span>
                          ))}
                      </p>
                      <p className="link-hashtag">
                        {selectedCommentMoment.external_link_title && (
                          <>External Reference :&nbsp;</>
                        )}
                        <a
                          target="_blank"
                          href={selectedCommentMoment.external_link}
                        >
                          {selectedCommentMoment.external_link_title}
                        </a>
                        {selectedCommentMoment.hashtags.map((hashtag) => (
                          <span>#{hashtag}</span>
                        ))}
                      </p>

                      <div
                        className="image-gallery"
                        style={{
                          display: "grid",
                          gridTemplateColumns:
                            selectedCommentMoment.images.length === 1
                              ? "1fr"
                              : selectedCommentMoment.images.length === 3
                              ? "repeat(2, 1fr)"
                              : "repeat(2, 1fr)",
                          gridAutoRows: "auto",
                          height:
                            selectedCommentMoment.images &&
                            (selectedCommentMoment.images.length === 2 ||
                              selectedCommentMoment.images.length === 3)
                              ? "200px"
                              : selectedCommentMoment.images &&
                                selectedCommentMoment.images.length === 1
                              ? "500px"
                              : "400px",
                        }}
                      >
                        {selectedCommentMoment.images.length === 3
                          ? selectedCommentMoment.images
                              .slice(0, 2)
                              .map((image, imageIndex) => (
                                <div
                                  //  onClick={() =>
                                  //    openModal(
                                  //     selectedCommentMoment.images,
                                  //      imageIndex
                                  //    )
                                  //  }
                                  key={imageIndex}
                                  className={`image-wrapper${
                                    imageIndex === 0 ? " full-width" : ""
                                  }`}
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    margin: "auto",
                                  }}
                                >
                                  {image.media_type === "image" ||
                                  !image.media_type ? (
                                    <img
                                      src={image.url}
                                      alt={`Image ${imageIndex + 1}`}
                                      style={{
                                        maxWidth: "100%",
                                        maxHeight: "100%",
                                      }}
                                    />
                                  ) : (
                                    image.media_type === "video" && (
                                      <VideoPlayer source={image.url} />
                                    )
                                  )}
                                  {imageIndex === 1 &&
                                    selectedCommentMoment.images.length > 2 &&
                                    !showAllImages && (
                                      <div
                                        className="more-indicator"
                                        //  onClick={() =>
                                        //    openModal(
                                        //     selectedCommentMoment.images,
                                        //      imageIndex
                                        //    )
                                        //  }
                                      >
                                        <i class="bi bi-image"></i>
                                      </div>
                                    )}
                                </div>
                              ))
                          : selectedCommentMoment.images
                              .slice(0, 4)
                              .map((image, imageIndex) => (
                                <div
                                  //  onClick={() =>
                                  //    openModal(
                                  //     selectedCommentMoment.images,
                                  //      imageIndex
                                  //    )
                                  //  }
                                  key={imageIndex}
                                  className={`image-wrapper${
                                    imageIndex === 0 ? " full-width" : ""
                                  }`}
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    margin: "auto",
                                  }}
                                >
                                  {image.media_type === "image" ||
                                  !image.media_type ? (
                                    <img
                                      src={image.url}
                                      alt={`Image ${imageIndex + 1}`}
                                      style={{
                                        maxWidth: "100%",
                                        maxHeight: "100%",
                                      }}
                                    />
                                  ) : (
                                    image.media_type === "video" && (
                                      <VideoPlayer source={image.url} />
                                    )
                                  )}
                                  {imageIndex === 3 &&
                                    selectedCommentMoment.images.length > 4 &&
                                    !showAllImages && (
                                      <div
                                        className="more-indicator"
                                        //  onClick={() =>
                                        //    openModal(
                                        //     selectedCommentMoment.images,
                                        //      imageIndex
                                        //    )
                                        //  }
                                      >
                                        +{" "}
                                        {selectedCommentMoment.images.length -
                                          4}
                                      </div>
                                    )}
                                </div>
                              ))}
                      </div>
                    </div>
                  </Card.Text>

                  <hr></hr>
                  <Row className="icons-row">
                    <Col>
                      <div>
                        <div></div>
                      </div>
                      <div className="icons d-flex">
                        {selectedCommentMoment.reaction_counts > 0 && (
                          <div>
                            {totalFeedbacks(
                              selectedCommentMoment.reaction_counts
                            )}
                          </div>
                        )}

                        <div class="feed" onClick={handleImpressionClick}>
                          <div style={{ display: "flex !important" }}>
                            {totalFeedbacks(
                              selectedCommentMoment.reaction_counts
                            ) === 0 ? (
                              <div>
                                <img
                                  src={images.ImpressionsIcon}
                                  alt="No Feedback"
                                  width="20"
                                  height="20"
                                  style={{
                                    marginLeft: "10px",
                                    marginTop: "-10px",
                                  }}
                                />{" "}
                              </div>
                            ) : (
                              <div
                                className="top3-emojis-container"
                                style={{
                                  marginLeft: "5px",
                                }}
                              >
                                {getTopReactions(
                                  selectedCommentMoment.reaction_counts
                                ).map((emoji) => (
                                  <div key={emoji}>
                                    <img
                                      src={getEmojiAsset(emoji)}
                                      alt={emoji}
                                      width="20"
                                      height="20"
                                    />
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                          <a class="like-btn">
                            {userLogin && impressionBoxVisible && (
                              <div class="reaction-box">
                                {(!selectedCommentMoment.impression_types ||
                                  selectedCommentMoment.impression_types
                                    .like === true) && (
                                  <div
                                    class="reaction-icon"
                                    onClick={() =>
                                      handleEmojiClick(
                                        "like",
                                        selectedCommentMoment._id
                                      )
                                    }
                                  >
                                    <label>Like this Moment</label>
                                    <img src={images.LikeIcon}></img>
                                  </div>
                                )}

                                {(!selectedCommentMoment.impression_types ||
                                  selectedCommentMoment.impression_types
                                    .great === true) && (
                                  <div
                                    class="reaction-icon"
                                    onClick={() =>
                                      handleEmojiClick(
                                        "great",
                                        selectedCommentMoment._id
                                      )
                                    }
                                  >
                                    <label>Great Moment</label>
                                    <img src={images.GreatIcon}></img>
                                  </div>
                                )}

                                {(!selectedCommentMoment.impression_types ||
                                  selectedCommentMoment.impression_types
                                    .emotional === true) && (
                                  <div
                                    class="reaction-icon"
                                    onClick={() =>
                                      handleEmojiClick(
                                        "emotional",
                                        selectedCommentMoment._id
                                      )
                                    }
                                  >
                                    <label>Emotional Moment</label>
                                    <img src={images.EmotionalIcon}></img>
                                  </div>
                                )}

                                {(!selectedCommentMoment.impression_types ||
                                  selectedCommentMoment.impression_types
                                    .nostalgic === true) && (
                                  <div
                                    class="reaction-icon"
                                    onClick={() =>
                                      handleEmojiClick(
                                        "nostalgic",
                                        selectedCommentMoment._id
                                      )
                                    }
                                  >
                                    <label>Nostalgic Moment</label>
                                    <img src={images.NostalgicIcon}></img>
                                  </div>
                                )}

                                {(!selectedCommentMoment.impression_types ||
                                  selectedCommentMoment.impression_types
                                    .wish === true) && (
                                  <div
                                    class="reaction-icon"
                                    onClick={() =>
                                      handleEmojiClick(
                                        "wish",
                                        selectedCommentMoment._id
                                      )
                                    }
                                  >
                                    <label>Wish I could be there</label>
                                    <img src={images.WishIcon}></img>
                                  </div>
                                )}

                                {(!selectedCommentMoment.impression_types ||
                                  selectedCommentMoment.impression_types
                                    .part === true) && (
                                  <div
                                    class="reaction-icon"
                                    onClick={() =>
                                      handleEmojiClick(
                                        "part",
                                        selectedCommentMoment._id
                                      )
                                    }
                                  >
                                    <label>Was part of this Moment</label>
                                    <img src={images.PartOfTheMomentIcon} />
                                  </div>
                                )}

                                {(!selectedCommentMoment.impression_types ||
                                  selectedCommentMoment.impression_types
                                    .witnessed === true) && (
                                  <div
                                    class="reaction-icon"
                                    onClick={() =>
                                      handleEmojiClick(
                                        "witnessed",
                                        selectedCommentMoment._id
                                      )
                                    }
                                  >
                                    <label>Witnessed this Moment</label>
                                    <img src={images.WitnessedIcon}></img>
                                  </div>
                                )}
                              </div>
                            )}
                          </a>
                        </div>
                        {/* <img className="icons-image" src={impressions}></img> */}
                        <p
                          className="impression-word"
                          onClick={() =>
                            handleImpressionSet(selectedCommentMoment._id)
                          }
                          style={{
                            marginLeft:
                              totalFeedbacks(
                                selectedCommentMoment.reaction_counts
                              ) > 0
                                ? "0px"
                                : "10px",
                          }}
                        >
                          Like
                        </p>
                      </div>
                    </Col>
                    <Col>
                      <div
                        className="icons comment-icon d-flex gap-3"
                        onClick={() =>
                          handleCommentOpen(selectedCommentMoment._id)
                        }
                      >
                        {selectedCommentMoment.number_of_comments > 0 && (
                          <div>{selectedCommentMoment.number_of_comments}</div>
                        )}
                        <img
                          className="icons-image"
                          src={images.CommentIcon}
                          style={{ marginTop: "2px" }}
                        ></img>
                        <p className="comments-word">Comment</p>
                      </div>
                    </Col>
                    <Col style={{ textAlign: "right" }}>
                      {tooltipState[selectedCommentMoment._id] && (
                        <div className="copy-message">Link copied</div>
                      )}
                      <div className="icons share-icon d-flex gap-3 justify-content-end">
                        <div class="share-feed">
                          <a
                            class="share-btn"
                            onClick={() =>
                              handleShareClick(selectedCommentMoment._id)
                            }
                            ref={shareRef}
                          >
                            <img
                              className="icons-image"
                              src={images.ShareIcon}
                            ></img>
                            {shareBoxVisible &&
                              shareMoment === selectedCommentMoment._id && (
                                <div class="share-box" ref={shareBoxRef}>
                                  <div class="share-icon">
                                    <label>Telegram</label>
                                    <TelegramShareButton
                                      url={`${currentUrl}/${
                                        selectedCommentMoment.life.verified_name
                                          ? selectedCommentMoment.life
                                              .verified_name
                                          : selectedCommentMoment.life.life_id
                                      }/${selectedCommentMoment._id}`}
                                      title={selectedCommentMoment.moment_title}
                                    >
                                      <TelegramIcon
                                        size={32}
                                        round
                                        className="icon"
                                      />
                                    </TelegramShareButton>
                                  </div>
                                  <div class="share-icon">
                                    <label>Linkedin</label>
                                    <LinkedinShareButton
                                      url={`${currentUrl}/${
                                        selectedCommentMoment.life.verified_name
                                          ? selectedCommentMoment.life
                                              .verified_name
                                          : selectedCommentMoment.life.life_id
                                      }/${selectedCommentMoment._id}`}
                                      title={selectedCommentMoment.moment_title}
                                      source="MoofLife"
                                    >
                                      <LinkedinIcon
                                        size={32}
                                        round
                                        className="icon"
                                      />
                                    </LinkedinShareButton>
                                  </div>
                                  <div class="share-icon">
                                    <label>Whatsapp</label>
                                    <WhatsappShareButton
                                      url={`${currentUrl}/${
                                        selectedCommentMoment.life.verified_name
                                          ? selectedCommentMoment.life
                                              .verified_name
                                          : selectedCommentMoment.life.life_id
                                      }/${selectedCommentMoment._id}`}
                                      title={selectedCommentMoment.moment_title}
                                      separator=":: "
                                    >
                                      <WhatsappIcon
                                        size={32}
                                        round
                                        className="icon"
                                      />
                                    </WhatsappShareButton>
                                  </div>
                                  <div class="share-icon">
                                    <label>Facebook</label>
                                    <FacebookShareButton
                                      url={`${currentUrl}/${
                                        selectedCommentMoment.life.verified_name
                                          ? selectedCommentMoment.life
                                              .verified_name
                                          : selectedCommentMoment.life.life_id
                                      }/${selectedCommentMoment._id}`}
                                    >
                                      <FacebookIcon
                                        size={32}
                                        round
                                        className="icon"
                                      />
                                    </FacebookShareButton>
                                  </div>
                                  <div class="share-icon">
                                    <label>Mask</label>
                                    <TwitterShareButton
                                      url={`${currentUrl}/${
                                        selectedCommentMoment.life.verified_name
                                          ? selectedCommentMoment.life
                                              .verified_name
                                          : selectedCommentMoment.life.life_id
                                      }/${selectedCommentMoment._id}`}
                                      title={selectedCommentMoment.moment_title}
                                    >
                                      <XIcon size={32} round className="icon" />
                                    </TwitterShareButton>
                                  </div>
                                  <div class="share-icon icon-copy">
                                    <div class="hover-text">
                                      {/* <!-- Copy Text --> */}
                                      <div className="copy-text d-flex">
                                        <input
                                          value={`${currentUrl}/${
                                            selectedCommentMoment.life
                                              .verified_name
                                              ? selectedCommentMoment.life
                                                  .verified_name
                                              : selectedCommentMoment.life
                                                  .life_id
                                          }/${selectedCommentMoment._id}`}
                                          disabled
                                          type="text"
                                          ref={textRef}
                                        />
                                      </div>
                                    </div>
                                    <img
                                      src={images.CopyIcon}
                                      onClick={() =>
                                        copyCommentModal(
                                          selectedCommentMoment._id,
                                          selectedCommentMoment
                                        )
                                      }
                                      style={{ marginTop: "-10px !important" }}
                                      className="icon"
                                    ></img>
                                  </div>
                                </div>
                              )}
                          </a>
                        </div>
                        <p
                          className="share-word"
                          onClick={() =>
                            handleShareClick(selectedCommentMoment._id)
                          }
                        >
                          Share
                        </p>
                      </div>
                    </Col>
                  </Row>
                  {userLogin
                    ? commentOpen &&
                      commentMoment === selectedCommentMoment._id && (
                        <Comments
                          currentUserId={
                            userData && userData.id ? userData.id : null
                          }
                          momentId={selectedCommentMoment._id}
                          area="landingPage"
                        ></Comments>
                      )
                    : commentOpen &&
                      commentMoment === selectedCommentMoment._id && (
                        <Comments
                          momentId={selectedCommentMoment._id}
                        ></Comments>
                      )}
                </Card.Body>
              </Card>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      {TopMoments.length > 0 && (
        <div className="on-this-day-main">
          <div className="title">
            <span>12th April </span> On This Day
          </div>

          <Slider {...settings} className="slider">
            {TopMoments.map((topMoment, index) =>
              topMoment.images?.length > 0 &&
              topMoment.images[0]?.media_type === "image" ? (
                <div className="card" key={index}>
                  <div className="moment-type">{topMoment.moment_type[0]}</div>
                  <Link
                    to={`/read/${
                      topMoment?.life?.verified_name || topMoment?.life?.life_id
                    }/${topMoment._id}`}
                    target="_blank"
                  >
                    <img
                      className="cover-image"
                      src={topMoment.images[0]?.url}
                    />
                  </Link>
                  <div className="d-flex gap-2 align-items-center mt-2 mb-2">
                    <img
                      className="logo-image"
                      src={topMoment.life?.life_logo}
                    />
                    <Link
                      to={`/${
                        topMoment.life.verified_name
                          ? topMoment.life.verified_name
                          : topMoment.life.life_id
                      }`}
                    >
                      <div className="life-name">
                        {topMoment.life?.life_name.length > 30
                          ? topMoment.life.life_name.slice(0, 30) + "..."
                          : topMoment.life.life_name}
                      </div>
                    </Link>
                  </div>

                  <div className="moment-date">{topMoment.moment_date}</div>
                  <Link
                    to={`/${
                      topMoment?.life?.verified_name || topMoment?.life?.life_id
                    }/${topMoment._id}`}
                    target="_blank"
                  >
                    <div className="moment-title">
                      {topMoment.moment_title.length > 100
                        ? topMoment.moment_title.slice(0, 100) + "..."
                        : topMoment.moment_title}
                    </div>
                  </Link>

                  <div className="moment-desc">
                    {topMoment.moment_details.length > 100
                      ? topMoment.moment_details.slice(0, 100) + "..."
                      : topMoment.moment_details}
                  </div>

                  <Link
                    to={`/read/${
                      topMoment?.life?.verified_name || topMoment?.life?.life_id
                    }/${topMoment._id}`}
                    target="_blank"
                  >
                  <div className="seemore-btn">
                    See More
                  </div>
                  </Link>

                  <div className="reaction-icons">
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={<Tooltip>Like</Tooltip>}
                    >
                      <div
                        className="mt-2"
                        onClick={() => handleShow(topMoment._id)}
                      >
                        <i class="bi bi-hand-thumbs-up"></i>
                      </div>
                    </OverlayTrigger>
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={<Tooltip>Comment</Tooltip>}
                    >
                      <div
                        className="mt-2"
                        onClick={() => handleShow(topMoment._id)}
                      >
                        <i class="bi bi-chat-text"></i>
                      </div>
                    </OverlayTrigger>

                    <div
                      className="mt-2"
                      onClick={() => handleShare(topMoment._id)}
                    >
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={<Tooltip>Share</Tooltip>}
                      >
                        <i class="bi bi-send-fill"></i>
                      </OverlayTrigger>

                      <Col>
                        {tooltipState[topMoment._id] && (
                          <div className="copy-message">Link copied</div>
                        )}
                        <div className="icons share-icon">
                          <div className="share-feed" ref={shareRef}>
                            <a className="share-btn">
                              {shareBoxShow &&
                                shareMoment === topMoment._id && (
                                  <div className="share-box">
                                    <div className="share-icon">
                                      <label>Telegram</label>
                                      <TelegramShareButton
                                        url={`${currentUrl}/${
                                          topMoment.life.verified_name
                                            ? topMoment.life.verified_name
                                            : topMoment.life.life_id
                                        }/${topMoment._id}`}
                                        title={topMoment.moment_title}
                                      >
                                        <TelegramIcon
                                          size={32}
                                          round
                                          className="icon"
                                        />
                                      </TelegramShareButton>
                                    </div>
                                    <div className="share-icon">
                                      <label>Linkedin</label>
                                      <LinkedinShareButton
                                        url={`${currentUrl}/${
                                          topMoment.life.verified_name
                                            ? topMoment.life.verified_name
                                            : topMoment.life.life_id
                                        }/${topMoment._id}`}
                                        title={topMoment.moment_title}
                                        source="MoofLife"
                                      >
                                        <LinkedinIcon
                                          size={32}
                                          round
                                          className="icon"
                                        />
                                      </LinkedinShareButton>
                                    </div>
                                    <div className="share-icon">
                                      <label>Whatsapp</label>
                                      <WhatsappShareButton
                                        url={`${currentUrl}/${
                                          topMoment.life.verified_name
                                            ? topMoment.life.verified_name
                                            : topMoment.life.life_id
                                        }/${topMoment._id}`}
                                        title={topMoment.moment_title}
                                        separator=":: "
                                      >
                                        <WhatsappIcon
                                          size={32}
                                          round
                                          className="icon"
                                        />
                                      </WhatsappShareButton>
                                    </div>
                                    <div className="share-icon">
                                      <label>Facebook</label>
                                      <FacebookShareButton
                                        url={`${currentUrl}/${
                                          topMoment.life.verified_name
                                            ? topMoment.life.verified_name
                                            : topMoment.life.life_id
                                        }/${topMoment._id}`}
                                      >
                                        <FacebookIcon
                                          size={32}
                                          round
                                          className="icon"
                                        />
                                      </FacebookShareButton>
                                    </div>
                                    <div className="share-icon">
                                      <label>Mask</label>
                                      <TwitterShareButton
                                        url={`${currentUrl}/${
                                          topMoment.life.verified_name
                                            ? topMoment.life.verified_name
                                            : topMoment.life.life_id
                                        }/${topMoment._id}`}
                                        title={topMoment.moment_title}
                                      >
                                        <XIcon
                                          size={32}
                                          round
                                          className="icon"
                                        />
                                      </TwitterShareButton>
                                    </div>
                                    <div className="share-icon icon-copy">
                                      <div className="hover-text">
                                        {/* Copy Text */}
                                        <div className="copy-text d-flex">
                                          <input
                                            value={`${currentUrl}/${
                                              topMoment.life.verified_name
                                                ? topMoment.life.verified_name
                                                : topMoment.life.life_id
                                            }/${topMoment._id}`}
                                            disabled
                                            type="text"
                                            ref={textRef}
                                          />
                                        </div>
                                      </div>
                                      <img
                                        src={images.CopyIcon}
                                        onClick={() =>
                                          copyToClipboard(
                                            topMoment._id,
                                            topMoment
                                          )
                                        }
                                        className="share-img"
                                        alt=""
                                      ></img>
                                    </div>
                                  </div>
                                )}
                            </a>
                          </div>
                        </div>
                      </Col>
                    </div>
                  </div>
                </div>
              ) : null
            )}
          </Slider>
        </div>
      )}
    </>
  );
};

export default OnThisDay;
