import React, { useState, useEffect } from "react";
import "../../styles/FavoritesStyle.css";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { getFromSessionStorage } from "../../utils/storageHandler";
import { updateUserInterest } from "../../store/action/userInteractionsAction";
import Modal from "react-bootstrap/Modal";
import { images } from "../../utils/image";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { countryList } from "../../utils/CountryList";
import { MultiSelect } from "react-multi-select-component";
import {
  getUserFollowingLife,
  removeFollower
} from "../../store/action/FollowerAction";

const Favorites = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showError, setShowError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [userLogin, setUserLogin] = useState("");
  const itemsPerPage = 5;

  const favoriteItems = [
    {
      id: 1,
      favorite: "Business",
      checked: false,
    },
    {
      id: 2,
      favorite: "People & Culture",
      checked: false,
    },
    {
      id: 3,
      favorite: "Political & Governance",
      checked: false,
    },
    {
      id: 4,
      favorite: "Technology & Science",
      checked: false,
    },
    {
      id: 5,
      favorite: "Sustainability",
      checked: false,
    },
    {
      id: 6,
      favorite: "Entertainment & Sports",
      checked: false,
    },
  ];

  const favoriteLives = useSelector((state) => state.Follower.followerList);
  const followerPage = useSelector((state) => state.Follower.page);
  const [isChecked, setIsChecked] = useState(favoriteItems);

  useEffect(() => {
    const interest = getFromSessionStorage("interest", true);
    if (interest && interest.length > 0) {
      for (let i = 0; i < interest.length; i++) {
        setIsChecked((prevItems) => {
          return prevItems.map((item) => {
            if (item.favorite === interest[i]) {
              return { ...item, checked: true };
            }

            return item;
          });
        });
      }
    }
  }, []);

  useEffect(() => {
    const storedSelectedCountries = JSON.parse(
      sessionStorage.getItem("selected_countries")
    );
    if (storedSelectedCountries && Array.isArray(storedSelectedCountries)) {
      setSelectedItems(storedSelectedCountries);
    }

    const userDetails = getFromSessionStorage("userDetails", true);
    setUserLogin(userDetails);
    dispatch(
      getUserFollowingLife({ userId: userDetails.id, page:followerPage, limit:6 }, followerPage)
    )
  }, []);

  const handleCheckbox = (itemId) => {
    setIsChecked((prevItems) => {
      return prevItems.map((item) => {
        if (item.id === itemId) {
          return { ...item, checked: !item.checked };
        }
        return item;
      });
    });
  };

  const handleFavorites = async () => {
    const selected_interest = [];
    const userDetails = getFromSessionStorage("userDetails", true);
    const loggedInUserId = userDetails?.id ? userDetails.id : null;
    isChecked.map((item) => {
      if (item.checked === true) {
        selected_interest.push(item.favorite);
      }
    });
    dispatch(
      await updateUserInterest({
        interest: selected_interest,
        id: loggedInUserId,
        selected_countries: selectedItems,
      })
    )
      .then((res) => {
        if (res) {
          sessionStorage.setItem(
            "selected_countries",
            JSON.stringify(selectedItems)
          );
          setShow(true);
          setTimeout(() => {
            setShow(false);
          }, 2000);
        } else {
          setShowError(true);
          setTimeout(() => {
            setShowError(false);
          }, 2000);
        }
      })
      .catch((error) => {
        console.error("Error in favourite update:", error);
      });
  };

  const handleRemoveLife = (id) => {
    dispatch(removeFollower({ user_id: userLogin.id, life_id:id },'setting'))
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
    if ((currentPage + 1) === followerPage) {

      dispatch(
        getUserFollowingLife({ userId: userLogin.id, page: followerPage, limit: 6 }, followerPage)
      )

    }
  };

  const handlePrevPage = () => {
    setCurrentPage(1);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  return (
    <>
      <div className="favorites-main">
        <div className="title">My Favorites</div>

        {/* Pop up */}
        <Modal className="popup-modal" show={show}>
          <div className="popup-header">
            <div className="logo-icon">
              <img src={images.MoofIcon} />
            </div>
          </div>

          <Modal.Body>
            <div className="title">Updated Successfully !</div>
          </Modal.Body>
        </Modal>

        {/* Pop up */}
        <Modal className="popup-modal" show={showError}>
          <div className="popup-header">
            <div className="logo-icon">
              <img src={images.MoofIcon} />
            </div>
          </div>

          <Modal.Body>
            <div className="title">Get Error While Updating !</div>
          </Modal.Body>
        </Modal>

        <div className="main-content">
          <div className="para-1">
            Make your Mooflife more you. Edit the details Mooflife uses to
            recommend ideas to you. These details won’t be shown publicly.
          </div>

          <div className="grid-container">
            {isChecked.map((item) => (
              <div className="grid-item" key={item.id}>
                <div className="text-checkbox">
                  <div className="check-title">{item.favorite}</div>
                  <div>
                    <Form.Check
                      className="yellow-checkbox"
                      type="checkbox"
                      checked={item.checked}
                      onChange={() => handleCheckbox(item.id)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="country-selection">
            <div>
              <div className="title-checkbox">
                <div className="check-title">Country & Region</div>
                <div>
                  <MultiSelect
                    className="country-select-box"
                    options={countryList.map((countryItem) => ({
                      label: countryItem.country,
                      value: countryItem.code,
                    }))}
                    value={selectedItems}
                    onChange={setSelectedItems}
                    labelledBy="Select"
                    valueRenderer={(selected) =>
                      selected.length >= countryList.length
                        ? "All countries selected"
                        : `${selected.length} countries selected`
                    }
                  />
                </div>
              </div>
            </div>
            <div className="country-list">
              {selectedItems.length >= countryList.length ? (
                <div>All countries selected</div>
              ) : selectedItems.length > 15 ? (
                <>
                  {selectedItems.slice(0, 15).map((country, index) => (
                    <span key={index}> {country} |&nbsp; </span>
                  ))}
                  <span>...</span>
                </>
              ) : (
                selectedItems.map((country, index) => (
                  <span key={index}> {country} |&nbsp; </span>
                ))
              )}
            </div>
          </div>

          {/* <div className="condition-row">
      <div>
        <Form.Check className="yellow-checkbox" type="checkbox" />
      </div>
      <div className="para-2">
        Show me the Moments only from the lives that I follow (Not
        Recommended. Everyday new lives are added onto MoofLife. If you
        select this option, you will miss certain moments of your interest){" "}
      </div>
    </div>

    <div className="condition-row">
      <div>
        <Form.Check className="yellow-checkbox" type="checkbox" />
      </div>
      <div className="para-2">
        Show me the moments of any Life (Not recommended, MoofLife will try
        to present most suitable lives based on the quality of the content
        as determined by our algorithms. If selected, MoofLife will not
        perform this analysis)
      </div>
    </div> */}

          <div className="button-row">
            <button className="yellow-btn" onClick={handleFavorites}>
              Update
            </button>
          </div>
        </div>
      </div>

      <div className="favorites-main" style={{marginTop:"10px"}}>
        <div className="title">Favorurite Lives</div>

        <div className="favorite-lives-main">
          <div className="grid-container">
            {favoriteLives.slice(startIndex, endIndex).map((item) => (
              <div className="grid-item" key={item.life_id}>
                <div className="content">
                  <div className="img-name">
                    <div>
                      <img src={item.life_logo} className="user-image" />
                    </div>
                    <div className="user-name">{item.life_name}</div>
                  </div>
                  <div>
                    <div
                      className="remove-word"
                      onClick={() => handleRemoveLife(item.life_id)}
                    >
                      Remove
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="pagination">
            {favoriteLives.length > endIndex ? (
              <div onClick={handleNextPage}>
                {currentPage} of {currentPage + 1}{" "}
                <span>
                  <i class="bi bi-caret-right-fill"></i>
                </span>
              </div>
            ) : (currentPage>1 ?
              (<div onClick={handlePrevPage}>
                <span>
                  <i class="bi bi-caret-left-fill"></i>
                </span>
                Back
              </div>): ''
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Favorites;
