import React, { useEffect, useState } from "react";
import "../../styles/ActionCenter.css";
import { useDispatch } from "react-redux";
import { getLifeManagerPendingReq, updateAcknowledge } from "../../store/action/AccountAdministrationAction"
import { getFromSessionStorage } from "../../utils/storageHandler";

const ActionCenter = () => {
  const dispatch = useDispatch();
  const [userLogin, setUserLogin] = useState("");
  const [newReq, setNewReq] = useState([]);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const userDetails = getFromSessionStorage("userDetails", true);
    setUserLogin(userDetails);
    fetchNewReq(userDetails.email);

  }, []);

  const fetchNewReq = async (life_manager_email) => {

    dispatch(
      await getLifeManagerPendingReq({ life_manager_email }

      )
    )
      .then((res) => {
        if (res) {
          setNewReq(res.data);

        }
      })
      .catch((error) => {
        console.error("Error in fetch life managers req:", error);
      });
  };

  const handleStatusChange = async (account_manager_email, status) => {
    dispatch(
      await updateAcknowledge({
        account_manager_email, status, life_manager_email:userLogin.email
      })
    )
      .then((res) => {
        if (res) {
          fetchNewReq(userLogin.email)
        }
        
        setTimeout(() => {
          if(status === "yes"){
            setMessage("Accepted the invitation !");
          }else if(status === "reject"){
            setMessage("Rejected the invitation !");
          }
        }, 1000);
       
        setTimeout(() => {
          setMessage("");
        }, 5000);
      })
      .catch((error) => {
        console.error("Error in update acknowledge:", error);
      });

  };

  return (
    <div>
      <div className="favorites-main action-center">
        <div className="title">My Actions</div>

        <div className="main-content">
        {newReq.length > 0 && newReq.map((action, index) => (
          <div className="para-btn-row">
            <div className="para">
             
                <div key={index}>
                  {new Date(action.date).toLocaleDateString()}, <b>{action.account_manager_name}</b> has invited you to become a Life Manager at MoofLife.com!
                </div>
            </div>
            
            
            <div className="btn-set">
              <div className="button" onClick={() => {handleStatusChange(action.account_manager_email, "yes");}}>Accept</div> &nbsp;|&nbsp;
              <div className="button" onClick={() => {handleStatusChange(action.account_manager_email, "reject");}}>Delete</div>
            </div>
          </div>
                ))}
        
        <div className="para" style={{fontWeight:"600"}}>{message}</div>
        </div>
      </div>
    </div>
  );
};

export default ActionCenter;
