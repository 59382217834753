import React from "react";
import LifeNavbar from "./LifeNavbar";
import LifeHeader from "./LifeHeader";
import "../../styles/LifePage/LifePageStyle.css";
import SearchPage from "./SearchPage";
import LifeMoments from "./LifeMoments";

const LifePage = () => {
  return (
    <div className="life-page-main">
      {/* <LifeNavbar />
      <LifeHeader />
      <SearchPage /> */}
      <LifeMoments />
    </div>
  );
};

export default LifePage;
