import './App.css';
import { BrowserRouter as Router,Routes,Route, Navigate } from 'react-router-dom' 
import { useState, useEffect } from "react";
import LandingPage from './views/LandingPage';
import ContactUs from './views/CooperatePages/ContactUs';
import AboutUs from './views/CooperatePages/AboutUs';
import PrivacyPolicy from './views/CooperatePages/PrivacyPolicy';
import MooflifeConcept from './views/CooperatePages/MooflifeConcept';
import LifePage from './views/LifePage';
import LoginPage from './views/LoginPage';
import RegisterPage from './views/RegisterPage';
import EmailVerification from './views/EmailVerification';
import ForgetPassword from './views/ForgetPassword';
import NewPassword from './views/NewPassword';
import TermsAndCondition from './views/TermsAndCondition';
import YourInterests from './views/YourInterests';
import OtpVerification from './views/OtpVerification';
import SearchPage from '../src/views/SearchPage/SearchPage';
import ReportLifeMoment from './views/ReportLifeMoment';
import { HelmetProvider } from 'react-helmet-async';
import ErrorPage from './views/ErrorPage';
import UserSettings from './views/UserSettings';
import UpdateMomentPage from './views/UpdateMomentPage';
// import LandingDummy from './views/LandingPage';
// import LifeDummy from './views/LifePage';

import '../src/fonts/selawik/selawk.ttf';
import '../src/fonts/selawik/selawkb.ttf';
import UpdateLifePage from './views/UpdateLifePage';
import SelectPackage from './views/SelectPackage';
import TopMomentTile from './views/TileViews/TopMomentTile';
import MostViewTile from './views/TileViews/MostViewTile';
import MyMomentsTile from './views/TileViews/MyMomentsTile';
import NewLandingPage from './views/LandingPage/LandingPage';
import NewLifePage from './views/LifePage/LifePage';

function App() {
  return (
    <div>
      <HelmetProvider>
      <Router>
        <Routes>
        <Route path='/read/' element={< LandingPage area="landingPage" />}/>
        <Route path='/read/:life_id' element={< LifePage area="lifePage" />}/>
        <Route path='/read/:life_id/:moment_id' element={< LifePage area="lifePage" />}/>

        {/* CooperatePages */}
        <Route path='/contact-us' element={< ContactUs />}/>
        <Route path='/about-us' element={< AboutUs />}/>
        <Route path='/privacy-policy' element={< PrivacyPolicy />}/>
        <Route path='/mooflife-concept' element={< MooflifeConcept />}/>

        {/* User Login */}
        <Route path='/login' element={< LoginPage />}/>

        {/* User Registration */}
        <Route path='/sign-up' element={< RegisterPage />}/>

        {/* Email Verification */}
        <Route path='/email-verification' element={< EmailVerification />}/>

        {/* Forget Password */}
        <Route path='/forget-password' element={< ForgetPassword />}/>

        {/* New Password */}
        <Route path='/new-password' element={< NewPassword />}/>

        {/* Terms and Conditions */}
        <Route path='/terms-and-conditions' element={< TermsAndCondition />}/>

        {/* Your Interests */}
        <Route path='/your-interests' element={< YourInterests />}/>

        {/* OTP Verification */}
        <Route path='/otp-verification' element={< OtpVerification />}/>

        {/* Search Page */}
        <Route path='/search' element={< SearchPage />}/>

        {/* Error Page */}
        <Route path='/404' element={< ErrorPage />}/>

        {/* User Settings Page */}
        <Route path='/user-settings' element={< UserSettings />}/>

        {/* Update Moment Page */}
        <Route path='/update-moment/:moment_id' element={< UpdateMomentPage />}/>

        {/* create Moment Page */}
        <Route path='/create-moment' element={< UpdateMomentPage />}/>

        {/* create Life Page */}
        <Route path='/create-life' element={< UpdateLifePage />}/>

        {/* update Life Page */}
        <Route path='/update-life' element={< UpdateLifePage />}/>

        {/* create Moment Page */}
        <Route path='/select-package' element={< SelectPackage />}/>

        {/* Top Moment Tile View */}
        <Route path='/top-moment-grid' element={< TopMomentTile />}/>

        {/* Most Viewed Moment Tile View */}
        <Route path='/most-viewed-grid' element={< MostViewTile />}/>

        {/* My Moments Tile View */}
        <Route path='/my-moments-grid' element={< MyMomentsTile />}/>

        {/* Landing Page Grid View */}
        <Route path='/' element={< NewLandingPage />}/>

        {/* Life Page Grid View */}
        <Route path='/:life_id' element={< NewLifePage />}/>

        <Route path='/:life_id/:moment_id' element={< NewLifePage />}/>

        {/* Landing Dummy */}
        {/* <Route path='/landing-dummy' element={< LandingDummy />}/> */}

        {/* Life Dummy */}
        {/* <Route path='/life-dummy' element={< LifeDummy />}/> */}

        </Routes>
      </Router>
      </HelmetProvider>
    </div>
  );
}

export default App;
