import React, { useEffect } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, useNavigate } from "react-router-dom";
import MomentJS from "moment";
import "../styles/TermsAndConditionStyle.css";
import { images } from "../utils/image";
import { useLocation } from "react-router-dom";
import {
  registerGoogleUser,
  setUserDetails,
} from "../store/action/userInteractionsAction";
import { useDispatch, useSelector } from "react-redux";
import {
  setIntoSessionStorage,
  getFromSessionStorage,
  setIntoLocalStorage,
} from "../utils/storageHandler";

const TermsAndCondition = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  const type = data && data.type ? data.type : "mooflife";
  const rememberMe = data && data.rememberMe ? data.rememberMe : "false";

  const userDetails = useSelector(
    (state) => state.UserInteractions.newUserDetails
  );

  useEffect(() => {
    const accessToken = getFromSessionStorage("accessToken");
    if (accessToken) {
      // Redirect to the home page if the user already loggedIn
      navigate("/");
    } else if (!userDetails.otp && !userDetails.image_url) {
      // Redirect to the loggin page if the user hasn't completed the sign-up process
      navigate("/login");
    }
  }, []);

  const verifyTerms = () => {
    if (type === "google") {
      let user = { ...userDetails };
      const dataToRegister = {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        image_url: user.image_url,
      };

      dispatch(registerGoogleUser(dataToRegister))
        .then((res) => {
          if (res && res.success && res.data.token) {
            const tokenExpireAt = MomentJS().add(10, "hours");
            if (rememberMe && rememberMe === "true") {
              setIntoLocalStorage("accessToken", res.data.token);
              setIntoLocalStorage("userDetails", res.data.userDetails, true);
              setIntoLocalStorage("tokenExpireTime", tokenExpireAt);  
              setIntoLocalStorage("interest", res.data.interest, true);
              setIntoLocalStorage("selected_countries", res.data.selected_countries, true);
              setIntoLocalStorage("register_type", res.data.register_type);
            } else {
              setIntoSessionStorage("accessToken", res.data.token);
              setIntoSessionStorage("userDetails", res.data.userDetails, true);
              setIntoSessionStorage("tokenExpireTime", tokenExpireAt);
              setIntoSessionStorage("interest", res.data.interest, true);
              setIntoSessionStorage("selected_countries", res.data.selected_countries, true);
              setIntoSessionStorage("register_type", res.data.register_type);
            }
            dispatch(setUserDetails({}));
            navigate("/");
          }
        })
        .catch((error) => {
          console.error("Error in login:", error);
        });
    } else {
      let dataToRegister = { ...userDetails };
      dataToRegister.terms_and_condition = true;
      dispatch(setUserDetails(dataToRegister))
        .then(() => {
          navigate("/your-interests");
        })
        .catch((error) => {
          console.error("Error in setUserDetails:", error);
        });
    }
  };

  const declineTerms = () => {
    dispatch(setUserDetails({}));
    navigate("/login");
  };

  return (
    <div>
      <div className="terms-main">
        <Card centered className="card">
          <Card.Header className="card-header">
            <p>
              <span>
                <img src={images.MoofIcon}></img>
              </span>
              MoofLife Terms & Conditions
            </p>
          </Card.Header>
          <Card.Body>
            <div className="text">
              Terms & Conditions for MoofLife Corporation Effective Date:
              01-October 2023
              <br />
              <div className="title">1. Acceptance of Terms</div>
              <br />
              By accessing or using the services provided by MoofLife
              Corporation ("we," "us," "our"), you agree to comply with and be
              bound by these Terms & Conditions. If you disagree with any part
              of these terms, you must not use our platform.
              <br />
              <div className="title">2. Age Requirement</div>
              <br />
              Our platform is suitable for all ages, but account creation is
              only allowed for individuals who are 13 years of age or older.
              Individuals under 13 may access the site under the guidance of a
              parent or guardian.
              <br />
              <div className="title">3. Content Guidelines </div>
              <br />
              <div className="sub-para">
                <div className="sub-title">a. Content Ownership:</div>Users who
                upload content must either own the rights to the content or
                provide proper attribution to the rights owner.
                <br />
                <div className="sub-title">b. Content Responsibility:</div>{" "}
                Please refrain from infringing upon the rights of image creators
                and respect the boundaries of fair use.
                <br />
                <div className="sub-title">c. Removal of Content:</div> We
                reserve the right to remove content or disable user accounts if
                we determine that the content violates copyrights, or for any
                other reason, as outlined in these terms.
                <br />
                <div className="sub-title">d. Content Discretion:</div> We have
                full discretion to hide or delete content without providing
                reasons or justifications.
                <br />
                <div className="sub-title">e. Public Viewing:</div> Content
                uploaded will be visible to anyone accessing the platform. Users
                should only share content they are comfortable making public.
                <br />
                <div className="sub-title">f. Content Security:</div> We do not
                guarantee the security of content as it is intended for public
                viewing.
                <br />
                <div className="sub-title">g. Content Behavior:</div> Users must
                respect the community and avoid inappropriate content or
                behavior. We reserve the right to remove content or disable
                accounts that violate these guidelines.
                <br />
              </div>
              <div className="title">4. Mature Content Filtering</div> <br />
              While we have implemented mature content filtering, we cannot
              guarantee its effectiveness in all cases. Parents and guardians
              are encouraged to supervise usage by individuals under 13.
              <br />
              <div className="title">5. Backups and Loss of Content</div>
              <br />
              Although we take measures to back up content, we are not
              responsible for any loss of content due to unforeseen
              circumstances.
              <br />
              {/* <div className="title">6. Premium Subscription </div>
              <br />
              The premium subscription service is for an annual subscription,
              and no refunds are applicable.
              <br /> */}
              <div className="title">6. Limitation of Liability</div>
              <br />
              MoofLife Corporation shall not be liable for any indirect,
              incidental, consequential, or punitive damages or losses, whether
              in contract, tort, or otherwise, related to your use of our
              services.
              <br />
              <div className="title">7. Changes to Terms & Conditions</div>
              <br />
              We may update these Terms & Conditions from time to time. Any
              changes will be posted on this page, and your continued use of our
              services following such changes will indicate your acceptance of
              the new terms.
              <br />
              <div className="title">8. Governing Law </div>
              <br />
              These Terms & Conditions are governed by the laws of California,
              USA, without regard to its conflict of laws principles.
              <br />
              <div className="title">9. Contact Us </div>
              <br />
              If you have any questions or concerns about these Terms &
              Conditions, please contact us at{" "}
              <a
                href="/contact-us"
                style={{ color: "#3787FF", marginLeft: "5px" }}
              >
                https://www.mooflife.com/Contact-Us
              </a>
              &nbsp;or{" "}
              <a
                style={{ color: "#3787FF", marginLeft: "5px" }}
                href="mailto:contact@mooflife.com"
              >
                contact@mooflife.com
              </a>{" "}
              .
              <br />
            </div>
          </Card.Body>
          <Card.Footer className="card-footer">
            <Row>
              <Col sm={6}></Col>
              <Col sm={3}>
                <button className="btn-decline" onClick={declineTerms}>
                  Decline
                </button>
              </Col>
              <Col sm={3}>
                <button className="btn-accept" onClick={verifyTerms}>
                  Accept
                </button>
                <Link to="/yourInterests"></Link>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </div>
    </div>
  );
};

export default TermsAndCondition;
