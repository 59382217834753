import { createRequest } from '../../utils/requestHandler';
import { getTopMoments, setLimitReached, setPage, setTopMoments } from '../slices/TopMomentsSlice';
const mooflife_backend = process.env.REACT_APP_BACK_END_URL;

export const fetchTopMoments = (date) => {
  return async (dispatch) => {
    try {
      const configs = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      const response = await createRequest('get', `${mooflife_backend}/top-moments/${date}`, {}, configs);
      if (response && response.data && response.data.success && response.data.data) {
        dispatch(getTopMoments(response.data.data));
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
};

export const getTopMomentsTile = (data, page) => {

  return async (dispatch) => {
    try {
      const configs = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      const response = await createRequest('post', `${mooflife_backend}/top-moments/tile`, data, configs);
      if (response && response.data && response.data.success && response.data.data) {
        if (response.data.data.length === 0) {
          dispatch(setLimitReached(true));
        }
        dispatch(setTopMoments(response.data.data));

        if (page) {
          dispatch(setPage(page + 1));
        }
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
};

export const getTopMomentsLife = (data) => {

  return async (dispatch) => {
    try {
      const configs = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      const response = await createRequest('post', `${mooflife_backend}/top-moments/life`, data, configs);
      if (response && response.data && response.data.success && response.data.data) {
      
        return response.data.data;


      
      }
      else{
        return [];
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
};