import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  createLifeId,
  createSubscription,
  validateCoupon,
  getSubscriptionLimits,
} from "../store/action/LifeAction";
import {
  getFromSessionStorage,
  setIntoLocalStorage,
} from "../utils/storageHandler";
import "../styles/SelectPackageStyle.css";
import { subscriptionPackages } from "../utils/SubscriptionPackages.js";
import { images } from "../utils/image.js";
import NavigationBar from "../views/LandingPage/NavigationBar.jsx";
import { Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { getAuthorizedEmails } from "../store/action/MomentAction.js";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const SelectPackage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentDate = new Date();
  const nextYearDate = new Date(currentDate);
  nextYearDate.setFullYear(currentDate.getFullYear() + 1);
  const [lifeName, setLifeName] = useState("");
  const [lifeType, setLifeType] = useState("");
  const [subscriptionType, setSubscriptionType] = useState("user");
  const [subscriptionPlan, setSubscriptionPlan] = useState("Free");
  const [couponCode, setCouponCode] = useState("");
  const userDetails = getFromSessionStorage("userDetails", true);
  const [isSelect, setIsSelect] = useState("Plus");
  const [isButtonSelect, setIsButtonSelect] = useState("Free");
  //const formattedDate = nextYearDate.toLocaleDateString();
  const formattedDate = nextYearDate.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  });
  const [validCouponCode, setValidCouponCode] = useState(false);
  const [totalPrice, setTotalPrice] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [autoRenewVal, setAutoRenewVal] = useState(true);
  const [show, setShow] = useState(false);
  const [waitingModalShow, setWaitingModalShow] =  useState(false);
  const [subscriptionLimits, setSubscriptionLimits] = useState([]);
  const [mooflifeAdmin, setMooflifeAdmin] = useState(false);

  useEffect(() => {
    const accessToken = getFromSessionStorage("accessToken");

    if (!accessToken) {
      navigate("/login");
    }

    if (userDetails) {
      requestSubscriptions();
    }

    checkAuthorizedEmails();
  }, []);

  const checkAuthorizedEmails = async () => {
    if(userDetails && userDetails.email){
      dispatch(await getAuthorizedEmails({userEmail: userDetails.email}))
      .then((res) => {
        if (res && res.success && res.data) {
          setMooflifeAdmin(res.data.result);
        } 
      });
    }
  };

  const requestSubscriptions = async () => {
    const subscriptions = await dispatch(getSubscriptionLimits());
    if (subscriptions.success && subscriptions.data) {
      setSubscriptionLimits(subscriptions.data);
    }
  };

  const setValues = (evt) => {
    const { id, value } = evt.target;

    if (id === "lifeName") {
      setLifeName(value);
    } else if (id === "lifeType") {
      setLifeType(value);
      //setSubscriptionType(value);
    }
  };

  const handleContinue = () => {
    dispatch(createLifeId({ lifeType: lifeType })).then((res) => {
      if (res.success && res.data) {
        const stateVal = {
          lifeName: lifeName,
          lifeType: lifeType,
          lifeId: res.data.lifeId,
          plan: `${lifeType}-${subscriptionPlan}`,
        };
        navigate("/create-life", { state: stateVal });
      }
    });
  };

  const handlePlan = (plan, price) => {
    console.log(plan);
    console.log(price);
    setSubscriptionPlan(plan);
    setTotalPrice(price);
  };

  const waitingForResponse = () => {
    setLoading(true);
    setWaitingModalShow(true);
    setShow(false);
    setTimeout(() => {
      setWaitingModalShow(false);
      proceedToPay();
    }, 5000);
  }

  const proceedToPay = async () => {
   
    if (subscriptionPlan === "Free" || subscriptionType === "mooflife") {
      handleContinue();
    } else {
      const lifeIdRes = await dispatch(createLifeId({ lifeType: lifeType }));
      if (lifeIdRes.success && lifeIdRes.data) {
        const setLifeToCreate = setIntoLocalStorage(
          "lifeToCreate",
          {
            lifeName: lifeName,
            lifeType: lifeType,
            lifeId: lifeIdRes.data.lifeId,
            plan: `${lifeType}-${subscriptionPlan}`,
          },
          true
        );
        if (setLifeToCreate) {
          const subscriptionProcess = await dispatch(
            createSubscription({
              lifeName: lifeName,
              lifeType: lifeType,
              lifeId: lifeIdRes.data.lifeId,
              plan: `${lifeType}-${subscriptionPlan}`,
              userEmail:
                userDetails && userDetails.email ? userDetails.email : null,
              name: userDetails
                ? `${userDetails.first_name} ${userDetails.last_name}`
                : null,
              coupon: couponCode !== "" ? couponCode : null,
              autoRenew: autoRenewVal,
            })
          );

          if (
            subscriptionProcess &&
            subscriptionProcess.success &&
            subscriptionProcess.data
          ) {
            window.location.href = subscriptionProcess.data.url;
          } else {
            if (
              subscriptionProcess.error &&
              subscriptionProcess.invalidCoupon
            ) {
              // err msg here
            }
          }
        }
      }
     
    }
  };

  const handleCouponCode = (evt) => {
    setCouponCode(evt.target.value);
  };

  const handleButtonClick = (subscription, price) => {
    setIsSelect(subscription);
    setIsButtonSelect(subscription);

    handlePlan(subscription, price);
  };

  const handleApplyCoupon = async (coupon) => {
    if (coupon && coupon !== "") {
      const couponCodeRes = await dispatch(validateCoupon({ coupon: coupon }));
      if (couponCodeRes && couponCodeRes.success) {
        if (!couponCodeRes.data.success && couponCodeRes.data.invalid) {
          setValidCouponCode(false);
          setErrorMessage("Invalid Coupon Code");
        } else if (
          couponCodeRes.data.success &&
          couponCodeRes.data.invalid === false
        ) {
          const amountToOff = couponCodeRes.data.data.amount_off;
          const roundedAmount = amountToOff / 100;
          setValidCouponCode(true);
          setTotalPrice(totalPrice - roundedAmount);
        }
      }
    }
  };

  const handleAutoRenew = async (evt) => {
    setAutoRenewVal(evt.target.checked);
  };

  const handlePlanChange = (event) => {
    const selectedOption = subscriptionLimits.find(
      (subscription) => subscription.plan === event.target.value
    );
    if (selectedOption) {
      setSubscriptionPlan(selectedOption.plan);
      setTotalPrice(selectedOption.price);
      setSubscriptionType(selectedOption.type);
    }

  };

  const lifeTypeLabel = lifeType === "PL" ? "Person Life" : lifeType === "OL" ? "Organization Life" : "Entity Life";

  return (
    <div className="select-package-main">
      <NavigationBar />

      <div>
        <div className="main-title">
          Choose the Life subscription that suits you best
        </div>
        <div className="sub-title">
          You’re about to create a new Life at MoofLife.com. Take a moment to
          review the options below and select the plan that best aligns with
          your Life. After creating your Life, you’ll be able to add Life
          Moments.
        </div>
      </div>

      <div className="package-set">
        <div className="details">
          <div className="top-box"></div>
          <div className="features">
            <div className="feature-name">
              Moments Per Year{" "}
              <OverlayTrigger
                key="right"
                placement="right"
                overlay={
                  <Tooltip id="tooltip-left">The maximum number of life moments you can create and share each year under each plan.</Tooltip>
                }
              >
                <span>
                  <i class="bi bi-info-circle"></i>
                </span>
              </OverlayTrigger>
            </div>
            <div className="feature-name">
              Images Per Moment{" "}
              <OverlayTrigger
                key="right"
                placement="right"
                overlay={
                  <Tooltip id="tooltip-left">The number of images you can add to each moment to make your memories more vivid.</Tooltip>
                }
              >
                <span>
                  <i class="bi bi-info-circle"></i>
                </span>
              </OverlayTrigger>
            </div>
            <div className="feature-name">
              Videos Per Moment
              <OverlayTrigger
                key="right"
                placement="right"
                overlay={
                  <Tooltip id="tooltip-left">Add videos to your life moments to capture every detail of your memories.</Tooltip>
                }
              >
                <span>
                  <i class="bi bi-info-circle"></i>
                </span>
              </OverlayTrigger>
            </div>
            <div className="feature-name">
              Life Together
              <OverlayTrigger
                key="right"
                placement="right"
                overlay={
                  <Tooltip id="tooltip-left">View moments of different lives together side-by-side. To see what Japan was doing, when the US sent a man to the moon</Tooltip>
                }
              >
                <span>
                  <i class="bi bi-info-circle"></i>
                </span>
              </OverlayTrigger>
            </div>
            <div className="feature-name">
              Timeline
              <OverlayTrigger
                key="right"
                placement="right"
                overlay={
                  <Tooltip id="tooltip-left">Organize and view your life moments in a chronological order in a classic timeline.</Tooltip>
                }
              >
                <span>
                  <i class="bi bi-info-circle"></i>
                </span>
              </OverlayTrigger>
            </div>
            <div className="feature-name">
              Life Support (email)
              <OverlayTrigger
                key="right"
                placement="right"
                overlay={
                  <Tooltip id="tooltip-left">Receive priority email support for any issues or questions related to your life moments.</Tooltip>
                }
              >
                <span>
                  <i class="bi bi-info-circle"></i>
                </span>
              </OverlayTrigger>
            </div>
            <div className="feature-name">
              Clean life (No Ads on your life page)
              <OverlayTrigger
                key="right"
                placement="right"
                overlay={
                  <Tooltip id="tooltip-left">Enjoy an ad-free experience on your life page, focusing solely on your memories.</Tooltip>
                }
              >
                <span>
                  <i class="bi bi-info-circle"></i>
                </span>
              </OverlayTrigger>
            </div>
          </div>
        </div>

        <div className="package-rows">
          {subscriptionLimits.map((subscription, index) => {
            if (subscription.type === "user") {
              const packageInfo = subscriptionPackages.find(
                (pkg) => pkg.plan === subscription.plan
              );

              return (
                <div
                  key={index}
                  className="package"
                  onClick={() =>
                    handleButtonClick(subscription.plan, subscription.price)
                  }
                  style={{
                    opacity: isSelect === subscription.plan ? 1 : 0.4,
                    cursor: "pointer",
                    background:
                      isSelect === subscription.plan
                        ? packageInfo.package_background_color
                        : "white",
                  }}
                >
                  <div className="top-box">
                    <div className="package-name">
                      Life
                      {subscription.plan === "Free"
                        ? "Basic"
                        : subscription.plan}
                    </div>

                    <div className="price">
                      {subscription.price === "0"
                        ? "Free"
                        : `$ ${subscription.price}`}
                      {subscription.price !== "0" && (
                        <span style={{ fontSize: "12px" }}> / year</span>
                      )}
                    </div>
                    <div className="detail-box">
                      <div className="desc">
                        {packageInfo.package_description}
                      </div>
                    </div>
                    <button
                      className="select-btn"
                      style={{
                        background:
                          isSelect === subscription.plan
                            ? packageInfo.package_theme_color
                            : "white",
                        border: `2px solid ${packageInfo.package_theme_color}`,
                        color:
                          isSelect === subscription.plan
                            ? packageInfo.package_theme_dark_color
                            : packageInfo.package_theme_color,
                      }}
                    >
                      <div className="d-flex gap-2 align-items-center">
                        {isSelect === subscription.plan && (
                          <span>
                            <img src={images.check} />
                          </span>
                        )}
                        Select
                      </div>
                    </button>
                  </div>
                  <div className="feature-counts">
                    <div className="count">{subscription.moments_per_year}</div>
                    <div className="count">
                      {subscription.images_per_moment}
                    </div>
                    <div className="count">
                      {subscription.videos_per_moment}
                    </div>
                    <div className="count">{subscription.life_together}</div>
                    <div className="count">
                      {subscription.timeline ? (
                        <img className="icon" src={images.check} />
                      ) : (
                        <img className="icon" src={images.cancel} />
                      )}
                    </div>
                    <div className="count">
                      {subscription.life_support_email === "true" ? (
                        <img className="icon" src={images.check} />
                      ) : (
                        <img className="icon" src={images.cancel} />
                      )}
                    </div>
                    <div className="count">
                      {subscription.clean_life ? (
                        <img className="icon" src={images.check} />
                      ) : (
                        <img className="icon" src={images.cancel} />
                      )}
                    </div>
                  </div>
                  <div>
                    <button
                      className="price-btn"
                      style={{
                        background: `${packageInfo.package_theme_dark_color}`,
                        border: `2px solid ${packageInfo.package_theme_dark_color}`,
                        color: "white",
                      }}
                      onClick={() =>
                        handlePlan(subscription.plan, subscription.price)
                      }
                    >
                      $ {subscription.price}.00
                    </button>
                  </div>
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>

      <div className="form">
        <div className="col1">
          {mooflifeAdmin && (
            <div>
              <label className="title">Subscription Name</label>
              <select
                className="select-tag"
                id="lifeType"
                value={subscriptionPlan}
                onChange={handlePlanChange}
              >
                <option value="" selected aria-readonly>
                  Select a plan
                </option>
                {subscriptionLimits
                  .filter((subscription) => subscription.type === "mooflife")
                  .map((subscription) => (
                    <option key={subscription.plan} value={subscription.plan}>
                      {subscription.plan}
                    </option>
                  ))}
              </select>
            </div>
          )}

          <div>
            <label className="title">Subscription Period</label>
            <span className="valid-year">
              1 year : Valid till {formattedDate}
            </span>
          </div>

          <div>
            <label className="title">Life Name</label>
            <input
              type="text"
              placeholder="Your Name or Organization Name"
              id="lifeName"
              value={lifeName}
              onChange={setValues}
              maxLength="40"
            ></input>
          </div>

          <div>
            <label className="title">
            Life Type
              <OverlayTrigger
                key="right"
                placement="right"
                overlay={<Tooltip id="tooltip-left">Choose the category that best describes the type of life you want to document, whether it's personal, organizational, or Entity (literally anything you can imagine).</Tooltip>}
              >
                <span> <i class="bi bi-info-circle" style={{fontSize: "12px", marginLeft: "10px"}}></i></span>
              </OverlayTrigger>
            </label>

            <select
              className="select-tag"
              id="lifeType"
              value={lifeType}
              onChange={setValues}
            >
              <option value="" disabled="disabled">
                Select Life Type
              </option>
              <option value="PL">Person Life</option>
              <option value="OL">Organization Life</option>
              <option value="EL">Entity Life</option>
            </select>
          </div>
        </div>

        <div className="col2">
          <div className="coupon-input">
            <input
              onChange={(evt) => handleCouponCode(evt)}
              value={couponCode}
              placeholder="Coupon Code"
            ></input>
            <span onClick={() => handleApplyCoupon(couponCode)}>Apply</span>
          </div>

          <div>
            {errorMessage && (
              <small>
                <div className="error-message">
                  <span>
                    <i class="bi bi-exclamation-circle"></i>
                  </span>
                  {errorMessage}
                </div>
              </small>
            )}
          </div>

          <div className="total">
            Total <span>$ {totalPrice}.00</span>
          </div>

          <div className="d-flex align-items-center gap-3 mt-2">
            <input
              type="checkbox"
              className="checkbox"
              onChange={handleAutoRenew}
              checked={autoRenewVal}
            />
            <span style={{ fontSize: "14px" }}>Auto Renew</span>
          </div>

          <button
            className="yellow-btn"
            onClick={() => setShow(true)}
            disabled={
              lifeName === "" ||
              lifeType === "" ||
              (couponCode !== "" && !validCouponCode) ||
              totalPrice === ""
            }
          >
            {loading ? (
              <Spinner
                animation="border"
                variant="light"
                style={{ width: "15px", height: "15px" }}
              />
            ) : (
              "Proceed to pay"
            )}
          </button>
        </div>

        {/* <div>
          <button onClick={() => handlePlan("Basic")}>Free</button>
          <button onClick={() => handlePlan("Plus")}>Plus</button>
          <button onClick={() => handlePlan("Premium")}>Premium</button>
          <button onClick={() => handlePlan("Elite")}>Elite</button>
        </div> */}
      </div>

      <div>
        <Modal
          className="popup-modal confirmation-modal"
          backdrop="static"
          centered
          show={show}
        >
          <div className="popup-header">
            <div className="logo-icon">
              <img src={images.MoofIcon} />
            </div>
          </div>
          <Modal.Body className="modal-body">
            <div className="title">
              You have selected{" "}
              <span style={{ color: "#FFC000", fontWeight: "600" }}>
                {lifeTypeLabel}
              </span>{" "}
              as the Life Type !{" "}
            </div>
            <div className="subtitle1">
              You will not be able to change the&nbsp;{lifeTypeLabel},&nbsp;once
              the Life is created.
            </div>

            <div className="subtitle2">Do you want to continue with the selected Life Type? ?</div>
          </Modal.Body>

          <div className="btn-set">
            <button
              className="yellow-btn"
              onClick={() => waitingForResponse()}
              style={{ marginTop: "0px" }}
            >
              {loading ? (
                <Spinner
                  animation="border"
                  variant="light"
                  style={{ width: "15px", height: "15px" }}
                />
              ) : (
                "OK"
              )}
            </button>
            <button
              className="yellow-btn"
              onClick={() => setShow(false)}
              disabled={loading}
              style={{ marginTop: "0px" }}
            >
              Cancel
            </button>
          </div>
        </Modal>
      </div>

      {/* Waiting Modal */}
      <div>
        <Modal
          className="popup-modal confirmation-modal"
          backdrop="static"
          centered
          show={waitingModalShow}
        >
          <div className="popup-header">
            <div className="logo-icon">
              <img src={images.MoofIcon} />
            </div>
          </div>

          <Modal.Body className="modal-body p-5">
            {subscriptionPlan === "Free" ? (
              <div className="title">We are creating your new Life !</div>
            ) : (
              <div className="title">
                We are taking to the payment gateway !
              </div>
            )}
            <Spinner
              animation="border"
              size="sm"
              style={{
                width: "20px",
                height: "20px",
                marginLeft: "2px",
                marginTop: "20px",
                color: "#FFC000",
              }}
            />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default SelectPackage;
