import { useState, useEffect } from "react";
import "../../styles/CommentStyle.css";
import "../../styles/CommonStyle.css";
import send from "../../assets/send.webp";
import { getFromSessionStorage } from "../../utils/storageHandler";

const CommentForm = ({
  handleSubmit,
  submitLabel,
  hasCancelButton = false,
  handleCancel,
  placeholder = "Write a Comment...",
  initialText = "",
}) => {
  const [text, setText] = useState(initialText);
  const [errorMessage, setErrorMessage] = useState("");
  const [initials, setInitials] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [themeColor, setThemeColor] = useState("");
  const [userLogged, setUserLogged] = useState(false);
  const isTextareaDisabled = text.length === 0;

  useEffect(() => {
    const userDetails = getFromSessionStorage("userDetails", true);
    userDetails ? setUserLogged(true): setUserLogged(false);
    const themeColor = userDetails ? userDetails.theme_colour : "";
    setThemeColor(themeColor);
    var firstInitial =  userDetails ? userDetails.first_name.charAt(0) : "";
    var lastInitial = userDetails && userDetails.last_name ? userDetails.last_name.charAt(0) : "";
    var initials = firstInitial + lastInitial;
    setInitials(initials);
    const imageUrl = userDetails ? userDetails.image_url : "";
    setImageUrl(imageUrl);
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();
    handleSubmit(text);
    setText("");
  };

  //check appropriate words
  const handleInputChange = (e) => {
    setText(e.target.value);
  };

  const filterWords = () => {
    let filteredText = text;
    setText(filteredText);
  };

  return (
    <div className="comment-footer">
      {userLogged && (
        <form onSubmit={onSubmit}>
        <div className="create-comment-main">
          <div>
              {imageUrl ? (
                <div>
                  <img src={imageUrl} className="user-image"></img>
                  
                </div>
              ) : (
                <div className="user-image-letter" id="name"
                style={{background : themeColor || "#D9D9D9"}}
                >
                  {initials}
                </div>
              )}
          </div>
          
          <textarea
            className="create-comment-input w-100"
            value={text}
            placeholder={placeholder}
            onChange={handleInputChange}
            maxLength="500"
          />

          <button
            className="send-btn"
            disabled={isTextareaDisabled}
            onClick={filterWords}
          >
            <img src={send}></img>
          </button>

          {hasCancelButton && (
            <button className="cancel-btn" type="button" onClick={handleCancel}>
              <i class="bi bi-x-lg"></i>
            </button>
          )}
        </div>
        <div className="error-message">{errorMessage}</div>
      </form>
       )} 
      
    </div>
  );
};

export default CommentForm;
