import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../../styles/SearchStyle.css";
import { useDispatch } from "react-redux";
import SearchLife from "./SearchLife";
import SearchMoment from "./SearchMoment";
import NavigationBar from "../../components/NavigationBar";
import { useLocation } from "react-router-dom";
import LandingRight from "../LandingPageParts/LandingRight";
import AdvanceSearch from "./AdvanceSearch";

const SearchPage = () => {
  const location = useLocation();
  const data = location.state;

  return (
    <div>
      <NavigationBar />
      <div className="search-main">
        <Row>
          <Col>
            <AdvanceSearch />
          </Col>
          <Col sm={6} className="search-main-content">
            <SearchLife searchData={data} />
            <SearchMoment searchData={data} />
          </Col>
          <Col className="search-right-side">
            <LandingRight />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SearchPage;
