import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import "../../styles/CooperatePagesStyle/ContactUsStyle.css";
import "../../styles/CommonStyle.css";
import ReCAPTCHA from "react-google-recaptcha";
import CooperateHeader from "./CooperateHeader";
import CooperateFooter from "./CooperateFooter";
import { images } from "../../utils/image";
import {
  sendContactUsMessage,
  verifyCaptchaToken,
  resetCaptchaDataInStore,
} from "../../store/action/userInteractionsAction";
const siteKey = process.env.REACT_APP_SITE_KEY;
const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const ContactUs = () => {
  const recaptchaRef = useRef(null);
  const dispatch = useDispatch();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [fieldValues, setFieldValues] = useState({
    user: "",
    email: "",
    subject: "",
    message: "",
  });

  //Re-Captcha
  const [captchaValue, setCaptchaValue] = useState("");
  const captchaResponseData = useSelector(
    (state) => state.UserInteractions.captchaResult
  );

  //Form Validation
  const [validationError, setValidationError] = useState(true);
  const [emailPatterError, setEmailPatternError] = useState();

  const onChangeCaptcha = async (value) => {
    setCaptchaValue(value);
    await verifyCaptcha(value);

    // Check if fields are empty
    if (
      fieldValues.email === "" ||
      fieldValues.user === "" ||
      fieldValues.subject === "" ||
      fieldValues.message === "" ||
      !emailPattern.test(fieldValues.email) ||
      !value
    ) {
      setValidationError(true);
    } else {
      setValidationError(false);
    }
  };

  const onChangeFields = (evt) => {
    const { id, value } = evt.target;
    isValid(id);
    setFieldValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
  };

  const isValid = (id) => {
    // Check if email doesn't match the pattern
    if (id === "email" && !emailPattern.test(fieldValues.email)) {
      setEmailPatternError(true);
    } else {
      setEmailPatternError(false);
    }
  };

  const verifyCaptcha = async (tokenValue) => {
    dispatch(verifyCaptchaToken({ token: tokenValue }));
  };

  const submitContactForm = async () => {
    const contactUsData = {
      sender: fieldValues.email,
      name: fieldValues.user,
      subject: fieldValues.subject,
      message: fieldValues.message,
    };

    if (contactUsData && captchaValue && captchaValue !== "") {
      if (captchaResponseData && captchaResponseData.success) {
        dispatch(sendContactUsMessage(contactUsData));
        setFieldValues({
          user: "",
          email: "",
          subject: "",
          message: "",
        });
        dispatch(resetCaptchaDataInStore());
        if (recaptchaRef.current) {
          recaptchaRef.current.reset();
        }
        setIsSubmitted(true);
      } else {
        setErrorMessage(true);
        console.error("Capture validation failed!");
      }
    }
  };

  return (
    <div>
      <CooperateHeader></CooperateHeader>

      <div>
        <div className="contact-header-part">
          <img src={images.ContactUsBg}></img>
          <div className="title-set">
            <div className="title-1">
              Contact <span>MoofLife</span>
            </div>
            <br />
            <div className="title-2">
              Report content | Advertising inquiries | Provide feedback &
              suggestions | Donate
            </div>
          </div>
        </div>
      </div>

      <Container className="contactus-main">
        <div>
          <Card className="contactUs-form">
            <div className="contact-word">Contact</div>
            <p className="title-2">
              Please fill out the form below, and we'll respond as quickly as
              possible. Together, we can make things better.
            </p>
            <div className="form-part">
              <input
                className="input"
                type="text"
                id="user"
                name="user_name"
                placeholder="Name"
                value={fieldValues.user}
                onChange={onChangeFields}
              />
              <input
                className="input"
                type="email"
                id="email"
                name="user_email"
                placeholder="Email"
                value={fieldValues.email}
                onChange={onChangeFields}
              />
              {emailPatterError && (
                <p className="error-message">
                  <span>
                    <i class="bi bi-exclamation-circle"></i>
                  </span>
                  Email is invalid
                </p>
              )}

              <select
                className="select-input"
                id="subject"
                name="user_select"
                value={fieldValues.subject}
                onChange={onChangeFields}
              >
                <option value="">Subject</option>
                <option value="Report Error">Report Error</option>
                <option value="Advertise">Advertise</option>
                <option value="Investor Relations">Investor Relations</option>
                <option value="Feedback Suggestions">
                  Feedback Suggestions
                </option>
                <option value="Other">Other</option>
              </select>

              <textarea
                className="textarea-input"
                id="message"
                name="user_message"
                placeholder="Message"
                maxLength="1000"
                value={fieldValues.message}
                onChange={onChangeFields}
              />
              <ReCAPTCHA
                sitekey={siteKey}
                onChange={onChangeCaptcha}
                ref={recaptchaRef}
                required
              />
              {errorMessage && (
                <p className="error-message">Something went wrong !</p>
              )}

              <button
                type="button"
                className="contactUs-btn blue-btn"
                value="Send"
                onClick={() => submitContactForm()}
                disabled={validationError}
              >
                Send
              </button>

              {isSubmitted && (
                <p className="success-message">
                  Thank you for contacting us. We will be in touch with you soon
                </p>
              )}
            </div>
            <div className="title-4">
              Stay connected with us! For the latest updates, insights, and
              exclusive content. Be part of our online community and join the
              conversation today!
            </div>
            <div class="contact-icon">
              <a href="https://www.facebook.com/MoofLife" target="_blank">
                <img
                  className="social-icons"
                  alt="img"
                  src={images.ContactUsFacebook}
                ></img>
              </a>
              <a href="https://www.linkedin.com/company/mooflife" target="_blank">
                <img
                  className="social-icons"
                  alt="img"
                  src={images.ContactUsLinkedin}
                ></img>
              </a>
              <a href="https://www.youtube.com/@MoofLife" target="_blank">
                <img
                  className="social-icons"
                  alt="img"
                  src={images.ContactUsYoutube}
                ></img>
              </a>
              <a href="https://www.tiktok.com/@mooflife_moments" target="_blank">
                <img
                  className="social-icons"
                  alt="img"
                  src={images.ContactUsTiktok}
                ></img>
              </a>
            </div>
          </Card>
        </div>
      </Container>

      <CooperateFooter></CooperateFooter>
    </div>
  );
};

export default ContactUs;
