
import { createSlice } from "@reduxjs/toolkit";

const SearchSlice = createSlice({
  name: "searchResults",
  initialState: {
    searchData: {
      lives: [],
      moments: [],
    },
    searchLifePage: 2,
    searchLifeLimitReached: false,
    searchMomentPage: 2,
    searchMomentLimitReached: false,
  },
  reducers: {
    getSearchResults: (state, action) => {
      state.searchData = action.payload;
    },
    setSearchMoments: (state, action) => {
      action.payload.map((moment) => {
        if (!state.searchData.moments.some((item) => item._id === moment._id)) {
          state.searchData.moments.push(moment);
        }
      });
    },
    setSearchLives: (state, action) => {
      action.payload.map((live) => {
        if (!state.searchData.lives.some((item) => item._id === live._id)) {
          state.searchData.lives.push(live);
        }
      });
    },
    setSearchLifePage: (state, action) => {
      state.searchLifePage = action.payload;
    },
    setSearchLifeLimitReached: (state, action) => {
      state.searchLifeLimitReached = action.payload;
    },
    setSearchMomentsPage: (state, action) => {
      state.searchMomentPage = action.payload;
    },
    setSearchMomentsLimitReached: (state, action) => {
      state.searchMomentLimitReached = action.payload;
    },
  },
});

export const { getSearchResults, setSearchMoments, setSearchLives, setSearchLifePage, setSearchLifeLimitReached, setSearchMomentsPage, setSearchMomentsLimitReached } = SearchSlice.actions;

export default SearchSlice.reducer;
