import React from "react";
import Modal from "react-bootstrap/Modal";
import "../../src/styles/ConfirmationPopupStyle.css";
import { Link } from "react-router-dom";
import { images } from "../utils/image";
import { useNavigate } from "react-router-dom";

const ConfirmationPopup = ({
  title,
  subtitle1,
  subtitle2,
  confirmationPopupShow,
  setConfirmationPopupShow,
  button1,
  button2,
  deleteExistImage,
  imageIndex,
  noInfoMessage,
  cancel,
  setCancel,
  setPopupMessages,
  handleDeleteMoment,
  momentIndex,
  isMomentDelete,
  setIsMomentDelete
}) => {
  const navigate = useNavigate();
  const handleClose = () => {
    setConfirmationPopupShow(false);
    setCancel(false);
  };

  const handleButton1 = () => {
    if (cancel === true) {
      handleNavigate();
    }
    else if (isMomentDelete === true) {
      handleMomentDelete();
    }
    else {
      handleDelete();
    }
  };

  const handleButton2 = () => {
    if (cancel === true) {
      handleClose();
    }
    else {
      handleModalClose();
    }
  };

  const handleNavigate = () => {
    navigate(-1);
    setCancel(false);
  };

  const handleDelete = () => {
    deleteExistImage(imageIndex);
    handleClose();
    setPopupMessages({
      title: "",
      subtitle1: "",
      subtitle2: "",
      button1: "",
      button2: "",
    });
  };

  const handleMomentDelete = () => {
    handleDeleteMoment(momentIndex);
    handleModalClose();
    setPopupMessages({
      title: "",
      subtitle1: "",
      subtitle2: "",
      button1: "",
      button2: "",
    });
  }

  const handleModalClose = () => {
    setConfirmationPopupShow(false);
    setIsMomentDelete(false);
  }

  return (
    <div>
      <Modal
        className="popup-modal confirmation-modal"
        show={confirmationPopupShow}
        onHide={handleClose}
        backdrop="static"
        centered
      >
        <div className="popup-header">
          <div className="logo-icon">
            <img src={images.MoofIcon} />
          </div>
        </div>

        <Modal.Body className="modal-body">
          <div className="title">{title}</div>
          <div className="subtitle1">{subtitle1}</div>
          <div className="subtitle2">{subtitle2}</div>
        </Modal.Body>

        {!noInfoMessage ? (
          <div className="btn-set">
            <button className="btn1" onClick={handleButton1}>
              {button1}
            </button>
            <button className="btn2" onClick={handleButton2}>
              {button2}
            </button>
          </div>
        ) : (
          <div className="btn-set">
            <Link to="/">
              <button className="btn2" onClick={handleClose}>
                {button2}
              </button>
            </Link>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ConfirmationPopup;
