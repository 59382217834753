import { createSlice } from "@reduxjs/toolkit";

const ImpressionSetSlice = createSlice({
    name: "ImpressionSet",
    initialState: {
        impressionSetApi: []
    },
    reducers: {
        getImpressionSet: (state, action) => {
            state.impressionSetApi = action.payload
        },

    }
})

export const { getImpressionSet } = ImpressionSetSlice.actions;

export default ImpressionSetSlice.reducer;