import { createRequest } from '../../utils/requestHandler';
import { getFromSessionStorage } from '../../utils/storageHandler';
const mooflife_backend = process.env.REACT_APP_BACK_END_URL;

export const getLifeManagers = (data) => {
  return async (dispatch) => {
    try {
      const accessToken = await getFromSessionStorage("accessToken");
      const configs = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await createRequest(
        "post",
        `${mooflife_backend}/life-manager/by-email`,
        data,
        configs
      );

      if (response && response.data.success && response.data.data) {
        return response.data;
      }
      return response.data;
    } catch (error) {
      console.error("Error updating Moment:", error);
      return false;
    }
  };
};

export const addLifeManager = (data) => {
  return async (dispatch) => {
    try {
      const accessToken = await getFromSessionStorage("accessToken");
      const configs = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await createRequest(
        "post",
        `${mooflife_backend}/life-manager`,
        data,
        configs
      );

      if (response && response.data && response.data.data) {
        return response.data;
      }
      return response.data;
    } catch (error) {
      console.error("Error updating Moment:", error);
      return false;
    }
  };
};

export const deleteLifeManager = (data) => {
  return async (dispatch) => {
    try {
      const accessToken = await getFromSessionStorage("accessToken");
      const configs = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await createRequest(
        "delete",
        `${mooflife_backend}/life-manager`,
        data,
        configs
      );

      if (response && response.data.success && response.data.data) {
        return response.data;
      }
      return response.data;
    } catch (error) {
      console.error("Error updating Moment:", error);
      return false;
    }
  };
};

export const enableDissableLifeManager = (data) => {
  return async (dispatch) => {
    try {
      const accessToken = await getFromSessionStorage("accessToken");
      const configs = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await createRequest(
        "post",
        `${mooflife_backend}/life-manager/enable-dissable`,
        data,
        configs
      );

      if (response && response.data.success && response.data.data) {
        return response.data;
      }
      return response.data;
    } catch (error) {
      console.error("Error updating Moment:", error);
      return false;
    }
  };
};

export const addLifeToLifeManager = (data) => {
  return async (dispatch) => {
    try {
      const accessToken = await getFromSessionStorage("accessToken");
      const configs = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await createRequest(
        "post",
        `${mooflife_backend}/life-manager/add-life`,
        data,
        configs
      );

      if (response && response.data.success && response.data.data) {
        return response.data;
      }
      return response.data;
    } catch (error) {
      console.error("Error updating Moment:", error);
      return false;
    }
  };
};

export const removeLifeFromLifeManager = (data) => {
  return async (dispatch) => {
    try {
      const accessToken = await getFromSessionStorage("accessToken");
      const configs = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await createRequest(
        "post",
        `${mooflife_backend}/life-manager/remove-life`,
        data,
        configs
      );

      if (response && response.data.success && response.data.data) {
        return response.data;
      }
      return response.data;
    } catch (error) {
      console.error("Error updating Moment:", error);
      return false;
    }
  };
};

export const updateAcknowledge = (data) => {
  return async (dispatch) => {
    try {
      const accessToken = await getFromSessionStorage("accessToken");
      const configs = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await createRequest(
        "post",
        `${mooflife_backend}/life-manager/update-acknowledge`,
        data,
        configs
      );

      if (response && response.data.success && response.data.data) {
        return response.data;
      }
      return response.data;
    } catch (error) {
      console.error("Error updating Moment:", error);
      return false;
    }
  };
};

export const getLifeManagerPendingReq = (data) => {
  return async (dispatch) => {
    try {
      const accessToken = await getFromSessionStorage("accessToken");
      const configs = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await createRequest(
        "post",
        `${mooflife_backend}/life-manager/life-manager-request`,
        data,
        configs
      );

      if (response && response.data.success && response.data.data) {
        return response.data;
      }
      return response.data;
    } catch (error) {
      console.error("Error updating Moment:", error);
      return false;
    }
  };
};

export const getLifeByAccountManagerEmail = (data) => {
  return async (dispatch) => {
    try {
      const accessToken = await getFromSessionStorage("accessToken");
      const configs = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await createRequest(
        "post",
        `${mooflife_backend}/life/get-by-account-manager`,
        data,
        configs
      );

      if (response && response.data.success && response.data.data) {
        return response.data;
      }
      return response.data;
    } catch (error) {
      console.error("Error updating Moment:", error);
      return false;
    }
  };
};
