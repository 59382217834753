import { configureStore } from '@reduxjs/toolkit';
import usersReducer from './slices/sample-slice';
import publicMomentsReducer from './slices/MomentSlice';
import MostViewedMomentsReducer from './slices/MostViewedMomentsSlice';
import MyMomentsReducer from './slices/MyMomentsTileSlice';
import FamousBdaysReducer from './slices/FamousBdaysSlice';
import TopMomentsReducer from './slices/TopMomentsSlice';
import UserInteractionsReducer from './slices/userInteractionsSlice';
import Life from './slices/LifeSlice';
import searchResults from './slices/SearchSlice';
import CommentsSlice from './slices/CommentsSlice';
import ImpressionSetReducer from './slices/ImpressionSetSlice';
import GeneralReducer from './slices/GeneralSlice';
import FollowerReducer from './slices/FollowerSlice';

const store = configureStore({
  reducer: {
    users: usersReducer,
    Moments: publicMomentsReducer,
    MostViewedMoments: MostViewedMomentsReducer,
    FamousBdays: FamousBdaysReducer,
    TopMoments: TopMomentsReducer,
    UserInteractions: UserInteractionsReducer,
    Life: Life,
    searchResults:searchResults,
    Comments:CommentsSlice,
    ImpressionSet : ImpressionSetReducer,
    General: GeneralReducer,
    Follower: FollowerReducer,
    MyMoments: MyMomentsReducer,
  },
});

export default store;