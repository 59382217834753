import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import CommentForm from "./CommentForm";
import Dropdown from "react-bootstrap/Dropdown";
import "../../styles/CommentStyle.css";
import "../../styles/CommonStyle.css";
import menu from "../../assets/dots.webp";
import { images } from "../../utils/image";
import { addNewEmoji } from "../../store/action/CommentAction";

const Comment = ({
  comment,
  replies,
  setActiveComment,
  activeComment,
  updateComment,
  deleteComment,
  hasCancelButton = false,
  handleCancel,
  addComment,
  parentId = null,
  currentUserId,
}) => {
  const isEditing =
    activeComment &&
    activeComment.id === comment.id &&
    activeComment.type === "editing";
  const isReplying =
    activeComment &&
    activeComment.id === comment.id &&
    activeComment.type === "replying";

  const dispatch = useDispatch();
  let lastComment = false;

  const [showReply, setShowReply] = useState(false);
  const [editedText, setEditedText] = useState(comment.body);
  const [initials, setInitials] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [themeColor, setThemeColor] = useState("");
  const [errorEditMessage, setErrorEditMessage] = useState("");
  const [selectEmoji, setSelectEmoji] = useState([]);
  const [impressionsNumber, setImpressionsNumber] = useState(0);

  const [currentComment, setCurrentComment] = useState("");
  const [editing, setEditing] = useState(false);
  const [replying, setReplying] = useState(false);

  const likeCount = comment.like_count ? comment.like_count : 0;
  const dislikeCount = comment.dislike_count ? comment.dislike_count : 0;

  if (comment.lastLevel && comment.lastLevel === true) {
    lastComment = true;
  }

  useEffect(() => {
    const themeColor = comment.user.theme_colour;
    setThemeColor(themeColor);
    var firstInitial = comment.user.first_name.charAt(0);
    var lastInitial = comment.user.last_name ? comment.user.last_name.charAt(0) : "";
    var initials = firstInitial + lastInitial;
    setInitials(initials);
    const imageUrl = comment.user && comment.user.image_url;
    setImageUrl(imageUrl);
    handleImpressions();
  }, []);

  useEffect(() => {
    handleImpressions();
  }, [comment]);

  const handleImpressions = () => {
    setImpressionsNumber(likeCount + dislikeCount);
    const emojis = [];
    if (likeCount > 0) {
      emojis.push(images.like);
    }

    if (dislikeCount > 0) {
      emojis.push(images.dislike);
    }

    setSelectEmoji(emojis);
  };

  const canDelete = currentUserId === comment.user._id;

  const canReply = Boolean(currentUserId);
  const canLike = Boolean(currentUserId);
  const canEdit = currentUserId === comment.user._id;

  const createdAt = moment(comment.date).fromNow();

  const handleInputChange = (e) => {
    setEditedText(e.target.value);
  };

  const handleEditClick = (commentId) => {
    setEditedText(comment.content);
    setCurrentComment(commentId);
    setEditing(true);
    setActiveComment({ id: comment.id, type: "editing" });
  };

  const handleSaveEdit = () => {
    let filteredText = editedText;
    // let hasBadWord = false;

    // Object.entries(bannedWordDictionary).forEach(([badWord, goodWord]) => {
    //   const regex = new RegExp(badWord, "gi");
    //   if (filteredText.match(regex)) {
    //     filteredText = filteredText.replace(regex, goodWord);
    //     hasBadWord = true;
    //   }
    // });

    // if (hasBadWord) {
    //   setErrorEditMessage(
    //     "Our Safe Community Algorithm (SCA) has screened and updated Your comment."
    //   );
    // } else {
    //   setErrorEditMessage("");
    // }

    // setTimeout(() => {
    //   setErrorEditMessage("");
    // }, 5000);

    setEditedText(filteredText);

    updateComment(filteredText, comment._id);
    setActiveComment(null);
    setEditing(false);
  };

  const handleShowReply = (commentId) => {
    setShowReply(!showReply);
  };

  // !selectEmoji.includes(emoji) - use to check the equal emojis

  const handleEmojiClick = (emoji, type) => {
    if (emoji) {
      const emojiData = {
        type: type,
        moment_id: comment.moment_id,
        user: currentUserId,
        comment_id: comment._id,
      };

      dispatch(addNewEmoji(emojiData));
    }
  };

  const cancelEdit = () => {
    setEditing(false);
    setActiveComment(null);
  };

  const handleReplyClick = (commentId) => {
    setCurrentComment(commentId);
    setReplying(true);
  };

  const handleReplySubmit = (content, parentId) => {
    addComment(content, parentId);
    setReplying(false);
    setShowReply(true);
  };

  const cancelReply = (content, parentId) => {
    setReplying(false);
    setActiveComment(null);
  };

  return (
    <div key={comment._id} className="comment-main">
      {imageUrl ? (
        <div>
          <img src={imageUrl} className="user-image"></img>
        </div>
      ) : (
        <div
          className="user-image-letter"
          id="name"
          style={{ background: themeColor || "#D9D9D9" }}
        >
          {initials}
        </div>
      )}

      <div>
        <div className="info-like" style={{ display: "flex" }}>
          <div className="info">
            <div className="name">
              {comment.user.first_name} {comment.user.last_name?comment.user.last_name: ""}
            </div>
            {editing && comment._id === currentComment ? (
              <>
                <div className="comment-content">
                  <textarea
                    className="edit-textarea"
                    type="text"
                    value={editedText}
                    onChange={handleInputChange}
                    maxLength="500"
                  />
                </div>

                <div style={{ display: "flex" }}>
                  <button
                    onClick={handleSaveEdit}
                    className="btn-save yellow-btn"
                  >
                    Save
                  </button>
                  <button
                    onClick={cancelEdit}
                    className="btn-save yellow-btn"
                    style={{ marginLeft: "5px" }}
                  >
                    Cancel
                  </button>
                </div>
              </>
            ) : (
              <div className="comment-content">{comment.content}</div>
            )}
          </div>
          <div className="selected-emoji-number">
            {selectEmoji.map((emoji, index) => (
              <img
                key={index}
                className="selected-emoji"
                src={emoji}
                style={{
                  width: "20px",
                  marginLeft: "-10px",
                }}
              />
            ))}
            {selectEmoji.length > 0 && (
              <p className="impression-number" style={{ marginRight: "15px" }}>
                {impressionsNumber}
              </p>
            )}
          </div>
          <div>
            {canEdit && (
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  <img
                    src={menu}
                    alt="image"
                    className="menu-icon"
                    id="dropdown-basic-button"
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>
                    {canEdit && (
                      <div
                        className="comment-action"
                        onClick={() => handleEditClick(comment._id)}
                      >
                        Edit
                      </div>
                    )}
                  </Dropdown.Item>
                  <Dropdown.Item>
                    {canDelete && (
                      <div
                        className="comment-action"
                        onClick={() => deleteComment(comment._id)}
                      >
                        Delete
                      </div>
                    )}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </div>
        <div className="error-message">{errorEditMessage}</div>
        <div className="actions">
          {canLike && !isEditing && (
            <div>
              <div className="icons">
                {/* <p>{user.NoofImpressions}</p> */}
                <div class="feed-comment">
                  <a class="like-btn-comment">
                    {selectEmoji && <div>Like</div>}
                    <div class="reaction-box-comment">
                      <div
                        class="reaction-icon-comment"
                        onClick={() => handleEmojiClick(images.like, "like")}
                      >
                        <img src={images.like} alt="Like Emoji" />
                      </div>
                      <div
                        class="reaction-icon-comment"
                        onClick={() =>
                          handleEmojiClick(images.dislike, "dislike")
                        }
                      >
                        <img src={images.dislike}></img>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          )}

          {canReply && !lastComment && !isEditing && (
            <div
              className="comment-action"
              onClick={() => handleReplyClick(comment._id)}
            >
              Reply
            </div>
          )}
          <div>{createdAt}</div>
        </div>
        <>
          {replying && currentComment === comment._id && (
            <CommentForm
              hasCancelButton
              handleCancel={() => cancelReply()}
              placeholder="Reply"
              submitLabel="Reply"
              handleSubmit={(text) => handleReplySubmit(text, parentId)}
            />
          )}
        </>
        {/* Dropdown icon to toggle reply comments */}
        {replies.length > 0 && (
          <div
            className="collapse-reply"
            onClick={() => handleShowReply(comment._id)}
          >
            {showReply || currentComment === comment._id ? (
              <i className="bi bi-caret-up-fill"></i>
            ) : (
              <div className="more-reply">
                View more replies -{" "}
                <span>
                  {" "}
                  {replies.length} {replies.length > 1 ? "replies" : "reply"}
                </span>
              </div>
            )}
          </div>
        )}

        {showReply && replies.length > 0 && (
          <div className="replies">
            {replies.map((reply) => (
              <Comment
                comment={reply}
                key={reply._id}
                setActiveComment={setActiveComment}
                activeComment={activeComment}
                updateComment={updateComment}
                deleteComment={deleteComment}
                addComment={addComment}
                parentId={reply._id}
                replies={reply.replies ? reply.replies : []}
                currentUserId={currentUserId}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Comment;
