import { createSlice } from "@reduxjs/toolkit";

const MyMomentsTileSlice = createSlice({
    name: "MyMoments",
    initialState: {
        MyMoments: [],
        Page: 1,
        limitReached: false,
    },
    reducers: {
        getMyMoments: (state, action) => {
            state.MyMoments = action.payload;
        },

        setMyMoments: (state, action) => {
            action.payload.map(myMoments => {
                if (!state.MyMoments.some(item => item._id === myMoments._id)) {
                    state.MyMoments.push(myMoments);
                }
            })
        },

        setMyMomentPage: (state, action) => {
            state.Page = action.payload
        },

        setMyMomentLimitReached: (state, action) => {
            state.limitReached = action.payload
        },

    }
})

export const { getMyMoments, setMyMoments, setMyMomentPage, setMyMomentLimitReached } = MyMomentsTileSlice.actions;

export default MyMomentsTileSlice.reducer;