import React from 'react';

import { Helmet } from 'react-helmet-async';

export const Seo = ({ title, description, type, name, image, url, author, NormalDescription, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={NormalDescription} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={url} />
      
      <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={ image} />

      <link rel="canonical" href={url} />
      <meta name="author" content={author}  />

      <meta name="keywords" content={keywords}/>
      <meta name="google-adsense-account" content="ca-pub-9623894499006251"/>
    </Helmet>
  );
};