import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../styles/LifeRightStyle.css";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../../components/Footer";
import { images } from "../../utils/image";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { getMostViewMoments } from "../../store/action/MostViewedMomentsAction";
import { Helmet } from 'react-helmet-async';

const LifeRight = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;

  const [mostViewedMomentsList, setMostViewedMomentsList] = useState([]);
  const [momentsLoadCompleted, setMomentsLoadCompleted] = useState(false);

  const Life = useSelector((state) => state.Life.Life);
  const MostViewedMoments = useSelector(
    (state) => state.MostViewedMoments.MostViewedMoments
  );

  const rightSideTopAdRedirectUrl = Life && Life.ad_top_right_redirect_url ? Life.ad_top_right_redirect_url : "#";
  const rightSideBottomAdRedirectUrl = Life && Life.ad_bottom_right_redirect_url ? Life.ad_bottom_right_redirect_url : "#";

  useEffect(() => {
    dispatch(getMostViewMoments());
  }, []);

  useEffect(() => {
    const isDisplayAll = state ? state.fullyMostViewed : "false";
    if (MostViewedMoments.length > 0) {
      if (!isDisplayAll || isDisplayAll === "false") {
        if (MostViewedMoments.length >= 2) {
          setMostViewedMomentsList([
            MostViewedMoments[0],
            MostViewedMoments[1],
          ]);
        } else if (MostViewedMoments.length === 1) {
          setMostViewedMomentsList([MostViewedMoments[0]]);
        }
      } else {
        setMostViewedMomentsList(MostViewedMoments);
        setMomentsLoadCompleted(true);
      }
    }
  }, [MostViewedMoments]);

  const handleMostViewedMoments = (type) => {
    if (type === "more") {
      let remainingMoments = [...mostViewedMomentsList];
      if (mostViewedMomentsList && MostViewedMoments) {
        MostViewedMoments.forEach((item) => {
          if (!mostViewedMomentsList.includes(item)) {
            remainingMoments.push(item);
          }
        });
      }
      setMostViewedMomentsList(remainingMoments);
      setMomentsLoadCompleted(true);
    } else {
      if (MostViewedMoments.length > 0) {
        if (MostViewedMoments.length >= 2) {
          setMostViewedMomentsList([
            MostViewedMoments[0],
            MostViewedMoments[1],
          ]);
        } else if (MostViewedMoments.length === 1) {
          setMostViewedMomentsList([MostViewedMoments[0]]);
        }
      }
      setMomentsLoadCompleted(false);
    }
  };

  const navigateMoment = (lifeId, momentId) => {
    if (lifeId && momentId) {
      const urlState = { fullyMostViewed: momentsLoadCompleted };
      navigate(`/${lifeId}/${momentId}`, { state: urlState });
    }
  };

  return (
    <div>
      <div className="life-right-full">
      <Helmet>
        <script async src="
https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9623894499006251"
          crossorigin="anonymous"></script>
      </Helmet>
        <div>
          <div>
          {Life.ad_space_top_right ? (
            <div>
              <Link to={rightSideTopAdRedirectUrl} target="_blank" rel="Advertiesement link">
                <img className="add-image" src={Life.ad_space_top_right}></img>
              </Link>
            </div>
          ) : (
            <div className="img-part">
              <Link to="/contact-us" target="_blank" rel="Advertiesement link">
                <img src={images.googleAdd1}></img>
              </Link>
            </div>
          )}
          <div className="ad-word">Ad</div>
          </div>
          

          <div>
            <div className="most-viewed">
              <Link to="/most-viewed-grid"><div className="title">Most viewed Moments of the day</div></Link>
              {mostViewedMomentsList.length > 0 ? (
                <>
                  {mostViewedMomentsList.map((mostview, index) => (
                    <Card className="card" key={index}>
                      <Card.Body>
                        <Row>
                          <Col className="view-logo" sm={1}>
                            <img
                              src={mostview.life.life_logo ? mostview.life.life_logo : ""}
                            ></img>
                          </Col>
                          <Col className="view-title">
                            <h3
                              onClick={() =>
                                navigateMoment(mostview.life.verified_name?mostview.life.verified_name:mostview.life.life_id, mostview._id)
                              }
                            >
                              {mostview.moment_title
                                ? mostview.moment_title.length > 55
                                  ? mostview.moment_title.slice(0, 55) + "..."
                                  : mostview.moment_title
                                : ""}
                            </h3>
                            <h4>
                              {mostview.moment_date ? mostview.moment_date : ""}
                            </h4>
                          </Col>
                        </Row>
                        <Card.Text>
                          <div class="view-content">
                            <p>
                              {mostview.moment_details
                                ? mostview.moment_details.length > 150
                                  ? mostview.moment_details.slice(0, 150) +
                                    "..."
                                  : mostview.moment_details
                                : ""}
                            </p>
                          </div>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  ))}
                </>
              ) : (
                <>
                  <div className="title sub-title">Loading...</div>
                </>
              )}

              {!momentsLoadCompleted && MostViewedMoments.length > 2 ? (
                <div
                  className="title sub-title"
                  onClick={() => handleMostViewedMoments("more")}
                >
                  See more
                </div>
              ) : (
                momentsLoadCompleted &&
                MostViewedMoments.length > 2 && (
                  <div
                    className="title sub-title"
                    onClick={() => handleMostViewedMoments("less")}
                  >
                    See less
                  </div>
                )
              )}

              {MostViewedMoments.length <= 2 && (
                <div
                  className="title sub-title"
                  onClick={() => handleMostViewedMoments("more")}
                ></div>
              )}
            </div>
          </div>

          <div>
            {Life.ad_space_bottom_right ? (
              <div>
                <Link to={rightSideBottomAdRedirectUrl} target="_blank" rel="Advertiesement link">
                  <img className="add-image" src={Life.ad_space_bottom_right}></img>
                </Link>
              </div>  
            ) : (
              <div className="img-part">
                <Link to="/contact-us" target="_blank" rel="Advertiesement link">
                  <img src={images.googleAdd2}></img>
                </Link>
              </div>
            )}
            <div className="ad-word">Ad</div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default LifeRight;
