import React, { useEffect, useState } from "react";
import "../../styles/LandingPage/NewMomentsStyle.css";
import Slider from "react-slick";
import { images } from "../../utils/image";
import ad3 from "../../assets/LandingPage/ad3.png";
import { useDispatch, useSelector } from "react-redux";
import { fetchNewMoments } from "../../store/action/MomentAction";
import { Link } from "react-router-dom";

const NewMoments = () => {
  const dispatch = useDispatch();
  const NewMoments = useSelector((state) => state.Moments.newMoments);

  useEffect(() => {
    dispatch(fetchNewMoments());
  }, [dispatch]);

  const [activeSlide, setActiveSlide] = useState(0);
  const isInfinite = NewMoments.length < 2;
  const settings = {
    dots: false,
    infinite: isInfinite ? false : true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
    waitForAnimate: false,
    beforeChange: (current, next) => setActiveSlide(next),
  };


  // Filter slides that have images
  const validSlides = NewMoments
    .map((moment, index) => ({ ...moment, index })) // Attach original index
    .filter((moment) => moment.images?.length > 0 && moment.images[0]?.media_type === "image");

  const totalSlides = validSlides.length;

  // Handle slide change
  const handleSlideChange = (index) => {
    setActiveSlide(index);
    sliderRef.slickGoTo(index); 
  };

  let sliderRef;

  return (
    <div className="new-moment-main">
      <div className="image-add">
        <div className="image-part">
          <div className="title">
            <div className="border">
              New <span>Moments</span>
            </div>
          </div>

          <Slider ref={(slider) => (sliderRef = slider)} {...settings}>
            {NewMoments.map((newMoment, index) =>
              newMoment.images?.length > 0 &&
              newMoment.images[0]?.media_type === "image" ? (
                <div className="new-moment-card" key={index}>
                  <img
                    src={newMoment.images[0]?.url}
                    alt={newMoment.moment_title}
                  />
                  <div className="details-set">
                    <Link
                      to={`/${
                        newMoment.life.verified_name
                          ? newMoment.life.verified_name
                          : newMoment.life.life_id
                      }`}
                    >
                      <div className="life-name">
                        {newMoment.life.life_name.length > 30
                          ? newMoment.life.life_name.slice(0, 30) + "..."
                          : newMoment.life.life_name}{" "}
                        : {newMoment.moment_date}
                      </div>
                    </Link>
                    <Link
                      to={`/${
                        newMoment?.life?.verified_name ||
                        newMoment?.life?.life_id
                      }/${newMoment._id}`}
                      target="_blank"
                    >
                      <div className="moment-title">
                        {newMoment.moment_title.length > 100
                          ? newMoment.moment_title.slice(0, 100) + "..."
                          : newMoment.moment_title}
                      </div>
                    </Link>
                    <div className="moment-desc truncated">
                      {newMoment.moment_details.length > 200
                        ? newMoment.moment_details.slice(0, 200) + "..."
                        : newMoment.moment_details}
                    </div>
                  </div>
                </div>
              ) : null
            )}
          </Slider>
          <div className="pagination">
            {Array.from({ length: Math.min(5, totalSlides) }, (_, i) => {
              const pageNumber = (activeSlide + i) % totalSlides; 
              return (
                <div
                  className={pageNumber === activeSlide ? "active" : "dot"}
                  key={pageNumber}
                  onClick={() => handleSlideChange(pageNumber)}
                >
                  {pageNumber + 1} 
                </div>
              );
            })}
          </div>
        </div>
        <div className="google-ad">
          <img src={images.ad3} alt="Advertisement" />
        </div>
      </div>
    </div>
  );
};

export default NewMoments;
