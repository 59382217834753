import React from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import '../../src/styles/FooterStyle.css'

const Footer = () => {
  return (
      <div className="footer-part">
        <Card className="text-center card">
          <Card.Body className="footer-content">
            <Row className="row">
              <Col className="col">
                <Link to="/about-us">
                  <h4>About Us</h4>
                </Link>
              </Col>
              <Col className="col">
                <Link to="/mooflife-concept">
                  <h4>Mooflife concept</h4>
                </Link>
              </Col>
              <Col className="col">
                <Link to="/contact-us">
                  <h4>Contact Us</h4>
                </Link>
              </Col>
            </Row>
            <Col className="col">
              <Link to="/privacy-policy">
                <h4>Mooflife Privacy Policy</h4>
              </Link>
            </Col>
          </Card.Body>
        </Card>
      </div>

  );
};

export default Footer;
