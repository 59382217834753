import { createRequest } from '../../utils/requestHandler';
import { getFromSessionStorage } from '../../utils/storageHandler';
const mooflife_backend = process.env.REACT_APP_BACK_END_URL;

export const reportMoment = (data) => {
    
    return async (dispatch) => {
      try { 
        const accessToken = await getFromSessionStorage("accessToken");
        const configs = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
              },
        }
  
        const response = await createRequest('post', `${mooflife_backend}/report/moment`, data, configs)
       
        if (response && response.data && response.data.success) {
          
          return response.data;
        } else {
          return false;
        }
        
      } catch (error) {
        return error;
      }
    };
  };

  export const reportLife = (data) => {
  
    return async (dispatch) => {
      try { 
        const accessToken = await getFromSessionStorage("accessToken");
        const configs = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
              },
        }
  
        const response = await createRequest('post', `${mooflife_backend}/report/life`, data, configs)
        if (response && response.data && response.data.success) {
          
          return response.data;
        } else {
          return false;
        }
        
      } catch (error) {
        return error;
      }
    };
  };