import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import "../styles/ReportLifeMomentStyle.css";
import { reportLife, reportMoment } from "../store/action/reportAction";
import { getFromSessionStorage } from "../utils/storageHandler";
import { useDispatch } from "react-redux";

function ReportLifeMoment({ show, setShow, title, subtitle, lifeMomentId }) {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    incorrectInformationChecked: false,
    inappropriateChecked: false,
    hateSpeechChecked: false,
    otherChecked: false,
    details: "",
  });
  const [reason, setReason] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  //text area max length
  const maxLength = 1000;

  const isButtonDisabled =
    !formData.incorrectInformationChecked &&
    !formData.inappropriateChecked &&
    !formData.hateSpeechChecked &&
    !formData.otherChecked;

  const closeModal = () => {
    setShow(false);
    setStep(1);
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const alreadyReportedModal = () => {
    setStep(step + 2);
  };

  const exceedDailyreportLimitModal = () => {
    setStep(step + 3);
  };

  const handleInputChange = (event) => {
    const { name, type, checked, value } = event.target;
    // If the clicked checkbox is checked, uncheck all other checkboxes
    const updatedFormData = { ...formData };
    if (type === "checkbox" && checked) {
      Object.keys(updatedFormData).forEach((key) => {
        if (key !== name && updatedFormData[key]) {
          if (key === "details" && name !== "otherChecked") {
            updatedFormData[key] = "";
          } else if (key !== "details") {
            updatedFormData[key] = false;
          }
        }
      });
    }

    // Update the state with the new checkbox state
    updatedFormData[name] = type === "checkbox" ? checked : event.target.value;
    setFormData(updatedFormData);

    //update reporting reason

    switch (name) {
      case "incorrectInformationChecked":
        setReason("incorrect information");
        break;
      case "inappropriateChecked":
        setReason("Inappropriate or harmful content");
        break;
      case "hateSpeechChecked":
        setReason("Hate Speech or Provoking violance");
        break;
      case "details":
        setReason(value);
        break;
    }
  };

  const clearFields = () => {
    const updatedFormData = { ...formData };
    Object.keys(updatedFormData).forEach((key) => {
      if (key === "details") {
        updatedFormData[key] = "";
      } else {
        updatedFormData[key] = false;
      }
    });
    setFormData(updatedFormData);
  };

  const handleSubmit = async () => {
    const userDetails = getFromSessionStorage("userDetails", true);

    // required the details field
    if (formData.otherChecked && formData.details === "") {
      setErrorMessage("Please include specific details in the text box.");
      return;
    }

    clearFields();

    if (title === "Report Life") {
      dispatch(
        await reportLife({
          reason: "wrong details",
          reporter_id: userDetails.id,
          life_id: lifeMomentId,
        })
      )
        .then((res) => {
          if (res.success) {
            nextStep();
          } else {
            if (res?.response?.status) {
              switch (res.response.status) {
                case 400:
                  alreadyReportedModal();
                  break;
                case 429:
                  exceedDailyreportLimitModal();
                  break;
                default:
                  console.log("internal server error");
              }
            }
          }
        })
        .catch((error) => {
          console.error("Error in reporting life:", error);
        });
    } else {
      dispatch(
        await reportMoment({
          reason: reason,
          reporter_id: userDetails.id,
          moment_id: lifeMomentId,
        })
      )
        .then((res) => {
          if (res.success) {
            nextStep();
          } else {
            if (res?.response?.status) {
              switch (res.response.status) {
                case 400:
                  alreadyReportedModal();
                  break;
                case 429:
                  exceedDailyreportLimitModal();
                  break;
                default:
                  console.log("internal server error");
              }
            }
          }
        })
        .catch((error) => {
          console.error("Error in reporting moment:", error);
        });
    }
  };

  return (
    <div>
      {step === 1 && (
        <Modal
          show={show}
          backdrop="static"
          keyboard={false}
          className="report-modal-main"
        >
          <Modal.Header className="report-modal-header">
            <div className="report-modal-title">{title}</div>
            <div onClick={closeModal}>
              <i class="bi bi-x-circle-fill" style={{ color: "#D9D9D9" }}></i>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="title-1">{subtitle}</div>
            <Row>
              <div style={{ display: "flex" }} className="lines">
                <Col>Incorrect Information</Col>
                <Col
                  sm={2}
                  className="d-flex align-items-center justify-content-end"
                >
                  <Form.Check
                    className="yellow-checkbox"
                    type="checkbox"
                    name="incorrectInformationChecked"
                    onChange={handleInputChange}
                    checked={formData.incorrectInformationChecked}
                  />
                </Col>
              </div>

              <div style={{ display: "flex" }} className="lines">
                <Col>Inappropriate or harmful content</Col>
                <Col
                  sm={2}
                  className="d-flex align-items-center justify-content-end"
                >
                  <Form.Check
                    className="yellow-checkbox"
                    type="checkbox"
                    name="inappropriateChecked"
                    onChange={handleInputChange}
                    checked={formData.inappropriateChecked}
                  />
                </Col>
              </div>

              <div style={{ display: "flex" }} className="lines">
                <Col>Hate Speech or Provoking violance</Col>
                <Col
                  sm={2}
                  className="d-flex align-items-center justify-content-end"
                >
                  <Form.Check
                    className="yellow-checkbox"
                    type="checkbox"
                    name="hateSpeechChecked"
                    onChange={handleInputChange}
                    checked={formData.hateSpeechChecked}
                  />
                </Col>
              </div>

              <div style={{ display: "flex" }} className="lines">
                <Col>Other</Col>
                <Col
                  sm={2}
                  className="d-flex align-items-center justify-content-end"
                >
                  <Form.Check
                    className="yellow-checkbox"
                    type="checkbox"
                    name="otherChecked"
                    onChange={handleInputChange}
                    checked={formData.otherChecked}
                  />
                </Col>
              </div>

              <Form.Group
                className="mb-0"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Control
                  className="text-area"
                  name="details"
                  value={formData.details}
                  as="textarea"
                  maxLength={maxLength}
                  placeholder="Please provide details"
                  onChange={handleInputChange}
                />

                <div className="error-message">{errorMessage}</div>
                <div
                  style={{
                    marginTop: "0.25rem",
                    textAlign: "right",
                    fontWeight: "500",
                    color: "#C0C0C0",
                    fontSize: "13px",
                  }}
                  id="counter"
                >
                  {formData.details.length}/{maxLength}
                </div>
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="report-btn yellow-btn"
              onClick={handleSubmit}
              disabled={isButtonDisabled}
            >
              {title}
            </button>
          </Modal.Footer>
        </Modal>
      )}

      {step === 2 && (
        <Modal
          show={show}
          backdrop="static"
          keyboard={false}
          className="report-modal-main"
        >
          <Modal.Header className="report-modal-header">
            <div className="report-modal-title">Report Received</div>
            <div onClick={closeModal}>
              <i class="bi bi-x-circle-fill" style={{ color: "#D9D9D9" }}></i>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="para-1">
              Thank you for bringing this to our attention. Our team is
              committed to ensuring accuracy of the information and safe &
              respectful environment for all users. Your report will be
              thoroughly reviewed, and we will take appropriate action based on
              our community guidelines and policies.
              <br></br>
              <br></br>
              While we may not provide individual updates on the status of your
              report, please know that every report matters to us. If further
              details or clarifications are required, we'll reach out to you via
              email.
              <br></br>
              <br></br>
              Your vigilance helps us make MoofLife a better place for everyone.
              Thank you for your cooperation
            </div>
          </Modal.Body>

          <button className="main-btn" onClick={closeModal}>
            Done
          </button>
        </Modal>
      )}

      {step === 3 && (
        <Modal
          show={show}
          backdrop="static"
          keyboard={false}
          className="report-modal-main"
        >
          <Modal.Header className="report-modal-header">
          <div className="report-modal-title"></div>
            <div onClick={closeModal}>
              <i class="bi bi-x-circle-fill" style={{ color: "#D9D9D9" }}></i>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="para-2">
              {title === "Report Life" ? (
                <>
                  You've already reported this life. Rest assured, we're on it
                  and are currently reviewing your previous report. Thanks for
                  helping us maintain a safe community!
                </>
              ) : (
                <>
                  It looks like you've already reported this moment. We take
                  each report seriously and are looking into it. Thank you for
                  your vigilance!
                </>
              )}
            </div>
          </Modal.Body>
          <button className="main-btn close-btn" onClick={closeModal}>
            Close
          </button>
        </Modal>
      )}

      {step === 4 && (
        <Modal
          show={show}
          backdrop="static"
          keyboard={false}
          className="report-modal-main"
        >
          <Modal.Header className="report-modal-header">
          <div className="report-modal-title"></div>
            <div onClick={closeModal}>
              <i class="bi bi-x-circle-fill" style={{ color: "#D9D9D9" }}></i>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="para-2">
              Thank you for helping to keep MoofLife safe! We're currently
              reviewing your recent reports. To ensure a thorough examination,
              there's a brief waiting period before you can submit additional
              reports. We appreciate your understanding and patience. If you
              have any questions or need further assistance, please don't
              hesitate to reach out to us through the 'Contact Us' section.
            </div>
          </Modal.Body>
          <button className="main-btn close-btn" onClick={closeModal}>
            Close
          </button>
        </Modal>
      )}
    </div>
  );
}

export default ReportLifeMoment;
