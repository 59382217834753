import { createSlice } from "@reduxjs/toolkit";

const momentSlice = createSlice({
    name: "Moments",
    initialState: {
        Moments: [],
        newMoments: [],
        Page: 1,
        limitReached: false,
        momentsLoading: false,
        momentSearchValues: {},
        momentSorting: -1
    },
    reducers: {
        getMoments: (state, action) => {
            state.Moments = action.payload.map(moment => {
                return {
                    moment_id: moment.moment_id,
                    moment_date: moment.moment_date,
                    moment_sorting_date: moment.moment_sorting_date,
                    moment_title: moment.moment_title,
                    moment_details: moment.moment_details,
                    moment_type: moment.moment_type,
                    external_link: moment.external_link,
                    external_link_title: moment.external_link_title,
                    hashtags: moment.hashtags,
                    images: moment.images,
                    moment_privacy: moment.moment_privacy,
                    restricted: moment.restricted,
                    era: moment.era,
                    number_of_comments: moment.number_of_comments,
                    number_of_impressions: moment.number_of_impressions,
                    top_moment: moment.top_moment,
                    world_moment: moment.world_moment,
                    national_moment: moment.national_moment,
                    sustainability_moment: moment.sustainability_moment,
                    shareable: moment.shareable,
                    commentable: moment.commentable,
                    impressionable: moment.impressionable,
                    life: moment.life,
                    
                }
            })
        },

        setMoments: (state, action) => {
            action.payload.map(moment => {
                if (!state.Moments.some(item => item._id === moment._id)) {
                    state.Moments.push(moment);
                  }
            })
        },

        setPage: (state, action) => {
            state.Page = action.payload
        },

        setLimitReached: (state, action) => {
            state.limitReached = action.payload
        },

        setMomentsLoading: (state, action) => {
            state.momentsLoading = action.payload
        },

        updateCommentsCount: (state, action) => {
            const updatedMoments = state.Moments.map(moment => {
                if (moment._id === action.payload.momentId) {
                    return {
                        ...moment,
                        number_of_comments: action.payload.count
                    };
                    
                } else {
                    return moment;
                }
            });
        
            return {
                ...state,
                Moments: updatedMoments
            };
        },
        updateImpressionsCount: (state, action) => {
            const { _id, reaction_counts, userImpression } = action.payload;
        
            // Convert Moments array to a dictionary 
            const momentsDict = state.Moments.reduce((acc, moment) => {
              acc[moment._id] = moment;
              return acc;
            }, {});
        
            // Update the impressions count for the specified moment
            if (momentsDict.hasOwnProperty(_id)) {
              momentsDict[_id] = {
                ...momentsDict[_id],
                reaction_counts: reaction_counts,
                userImpression: userImpression
              };
            }
        
            // Convert the dictionary back to an array
            const updatedMoments = Object.values(momentsDict);
        
            return {
              ...state,
              Moments: updatedMoments,
            };
        },
        setMomentSearchCriteria: (state, action) => {
            state.momentSearchValues = action.payload
        },

        resetMoments: (state, action) => {
            state.Moments = []
        },

        setMomentsSorting: (state, action) => {
            state.momentSorting = action.payload
        },
        removeMoment: (state, action) => {
            const momentIdToRemove = action.payload;
            state.Moments = state.Moments.filter(
              (item) => item._id !== momentIdToRemove
            );
        },
        getNewMoments: (state, action) => {
            state.newMoments = action.payload;
        },

    }
})

export const { 
    getMoments, 
    setMoments, 
    setPage, 
    setLimitReached,
    setMomentsLoading,
    updateCommentsCount,
    updateImpressionsCount,
    setMomentSearchCriteria,
    resetMoments,
    setMomentsSorting,
    removeMoment ,
    getNewMoments
} = momentSlice.actions;

export default momentSlice.reducer;