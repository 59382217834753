import React, { useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../styles/NewPasswordStyle.css";
import { images } from "../utils/image";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword, setForgotPasswordDetails } from "../store/action/userInteractionsAction";
import { getFromSessionStorage } from "../utils/storageHandler";

const NewPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const data = location.state;

  const [show, setShow] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [resetPasswordError, setResetPasswordError] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [matchPassword, setMatchPassword] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    const accessToken = getFromSessionStorage('accessToken');
    if(accessToken){
      // Redirect to the home page if the user already loggedIn
      navigate("/");
    }else if (!forgetPasswordStatus.otp) {
      // Redirect to the loggin page if the user hasn't completed the forget password process
      navigate("/login");
    }
    
  }, []);

  const forgetPasswordStatus = useSelector(
    (state) => state.UserInteractions.forgotPasswordStatus
  );

  //Toggle Eye for password visible
  const togglePassword = () => {
    setPasswordType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };

  const toggleConfirmPassword = () => {
    setConfirmPasswordType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };

  //Show and hide modal
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const onValueChange = (evt) => {
    setShowMessage(true);
    setNewPassword(evt.target.value);
  };

  const verifyOTP = () => {
    handleShow(); //modal open
  };

  const handleResetPassword = async () => {
    if (data && data.email && data.userCode && newPassword) {
      dispatch(
        await resetPassword({
          email: data.email,
          userCode: data.userCode,
          newPassword: newPassword,
        })
      )
        .then((res) => {
          if (res) {
            dispatch(setForgotPasswordDetails({}))
            setResetPasswordError(false);
            verifyOTP();
          } else {
            setResetPasswordError(true);
          }
        })
        .catch((error) => {
          console.error("Error in reset password:", error);
        });
    } else {
      console.log("missing data"); //add user notification
    }
  };

  //Password validation
  const validatePassword = () => {
    const lowerUppercaseRegex = /[a-zA-Z]/g;
    const numberRegex = /[0-9]/g;
    const specialCharRegex = /[!@#?]/g; 
    const noSpaceOrRestrictedCharRegex = /^[^<>()&/\\'"\s]+$/; 
    const minLength = newPassword.length >= 8;

    return {
      lowerUppercase: newPassword.match(lowerUppercaseRegex),
      number: newPassword.match(numberRegex),
      specialChar: newPassword.match(specialCharRegex),
      noSpaceOrRestrictedChar: noSpaceOrRestrictedCharRegex.test(newPassword),
      length: minLength,
    };
  };

  const validationResults = validatePassword();

  //Check the password matching

  const checkPassword = (e) => {
    const { id, value } = e.target;
    if (id === "confirmPassword") {
      setConfirmPassword(value);
      if (value === newPassword) {
        setMatchPassword("");
      } else {
        setMatchPassword("Password does not match");
      }
    }
  };

  return (
    <div>
      <div className="new-password-main">
        <Row className="col1">
          <Col sm={6}>
            <p className="go-back" onClick={() => navigate(-1)}>
              <span>
                <i class="bi bi-arrow-left"></i>
              </span>
              Back
            </p>
            <Card className="card">
              <Card.Body>
                <section className="email-logo">
                  <img className="logo" src={images.moofLifeLogo}></img>
                </section>
                <div className="new-password-container">
                  <div className="title-set">
                    <p className="title-1">Create New Password</p>
                    <p className="title-2">Create Your New Strong Password</p>
                  </div>
                  <div className="new-password-form">
                    <div className="password-input-container">
                      <input
                        id="password"
                        name="password"
                        className="form-control"
                        type={passwordType}
                        maxLength={25}
                        placeholder="Password"
                        onChange={onValueChange}
                      />
                      <button
                        type="button"
                        className="toggle-password"
                        onClick={togglePassword}
                      >
                        {passwordType === "password" ? (
                          <img src={images.eyeSlash} width="20px"></img>
                        ) : (
                          <img src={images.eye} width="20px"></img>
                        )}
                      </button>
                    </div>
                    <div className="password-input-container">
                      <input
                        className="form-control"
                        type={confirmPasswordType}
                        placeholder="Confirm Password"
                        maxLength={25}
                        name="confirmPassword"
                        onChange={checkPassword}
                        id="confirmPassword"
                      />
                      <button
                        type="button"
                        className="toggle-password"
                        onClick={toggleConfirmPassword}
                      >
                        {passwordType === "password" ? (
                          <img src={images.eyeSlash} width="20px"></img>
                        ) : (
                          <img src={images.eye} width="20px"></img>
                        )}
                      </button>
                    </div>

                    {matchPassword && (
                      <small>
                        <div className="error-message">
                          <span>
                            <i class="bi bi-exclamation-circle"></i>
                          </span>
                          {matchPassword}
                        </div>
                      </small>
                    )}
                  </div>

                  <Link>
                    <button
                      type="button"
                      className="btn-new-password"
                      onClick={() => {
                        handleResetPassword();
                      }}
                      disabled={
                        confirmPassword !== newPassword ||
                        validationResults.length === false ||
                        validationResults.lowerUppercase === false ||
                        validationResults.number === false ||
                        validationResults.specialChar === false ||
                        validationResults.noSpaceOrRestrictedChar === false 
                       
                      }
                    >
                      Reset Password
                    </button>
                  </Link>

                  {showMessage && (
                    <div id="message">
                      <h3 className="message-topic">Create a password that:</h3>
                      <p
                        className={ validationResults.length ? "valid" : "invalid" }
                      >
                        Contains 8 - 25 characters
                      </p>
                      <p
                        className={ validationResults.lowerUppercase ? "valid" : "invalid" }
                      >
                        Contains both lower (a-z) and upper case letters (A-Z)
                      </p>
                      <p
                        className={ validationResults.number ? "valid" : "invalid" }
                      >
                        Contains at least one number (0-9) or a symbol
                      </p>
                      <p
                        className={ validationResults.specialChar ? "valid" : "invalid" }
                      >
                        inclusion of at least one special character e.g., ! @ #
                        ? ]
                      </p>
                      <p
                        className={ validationResults.noSpaceOrRestrictedChar ? "valid" : "invalid" } >
                        password should not include ‘spaces’ or any of these
                        characters
                      </p>
                    </div>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6} style={{ textAlign: "right" }}>
            <img src={images.forgetPasswordBg}></img>
          </Col>
        </Row>

        <Modal
          centered
          className="modal-new-password"
          show={show}
          onHide={handleClose}
          animation={true}
        >
          <Modal.Body>
            <Card className="text-center card" style={{ border: "none" }}>
              <Card.Body>
                <img src={images.successImage}></img>
                <p className="modal-text">
                  Password Has Been Changed Successfully
                </p>
                <Link to="/login">
                  <button className="btn-new-password-modal">Log In</button>
                </Link>
              </Card.Body>
            </Card>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default NewPassword;
