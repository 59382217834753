import { getFamousBdays } from '../slices/FamousBdaysSlice';
import { createRequest } from '../../utils/requestHandler';
const mooflife_backend = process.env.REACT_APP_BACK_END_URL;

export const fetchFamousBdays = (date) => {
  return async (dispatch) => {
    try {

      const configs = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      const response = await createRequest('get', `${mooflife_backend}/famous-birthdays/${date}`, {}, configs);
      if (response && response.data && response.data.success && response.data.data) {
        dispatch(getFamousBdays(response.data.data));
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
};
