import { createRequest } from '../../utils/requestHandler';
import { getFromSessionStorage } from '../../utils/storageHandler';
import { setCommentsForMoments, resetCommentsForMoments, setPage,
  setLimitReached, insertNewComment } from '../slices/CommentsSlice';
import { updateCommentsCount } from '../slices/MomentSlice';
import { updateLifePageCommentsCount } from '../slices/LifeSlice';
const mooflife_backend = process.env.REACT_APP_BACK_END_URL;

export const createComment = (data, area) => {
    return async (dispatch, getState) => {
        try {
          const accessToken = await getFromSessionStorage("accessToken");
          const configs = {
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${accessToken}`
                },
          }
    
          const response = await createRequest('post', `${mooflife_backend}/comment`, data, configs);
    
          if (response && response.data.success && response.data.data) {
              dispatch(getCommentsForMoment(data.moment_id, area));
          } 
        } catch (error) {
            console.error('Error while creating new comment:', error);
        }
    };
};

export const getCommentsForMoment = (momentId, area) => {
    return async (dispatch) => {
        try {
          const accessToken = await getFromSessionStorage("accessToken");
          const configs = {
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${accessToken}`
                },
          }

          const body = {
            selector : {moment_id: momentId},
          }
    
          const response = await createRequest('post', `${mooflife_backend}/comment/get`, body, configs);
          if (response && response.data.success && response.data.data) {
            if(response.data.data.length > 0){
              dispatch(setCommentsForMoments(response.data.data));
            }else {
              // dispatch(handleLimitReached(true));
            } 

            if(area === 'lifePage'){
              dispatch(updateLifePageCommentsCount({
                momentId: momentId,
                count: response.data.commentCount
              }));
            }else {
              dispatch(updateCommentsCount({
                momentId: momentId,
                count: response.data.commentCount
              }));
            }
            
          }
        } catch (error) {
            console.error('Error while get Comments for Momemnt:', error);
        }
    };
};

export const resetMomentComments = () => {
  return async (dispatch) => {
    try {
      dispatch(resetCommentsForMoments())
    } catch (error) {
      console.error('Error resetting Comments for Moments:', error);
    }
  };
};

export const deleteComments = (data) => {
  return async (dispatch) => {
      try {
        const accessToken = await getFromSessionStorage("accessToken");
        const configs = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
              },
        }
  
        const response = await createRequest('post', `${mooflife_backend}/comment/delete`, data, configs);
  
        if (response && response.data.success && response.data.data) {
            dispatch(resetMomentComments());
            dispatch(getCommentsForMoment(data.momentId, data.area));
        } 
      } catch (error) {
          console.error('Error while deleting comments:', error);
      }
  };
};

export const editComment = (data) => {
  return async (dispatch) => {
      try {
        const accessToken = await getFromSessionStorage("accessToken");
        const configs = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
              },
        }
  
        const response = await createRequest('post', `${mooflife_backend}/comment/edit`, data, configs);
        if (response && response.data.success && response.data.data) {
           dispatch(getCommentsForMoment(data.momentId));
        } 
      } catch (error) {
          console.error('Error while editing comment:', error);
      }
  };
};

export const handlePageCount = (count) => {
  return async (dispatch) => {
    try {
      dispatch(setPage(count));
    } catch (error) {
      console.error('Error handling page count for comments:', error);
    }
  };
};

export const handleLimitReached = (val) => {
  return async (dispatch) => {
    try {
      dispatch(setLimitReached(val));
    } catch (error) {
      console.error('Error handling page count for comments:', error);
    }
  };
};

export const placeNewComment = (comment) => {
  return async (dispatch) => {
    try {
      dispatch(insertNewComment(comment))
    } catch (error) {
      console.error('Error placing new comment to list :', error);
    }
  };
};

export const addNewEmoji = (data) => {
  return async (dispatch) => {
    try {
      const accessToken = await getFromSessionStorage("accessToken");
        const configs = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
              },
        }
  
        const response = await createRequest('post', `${mooflife_backend}/comment/impression`, data, configs);
        if (response && response.data.success && response.data.data) {
          dispatch(getCommentsForMoment(data.moment_id))
        }
    } catch (error) {
      console.error('Error resetting Comments for Moments:', error);
    }
  };
};


