import {createSlice} from "@reduxjs/toolkit";

const userInteractionsSlice = createSlice({
    name: "userInteractions",
    initialState:{
        captchaResult: {},
        otpVerified: false,
        newUserDetails: {},
        userLogged: false,
        forgotPasswordStatus: {}
    },
    reducers:{
        getCaptchaResult : (state, action) => {
            state.captchaResult = action.payload; 
        },

        setOTPVerification : (state, action) => {
            state.otpVerified = action.payload; 
        },

        setNewUserDetails : (state, action) => {
            state.newUserDetails = action.payload; 
        },

        setUserLogged : (state, action) => {
            state.userLogged = action.payload; 
        },

        setForgotPasswordStatus : (state, action) => {
            state.forgotPasswordStatus = action.payload; 
        },
    }
})

export const {
    getCaptchaResult,
    setOTPVerification,
    setNewUserDetails,
    setUserLogged,
    setForgotPasswordStatus
} = userInteractionsSlice.actions;

export default userInteractionsSlice.reducer;