import React, { useState, useEffect, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import "../../styles/LifeHeaderStyle.css";
import "../../styles/CommonStyle.css";
import "../../styles/ShareIconStyle.css";
import "../../styles/NavbarStyle.css";
import { images } from "../../utils/image";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReportLifeMoment from "../ReportLifeMoment";
import { getFromSessionStorage } from "../../utils/storageHandler";
import copy from "copy-to-clipboard";
import { Seo } from "../../utils/metaTags";
import Accordion from "react-bootstrap/Accordion";
import Modal from "react-bootstrap/Modal";
import LifeSearch from "./LifeSearch";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import {
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  WhatsappIcon,
  XIcon,
} from "react-share";

import Spinner from "react-bootstrap/Spinner";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

import {
  doesUserFollowLife,
  addFollower,
  removeFollower,
} from "../../store/action/FollowerAction";

import {
  getPermissionsToUpdate,
  getAuthorizedEmails,
} from "../../store/action/MomentAction";
import {
  momentBulkUpload,
  connectLife,
  disconnectLife,
  searchLivesToConnect,
  getConnectedLives,
} from "../../store/action/LifeAction";
import PopUpModal from "../PopUpModal";
import { is } from "@babel/types";

const LifeHeader = () => {
  const textRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [follow, setFollow] = useState(false);
  const [show, setShow] = useState(false);
  //check logged users
  const [userLogin, setUserLogin] = useState("");

  //copy text
  const [showTooltip, setTooltipShow] = useState(false);

  //Share
  const [shareBoxVisible, setShareBoxVisible] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingDropdown, setLoadingDropdown] = useState(false);
  const [permissionGranted, setPermissionGrant] = useState(false);
  const [lifeToConnect, setLifeToConnect] = useState(null);
  const [bulkUpload, setBulkUpload] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [mooflifeAdmin, setMooflifeAdmin] = useState(false);
  const [connectedLives, setConnectedLives] = useState([]);
  const [searchSuggetion, setSearchSuggestion] = useState([]);

  const [showConnectedLifeModal, setShowConnectedLifeModal] = useState(false);
  const [words, setWords] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [showDropdown, setShowDropdown] = useState(true);
  const [showSearch, setShowSearch] = useState(true);
  const [selectedLifeId, setSelectedLifeId] = useState(null);
  const [popupModalShow, setPopupModalShow] = useState(false);
  const [loadingConnectLife, setLoadingConnectLife] = useState(false);
  const [actionModal, setActionModal] = useState(false);
  const inputDropdownRef = useRef(null);
  const shareBoxRef = useRef(null);
  const params = useParams();

  const Life = useSelector((state) => state.Life.Life);

  useEffect(() => {
    const userDetails = getFromSessionStorage("userDetails", true);
    setUserLogin(userDetails);
    if (userDetails) {
      dispatch(
        doesUserFollowLife({ userId: userDetails.id, lifeId: Life.life_id })
      )
        .then((res) => {
          setFollow(res);
        })
        .catch((error) => {
          console.error("Error in get follow state", error);
        });
    }
    handleConnectedLives();
  }, [Life.life_id]);

  useEffect(() => {
    checkAuthorizedEmails();
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (shareBoxRef.current && !shareBoxRef.current.contains(event.target)) {
        setShareBoxVisible(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleConnectedLives = async () => {
    if (Life && Life._id) {
      const connectedLivesList = await dispatch(
        getConnectedLives({ lifeId: Life._id })
      );
      if (connectedLivesList && connectedLivesList.success) {
        setConnectedLives(connectedLivesList.data);
      }
    }
  };

  const checkAuthorizedEmails = async () => {
    const userDetails = getFromSessionStorage("userDetails", true);
    if (userDetails && userDetails.email) {
      dispatch(
        await getAuthorizedEmails({ userEmail: userDetails.email })
      ).then((res) => {
        if (res && res.success && res.data) {
          setMooflifeAdmin(res.data.result);
        }
      });
    }
  };

  const handleFollowClick = () => {
    setLoading(true);
    if (follow) {
      //unfollow life
      dispatch(removeFollower({ user_id: userLogin.id, life_id: Life.life_id }))
        .then((res) => {
          setFollow(false);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error in remove follower", error);
          setLoading(false);
        });
    } else {
      // follow life
      dispatch(addFollower({ user_id: userLogin.id, life_id: Life.life_id }))
        .then((res) => {
          setFollow(true);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error in add follower", error);
          setLoading(false);
        });
    }
  };

  const showReportModal = () => {
    setShow(true);
  };

  //Function to add text to clipboard
  const copyToClipboard = () => {
    let copyText = textRef.current.value;
    let isCopy = copy(copyText);
    if (isCopy) {
      setTooltipShow(true);

      setTimeout(() => {
        setTooltipShow(false);
      }, 3000);
    }
  };

  const handleShareClick = () => {
    setShareBoxVisible(!shareBoxVisible);
  };

  const handleDropDown = async (isOpen) => {
    setLoadingDropdown(true);
    if (isOpen && Life) {
      const userData = getFromSessionStorage("userDetails", true);
      dispatch(
        await getPermissionsToUpdate({
          lifeId: Life.life_id,
          userEmail: userData.email,
        })
      ).then((permission) => {
        setPermissionGrant(permission);
        setLoadingDropdown(false);
      });
    }
  };

  const updateLife = () => {
    if (Life && Life.life_id) {
      const lifeInState = {
        lifeId: Life.life_id,
      };
      navigate("/update-life", { state: lifeInState });
    }
  };

  const [showBulkUpload, setShowBulkUpload] = useState(false);
  const [message, setMessage] = useState("");

  const handleBulkUploadClose = () => {
    setSelectedFile(null);
    setShowBulkUpload(false);
    setMessage("");
  };

  const handleBulkUploadShow = () => {
    setShowBulkUpload(true);
    setShowBulkUpload(true);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleBulkUploadFile = async () => {
    setLoading(true);
    if (selectedFile && Life && Life._id && Life.life_name) {
      const bulkUploadRes = await dispatch(
        momentBulkUpload(selectedFile, Life._id, Life.life_name)
      );
      if (bulkUploadRes && bulkUploadRes.success) {
        //upload completed!
        setLoading(false);
        setMessage("Upload Completed!");
        window.location.reload();
      } else {
        let error = null;
        if (bulkUploadRes.error) {
          error = bulkUploadRes.error;
        }
        setMessage(error ? `Error: ${error}` : "Moment bulk upload failed!");
        setTimeout(() => {
          setMessage("");
        }, 3000);
      }
    }
  };

  const connectedLifeModalClose = () => {
    setShowConnectedLifeModal(false);
    setSelectedLifeId("");
  };

  const handleConnections = async () => {
    setShowConnectedLifeModal(true);
  };

  const handleSearchSuggestion = async (input) => {
    if (input && Life && Life._id) {
      const searchresults = await dispatch(
        searchLivesToConnect({ input: input, sourceLifeId: Life._id })
      );
      if (searchresults && searchresults.success) {
        setSearchSuggestion(searchresults.data);
      }
    }
  };

  const handleLifeModalShow = () => {
    setSearchSuggestion([]);
    setWords("");
    setSelectedLifeId(null);
  };

  const getSuggestionWhenClick = async () => {
    if (showDropdown === true) {
      // dispatch(getSearchSuggestion({ count: 0 }));
    }
    setShowDropdown(true);
  };

  const handleSelectedLife = (lifeId) => {
    // setSelectedLifeId(lifeId);
    setSelectedLifeId((prevState) => (prevState === lifeId ? null : lifeId));
  };

  const handleDisconnectLife = async (lifeId) => {
    setLoadingConnectLife(true);
    const disconnectLifeRes = await dispatch(
      disconnectLife({ sourceLifeId: lifeId, targetLifeId: Life._id })
    );
    if (disconnectLifeRes && disconnectLifeRes.success) {
      const message = disconnectLifeRes.data.message
        ? disconnectLifeRes.data.message
        : "Successful! Life disconnection Completed.";
      handleConnectedLives();
      setLoadingConnectLife(false);
      setMessage(message);
      setLoadingConnectLife(false);
      setShowConnectedLifeModal(false);
      setPopupModalShow(true);

      setTimeout(() => {
        setPopupModalShow(false);
        window.location.reload();
      }, 3000);
    } else {
      let error = null;
      let actionModal = false;
      if (disconnectLifeRes.error) {
        error = disconnectLifeRes.error;
      }

      if (disconnectLifeRes.action) {
        actionModal = true;
        setActionModal(true);
      }

      const errorMsg = error
        ? error
        : "We ran into an error ! Please try again later.";
      setLoadingConnectLife(false);
      setMessage(errorMsg);
      setShowConnectedLifeModal(false);
      setPopupModalShow(true);
    }

    setWords("");
  };

  const handleConnectLife = async () => {
    setLoadingConnectLife(true);
    if (selectedLifeId && selectedLifeId !== "" && Life) {
      const lifeConnectRes = await dispatch(
        connectLife({ sourceLife: selectedLifeId, targetLife: Life.life_id })
      );
      if (lifeConnectRes && lifeConnectRes.success) {
        const message = lifeConnectRes.data.message
          ? lifeConnectRes.data.message
          : "Successful! Life Connection Completed.";
        handleConnectedLives();
        setMessage(message);
        setLoadingConnectLife(false);
        setShowConnectedLifeModal(false);
        setPopupModalShow(true);

        setTimeout(() => {
          setPopupModalShow(false);
          window.location.reload();
        }, 3000);
      } else {
        let error = null;
        let actionModal = false;
        if (lifeConnectRes.error) {
          error = lifeConnectRes.error;
        }

        if (lifeConnectRes.action) {
          actionModal = true;
          setActionModal(true);
        }

        const errorMsg = error
          ? error
          : "We ran into an error ! Please try again later.";
        setMessage(errorMsg);
        setLoadingConnectLife(false);
        setShowConnectedLifeModal(false);
        setPopupModalShow(true);
      }

      setSearchSuggestion([]);
      setWords("");
      setSelectedLifeId(null);
    }
  };

  const currentUrl =
    window.location.protocol +
    "//" +
    window.location.hostname +
    (window.location.port ? ":" + window.location.port : "");

    const [packageColor, setPackageColor] = useState("");

    useEffect(() => {
      if (Life.current_subscription && Life.current_subscription.slice(3) === "Elite") {
        setPackageColor("#FFBF00");
      } else if (Life.current_subscription && Life.current_subscription.slice(3) === "Premium") {
        setPackageColor("#FF5900");
      } else if (Life.current_subscription && Life.current_subscription.slice(3) === "Plus") {
        setPackageColor("#3787FF");
      } else if (Life.current_subscription === "Free") {
        setPackageColor("#484848");
      } else {
        setPackageColor("#FFBF00");
      }
    }, [Life.current_subscription]);

  return (
    <div>
      <Seo
        title={`${Life.life_name ? `${Life.life_name} | ` : ""} MoofLife`}
        description={`Explore moments of ${
          Life.life_name
        } on MoofLife. Engage with the history of 
        ${Life.life_name}. https://www.MoofLife.com/${
          Life.verified_name ? Life.verified_name : Life.life_id
        }`}
        url={`${currentUrl}/${
          Life.verified_name ? Life.verified_name : Life.life_id
        }`}
        type="profile"
        name="MoofLife"
        image={Life.life_logo}
        author={Life.life_name}
        NormalDescription={`Moments of ${Life.life_name} - MoofLife - ${Life.life_description}`}
        keywords={`Moments of ${Life.life_name}, ${Life.life_name},  ${Life.life_name} history , 
        history of ${Life.life_name},  ${Life.life_name} Timeline, Timeline of ${Life.life_name}, 
        History Timeline of ${Life.life_name}, ${Life.life_name} History Timeline, ${Life.life_name} MoofLife, 
        past moments of ${Life.life_name}, Historical Moments of ${Life.life_name}, ${Life.life_name} past moments,
         ${Life.life_name} historical moments, ${Life.life_name} historical events, historical events of ${Life.life_name},
          past events of ${Life.life_name}, ${Life.life_name} past events`}
      />
      {userLogin && (
        <ReportLifeMoment
          title="Report Life"
          subtitle="Why do you want to report this life?"
          lifeMomentId={Life._id}
          show={show}
          setShow={(bool) => setShow(bool)}
        ></ReportLifeMoment>
      )}

      <PopUpModal
        title={message}
        show={popupModalShow}
        setPopupModalShow={(bool) => setPopupModalShow(bool)}
        setActionModal={(bool) => setActionModal(bool)}
        actionModal={actionModal}
      ></PopUpModal>

      {showBulkUpload && (
        <Modal
          show={handleBulkUploadShow}
          onHide={handleBulkUploadClose}
          backdrop="static"
          keyboard={false}
          centered
          className="bulk-upload-modal"
        >
          <Modal.Header className="modal-topic" closeButton>
            <div>Upload File</div>
          </Modal.Header>
          <Modal.Body className="modal-body">
            <input
              id="fileSelect"
              type="file"
              accept=".xlsx"
              onChange={handleFileChange}
            />

            {message && <div className="error-message">{message}</div>}

            <div className="d-flex gap-2 justify-content-center">
              <button
                className="yellow-btn"
                onClick={() => handleBulkUploadFile()}
              >
                {loading ? (
                  <Spinner
                    animation="border"
                    variant="light"
                    style={{
                      width: "15px",
                      height: "15px",
                    }}
                  />
                ) : (
                  "Submit"
                )}
              </button>
              <button
                className="yellow-btn"
                onClick={handleBulkUploadClose}
                disabled={loading}
              >
                Cancel
              </button>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      )}

      {showConnectedLifeModal && (
        <Modal
          className="link-moment-modal connected-life-modal"
          show={showConnectedLifeModal}
          onHide={connectedLifeModalClose}
          onShow={handleLifeModalShow}
          centered
          size="xl"
          backdrop="static"
        >
          <Modal.Header className="title">Connect Life to My Life</Modal.Header>
          <div className="d-flex gap-5">
            <div className="modal-body">
              <div className="title">Connected Lives</div>

              {connectedLives.map((life, index) => (
                <div className="card">
                  <div
                    key={index}
                    className="d-flex gap-3 align-items-center justify-content-between"
                  >
                    <div className="life-set">
                      <div className="d-flex gap-3 align-items-center">
                        <Zoom>
                          <img
                            src={life.life_logo}
                            className="create-dropdown-img"
                          ></img>
                        </Zoom>
                        <div>
                          <div className="life-name">{life.life_name}</div>
                          <div className="life-tag">{life.life_title}</div>
                        </div>
                      </div>
                      <div></div>
                    </div>
                    <button
                      className="yellow-btn"
                      onClick={() => handleDisconnectLife(life._id)}
                      disabled={loadingConnectLife}
                    >
                      {loadingConnectLife ? (
                        <Spinner
                          animation="border"
                          variant="light"
                          style={{
                            width: "15px",
                            height: "15px",
                          }}
                        />
                      ) : (
                        "Disconnect"
                      )}
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <div className="vl"></div>
            <div className="modal-body">
              <div className="title">Connect a New Life</div>

              <div>
                {showSearch && (
                  <Form>
                    <div className="select-form">
                      <div className="search-menu-btn" ref={inputDropdownRef}>
                        <div>
                          <input
                            id="searchText"
                            className="search"
                            type="text"
                            placeholder="Search Lives"
                            value={words}
                            onChange={(e) => {
                              setWords(e.target.value);
                              handleSearchSuggestion(e.target.value);
                            }}
                            autocomplete="one-time-code"
                          />
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </div>

              {showDropdown === true &&
                searchSuggetion &&
                searchSuggetion.length > 0 && (
                  <div className="suggest-lives">
                    {searchSuggetion.map((searchLife, index) => (
                      <div
                        className="card"
                        key={index}
                        onClick={() => handleSelectedLife(searchLife.life_id)}
                        style={{
                          border:
                            selectedLifeId === searchLife.life_id
                              ? "2px solid #FFC000"
                              : "",
                        }}
                      >
                        <div className="life-set">
                          <div className="d-flex gap-3 align-items-center">
                            <Zoom>
                              <img
                                src={searchLife.life_logo}
                                className="create-dropdown-img"
                              ></img>
                            </Zoom>
                            <div>
                              <div className="life-name">
                                {searchLife.life_name}
                              </div>
                              <div className="life-tag">
                                {searchLife.life_title}
                              </div>
                            </div>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              <div className="btn-set">
                <button
                  className="yellow-btn"
                  onClick={() => handleConnectLife()}
                  disabled={loadingConnectLife || !selectedLifeId}
                >
                  {loadingConnectLife ? (
                    <Spinner
                      animation="border"
                      variant="light"
                      style={{
                        width: "15px",
                        height: "15px",
                      }}
                    />
                  ) : (
                    "Connect"
                  )}
                </button>
                <button
                  className="yellow-btn"
                  onClick={connectedLifeModalClose}
                  disabled={loadingConnectLife}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      <div className="life-header">
        <Row>
          <Col>
            <Row>
              <div className="life-search">
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header></Accordion.Header>
                    <Accordion.Body>
                      <LifeSearch />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Row>

            <Row>
              <div className="header-card">
                <div>
                  <div className="header-content">
                    <Zoom>
                      <div className="overlay"></div>
                      <img className="cover-image" src={Life.cover_image} style={{ borderBottom: `10px solid ${packageColor}` }}/>
                    </Zoom>
                    <Zoom>
                      <img className="profile-image" src={Life.life_logo} />
                    </Zoom>
                    <div className="main-row">
                      <div className="name-row">
                        <h6 className="life-name">
                          {Life.life_name
                            ? Life.life_name.length > 20
                              ? Life.life_name.slice(0, 20) + "..."
                              : Life.life_name
                            : ""}
                          <span>
                            <img
                              className="verified-icon"
                              src={images.VerifiedIcon}
                            ></img>
                          </span>{" "}
                          <div className="life-tag">{Life.life_title}</div>
                        </h6>
                      </div>
                      <div className="moments-share-row">
                        <div className="moments-row">
                          <h6 className="moments-number">
                            {Life.no_of_moments}
                          </h6>
                          <h6 className="moment-tag">Moments</h6>
                        </div>
                        <div className="icons share-icon">
                          <div class="share-feed">
                            <a
                              class="share-btn"
                              onClick={handleShareClick}
                              ref={shareBoxRef}
                            >
                              <i class="bi bi-share-fill"></i>
                              {showTooltip === true && (
                                <div className="copy-message">Link copied</div>
                              )}
                              {shareBoxVisible && (
                                <div
                                  class="share-box"
                                  style={{
                                    left: userLogin ? "-15px" : "-135px",
                                  }}
                                >
                                  <div class="share-icon">
                                    <label>Telegram</label>
                                    <TelegramShareButton
                                      url={`${currentUrl}/${Life.verified_name?Life.verified_name:Life.life_id}`}
                                      title={`MoofLife | ${Life.life_name}`}
                                    >
                                      <TelegramIcon
                                        size={32}
                                        round
                                        className="icon"
                                      />
                                    </TelegramShareButton>
                                  </div>
                                  <div class="share-icon">
                                    <label>Linkedin</label>
                                    <LinkedinShareButton
                                      url={`${currentUrl}/${Life.verified_name?Life.verified_name:Life.life_id}`}
                                      title={`MoofLife | ${Life.life_name}`}
                                    >
                                      <LinkedinIcon
                                        size={32}
                                        round
                                        className="icon"
                                      />
                                    </LinkedinShareButton>
                                  </div>
                                  <div class="share-icon">
                                    <label>Whatsapp</label>
                                    <WhatsappShareButton
                                      url={`${currentUrl}/${Life.verified_name?Life.verified_name:Life.life_id}`}
                                      title={`MoofLife | ${Life.life_name}`}
                                      separator=": "
                                    >
                                      <WhatsappIcon
                                        size={32}
                                        round
                                        className="icon"
                                      />
                                    </WhatsappShareButton>
                                  </div>
                                  <div class="share-icon">
                                    <label>Facebook</label>
                                    <FacebookShareButton
                                      url={`${currentUrl}/${
                                        Life.verified_name
                                          ? Life.verified_name
                                          : Life.life_id
                                      }`}
                                    >
                                      <FacebookIcon
                                        size={32}
                                        round
                                        className="icon"
                                      />
                                    </FacebookShareButton>
                                  </div>
                                  <div class="share-icon">
                                    <label>Mask</label>
                                    <TwitterShareButton
                                      url={`${currentUrl}/${Life.verified_name?Life.verified_name:Life.life_id}`}
                                      title={`MoofLife | ${Life.life_name}`}
                                    >
                                      <XIcon size={32} round className="icon" />
                                    </TwitterShareButton>
                                  </div>
                                  <div class="share-icon icon-copy">
                                    <div class="hover-text">
                                      {/* <!-- Copy Text --> */}
                                      <div className="copy-text d-flex">
                                        <input
                                          value={`${currentUrl}/${
                                            Life.verified_name
                                              ? Life.verified_name
                                              : Life.life_id
                                          }`}
                                          disabled
                                          type="text"
                                          ref={textRef}
                                        />
                                      </div>
                                    </div>
                                    <img
                                      src={images.CopyIcon}
                                      onClick={copyToClipboard}
                                      className="icon"
                                    ></img>
                                  </div>
                                </div>
                              )}
                            </a>
                          </div>
                        </div>

                        {userLogin && (
                          <>
                            {!follow && (
                              <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                  <div
                                    className="follow-btn"
                                    onClick={handleFollowClick}
                                  >
                                    {loading ? (
                                      <Spinner
                                        animation="border"
                                        variant="dark"
                                        style={{
                                          width: "15px",
                                          height: "15px",
                                        }}
                                      />
                                    ) : (
                                      "Follow"
                                    )}
                                  </div>
                                </Dropdown.Toggle>
                              </Dropdown>
                            )}

                            {follow && (
                              <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                  <button className="follow-btn">
                                    {loading ? (
                                      <Spinner
                                        animation="border"
                                        variant="dark"
                                        style={{
                                          width: "15px",
                                          height: "15px",
                                        }}
                                      />
                                    ) : (
                                      <div>
                                        <span>
                                          <i class="bi bi-check-lg"></i>
                                        </span>
                                        Following
                                      </div>
                                    )}
                                  </button>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    href="#"
                                    onClick={handleFollowClick}
                                  >
                                    Unfollow
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            )}

                            <div className="share-follow-row">
                              <Dropdown
                                onToggle={(isOpen) => handleDropDown(isOpen)}
                              >
                                <Dropdown.Toggle id="dropdown-basic">
                                  <button className="follow-btn more-btn">
                                    More
                                  </button>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    href="#"
                                    onClick={() => showReportModal()}
                                  >
                                    {loadingDropdown ? (
                                      <Spinner
                                        animation="border"
                                        variant="dark"
                                        style={{
                                          width: "15px",
                                          height: "15px",
                                        }}
                                      />
                                    ) : (
                                      "Report Life"
                                    )}
                                  </Dropdown.Item>

                                  {mooflifeAdmin && (
                                    <Dropdown.Item
                                      href="#"
                                      onClick={handleBulkUploadShow}
                                    >
                                      Bulk Upload
                                    </Dropdown.Item>
                                  )}
                                  {(permissionGranted || mooflifeAdmin) && (
                                    <Dropdown.Item onClick={handleConnections}>
                                      Connect Lives
                                    </Dropdown.Item>
                                  )}
                                </Dropdown.Menu>
                                <Dropdown.Menu>
                                  {permissionGranted && (
                                    <Dropdown.Item onClick={() => updateLife()}>
                                      {loadingDropdown ? (
                                        <Spinner
                                          animation="border"
                                          variant="dark"
                                          style={{
                                            width: "15px",
                                            height: "15px",
                                          }}
                                        />
                                      ) : (
                                        "Update Life"
                                      )}
                                    </Dropdown.Item>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LifeHeader;
