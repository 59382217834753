import React, { useState, useEffect } from "react";
import "../../styles/LandingRightStyle.css";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Footer from "../../components/Footer";
import { images } from "../../utils/image";
import { getMostViewMoments } from "../../store/action/MostViewedMomentsAction";
import { fetchFamousBdays } from "../../store/action/FamousBdaysAction";
import { getGeneralAds } from "../../store/action/GeneralAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Helmet } from "react-helmet-async";

import { fetchTopMoments } from "../../store/action/TopMomentsAction";

const LandingRight = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.toLocaleString("en-US", { month: "long" });
  const currentDay = currentDate.toLocaleString("en-US", { day: "2-digit" });
  const [showMore, setShowMore] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Life = useSelector((state) => state.Life.Life);
  const location = useLocation();
  const { state } = location;

  const MostViewedMoments = useSelector(
    (state) => state.MostViewedMoments.MostViewedMoments
  );
  const FamousBirthdays = useSelector((state) => state.FamousBdays.FamousBdays);

  let rightSideTopAd = null;
  let rightSideTopAdRedirectUrl = null;
  let rightSideBottomAd = null;
  let rightSideBottomAdRedirectUrl = null;
  const GeneralAds = useSelector((state) => state.General.Advertisements);

  if (GeneralAds.length > 0) {
    GeneralAds.map((adItem) => {
      if (adItem.placement === "right_side_top") {
        rightSideTopAd = adItem.ad_url;
        rightSideTopAdRedirectUrl =
          adItem.redirect_url && adItem.redirect_url !== null
            ? adItem.redirect_url
            : "#";
      } else if (adItem.placement === "right_side_bottom") {
        rightSideBottomAd = adItem.ad_url;
        rightSideBottomAdRedirectUrl =
          adItem.redirect_url && adItem.redirect_url !== null
            ? adItem.redirect_url
            : "#";
      }
    });
  }

  const [mostViewedMomentsList, setMostViewedMomentsList] = useState([]);
  const [momentsLoadCompleted, setMomentsLoadCompleted] = useState(false);

  useEffect(() => {
    const momentDate = moment(currentDate).format("YYYY-MM-DD");
    dispatch(getMostViewMoments());
    dispatch(fetchFamousBdays(momentDate));
    dispatch(getGeneralAds());
  }, [dispatch]);

  useEffect(() => {
    const isDisplayAll = state ? state.fullyMostViewed : "false";
    if (MostViewedMoments.length > 0) {
      if (!isDisplayAll || isDisplayAll === "false") {
        if (MostViewedMoments.length >= 2) {
          setMostViewedMomentsList([
            MostViewedMoments[0],
            MostViewedMoments[1],
          ]);
        } else if (MostViewedMoments.length === 1) {
          setMostViewedMomentsList([MostViewedMoments[0]]);
        }
      } else {
        setMostViewedMomentsList(MostViewedMoments);
        setMomentsLoadCompleted(true);
      }
    }
  }, [MostViewedMoments]);

  const handleMostViewedMoments = (type) => {
    if (type === "more") {
      let remainingMoments = [...mostViewedMomentsList];
      if (mostViewedMomentsList && MostViewedMoments) {
        MostViewedMoments.forEach((item) => {
          if (!mostViewedMomentsList.includes(item)) {
            remainingMoments.push(item);
          }
        });
      }
      setMostViewedMomentsList(remainingMoments);
      setMomentsLoadCompleted(true);
    } else {
      if (MostViewedMoments.length > 0) {
        if (MostViewedMoments.length >= 2) {
          setMostViewedMomentsList([
            MostViewedMoments[0],
            MostViewedMoments[1],
          ]);
        } else if (MostViewedMoments.length === 1) {
          setMostViewedMomentsList([MostViewedMoments[0]]);
        }
      }
      setMomentsLoadCompleted(false);
    }
  };

  const navigateMoment = (lifeId, momentId) => {
    if (lifeId && momentId) {
      const urlState = { fullyMostViewed: momentsLoadCompleted };
      navigate(`/${lifeId}/${momentId}`, { state: urlState });
    }
  };

  const handleToggleShowMore = () => {
    setShowMore(!showMore);
  };

  const displayBirthdays = () => {
    if (!showMore) {
      return FamousBirthdays.slice(0, 2);
    }
    return FamousBirthdays;
  };

  return (
    <div className="right-main">
      <Helmet>
        <script
          async
          src="
https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9623894499006251"
          crossorigin="anonymous"
        ></script>
      </Helmet>
      <div>
        {rightSideTopAd ? (
          <div>
            <Link
              to={rightSideTopAdRedirectUrl}
              target="_blank"
              rel="Advertiesement link"
            >
              <img className="add-image" src={rightSideTopAd} alt=""></img>
            </Link>
          </div>
        ) : (
          <div className="img-part">
            <Link to="/contact-us" target="_blank" rel="Advertiesement link">
              <img src={images.googleAdd1} alt=""></img>
            </Link>
          </div>
        )}
        <div className="ad-word">Ad</div>
      </div>

      {mostViewedMomentsList.length > 1 && (
        <div className="most-viewed">
          <Link to="/most-viewed-grid">
            <div className="title">Most viewed Moments of the day</div>
          </Link>
          {mostViewedMomentsList.length > 0 ? (
            <>
              {mostViewedMomentsList.map((mostview, index) => (
                <Card className="card" key={index}>
                  <Card.Body>
                    <Row>
                      <Col className="view-logo" sm={1}>
                        <img
                          src={mostview.life?.life_logo ? mostview.life.life_logo : ""}
                          alt=""
                        ></img>
                      </Col>
                      <Col className="view-title">
                        <h3
                          onClick={() =>
                            navigateMoment(
                              mostview.life.verified_name
                                ? mostview.life.verified_name
                                : mostview.life.life_id,
                              mostview._id
                            )
                          }
                        >
                          {mostview.moment_title
                            ? mostview.moment_title.length > 55
                              ? mostview.moment_title.slice(0, 55) + "..."
                              : mostview.moment_title
                            : ""}
                        </h3>
                        <h4>
                          {mostview.moment_date ? mostview.moment_date : ""}
                        </h4>
                      </Col>
                    </Row>
                    <Card.Text>
                      <div class="view-content">
                        <p>
                          {mostview.moment_details
                            ? mostview.moment_details.length > 150
                              ? mostview.moment_details.slice(0, 150) + "..."
                              : mostview.moment_details
                            : ""}
                        </p>
                      </div>
                    </Card.Text>
                  </Card.Body>
                </Card>
              ))}
            </>
          ) : (
            <>
              <div className="title sub-title">Loading...</div>
            </>
          )}

          {!momentsLoadCompleted && MostViewedMoments.length > 2 ? (
            <div
              className="title sub-title"
              onClick={() => handleMostViewedMoments("more")}
            >
              See more
            </div>
          ) : (
            momentsLoadCompleted &&
            MostViewedMoments.length > 2 && (
              <div
                className="title sub-title"
                onClick={() => handleMostViewedMoments("less")}
              >
                See less
              </div>
            )
          )}

          {MostViewedMoments.length <= 2 && (
            <div
              className="title sub-title"
              onClick={() => handleMostViewedMoments("more")}
            ></div>
          )}
        </div>
      )}

      <div>
        {FamousBirthdays.length > 1 && (
          <div className="most-viewed famous-bday">
            <div className="title">Famous Birth Days Today</div>
            <Card className="card">
              {displayBirthdays().map((bday, index) => (
                <div className="famous-content" key={index}>
                  <div className="image-logo">
                    <div className="col1">
                      <img className="image" src={bday.life_logo}></img>
                    </div>
                    <div className="col2">
                      <h3 className="topic">{bday.life_name}</h3>
                      <h3 className="sub-topic">{bday.life_title}</h3>
                    </div>
                  </div>

                  {bday.bday_display === "show" && (
                    <div className="col3">
                      {moment().diff(bday.since, "years")} yr
                    </div>
                  )}
                </div>
              ))}

              {FamousBirthdays.length > 2 && (
                <div className="sub-title" onClick={handleToggleShowMore}>
                  {showMore ? "See less" : "See more"}
                </div>
              )}
            </Card>
          </div>
        )}
      </div>

      <div>
        {rightSideBottomAd ? (
          <div>
            <Link
              to={rightSideBottomAdRedirectUrl}
              target="_blank"
              rel="Advertiesement link"
            >
              <img className="add-image" src={rightSideBottomAd} alt=""></img>
            </Link>
          </div>
        ) : (
          <div className="img-part">
            <Link to="/contact-us" target="_blank" rel="Advertiesement link">
              <img src={images.googleAdd2} alt=""></img>
            </Link>
          </div>
        )}
        <div className="ad-word">Ad</div>
      </div>

      <div>
        {/* Footer  */}
        <Footer />
      </div>
    </div>
  );
};

export default LandingRight;
