import React, { useState, useEffect } from "react";
import Favorites from "./UserSettingPages/Favorites";
import Access from "./UserSettingPages/Access";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useDispatch } from "react-redux";
import "../styles/UserSettings.css";
import { images } from "../utils/image";
import NavigationBar from "../views/LandingPage/NavigationBar.jsx";
import CooperateFooter from "../views/CooperatePages/CooperateFooter";
import AccountAdministration from "./UserSettingPages/AccountAdministration";
import ActionCenter from "./UserSettingPages/ActionCenter";
import { useNavigate, useLocation } from "react-router-dom";
import { getFromSessionStorage } from "../utils/storageHandler";
import { completeSubscription } from "../store/action/LifeAction";
import Subscription from "./UserSettingPages/Subscription";

const UserSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const subUpdated = queryParams.has('subs-upgraded');
  const paramsLifeId = queryParams.get('lifeId');
  const userDetails = getFromSessionStorage("userDetails", true);
  const [showComponent, setShowComponent] = useState(<AccountAdministration />);
  const [isActive, setIsActive] = useState("account");

  useEffect(() => {
    const accessToken = getFromSessionStorage("accessToken");
    if (!accessToken) {
      const stateValues = { from: 'user-settings' };
      navigate("/login", { state: stateValues });
    }

    if(subUpdated && paramsLifeId){
      handleSubscriptionComplete(paramsLifeId);
    }
  }, []);

  const handleSubscriptionComplete = async(lifeId) => {
    const updateSub = await dispatch(completeSubscription(
      {
        lifeId: lifeId, 
        userEmail: userDetails.email,
        isNew: false,

    }));

    if(updateSub && updateSub.success){
      const newUrl = window.location.pathname;
      window.history.replaceState({}, document.title, newUrl);
    }
  };

  const handleButtonClick = (component, buttonName) => {
    setShowComponent(component);
    setIsActive(buttonName);
  };

  return (
    <>
      <NavigationBar />
      <div className="user-settings-main">
        <Row>
          <Col sm={2} className="user-settings-left">
            <div className="title">User Settings</div>
            <div className="user-settings-buttons">
              
              <button
                onClick={() =>
                  handleButtonClick(<AccountAdministration />, "account")
                }
                style={{
                  background:
                    isActive === "account" ? "#F6E7B9" : "transparent",
                }}
              >
                <Row>
                  <Col sm={1}>
                    <img src={images.admin} />
                  </Col>
                  <Col className="sub-title">Account Administration</Col>
                </Row>
              </button>

              <button
              className="disabled-row"
                onClick={() =>
                  handleButtonClick(<Subscription />, "subscription")
                }
                style={{
                  background:
                    isActive === "subscription" ? "#F6E7B9" : "transparent",
                }}
              >
                <Row>
                  <Col sm={1}>
                    <img src={images.subscription} />
                  </Col>
                  <Col className="sub-title">Subscription Setting</Col>
                </Row>
              </button>
              
              <button
                onClick={() => handleButtonClick(<Favorites />, "favorites")}
                style={{
                  background:
                    isActive === "favorites" ? "#F6E7B9" : "transparent",
                }}
              >
                <Row>
                  <Col sm={1}>
                    <img src={images.fav} />
                  </Col>
                  <Col className="sub-title">General / Favorites</Col>
                </Row>
              </button>
              
              <button
                onClick={() => handleButtonClick(<Access />, "access")}
                style={{
                  background: isActive === "access" ? "#F6E7B9" : "transparent",
                }}
              >
                <Row>
                  <Col sm={1}>
                    <img src={images.access} />
                  </Col>
                  <Col className="sub-title">Access / Security</Col>
                </Row>
              </button>

              <button
                onClick={() => handleButtonClick(<ActionCenter />, "action")}
                style={{
                  background: isActive === "action" ? "#F6E7B9" : "transparent",
                }}
              >
                <Row>
                  <Col sm={1}>
                    {/* <img src={images.access} /> */}
                  </Col>
                  <Col className="sub-title">Action Center</Col>
                </Row>
              </button>
            </div>
          </Col>
          <Col className="user-settings-right">{showComponent}</Col>
        </Row>
      </div>
      <CooperateFooter />
    </>
  );
};

export default UserSettings;
