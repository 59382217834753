import { createRequest } from '../../utils/requestHandler';
import { getCaptchaResult, setNewUserDetails, setUserLogged, setForgotPasswordStatus } from '../slices/userInteractionsSlice';
import { getFromSessionStorage, setIntoSessionStorage } from '../../utils/storageHandler';
const mooflife_backend = process.env.REACT_APP_BACK_END_URL;


export const verifyCaptchaToken = (data) => {
  return async (dispatch) => {
    try { 
      const configs = {
          headers: {
              'Content-Type': 'application/json',
            },
      }
      await createRequest('post', `${mooflife_backend}/user/verify-captcha`, data, configs)
      .then((response) => {
        dispatch(getCaptchaResult(response.data))
      })
    } catch (error) {
      console.error('Error while verifying captcha:', error);
    }
  };
};

export const resetCaptchaDataInStore = () => {
  return async (dispatch) => {
    try { 
        dispatch(getCaptchaResult({}));
    } catch (error) {
      console.error('Error while reseeting captcha data:', error);
    }
  };
};

export const sendContactUsMessage = (data) => {
    return async () => {
      try { 
        const configs = {
            headers: {
                'Content-Type': 'application/json',
              },
        }
        await createRequest('post', `${mooflife_backend}/notification/contact-us`, data, configs)
        .then((response) => {
            return response;
        })
      } catch (error) {
        console.error('Error while submitting message:', error);
      }
    };
};

export const registerNewUser = (data) => {
  return async (dispatch) => {
    try { 
      const configs = {
          headers: {
              'Content-Type': 'application/json',
            },
      }
      const response = await createRequest('post', `${mooflife_backend}/auth/sign-up`, data, configs)
      if (response && response.data && response.data.success) {
        return response.data.success;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error while submitting message:', error);
    }
  };
};

export const setUserDetails = (user) => {
  return async (dispatch) => {
    try { 
      dispatch(setNewUserDetails(user));
    } catch (error) {
      console.error('Error while submitting message:', error);
    }
  };
};

export const sendOTP = (data) => {
  return async () => {
    try { 
      const configs = {
        headers: {
            'Content-Type': 'application/json',
          },
    }

    await createRequest('post', `${mooflife_backend}/notification/send-otp`, data, configs)
      .then((response) => {
          
      })
    } catch (error) {
      console.error('Error while submitting message:', error);
    }
  };
};

export const googleAuth = (data) => {
  return async (dispatch) => {
    try {
      const configs = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await createRequest(
        "post",
        `${mooflife_backend}/auth/google-sign-in`,
        data,
        configs
      );
      if (response && response.data) {
        dispatch(handleUserLogged(true));
        return response.data;
      }
    } catch (error) {
      console.error("Error while google login:", error);
      if(error && error.response && error.response.data && error.response.data?.data?.anotherMethod){
        return error.response.data;
      }
    }
  };
};

export const registerGoogleUser = (data) => {
  return async (dispatch) => {
    try {
      const configs = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await createRequest(
        "post",
        `${mooflife_backend}/auth/register-google-user`,
        data,
        configs
      );
      if (response && response.data) {
        return response.data;
      }
    } catch (error) {
      console.error("Error while google login:", error);
    }
  };
};

export const verifyOTP = async (data) => {
  return async () => {
    try { 
      const configs = {
          headers: {
              'Content-Type': 'application/json',
            },
      }

      const response = await createRequest('post', `${mooflife_backend}/notification/verify-otp`, data, configs);

      if (response && response.data && response.data.success) {
        return response.data.success;
      } else {
        return false;
      }
    } catch (error) {
        console.error('Error while submitting message:', error);
      }
    };
};

export const facebookAuth = (data) => {
  return async (dispatch) => {
    try { 
      const configs = {
          headers: {
              'Content-Type': 'application/json',
            },
      }
      await createRequest('post', `${mooflife_backend}/auth/facebook-sign-in`, data, configs)
      .then((response) => {
        if(response.data.length === 0){

        }
        
      })
    } catch (error) {
      console.error('Error while facebook login:', error);
    }
  };
};

export const userLogin = (data) => {
  return async (dispatch) => {
    try { 
      const configs = {
          headers: {
              'Content-Type': 'application/json',
            },
      }
      const response = await createRequest('post', `${mooflife_backend}/auth/sign-in`, data, configs);

      if (response && response.data && response.data.success) {
        dispatch(handleUserLogged(true));
        return response.data;
      } else if (response && response.data && !response.data.success) {
        return response.data;
      }
    } catch (error) {
      console.error('Error while login:', error);
      
    }
  };
};

export const sendEmailForGetOTP = async (data) => {
  return async () => {
    try { 
      const configs = {
          headers: {
              'Content-Type': 'application/json',
            },
      }

      const response = await createRequest('post', `${mooflife_backend}/notification/send-otp`, data, configs);

      if (response && response.data && response.data.success) {
        return response.data.success;
      } else {
        return false;
      }
    } catch (error) {
        console.error('Error while send OTP:', error);
      }
    };
};

export const verifyUserOTP = async (data) => {
  return async () => {
    try { 
      const configs = {
          headers: {
              'Content-Type': 'application/json',
            },
      }

      const response = await createRequest('post', `${mooflife_backend}/auth/verify-user-otp`, data, configs);

      if (response && response.data && response.data.userCode) {
        return response.data.userCode;
      } else {
        return false;
      }
    } catch (error) {
        console.error('Error while verify user OTP:', error);
      }
    };
};

export const resetPassword = async (data) => {
  return async () => {
    try { 
      const configs = {
          headers: {
              'Content-Type': 'application/json',
            },
      }

      const response = await createRequest('post', `${mooflife_backend}/auth/reset-password`, data, configs);

      if (response && response.data && response.data.success) {
        return response.data.success;
      } else {
        return false;
      }
    } catch (error) {
        console.error('Error while reset password:', error);
      }
    };
};

export const handleUserLogged = (logged) => {
  return async (dispatch) => {
    try {
      dispatch(setUserLogged(logged));
    } catch (error) {
      console.error('Error resetting Life Moments:', error);
    }
  };
};

export const isEmailAvailable = async (data) => {
  return async () => {
    try { 
      const configs = {
          headers: {
              'Content-Type': 'application/json',
            },
      }

      const response = await createRequest('post', `${mooflife_backend}/user/email-available`, data, configs);

      if (response && response.data && response.data.success) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
        console.error('Error while getting email exists:', error);
      }
    };
};

export const setForgotPasswordDetails = (data) => {
  return async (dispatch) => {
    try { 
      dispatch(setForgotPasswordStatus(data));
    } catch (error) {
      console.error('Error while setForgotPasswordDetails:', error);
    }
  };
};

export const changePassword = async (data) => {
  return async () => {
    try { 
      const accessToken = await getFromSessionStorage("accessToken");
      const configs = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
      }

      const response = await createRequest('post', `${mooflife_backend}/auth/change-password`, data, configs);

      if (response && response.data && response.data.success) {
        return response.data.success;
      } else {
        return false;
      }
    } catch (error) {
        console.error('Error while reset password:', error);
      }
    };
}

export const updateUserInterest = async (data) => {
  return async () => {
    try {
      const accessToken = await getFromSessionStorage("accessToken");
      const configs = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await createRequest(
        "post",
        `${mooflife_backend}/user/interest`,
        data,
        configs
      );

      if (response && response.data && response.data.success) {
        setIntoSessionStorage("interest", data.interest, true);
        setIntoSessionStorage("selected_countries", data.selected_countries, true);
        return response.data.success;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error while updating user interest:", error);
    }
  };
};