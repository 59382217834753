import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../../styles/LifeLeftStyle.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getLifeMomemntsForSearch } from "../../store/action/LifeAction";
import { images } from "../../utils/image";
import LifeSearch from "./LifeSearch";

const LifeLeft = () => {
  const [expanded, setExpanded] = useState("");
  const Life = useSelector((state) => state.Life.Life);

  let LifeDescription =
    Life && Life.life_description ? Life.life_description : "";
  const descriptionContent = expanded
    ? LifeDescription
    : LifeDescription.slice(0, 300);
  const leftSideBottomAdRedirectUrl =
    Life && Life.ad_bottom_left_redirect_url
      ? Life.ad_bottom_left_redirect_url
      : "#";

  return (
    <div id="lifeLeftDiv">
      <LifeSearch />
      <div className="profile">
        <div className="title">Life Profile</div>

        <Card className="card">
          <Card.Body>
            <p className="profile-content">
              {descriptionContent}
              {LifeDescription.length > 300 ? (
                <span onClick={() => setExpanded(!expanded)}>
                  {expanded ? "see less" : "see more"}
                </span>
              ) : (
                ""
              )}
            </p>
            {Life.life_type === "EL" && (
              <div>
                <div className="profile-details">
                  <h6 className="web">
                    <a href={`${Life.web_link}`} target="_blank">
                      {Life.web_link}
                    </a>
                  </h6>
                </div>
              </div>
            )}
            {Life.life_type === "PL" && (
              <div>
                <Row className="profile-details">
                  <Col className="col-1" sm={4}>
                    <li>Name</li>
                  </Col>
                  <Col className="col-2" sm={8}>
                    <h6>{Life.life_name}</h6>
                  </Col>
                </Row>
                <Row className="profile-details">
                  <Col className="col-1" sm={4}>
                    <li>DOB</li>
                  </Col>
                  <Col className="col-2" sm={8}>
                    <h6>{new Date(Life.since).toISOString().split("T")[0]}</h6>
                  </Col>
                </Row>
                {Life.death && (
                  <Row className="profile-details">
                    <Col className="col-1" sm={4}>
                      <li>Death</li>
                    </Col>
                    <Col className="col-2" sm={8}>
                      <h6>
                        {new Date(Life.death).toISOString().split("T")[0]}
                      </h6>
                    </Col>
                  </Row>
                )}
                <Row className="profile-details">
                  <Col className="col-1" sm={4}>
                    <li>Web</li>
                  </Col>
                  <Col className="col-2" sm={8}>
                  <h6 className="web" style={{textAlign:"left"}}>
                    <a href={`${Life.web_link}`} target="_blank">
                      {Life.web_link}
                    </a>
                  </h6>
                  </Col>
                </Row>
              </div>
            )}
            {Life.life_type === "OL" && (
              <div>
                <Row className="profile-details">
                  <Col className="col-1" sm={4}>
                    <li>Founder(s)</li>
                  </Col>
                  <Col className="col-2" sm={8}>
                    {Life.founders.map((founder, index) => (
                      <div key={index}>{founder} |&nbsp;</div>
                    ))}
                  </Col>
                </Row>
                <Row className="profile-details">
                  <Col className="col-1" sm={4}>
                    <li>Since</li>
                  </Col>
                  <Col className="col-2" sm={8}>
                    <h6>{new Date(Life.since).toISOString().split("T")[0]}</h6>
                  </Col>
                </Row>
                {Life.death && (
                  <Row className="profile-details">
                    <Col className="col-1" sm={4}>
                      <li>End</li>
                    </Col>
                    <Col className="col-2" sm={8}>
                      <h6>
                        {new Date(Life.death).toISOString().split("T")[0]}
                      </h6>
                    </Col>
                  </Row>
                )}
                <Row className="profile-details">
                  <Col className="col-1" sm={4}>
                    <li>Status</li>
                  </Col>
                  <Col className="col-2" sm={8}>
                    {/* condition - is_operating true Operating : no */}
                    {Life.is_operating === true ? (
                      <h6>Operating</h6>
                    ) : (
                      <h6>Inactive</h6>
                    )}
                  </Col>
                </Row>
                <Row className="profile-details">
                  <Col className="col-1" sm={4}>
                    <li>Web</li>
                  </Col>
                  <Col className="col-2" sm={8}>
                    <h6 className="web" style={{textAlign:"left"}}>
                      <a href={`${Life.web_link}`} target="_blank">
                        {Life.web_link}
                      </a>
                    </h6>
                  </Col>
                </Row>
              </div>
            )}
          </Card.Body>
        </Card>
      </div>
      <div>
        {Life.ad_space_bottom_left ? (
          <div>
            <Link
              to={leftSideBottomAdRedirectUrl}
              target="_blank"
              rel="Advertiesement link"
            >
              <img className="add-image" src={Life.ad_space_bottom_left}></img>
            </Link>
          </div>
        ) : (
          <Link to="/contact-us" target="_blank" rel="Advertiesement link">
            <img className="google-add" src={images.googleAdd3}></img>
          </Link>
        )}
        <div className="ad-word">Ad</div>
      </div>
    </div>
  );
};

export default LifeLeft;
