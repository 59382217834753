import { createRequest } from '../../utils/requestHandler';
import { setGeneralAds } from '../slices/GeneralSlice';
const mooflife_backend = process.env.REACT_APP_BACK_END_URL;

export const getGeneralAds = () => {
    return async (dispatch) => {
      try {
        const configs = {
            headers: {
                'Content-Type': 'application/json',
              },
          }
    
          const response = await createRequest('get', `${mooflife_backend}/general/ads`, null, configs);
          if(response && response.data && response.data.success){
            dispatch(setGeneralAds(response.data.data))
          }
       
      } catch (error) {
        console.error('Error getting General Advertisements:', error);
      }
    };
  };