import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Navbar from "react-bootstrap/Navbar";
import "../../styles/LandingPage/NavigationBarStyle.css";
import { images } from "../../utils/image";
import {
  getFromSessionStorage,
  removeLoginDataFromStorage,
} from "../../utils/storageHandler";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getLivesForUser,
  getSearchSuggestion,
} from "../../store/action/LifeAction";
import { handleUserLogged } from "../../store/action/userInteractionsAction";
import Modal from "react-bootstrap/Modal";
import Zoom from "react-medium-image-zoom";
import { getAuthorizedEmails } from "../../store/action/MomentAction";
import { useLocation } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

const NavigationBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [user, setUser] = useState("");
  const [userLogin, setUserLogin] = useState("");
  const [themeColor, setThemeColor] = useState("");
  const [initials, setInitials] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [words, setWords] = useState("");
  const inputDropdownRef = useRef(null);
  const [lifeReadingView, setLifeReadingView] = useState(false);
  const [show, setShow] = useState(false);

  const [showCreateDropdown, setShowCreateDropdown] = useState(false);
  const [livesForUser, setLivesForUser] = useState("");
  const [mooflifeAdmin, setMooflifeAdmin] = useState(false);
  const dropdownRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const Life = useSelector((state) => state.Life.Life);
  const currentUrl = window.location.href;

  const searchSuggetion = useSelector((state) => state.Life.searchSuggetion);

  useEffect(() => {
    const userDetails = getFromSessionStorage("userDetails", true);
    setUserLogin(userDetails);
    const themeColor = userDetails ? userDetails.theme_colour : "";
    setThemeColor(themeColor);
    const user = userDetails ? userDetails.first_name : "";
    setUser(user);
    const firstInitial = userDetails ? userDetails.first_name.charAt(0) : "";
    const lastInitial =
      userDetails && userDetails.last_name
        ? userDetails.last_name.charAt(0)
        : "";
    var initials = firstInitial + lastInitial;
    setInitials(initials);
    const imageUrl = userDetails ? userDetails.image_url : "";
    setImageUrl(imageUrl);

    if (userDetails && userDetails.email) {
      requestLivesForUser(userDetails);
      checkAuthorizedEmails(userDetails);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        inputDropdownRef.current &&
        !inputDropdownRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }

      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowCreateDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [setShowDropdown, setShowCreateDropdown]);

  const [lifeContentView, setLifeContentView] = useState(false);
  const isLifePage = Life.life_id ? true : false;

  useEffect(() => {
    const { pathname } = location;
    if (currentUrl.includes("/read/") && isLifePage === true) {
      setLifeContentView(true);
    } else if (isLifePage === true) {
      setLifeReadingView(true);
    } else {
      setLifeReadingView(false);
    }
  }, [Life.life_id]);

  const getSuggestionWhenClick = () => {
    if (showDropdown === false && !words.length > 0) {
      dispatch(
        getSearchSuggestion({
          count: 0,
          user_email: userLogin?.email ? userLogin.email : "",
        })
      );
    }
    setShowDropdown((prevState) => !prevState);
  };

  const handleSearchSuggestion = (input) => {
    dispatch(
      getSearchSuggestion({
        input: input,
        user_email: userLogin?.email ? userLogin.email : "",
      })
    );
  };

  const handleDropdownItem = (lifeId, verifiedName) => {
    // navigate(`/${lifeId}`);
    // if(isLifePage === true){
    //   navigate('/');
    // }
    // else{
      navigate(`/${verifiedName ? verifiedName : lifeId}`);
    // }
  };

  const logout = () => {
    setLoading(true);
    if (user) {
      setShow(true);

      setTimeout(() => {
        setLoading(false);
      }, 2000);
      removeLoginDataFromStorage();
      setUser("");
      setInitials("");
      setImageUrl("");
      dispatch(handleUserLogged(false));
      setTimeout(() => {
        setShow(false);
        navigate("/");
      }, 3000);
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const navigateMomentCreate = (lifeData) => {
    if (lifeData) {
      const lifeInState = { lifeData: lifeData };
      navigate("/create-moment", { state: lifeInState });
    }
  };

  const checkAuthorizedEmails = async (userDetails) => {
    if (userDetails && userDetails.email) {
      dispatch(
        await getAuthorizedEmails({ userEmail: userDetails.email })
      ).then((res) => {
        if (res && res.success && res.data) {
          setMooflifeAdmin(res.data.result);
        }
      });
    }
  };

  const requestLivesForUser = async (userDetails) => {
    dispatch(await getLivesForUser({ email: userDetails.email })).then(
      (res) => {
        if (res && res.success && res.data) {
          setLivesForUser(res.data);
        }
      }
    );
  };

  return (
    <div className="navbar-main">
      {/* Pop up */}
      <Modal className="popup-modal" show={show} onHide={handleClose}>
        <div className="popup-header">
          <div className="logo-icon">
            <img src={images.MoofIcon} alt="moofIcon" />
          </div>
        </div>

        <Modal.Body>
          <div className="title">
            {loading ? (
              <Spinner
                animation="border"
                variant="dark"
                style={{ width: "15px", height: "15px" }}
              />
            ) : (
              "Successfully Logout"
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Navbar
        collapseOnSelect
        expand="lg"
        className="bg-body-tertiary"
        fixed="top"
        style={{
          width:
            lifeReadingView === true || lifeContentView === true
              ? "99%"
              : "100%",
        }}
        //style={{ width: isLifePage && lifeReadingView ? "99%" : "100%" }}
      >
        <Container>
          <Navbar.Brand>
            <a href="/">
              <img src={images.moofLogo} className="logo" />
            </a>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav className="navbar-items">
              {!showDropdown && (
                <>
                  <Link to="/about-us">
                    <div className="links">About</div>
                  </Link>
                  <Link to="/mooflife-concept">
                    <div className="links">MoofLife Concept</div>
                  </Link>
                </>
              )}

              <>
                {lifeContentView ? (
                  <Link
                    to={`/${
                      Life.verified_name ? Life.verified_name : Life.life_id
                    }`}
                  >
                    <div className="links">Content View</div>
                  </Link>
                ) : lifeReadingView === true ? (
                  <Link
                    to={`/read/${
                      Life.verified_name ? Life.verified_name : Life.life_id
                    }`}
                  >
                    <div className="links">Reading View</div>
                  </Link>
                ) : (
                  <Link to="/read">
                    <div className="links">Reading View</div>
                  </Link>
                )}

                {mooflifeAdmin && (
                  <div
                  className="links"
                  onClick={() => setShowCreateDropdown(true)}
                  ref={dropdownRef}
                >
                  <b style={{ color: "#002060" }}>Create Moments</b>
                </div>
                )}
              </>

              {showCreateDropdown && (
                <div className="create-content">
                  <div>
                    {lifeReadingView !== false && isLifePage === true && (
                      <div
                        className="life-button"
                        onClick={() => navigateMomentCreate(Life)}
                      >
                        Create a Moment on{" "}
                        {Life.life_name.length > 15
                          ? Life.life_name.slice(0, 15) + "..."
                          : Life.life_name}
                      </div>
                    )}

                    <p className="dropdown-topic">
                      {userLogin
                        ? livesForUser.length > 1
                          ? "Select a life to create moments..."
                          : "You do not have a Life profile to create a Moment.."
                        : "You need to Login to create a Life Moment..."}
                    </p>

                    {livesForUser &&
                      livesForUser.length > 0 &&
                      livesForUser.map((life) => {
                        return (
                          <div className="dropdown-item" onClick={() => navigateMomentCreate(life)}>
                            <Zoom>
                              <img
                                src={
                                  life.life_logo
                                    ? life.life_logo
                                    : images.UserImage
                                }
                                className="create-dropdown-img"
                              ></img>
                            </Zoom>

                            <div>
                              <div
                                className="life-name"
                                onClick={() => navigateMomentCreate(life)}
                              >
                                {life.life_name.length > 30
                                  ? life.life_name.slice(0, 30) + "..."
                                  : life.life_name}
                              </div>
                              <div className="life-tag">
                                {life.life_title.length > 30
                                  ? life.life_title.slice(0, 30) + "..."
                                  : life.life_title}
                              </div>
                            </div>
                          </div>
                        );
                      })}

                    {userLogin ? (
                      <div className="dropdown-item">
                        {mooflifeAdmin && (
                          <>
                            <i
                              class="bi bi-plus-circle-fill create-dropdown-img"
                              style={{ color: "#DFEAFF", fontSize: "40px" }}
                            ></i>
                            <div>
                              <div className="new-life-link">
                                <Link to="/select-package">
                                  Create a new Life Profile
                                </Link>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    ) : (
                      <div>
                        <div className="dropdown-item">
                          <div>
                            <img
                              src={images.UserImage}
                              className="create-dropdown-img"
                            ></img>
                          </div>
                          <div>
                            <div>
                              <Link className="link-tag" to="/login">
                                Log In&nbsp;
                              </Link>{" "}
                              |
                              <Link className="link-tag" to="/sign-up">
                                {" "}
                                &nbsp;Sign Up
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div>
                <div className="search-form" ref={inputDropdownRef}>
                  <input
                    id="searchText"
                    className={`search ${
                      showDropdown || words.length > 0 ? "expanded" : "search"
                    }`}
                    type="text"
                    placeholder="Life Search"
                    autocomplete="one-time-code"
                    value={words}
                    onChange={(e) => {
                      setShowDropdown(true);
                      setWords(e.target.value);
                      handleSearchSuggestion(e.target.value);
                    }}
                    onClick={() => {
                      getSuggestionWhenClick();
                    }}
                  />
                  {showDropdown === true &&
                    searchSuggetion &&
                    searchSuggetion.length > 0 && (
                      <div className="search-dropdown-content">
                        {searchSuggetion.map((searchLife, index) => (
                          <div className="d-flex">
                            <div
                              className="search-dropdown-item"
                              onClick={() =>
                                handleDropdownItem(
                                  searchLife.life_id,
                                  searchLife.verified_name
                                )
                              }
                            >
                              <div>
                                <img
                                  src={searchLife.life_logo}
                                  className="search-dropdown-user"
                                  alt="lifeLogo"
                                ></img>
                              </div>
                              <div className="search-dropdown-name">
                                {searchLife.life_name.length > 30
                                  ? searchLife.life_name.slice(0, 30) + "..."
                                  : searchLife.life_name}
                              </div>
                            </div>
                          </div>
                        ))}
                        <Row>
                          <Col>
                            <Link to="/search" className="advance-search">
                              Advance Search
                            </Link>
                          </Col>
                        </Row>
                      </div>
                    )}
                </div>
              </div>
              <div>
                <div className="dropdown">
                  {user ? (
                    <>
                      {imageUrl ? (
                        <div>
                          <img
                            src={imageUrl}
                            className="user-image"
                            alt="userImage"
                          ></img>
                        </div>
                      ) : (
                        <div
                          className="user-image-letter"
                          id="name"
                          style={{ background: themeColor || "#E0E0E0" }}
                        >
                          {initials}
                        </div>
                      )}

                      <div className="dropdown-content">
                        <div className="d-flex ">
                          <div className="item">
                            <Link to="/about-us" className="pages">
                              About Us
                            </Link>
                          </div>
                        </div>
                        <div className="d-flex ">
                          <div className="item">
                            <Link to="/mooflife-concept" className="pages">
                              MoofLife Concept
                            </Link>
                          </div>
                        </div>
                        <div className="d-flex ">
                          <div className="item">
                            <Link to="/contact-us" className="pages">
                              Contact Us
                            </Link>
                          </div>
                        </div>
                        <div className="d-flex ">
                          <div className="item">
                            <Link to="/user-settings" className="pages">
                              User Settings
                            </Link>
                          </div>
                        </div>
                        <div className="d-flex ">
                          <div className="item" onClick={logout}>
                            <span>
                              <img
                                src={images.logout}
                                width="20px"
                                alt="logout"
                                style={{ marginRight: "5px" }}
                              />
                            </span>
                            <a className="pages" onClick={logout}>
                              Logout
                            </a>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <Link to="/login">
                          <img
                            className="user-image"
                            src={images.UserImage}
                            alt="userImage"
                          ></img>
                        </Link>
                      </div>
                      <div className="dropdown-content">
                        <div className="d-flex dropdown-item menu-items">
                          <div className="item">
                            <span>
                              <img
                                src={images.login}
                                width="20px"
                                alt="login"
                                style={{ marginRight: "5px" }}
                              />
                            </span>
                            <Link className="link-tag1" to="/login">
                              Log In&nbsp;
                            </Link>{" "}
                            &nbsp;|&nbsp;
                            <Link className="link-tag2" to="/sign-up">
                              {" "}
                              &nbsp;Sign Up
                            </Link>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavigationBar;
