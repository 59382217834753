import { createRequest } from '../../utils/requestHandler';
import { getFromSessionStorage } from '../../utils/storageHandler';
import { updateImpressionsCount } from '../slices/MomentSlice';
import { getImpressionSet } from '../slices/ImpressionSetSlice';
import { updateLifePageImpressionsCount } from '../slices/LifeSlice';
const mooflife_backend = process.env.REACT_APP_BACK_END_URL;

export const createImpression = (data) => {
    return async (dispatch) => {
        try {
          const accessToken = await getFromSessionStorage("accessToken");
          const configs = {
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${accessToken}`
                },
          }
    
          const response = await createRequest('post', `${mooflife_backend}/impression`, data, configs);
    
          if (response && response.data.success && response.data.data) {
            if(data?.page === 'life'){
              dispatch(updateLifePageImpressionsCount(response.data.data));
            }else{
              dispatch(updateImpressionsCount(response.data.data));
            }
          } 
        } catch (error) {
            console.error('Error while creating new impression:', error);
        }
    };
};

export const fetchImpressionSet = (id) => {

    return async (dispatch) => {
        try {
          const accessToken = await getFromSessionStorage("accessToken");
          const configs = {
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${accessToken}`
                },
          }
    
          const response = await createRequest('get', `${mooflife_backend}/impression/${id}`,  configs);
    
          if (response && response.data.success && response.data.data) {
            dispatch(getImpressionSet( response.data.data));
          } 
        } catch (error) {
            console.error('Error while getting impression:', error);
        }
    };
  };
  