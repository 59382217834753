import React, { useEffect } from "react";
import "../../styles/LandingPage/FamousBirthdayStyle.css";
import ad4 from "../../assets/LandingPage/ad4.png";
import { images } from "../../utils/image";
import Slider from "react-slick";
import { fetchFamousBdays } from "../../store/action/FamousBdaysAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";

const FamousBirthday = () => {
  const currentDate = new Date();
  const FamousBirthdays = useSelector((state) => state.FamousBdays.FamousBdays);
  const dispatch = useDispatch();

  useEffect(() => {
    const momentDate = moment(currentDate).format("YYYY-MM-DD");
    dispatch(fetchFamousBdays(momentDate));
  }, [dispatch]);

  const beforeChange = function (currentSlide, nextSlide) {};

  const afterChange = function (currentSlide) {};

  const isInfinite = FamousBirthdays.length < 4;

  const isFirstSlideInfinite = FamousBirthdays.length === 1;

  const settings = {
    dots: false,
    infinite: isInfinite ? false : true,
    speed: 1000,
    rows: 3,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 578,
        settings: {
          infinite: isInfinite ? false : true,
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 3,
        },
      },
    ],
    prevArrow: <button onClick={beforeChange}></button>,
    nextArrow: <button onClick={afterChange}></button>,
  };

  const firstSlider = {
    dots: false,
    infinite: isFirstSlideInfinite ? false : true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    prevArrow: <button onClick={beforeChange}></button>,
    nextArrow: <button onClick={afterChange}></button>,
  };

  return (
    <div className="famous-birthday-main">
      {FamousBirthdays.length > 0 && (
        <div className="full-content">
          <div className="google-ad">
            <img src={ad4} alt="google-ad" />
          </div>

          <div className="famous-birthday-content">
            <div className="title">
              <span>Birthday </span> Celebrations
            </div>

            {FamousBirthdays.length <= 3 ? (
              <Slider {...firstSlider} className="first-slider">
                {FamousBirthdays.map((famousBday, index) => (
                  <div key={index}>
                    <div className="d-flex gap-2">
                      <div>
                        <img
                          className="cover-image"
                          src={famousBday.cover_image}
                          alt="image"
                        />
                      </div>

                      <div className="content">
                        <div>
                          <img
                            className="logo-image"
                            src={famousBday.life_logo}
                            alt="image"
                          />
                        </div>

                        <div className="details">
                          <Link
                            to={`/${
                              famousBday.verified_name
                                ? famousBday.verified_name
                                : famousBday.life_id
                            }`}
                          >
                            <div className="life-name">
                              {famousBday.life_name.length > 15
                                ? famousBday.life_name.slice(0, 15) + "..."
                                : famousBday.life_name}
                            </div>
                          </Link>
                          <div className="life-bday">
                            {moment().diff(famousBday.since, "years")} Years
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            ) : (
              <Slider {...settings} className="slider">
                {FamousBirthdays.map((famousBday, index) => (
                  <div className="famous-birthday-card" key={index}>
                    <div className="d-flex gap-2">
                      <div>
                        <img src={famousBday.life_logo} alt="image" />
                      </div>

                      <div>
                        <Link
                          to={`/${
                            famousBday.verified_name
                              ? famousBday.verified_name
                              : famousBday.life_id
                          }`}
                        >
                          <div className="life-name">
                            {famousBday.life_name.length > 15
                              ? famousBday.life_name.slice(0, 15) + "..."
                              : famousBday.life_name}
                          </div>
                        </Link>
                        <div className="life-bday">
                          {moment().diff(famousBday.since, "years")} Years
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default FamousBirthday;
