import React, { useEffect, useRef, useState } from "react";
import "../../styles/SubscriptionStyle.css";
import { images } from "../../utils/image";
import { subscriptionPackages } from "../../utils/SubscriptionPackages.js";
import "../../styles/SelectPackageStyle.css";
import { useDispatch, useSelector } from "react-redux";
import { getLivesForUser, getSubscriptionData, upgradeSubscription, 
  renewSubscription, getSubscriptionLimits, downgradeSubscription } from "../../store/action/LifeAction.js";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Spinner } from "react-bootstrap";
import { getFromSessionStorage } from "../../utils/storageHandler.js";
import Dropdown from "react-bootstrap/Dropdown";

const Subscription = () => {
  const currentDate = new Date();
  const [isRenew, setIsRenew] = useState(false);
  const [isUpgrade, setIsUpgrade] = useState(false);
  const [isDowngrade, setIsDowngrade] = useState(false);
  const [step, setStep] = useState(1);
  const [isSelect, setIsSelect] = useState("");
  const [isButtonSelect, setIsButtonSelect] = useState("");
  const [currentSubscription, setCurrentSubscription] = useState("");
  const [totalPrice, setTotalPrice] = useState("");
  const [livesForUser, setLivesForUser] = useState("");
  const [userLogin, setUserLogin] = useState("");

  const inputDropdownRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [downgradeModal, setDowngradeModal] = useState(false);
  const [downgradeMessage, setDowngradeMessage] = useState(false);
  const [words, setWords] = useState("");
  const [lifeDetails, setLifeDetails] = useState({
    lifeId: "",
    lifeName: "",
    lifeTitle: "",
    lifeLogo: "",
  });
  const [selectLife, setSelectLife] = useState(false);
  const [show, setShow] = useState(false);
  const [lifeSubscription, setLifeSubscription] = useState(null);
  const [upgradePlan, setUpgradePlan] = useState(null);
  const [loading, setLoading] = useState(false);
  const [subscriptionLimits, setSubscriptionLimits] = useState([]);
  const [currentPrice, setCurrentPrice] = useState("");
  const [downgradeTotalPrice, setDowngradeTotalPrice] = useState("");
  const [downgradePlan, setDowngradePlan] = useState(null);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        inputDropdownRef.current &&
        !inputDropdownRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };

  }, []);

  useEffect(() => {
    const userDetails = getFromSessionStorage("userDetails", true);
    setUserLogin(userDetails);

    if (userDetails && userDetails.email) {
      requestLivesForUser(userDetails);
      requestSubscriptions();
    }
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const requestSubscriptions = async () => {
    const subscriptions = await dispatch(getSubscriptionLimits());
    if(subscriptions.success && subscriptions.data){
      setSubscriptionLimits(subscriptions.data)
    }
  };

  const selectRenew = async () => {
    setLoading(true);
    if(lifeDetails.lifeId){
      const renewRes = await dispatch(renewSubscription({lifeId: lifeDetails.lifeId}));
      if(renewRes && renewRes.success){
        setShow(false);
      }
    }
  };

  const selectUpgrade = () => {
    setIsUpgrade(true);
    setStep(2);
    getCurrentPackagePrice();
  };

  const selectDowngrade = () => {
    setIsDowngrade(true);
    setStep(3);
    getCurrentPackagePrice();
  };


  const handleBack = () => {
    setStep(1);
    setIsUpgrade(false);
    setDowngradeModal(false);

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleButtonClick = (subscription, price) => {
    setIsSelect(subscription);
    setIsButtonSelect(subscription);

    setIsSelect(subscription);
    handlePlan(subscription, price);
  };

  const getCurrentPackagePrice = () => {
  
    const planName = lifeSubscription.subscription.type === "user" || lifeSubscription.subscription.plan !== "Free"
      ? lifeSubscription.subscription.plan.slice(3) 
      : lifeSubscription.subscription.plan;
  
    const currentPackage = subscriptionLimits.find(
      (packageName) => packageName.plan === planName
    );
  
    const currentPrice = currentPackage ? currentPackage.price : 0;
    setCurrentPrice(currentPrice);
  
    return currentPrice;
  };
  

  const handlePlan = (plan, price) => {
    console.log(plan,price)
    setTotalPrice(price);
    setUpgradePlan(plan);
  };

  const handleDropdownItem = async (lifeId, lifeName, logo, title) => {
    const subscriptionData = await dispatch(getSubscriptionData({lifeId: lifeId}));
    if(subscriptionData && subscriptionData.success && subscriptionData.data){
      setLifeSubscription(subscriptionData.data);
    }else {
      setLifeSubscription(null);
    }

    setLifeDetails({
      lifeId: lifeId,
      lifeName: lifeName,
      lifeTitle: title,
      lifeLogo: logo
    });
    setShowDropdown(false);
    setSelectLife(true);
  };

  const requestLivesForUser = async (userDetails) => {
    dispatch(await getLivesForUser({ email: userDetails.email })).then(
      (res) => {
        if (res.success && res.data) {
          setLivesForUser(res.data);
        }
      }
    );
  };

  const processUpgrade = async () => {
    if(lifeSubscription.subscription && lifeSubscription.subscription.plan){
      const newPlanName = lifeDetails.lifeId.substring(0, 2);
      console.log('newPlanName : ', newPlanName)
      const reqData = {
        currentPlan: lifeSubscription.subscription.plan,
        lifeId: lifeDetails.lifeId,
        newPlan: `${newPlanName}-${upgradePlan}`
      }

      const upgradedSubscription = await dispatch(upgradeSubscription(reqData));
      console.log('upgradedSubscription : ', upgradedSubscription);
      if(upgradedSubscription && upgradedSubscription.success && upgradedSubscription.data){
        // success
        window.location.href = upgradedSubscription.data.url;
      }else {
        // error subscription update failed!
      }
    }
  }
  const handledownPackageButtonClick = (subscription, price) => {
    setIsSelect(subscription);
    setIsButtonSelect(subscription);
    handleDowngradePlan(subscription, price);
  };

  const handleDowngradePlan = (plan, price) => {
    console.log(price,plan)
    setDowngradeTotalPrice(price);
    setDowngradePlan(plan);
  };

  const processDowngrade = async () => {
    if(lifeSubscription.subscription && lifeSubscription.subscription.plan){
      const newPlanName = lifeDetails.lifeId.substring(0, 2);
      console.log('newPlanName : ', newPlanName)
      const newPlan = downgradePlan === "Free"?"Free":`${newPlanName}-${downgradePlan}`;
      const reqData = {
        currentPlan: lifeSubscription.subscription.plan,
        lifeId: lifeDetails.lifeId,
        newPlan:newPlan,// `${newPlanName}-${upgradePlan}`
      }

      const downgradedSubscription = await dispatch(downgradeSubscription(reqData));
      console.log('upgradedSubscription : ', downgradedSubscription);
      if(downgradedSubscription && downgradedSubscription.success && downgradedSubscription.data){
        // success
        setShow(false);
        handleBack();
        setDowngradeMessage("Successfully Downgraded !");
        setShow(false);
        setDowngradeModal(true);
       // window.location.href = downgradedSubscription.data.url;
      }else {
        // error subscription update failed!
        setDowngradeMessage("Something went wrong !");
        setShow(false);
        setDowngradeModal(true);
      }
    }
  }

  const endDateString =
    lifeSubscription && lifeSubscription.subscription
      ? lifeSubscription.subscription.end_date
      : "";
  const endDate = new Date(endDateString);
  const timeDiff = endDate.getTime() - currentDate.getTime();
  const diffInDays = Math.round(timeDiff / (1000 * 3600 * 24));

  const validDate = new Date(lifeSubscription?.subscription?.end_date);
  const formattedDate = validDate.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  });


  return (
    <div>
      <div className="subscription-main">
        <div className="title">
          {step === 2
            ? "Upgrade Subscription Plan"
            : step === 3
            ? "Downgrade Subscription Plan"
            : "Select Life Profiles"}
        </div>

        {step === 1 && (
          <div className="main-content" style={{ height: "100vh" }}>
            <div>
              <div className="create-container">
                <div className="select-form">
                  <div className="search-menu-btn" ref={inputDropdownRef}>
                    <div>
                      <div className="dropdown" ref={dropdownRef}>
                        <div
                          className="create-div"
                          type="text"
                          readonly
                          onClick={() =>
                            setShowDropdown((prevState) => !prevState)
                          }
                        >
                          Select Your Life
                        </div>
                        {showDropdown && (
                          <div className="dropdown-content">
                            {livesForUser &&
                              livesForUser.length > 0 &&
                              livesForUser.map((life) => {
                                return (
                                  <div className="dropdown-item">
                                    <img
                                      src={
                                        life.life_logo
                                          ? life.life_logo
                                          : images.UserImage
                                      }
                                      className="create-dropdown-img"
                                    ></img>

                                    <div>
                                      <div
                                        className="life-name"
                                        onClick={() =>
                                          handleDropdownItem(
                                            life.life_id,
                                            life.life_name,
                                            life.life_logo,
                                            life.life_title
                                          )
                                        }
                                      >
                                        {life.life_name}
                                      </div>
                                      <div className="life-tag">
                                        {life.life_title ? life.life_title : ""}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {selectLife && !showDropdown && (
                <div className="d-flex gap-3 mt-4 align-items-center">
                  <div>
                    <img src={lifeDetails.lifeLogo} className="user-img" />
                  </div>

                  <div>
                    <div className="life-name">{lifeDetails.lifeName}</div>
                    <div className="life-title">{lifeDetails.lifeTitle}</div>
                  </div>
                </div>
              )}
            </div>

            {selectLife && !showDropdown && (
              <div className="subscription-details">
                <div className="d-flex gap-3 align-items-center current-sub">
                  <div>
                    <div className="current-subscription">Current Subscription</div>
                    <div className="valid-date">Valid Until : {formattedDate}</div>
                  </div>
                  <div className="package-name">
                    Life
                    {lifeSubscription.subscription.plan === "Free" ? "Basic" : lifeSubscription.subscription.plan.slice(3)}
                  </div>
                </div>

                <div className="d-flex gap-3 justify-content-end mt-4">
                  {lifeSubscription.subscription.plan !== "Free" && (
                    <div className="renew" onClick={() => setShow(true)}>
                      Renew
                    </div>
                  )}
                  <div className="upgrade" onClick={selectUpgrade}>
                    Upgrade
                  </div>
                  <div>
                    {lifeSubscription.subscription.plan !== "Free" && (
                      <Dropdown>
                        <Dropdown.Toggle>
                          <i class="bi bi-three-dots-vertical"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item onClick={selectDowngrade}>
                            Downgrade Package
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {step === 2 && (
          <>
            <div className="back" onClick={handleBack}>
              <i class="bi bi-chevron-double-left"></i>Back
            </div>
            <div className="main-content">
              <div>
                <div className="d-flex gap-3 mt-4">
                  <div>
                    <img src={lifeDetails.lifeLogo} className="user-img" />
                  </div>

                  <div>
                    <div className="life-name">{lifeDetails.lifeName}</div>
                    <div className="life-title">{lifeDetails.lifeTitle}</div>
                  </div>
                </div>
              </div>

              <div>
                <div className="d-flex gap-5 align-items-end upgrade-des">
                  <div>
                    <div className="subscription-year">
                      Subscription for{" "}
                      {lifeSubscription.subscription.start_date.split("-")[0]}
                    </div>
                  </div>
                  <div>
                    <div className="current-subscription">
                      Current Subscription
                    </div>
                    <div className="package-name">
                      Life{lifeSubscription.subscription.plan === "Free" ? "Basic" : lifeSubscription.subscription.plan.slice(3)}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="packages">
              <div className="select-package-main">
                <div className="package-set">
                  <div className="details">
                    <div className="top-box"></div>
                    <div className="features">
                      <div className="feature-name">Moments Per Year</div>
                      <div className="feature-name">Images Per Moment</div>
                      <div className="feature-name">Videos Per Moment</div>
                      <div className="feature-name">Life Together</div>
                      <div className="feature-name">Timeline </div>
                      <div className="feature-name">Life Support (email)</div>
                      <div className="feature-name">
                        Clean life (No Ads on your life page)
                      </div>
                    </div>
                  </div>

                  <div className="package-rows">
                    {subscriptionLimits.map((subscription, index) => {
                      if (subscription.type === "user") {
                        const packageInfo = subscriptionPackages.find(
                          (pkg) => pkg.plan === subscription.plan
                        );

                        const subscriptionPrice = parseFloat(
                          subscription.price
                        );
                        const currentPriceNumber = parseFloat(currentPrice);

                        return (
                          subscriptionPrice > currentPriceNumber && (
                            <div
                              key={index}
                              className="package"
                              onClick={() =>
                                handleButtonClick(
                                  subscription.plan,
                                  subscription.price
                                )
                              }
                              style={{
                                opacity:
                                  isSelect === subscription.plan ? 1 : 0.5,
                                cursor: "pointer",
                                background:
                                  isSelect === subscription.plan
                                    ? packageInfo.package_background_color
                                    : "white",
                              }}
                            >
                              <div className="top-box">
                                <div className="package-name">
                                  Life{subscription.plan}
                                </div>
                                <div className="starting-from">
                                  <div>
                                    {" "}
                                    {subscription.price === "0"
                                      ? "\u00A0"
                                      : "Starting From"}
                                  </div>
                                </div>
                                <div className="price">
                                  {subscription.price === "0"
                                    ? "Free"
                                    : `$ ${subscription.price}`}

                                  {subscription.price !== "0" && (
                                    <span style={{ fontSize: "12px" }}>
                                      {" "}
                                      / year
                                    </span>
                                  )}
                                </div>

                                <div className="detail-box">
                                  <div className="desc">
                                    {packageInfo.package_description}
                                  </div>
                                </div>
                                <button
                                  className="select-btn"
                                  style={{
                                    background:
                                      isSelect === subscription.plan
                                        ? packageInfo.package_theme_color
                                        : "white",
                                    border: `2px solid ${packageInfo.package_theme_color}`,
                                    color:
                                      isSelect === subscription.plan
                                        ? packageInfo.package_theme_dark_color
                                        : packageInfo.package_theme_color,
                                  }}
                                >
                                  <div className="d-flex gap-2 align-items-center">
                                    {isSelect === subscription.plan && (
                                      <span>
                                        <img src={images.check} />
                                      </span>
                                    )}
                                    Select
                                  </div>
                                </button>
                              </div>

                              <div className="feature-counts">
                                <div className="count">
                                  {subscription.moments_per_year}
                                </div>
                                <div className="count">
                                  {subscription.images_per_moment}
                                </div>
                                <div className="count">
                                  {subscription.videos_per_moment}
                                </div>
                                <div className="count">
                                  {subscription.life_together}
                                </div>
                                <div className="count">
                                  {subscription.timeline === true ? (
                                    <img className="icon" src={images.check} />
                                  ) : (
                                    <img className="icon" src={images.cancel} />
                                  )}
                                </div>
                                <div className="count">
                                  {subscription.life_support_email === true ? (
                                    <img className="icon" src={images.check} />
                                  ) : (
                                    <img className="icon" src={images.cancel} />
                                  )}
                                </div>
                                <div className="count">
                                  {subscription.clean_life === true ? (
                                    <img className="icon" src={images.check} />
                                  ) : (
                                    <img className="icon" src={images.cancel} />
                                  )}
                                </div>
                              </div>

                              <div>
                                <button
                                  className="price-btn"
                                  style={{
                                    background: `${packageInfo.package_theme_dark_color}`,
                                    border: `2px solid ${packageInfo.package_theme_dark_color}`,
                                    color: "white",
                                  }}
                                  onClick={() =>
                                    handlePlan(
                                      subscription.plan,
                                      subscription.price
                                    )
                                  }
                                >
                                  $ {subscription.price}.00
                                </button>
                              </div>
                            </div>
                          )
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex gap-5 p-5 pt-0 total-des">
              <div className="subscribe-word">Subscribed Life{isSelect}</div>
              <div className="text-center">
                <div className="total">
                  Total <span>$ {totalPrice}.00</span>
                </div>
                <button
                  className="upgrade-btn"
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  Upgrade
                </button>
              </div>
            </div>
          </>
        )}

        {step === 3 && (
          <>
            <div className="back" onClick={handleBack}>
              <i class="bi bi-chevron-double-left"></i>Back
            </div>
            <div className="main-content">
              <div>
                <div className="d-flex gap-3 mt-4">
                  <div>
                    <img src={lifeDetails.lifeLogo} className="user-img" />
                  </div>

                  <div>
                    <div className="life-name">{lifeDetails.lifeName}</div>
                    <div className="life-title">{lifeDetails.lifeTitle}</div>
                  </div>
                </div>
              </div>

              <div>
                <div className="d-flex gap-5 align-items-end upgrade-des">
                  <div>
                    <div className="subscription-year">
                      Subscription for{" "}
                      {lifeSubscription.subscription.start_date.split("-")[0]}
                    </div>
                  </div>
                  <div>
                    <div className="current-subscription">
                      Current Subscription
                    </div>
                    <div className="package-name">
                      Life{lifeSubscription.subscription.plan === "Free" ? "Basic" : lifeSubscription.subscription.plan.slice(3)}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="packages">
              <div className="select-package-main">
                <div className="package-set">
                  <div className="details">
                    <div className="top-box"></div>
                    <div className="features">
                      <div className="feature-name">Moments Per Year</div>
                      <div className="feature-name">Images Per Moment</div>
                      <div className="feature-name">Videos Per Moment</div>
                      <div className="feature-name">Life Together</div>
                      <div className="feature-name">Timeline </div>
                      <div className="feature-name">Life Support (email)</div>
                      <div className="feature-name">
                        Clean life (No Ads on your life page)
                      </div>
                    </div>
                  </div>

                  <div className="package-rows">
                    {subscriptionLimits.map((subscription, index) => {
                      if (subscription.type === "user") {
                        const packageInfo = subscriptionPackages.find(
                          (pkg) => pkg.plan === subscription.plan
                        );

                        const subscriptionPrice = parseFloat(
                          subscription.price
                        );
                        const currentPriceNumber = parseFloat(currentPrice);

                        return (
                          subscriptionPrice < currentPriceNumber && (
                            <div
                              key={index}
                              className="package"
                              onClick={() =>
                                handledownPackageButtonClick(
                                  subscription.plan,
                                  subscription.price
                                )
                              }
                              style={{
                                opacity:
                                  isSelect === subscription.plan ? 1 : 0.5,
                                cursor: "pointer",
                                background:
                                  isSelect === subscription.plan
                                    ? packageInfo.package_background_color
                                    : "white",
                              }}
                            >
                              <div className="top-box">
                                <div className="package-name">
                                  Life{subscription.plan}
                                </div>
                                <div className="starting-from">
                                  <div>
                                    {" "}
                                    {subscription.price === "0"
                                      ? "\u00A0"
                                      : "Starting From"}
                                  </div>
                                </div>
                                <div className="price">
                                  {subscription.price === "0"
                                    ? "Free"
                                    : `$ ${subscription.price}`}

                                  {subscription.price !== "0" && (
                                    <span style={{ fontSize: "12px" }}>
                                      {" "}
                                      / year
                                    </span>
                                  )}
                                </div>

                                <div className="detail-box">
                                  <div className="desc">
                                    {packageInfo.package_description}
                                  </div>
                                </div>
                                <button
                                  className="select-btn"
                                  style={{
                                    background:
                                      isSelect === subscription.plan
                                        ? packageInfo.package_theme_color
                                        : "white",
                                    border: `2px solid ${packageInfo.package_theme_color}`,
                                    color:
                                      isSelect === subscription.plan
                                        ? packageInfo.package_theme_dark_color
                                        : packageInfo.package_theme_color,
                                  }}
                                >
                                  <div className="d-flex gap-2 align-items-center">
                                    {isSelect === subscription.plan && (
                                      <span>
                                        <img src={images.check} />
                                      </span>
                                    )}
                                    Select
                                  </div>
                                </button>
                              </div>

                              <div className="feature-counts">
                                <div className="count">
                                  {subscription.moments_per_year_count}
                                </div>
                                <div className="count">
                                  {subscription.images_per_moment_count}
                                </div>
                                <div className="count">
                                  {subscription.videos_per_moment_count}
                                </div>
                                <div className="count">
                                  {subscription.life_together_count}
                                </div>
                                <div className="count">
                                  {subscription.timeline === "true" ? (
                                    <img className="icon" src={images.check} />
                                  ) : (
                                    <img className="icon" src={images.cancel} />
                                  )}
                                </div>
                                <div className="count">
                                  {subscription.life_support_email ===
                                  "true" ? (
                                    <img className="icon" src={images.check} />
                                  ) : (
                                    <img className="icon" src={images.cancel} />
                                  )}
                                </div>
                                <div className="count">
                                  {subscription.clean_life === "true" ? (
                                    <img className="icon" src={images.check} />
                                  ) : (
                                    <img className="icon" src={images.cancel} />
                                  )}
                                </div>
                              </div>

                              <div>
                                <button
                                  className="price-btn"
                                  style={{
                                    background: `${packageInfo.package_theme_dark_color}`,
                                    border: `2px solid ${packageInfo.package_theme_dark_color}`,
                                    color: "white",
                                  }}
                                  onClick={() =>
                                    handleDowngradePlan(
                                      subscription.plan,
                                      subscription.price
                                    )
                                  }
                                >
                                  $ {subscription.price}.00
                                </button>
                              </div>
                            </div>
                          )
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end gap-5 p-5 pt-0 total-des">
              <div className="subscribe-word">Subscribed Life{isSelect}</div>
              <div className="text-center">
                <div className="total">
                  Total <span>$ {downgradeTotalPrice}.00</span>
                </div>
                <button className="upgrade-btn" onClick={() => setShow(true)}>
                  Downgrade
                </button>
              </div>
            </div>
          </>
        )}

        <div>
          <Modal className="popup-modal" show={show} centered backdrop="static">
            <div className="popup-header">
              <div className="logo-icon">
                <img src={images.MoofIcon} />
              </div>
            </div>
            <Modal.Body className="modal-body">
              {step == 1 ? (
                <>
                  <div className="">Your subscription will be renewed for one more year. </div>
                  <button className="yellow-btn" onClick={selectRenew}>
                    {loading ? (
                      <Spinner
                        animation="border"
                        variant="light"
                        style={{ width: "15px", height: "15px" }}
                      />
                    ) : (
                      "OK"
                    )}
                  </button>
                  <button
                    className="yellow-btn"
                    onClick={() => setShow(false)}
                    disabled={loading}
                  >
                    Cancel
                  </button>
                </>
              ) : (
                <>
                  {step === 2 ? (
                    <>
                      <div className="">
                        You are about to unlock the benefits of the 'Life {isSelect}' subscription. You will be directed to the payment page
                      </div>
                      <button
                        className="yellow-btn"
                        onClick={() => {
                          processUpgrade();
                        }}
                        disabled={!isSelect}
                      >
                        {loading ? (
                          <Spinner
                            animation="border"
                            variant="light"
                            style={{ width: "15px", height: "15px" }}
                          />
                        ) : (
                          "OK"
                        )}
                      </button>
                    </>
                  ) : (
                    <>
                      <div className="">
                      Are you sure you want to downgrade your current subscription?
                      </div>
                      <button
                        className="yellow-btn"
                        onClick={() => {
                          processDowngrade();
                        }}
                      >
                        {loading ? (
                          <Spinner
                            animation="border"
                            variant="light"
                            style={{ width: "15px", height: "15px" }}
                          />
                        ) : (
                          "Yes"
                        )}
                      </button>
                    </>
                  )}

                  <button
                    className="yellow-btn"
                    onClick={() => setShow(false)}
                    disabled={loading}
                  >
                    No
                  </button>
                </>
              )}
            </Modal.Body>
          </Modal>
        </div>

        <div>
          <Modal
            className="popup-modal"
            show={downgradeModal}
            centered
            backdrop="static"
          >
            <div className="popup-header">
              <div className="logo-icon">
                <img src={images.MoofIcon} />
              </div>
            </div>
            <Modal.Body className="modal-body">
              <div>
              {downgradeMessage}
              </div>
              

              <button className="yellow-btn" onClick={handleBack}>
                Close
              </button>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
