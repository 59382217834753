import { createSlice } from "@reduxjs/toolkit";

const LifeSlice = createSlice({
    name: "Life",
    initialState: {
        Life: {},
        LifeMoments: [],
        NewLives: [],
        Page: 1,
        limitReached: false,
        lifeMomentSearchValues: {},
        lifeMomentsLoading: false,
        currentPage: 0,
        searchSuggetion: {},
        momentSorting: -1
    },
    reducers: {
        clearLifeMoments: (state, action) => {
            state.LifeMoments = []; 
        },

        setLife: (state, action) => {
            state.Life = action.payload; 
        },

        setLifeMoments: (state, action) => {
            action.payload.map(moment => {
                if (!state.LifeMoments.some(item => item._id === moment._id)) {
                    state.LifeMoments.push(moment);
                }
            })
        },

        getNewLives: (state, action) => {
            state.NewLives = action.payload;
        },

        setLifeMomentsFromSearch: (state, action) => {
            state.LifeMoments = action.payload;
        },

        setPage: (state, action) => {
            state.Page = action.payload
        },

        setLimitReached: (state, action) => {
            state.limitReached = action.payload
        },

        setLifeMomentLoading: (state, action) => {
            state.lifeLoading = action.payload
        },

        setCurrentPage: (state, action) => {
            state.currentPage = action.payload
        },

        setLifeMomentSearchCriteria: (state, action) => {
            state.lifeMomentSearchValues = action.payload
        },

        setSearchSuggestion: (state, action) => {
            state.searchSuggetion = action.payload;
        },
        updateLifePageImpressionsCount: (state, action) => {
            const { _id, reaction_counts, userImpression } = action.payload;
        
            // Convert Moments array to a dictionary 
            const momentsDict = state.LifeMoments.reduce((acc, moment) => {
              acc[moment._id] = moment;
              return acc;
            }, {});
        
            // Update the impressions count for the specified moment
            if (momentsDict.hasOwnProperty(_id)) {
              momentsDict[_id] = {
                ...momentsDict[_id],
                reaction_counts: reaction_counts,
                userImpression: userImpression
              };
            }
        
            // Convert the dictionary back to an array
            const updatedMoments = Object.values(momentsDict);
        
            return {
              ...state,
              LifeMoments: updatedMoments,
            };
        },

        updateLifePageCommentsCount: (state, action) => {
            const updatedMoments = state.LifeMoments.map(moment => {
                if (moment._id === action.payload.momentId) {
                    return {
                        ...moment,
                        number_of_comments: action.payload.count
                    };
                    
                } else {
                    return moment;
                }
            });
        
            return {
                ...state,
                LifeMoments: updatedMoments
            };
        },

        updateFollowersCount: (state, action) => {
            state.Life = {
                ...state.Life,
                no_of_followers: action.payload
            };
        },

        removeLifeMoment: (state, action) => {
            const momentIdToRemove = action.payload;
            state.LifeMoments = state.LifeMoments.filter(
              (item) => item._id !== momentIdToRemove
            );
        },

        setMomentsSorting: (state, action) => {
            state.momentSorting = action.payload
        },

    }
})

export const { 
    setLife, 
    setLifeMoments,
    clearLifeMoments,
    setPage,
    setLimitReached,
    setLifeMomentsFromSearch,
    setLifeMomentLoading,
    setLifeMomentSearchCriteria,
    setCurrentPage,
    setSearchSuggestion,
    updateLifePageImpressionsCount,
    updateLifePageCommentsCount,
    updateFollowersCount,
    removeLifeMoment,
    setMomentsSorting,
    getNewLives
} = LifeSlice.actions;

export default LifeSlice.reducer;