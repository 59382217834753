import { createSlice } from "@reduxjs/toolkit";
import MomentJs from 'moment';

const GenralSlice = createSlice({
    name: "General",
    initialState: {
        Advertisements: [],
        
    },
    reducers: {
        setGeneralAds: (state, action) => {
            const ads = [];
            if(action.payload && action.payload.length > 0){
                action.payload.map((ad) => {
                    if(MomentJs(ad.expire_in).isAfter(MomentJs(), 'day')){
                        ads.push(ad)
                    }
                })
            }
            state.Advertisements = ads; 
        },

    }
})

export const { 
    setGeneralAds, 
} = GenralSlice.actions;

export default GenralSlice.reducer;