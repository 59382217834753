import { createRequest } from "../../utils/requestHandler";
import { getFromSessionStorage } from "../../utils/storageHandler";
import { updateFollowersCount } from "../slices/LifeSlice";
import { followerList, setPage, deleteFollower } from "../slices/FollowerSlice";
const mooflife_backend = process.env.REACT_APP_BACK_END_URL;

export const doesUserFollowLife = (data) => {
  return async (dispatch) => {
    try {
      const accessToken = await getFromSessionStorage("accessToken");
      const configs = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await createRequest(
        "post",
        `${mooflife_backend}/follow/is-follow`,
        data,
        configs
      );
      if (response && response.data.success) {
        return response.data.data;
      }
    } catch (error) {
      console.error("Error while get follow status:", error);
    }
  };
};

export const addFollower = (data) => {
  return async (dispatch) => {
    try {
      const accessToken = await getFromSessionStorage("accessToken");
      const configs = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await createRequest(
        "post",
        `${mooflife_backend}/follow`,
        data,
        configs
      );
      if (response && response.data.success) {
        dispatch(updateFollowersCount(response.data.data));
      }
    } catch (error) {
      console.error("Error while get follow status:", error);
    }
  };
};

export const removeFollower = (data, page) => {
  return async (dispatch) => {
    try {
      const accessToken = await getFromSessionStorage("accessToken");
      const configs = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await createRequest(
        "post",
        `${mooflife_backend}/follow/remove`,
        data,
        configs
      );
      if (response && response.data.success) {
        if (page === "setting") {
          dispatch(deleteFollower(data.life_id));
        } else {
          dispatch(updateFollowersCount(response.data.data));
        }
      }
    } catch (error) {
      console.error("Error while get follow status:", error);
    }
  };
};

export const getUserFollowingLife = (data, page) => {
  return async (dispatch) => {
    try {
      const accessToken = await getFromSessionStorage("accessToken");
      const configs = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await createRequest(
        "post",
        `${mooflife_backend}/follow/following_life`,
        data,
        configs
      );
      if (response && response.data.success) {
        if (page) {
          dispatch(setPage(page + 1));
        }
        dispatch(followerList(response.data.data));
      }
    } catch (error) {
      console.error("Error while get user following list:", error);
    }
  };
};
