import React from "react";
import { images } from "../utils/image";
import Modal from "react-bootstrap/Modal";

const PopUpModal = ({ show, setShow, title,setPopupModalShow, isMomentDelete , actionModal, setActionModal}) => {
  
  const handleClose = () => {
    if(isMomentDelete === true) {
      setPopupModalShow(false);
    }
    else{
      setShow(false);
    }
  };

  const handleOkBtn = () => {
    setPopupModalShow(false);
    setActionModal(false);
  }

  return (
    <div>
      <Modal className="popup-modal" show={show} onHide={handleClose} centered backdrop="static">
        <div className="popup-header">
          {/* <div className="close-icon" onClick={handleClose}>
            <img src={images.closeIcon}></img>
          </div> */}
          <div className="logo-icon">
            <img src={images.MoofIcon} />
          </div>
        </div>

        <Modal.Body className="modal-body">
          {title} <br></br>

          {actionModal && (
          <button className="yellow-btn" onClick={handleOkBtn}>OK</button>
        )}
        </Modal.Body>
       
        
      </Modal>
    </div>
  );
};

export default PopUpModal;
