import React from "react";
import "../../styles/LandingPage/FooterStyle.css";
import { Link } from "react-router-dom";

const Footer = () => {
  const now = new Date();
  const currentYear = now.getFullYear();

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="new-footer-main">
      <div className="content">
        <div className="icons-copyright">
          <div className="social-icons">
            <a href="https://www.facebook.com/MoofLife" target="_blank">
              <div className="icon">
                <i class="bi bi-facebook"></i>
              </div>
            </a>
            <a href="https://www.pinterest.com/mooflife/" target="_blank">
              <div className="icon">
                <i class="bi bi-pinterest"></i>
              </div>
            </a>
            <a
              href="https://x.com/MoofLife_Moment?fbclid=IwZXh0bgNhZW0CMTAAAR3CDKGf5HODN1BKTYcctk3mrFf_l1zMM73OaS-Wvj9BwaG-SzyurGNquJY_aem_N0FETQ8cr9ny4wjilXXHIg"
              target="_blank"
            >
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="white"
                  class="bi bi-twitter-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                </svg>
              </div>
            </a>
            <a href="https://www.linkedin.com/company/mooflife" target="_blank">
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="white"
                  class="bi bi-linkedin"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
                </svg>
              </div>
            </a>
          </div>
          <div className="copyright">MoofLife Corporation © {currentYear}</div>
        </div>

        <div className="footer-pages">
          <Link to="/about-us" onClick={handleClick}>
            <div className="link">About Us</div>
          </Link>
          <Link to="/mooflife-concept" onClick={handleClick}>
            <div className="link">MoofLife Concept</div>
          </Link>
          <Link to="/contact-us" onClick={handleClick}>
            <div className="link">Contact Us</div>
          </Link>
          <Link to="/privacy-policy" onClick={handleClick}>
            <div className="link">Privacy Policy</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
