import React from "react";
import Modal from "react-bootstrap/Modal";
import "../styles/ImagePrivacyStyle.css";

const ImagePrivacy = (props) => {
  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="image-privacy-main"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="image-privacy-card">
            <div>
              <div className="title">Image Copyrights</div>
              <div className="description">
                Welcome to MoofLife, where we celebrate creativity and respect
                intellectual property. We are committed to adhering to the
                stringent copyright laws that protect artists and their work.
                Our platform features a curated selection of images,
                thoughtfully sourced and displayed with an awareness of their
                copyrighted status.
              </div>
            </div>
            <div>
              <div className="title">Understanding Copyright</div>
              <div className="description">
                Copyright is a legal protection that covers original works of
                authorship, including photographs, graphics, and other visual
                media. These laws ensure that creators retain control over their
                work and receive recognition and compensation for their
                creativity.
              </div>
            </div>
            <div>
              <div className="title">Our Commitment</div>
              <div className="description">
                At MoofLife, we take great care to respect the rights of
                creators. Each image on our platform is accompanied by a note
                indicating the source website of the image, reflecting our
                commitment to ethical content sharing. We believe in
                acknowledging the hard work and talent of external websites and
                artists whose work adds value to our platform.
              </div>
            </div>

            <div>
              <div className="title">Copyright and Usage Rights</div>
              <div className="description">
                At MoofLife, we employ advanced Artificial Intelligence (AI) to
                generate detailed narratives of historical moments, including
                titles, descriptions, and classifications. Accompanying these
                narratives, we display internet search results of images related
                to these moments. We acknowledge that these images may be
                protected by copyright laws, owned by their respective creators
                or holders. Users of the MoofLife platform are advised against
                using these images without obtaining explicit permission from
                the copyright owners. It is the user's responsibility to ensure
                they have the necessary rights or permissions for any further
                use of the content displayed.
              </div>
            </div>

            <div>
              <div className="title">Content Policy</div>
              <div className="description">
                MoofLife is committed to respecting copyright laws and the
                rights of artists and creators. Our platform displays content
                from online search results and does not store copies of these
                images internally. We strive to ensure that the content
                displayed aligns with legal and ethical standards.
              </div>
            </div>

            <div>
              <div className="title">Liability Disclaimer</div>
              <div className="description">
                If you have concerns regarding the copyright of any image
                featured on MoofLife, or if you believe that your copyright has
                been infringed, we encourage you to contact us through our
                'Contact Us' page. We take such matters seriously and are
                dedicated to addressing them promptly and in accordance with
                legal requirements. MoofLife disclaims liability for copyright
                infringement issues arising from the use of images by its users.
              </div>
            </div>

            <div>
              <div className="title">Opt-Out Mechanism for Creators</div>
              <div className="description">
                Copyright owners who wish to opt-out of having their content
                displayed as part of MoofLife's search results can notify us
                through the following methods: <br></br> - Report specific
                content using the yellow color 'MoofLife icon' icon available on
                each moment page. <br></br> - Fill out the form on our 'Contact
                Us' page at
                <a
                  href="/contact-us"
                  style={{ color: "#3787FF", marginLeft: "5px" }}
                >
                  https://www.mooflife.com/Contact-Us
                </a>
                .<br></br> - Send an email to
                <a
                  style={{ color: "#3787FF", marginLeft: "5px" }}
                  href="mailto:contact@mooflife.com"
                >
                  contact@mooflife.com
                </a>{" "}
                with the subject line 'Opt-Out Request'. <br></br> Your request will be
                processed, and the identified content will be removed from our
                search results in a timely manner.
              </div>
            </div>

            <div>
              <div className="title">User Conduct and Responsibilities</div>
              <div className="description">
                As a user of MoofLife, you are part of our effort to respect and
                uphold the rights of content creators. We count on your
                cooperation to maintain a legal and ethical online environment.
                Please refrain from infringing upon the rights of image creators
                and respect the boundaries of fair use.
              </div>
            </div>

            <div>
              <div className="title">Have Questions or Concerns?</div>
              <div className="description">
                If you have any inquiries or concerns regarding the copyright of
                any image featured on MoofLife, or if you believe that an image
                infringes upon your copyright, we invite you to reach out to us.
                Please contact us through our 'Contact Us' page for any such
                issues. We take these matters seriously and are committed to
                resolving them in accordance with the law.
              </div>
            </div>
            <div>
              <div className="title">Your Role in Respecting Copyrights</div>
              <div className="description">
                By using our platform, you join us in our mission to respect and
                uphold the rights of creators. We appreciate your understanding
                and cooperation in helping us maintain a legal and ethical
                online environment.
              </div>
            </div>
            <div className="description">
              Thank you for being a part of MoofLife. Together, we can celebrate
              creativity responsibly.
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ImagePrivacy;
