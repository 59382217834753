import "../styles/OtpVerificationStyle.css";
import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Link, useNavigate } from "react-router-dom";
import { images } from "../utils/image";
import Countdown from "react-countdown";
import { verifyUserOTP, sendOTP, setForgotPasswordDetails } from "../store/action/userInteractionsAction";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getFromSessionStorage } from "../utils/storageHandler";
import "../styles/CommonStyle.css";

const OtpVerification = () => {
  const now = new Date();
  const currentYear = now.getFullYear();
  const targetDate = Date.now() + 5 * 60 * 1000;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  const warning = data && data.warning ? data.warning : false; //if warning true.then show text like this (we detected several unsuccessfull logging attempt from your account. please verify otp from e-mail adders associated with your account)
  const [inputValues, setInputValues] = useState(["", "", "", "", "", ""]);
  const [verificationError, setVerificationError] = useState(false);
  const [isExpired, setIsExpired] = useState(false);

  const forgetPasswordStatus = useSelector(
    (state) => state.UserInteractions.forgotPasswordStatus
  );

  //OTP expired
  useEffect(() => {
    const accessToken = getFromSessionStorage('accessToken');
    if(accessToken){
      // Redirect to the home page if the user already loggedIn
      navigate("/");
    }else if (!forgetPasswordStatus.forgetPassword) {
      // Redirect to the loggin page if the user hasn't completed the forget password process
      navigate("/login");
    }
    const interval = setInterval(() => {
      if (Date.now() > targetDate) {
        setIsExpired(true);
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const handleButton = () => {
    navigate("/new-password");
  };

  const handleInputChange = (index, value) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);

    // Move to the previous input field if the current input field is empty and backspace is pressed
    if (value === "" && index > 0) {
      const prevInput = document.getElementById(`opt${index}`);
      prevInput.focus();
    }

    // Move to the next input field if the current input field is not the last one
    if (index < inputValues.length - 1 && value !== "") {
      const nextInput = document.getElementById(`opt${index + 2}`);
      nextInput.focus();
    }
  };

  const verifyOTPCode = async () => {
    const otpString = inputValues.join("");
    if (data && data.email && otpString) {
      dispatch(await verifyUserOTP({ email: data.email, otp: otpString }))
        .then((res) => {
          if (res) {
            const userCode = res;
            let forgetPasswordData = { ...forgetPasswordStatus };
            forgetPasswordData.otp = true;

            dispatch(setForgotPasswordDetails(forgetPasswordData))
              .then(() => {
                navigate(`/new-password`, {
                  state: { email: data.email, userCode: userCode },
                });
              })
              .catch((error) => {
                console.error("Error in setForgotPasswordDetails:", error);
              });

            setVerificationError(false);
          } else {
            setVerificationError(true);
          }
        })
        .catch((error) => {
          console.error("Error in verification:", error);
        });
    } else {
      console.log("missing data"); //add user notification
    }
  };

  const resendOTP = () => {
    setIsExpired(false);
    dispatch(sendOTP({ email: data.email, forgotPassword: true }));
  };

  return (
    <div>
      <div className="otp-verification-main">
        <Row className="col1">
          <Col sm={7}>
            <Card className="text-center otp-verify-card">
              <div className="otp-verification-container">
                <section className="otp-logo">
                  <img className="logo" src={images.moofLifeLogo}></img>
                </section>
                <div className="title-set">
                  <div className="error-message">
                    {warning && (
                      <div>
                        We detected several unsuccessful logging attempt from
                        your account. Please verify otp from e-mail adders
                        associated with your account
                      </div>
                    )}
                  </div>
                  <p className="title-1">OTP Verification</p>
                  <p className="title-3">
                    OTP expires in{" "}
                    <span>
                      <Countdown
                        date={targetDate}
                        renderer={({ minutes, seconds }) => (
                          isExpired === true ? (
                            <div>00:00</div>
                          ):(
                            <div>
                            0{minutes} : {seconds}
                          </div>
                          )
                        )}
                      />
                    </span>
                  </p>
                  {isExpired === true && (
                    <div className="otp-error-message error-message">
                      OTP expired. Please request to resend it.
                    </div>
                  )}
                </div>
                <div className="input-set">
                  <Row>
                    <Col>
                      <input
                        type="number"
                        id="opt1"
                        maxLength="1"
                        name="opt1"
                        value={inputValues[0]}
                        onChange={(e) => handleInputChange(0, e.target.value)}
                      />
                    </Col>
                    <Col>
                      <input
                        type="number"
                        id="opt2"
                        maxLength="1"
                        name="opt2"
                        value={inputValues[1]}
                        onChange={(e) => handleInputChange(1, e.target.value)}
                      />
                    </Col>
                    <Col>
                      <input
                        type="number"
                        id="opt3"
                        maxLength="1"
                        name="opt3"
                        value={inputValues[2]}
                        onChange={(e) => handleInputChange(2, e.target.value)}
                      />
                    </Col>
                    <Col>
                      <input
                        type="number"
                        id="opt4"
                        maxLength="1"
                        name="opt4"
                        value={inputValues[3]}
                        onChange={(e) => handleInputChange(3, e.target.value)}
                      />
                    </Col>
                    <Col>
                      <input
                        type="text"
                        id="opt5"
                        maxLength="1"
                        name="opt5"
                        value={inputValues[4]}
                        onChange={(e) => handleInputChange(4, e.target.value)}
                      />
                    </Col>
                    <Col>
                      <input
                        type="text"
                        id="opt6"
                        maxLength="1"
                        name="opt6"
                        value={inputValues[5]}
                        onChange={(e) => handleInputChange(5, e.target.value)}
                      />
                    </Col>
                  </Row>
                </div>
                {!warning && (
                <p className="title-4">
                If you have registered with the provided email, please check your email to verify the OTP</p>
                )}
                <p className="title-4">
                Please ensure that the OTP email has not been directed to your Spam folder.</p>
                {verificationError === true && (
                  <small>
                    <div className="error-message">
                      <span>
                        <i class="bi bi-exclamation-circle"></i>
                      </span>
                      Invalid OTP
                    </div>
                  </small>
                )}
              </div>

              <button
                type="button"
                className="btn-verify yellow-btn"
                onClick={() => verifyOTPCode()}
                disabled={isExpired === true || inputValues.some(value => value === "")}
              >
                Verify
              </button>

              <Link to="/termsAndServices"> </Link>

              <p className="title-3">
                Didn’t Receive the code?
                <span>
                  <Link onClick={() => resendOTP()}>Resend</Link>
                </span>
              </p>
              <p className="all-right">MoofLife © {currentYear}</p>
            </Card>
          </Col>
          <Col sm={5} style={{ textAlign: "right" }}>
            <img src={images.otpVerificationBg}></img>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default OtpVerification;
