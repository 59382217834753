import React, { useState, useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useNavigate } from "react-router-dom";
import userimage from "../../src/assets/userimage.webp";
import "../styles/NavbarStyle.css";
import { images } from "../utils/image";
import {
  getFromSessionStorage,
  removeLoginDataFromStorage,
} from "../utils/storageHandler";
import { handleUserLogged } from "../store/action/userInteractionsAction";
import Footer from "./Footer";
import { useDispatch, useSelector } from "react-redux";
import { getSearchSuggestion } from "../store/action/LifeAction";
import { getMomentsForSearch } from "../store/action/MomentAction";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useParams } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

const NavigationBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUrl = window.location.href;

  const [fromYear, setFromYear] = useState("");
  const [toYear, setToYear] = useState("");
  const [words, setWords] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [user, setUser] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [themeColor, setThemeColor] = useState("");
  const [initials, setInitials] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [showFromYearDropdown, setFromYearDropdown] = useState(false);
  const [showToYearDropdown, setToYearDropdown] = useState(false);
  const [show, setShow] = useState(false);
  const [showSearch, setShowSearch] = useState(true);
  const [sortByDate, setSortByDate] = useState(false);
  const [userLogin, setUserLogin] = useState("");
  const [fromYearEra, setFromYearEra] = useState("CE");
  const [toYearEra, setToYearEra] = useState("CE");

  const [loading, setLoading] = useState(false);
  const inputDropdownRef = useRef(null);
  const fromYearDropdownRef = useRef(null);
  const toYearDropdownRef = useRef(null);
  const params = useParams();

  useEffect(() => {
    const userDetails = getFromSessionStorage("userDetails", true);
    setUserLogin(userDetails);
    const themeColor = userDetails ? userDetails.theme_colour : "";
    setThemeColor(themeColor);
    const firstName = userDetails ? userDetails.first_name : "";
    const lastName =
      userDetails &&
      userDetails.last_name !== null &&
      userDetails.last_name !== undefined
        ? userDetails.last_name
        : "";
    setFirstName(firstName);
    setLastName(lastName);
    const user = userDetails ? userDetails.first_name : "";
    setUser(user);
    const firstInitial = userDetails ? userDetails.first_name.charAt(0) : "";
    const lastInitial =
      userDetails && userDetails.last_name
        ? userDetails.last_name.charAt(0)
        : "";
    var initials = firstInitial + lastInitial;
    setInitials(initials);
    const imageUrl = userDetails ? userDetails.image_url : "";
    setImageUrl(imageUrl);
  }, []);

  useEffect(() => {
    const isLifePage = params && params.life_id ? true : false;

    if (
      currentUrl.includes("/search") ||
      currentUrl.includes("/user-settings") ||
      currentUrl.includes("/update-moment") ||
      currentUrl.includes("/select-package") ||
      isLifePage
    ) {
      setShowSearch(false);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        inputDropdownRef.current &&
        !inputDropdownRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
      if (
        fromYearDropdownRef.current &&
        !fromYearDropdownRef.current.contains(event.target)
      ) {
        setFromYearDropdown(false);
      }
      if (
        toYearDropdownRef.current &&
        !toYearDropdownRef.current.contains(event.target)
      ) {
        setToYearDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const Life = useSelector((state) => state.Life.Life);

  const searchSuggetion = useSelector((state) => state.Life.searchSuggetion);
  const momentSorting = useSelector((state) => state.Moments.momentSorting);

  const handleSearch = async () => {
    setShowDropdown(false);
    setFromYearDropdown(false);
    setToYearDropdown(false);

    let from = fromYear;
    let to = toYear;

    if (fromYear > toYear) {
      from = toYear;
      to = fromYear;
    }

    handleAddKeywords();
  };

  const handleAddKeywords = () => {
    // From Year - To Year
    if (fromYear != "" && toYear != "") {
      const keywordText = `${fromYear} - ${toYear}`;
      setSearchKeywords("fromAndToYear", keywordText);
    } else {
      // From - Present
      if (fromYear !== "") {
        const keywordText = `${fromYear} - Present`;
        setSearchKeywords("fromYear", keywordText);
      }

      // Past - ToYear
      if (toYear !== "") {
        const keywordText = `Past - ${toYear}`;
        setSearchKeywords("toYear", keywordText);
      }
    }

    // Word
    if (words !== "") {
      const keywordText = `${words}`;
      //setKeywords((prevKeywords) => [...prevKeywords, {type: 'keyword', value: keywordText}]);
      setSearchKeywords("keyword", keywordText);
    }

    startMomentSearch();
  };

  const setSearchKeywords = (itemName, keywordText) => {
    setKeywords((prevKeywords) => {
      const keywordIndex = prevKeywords.findIndex(
        (item) => item.type === itemName
      );
      let updatedKeywords = [...prevKeywords];

      if (keywordText === "") {
        updatedKeywords = updatedKeywords.filter(
          (item) => item.type !== itemName
        );
      }

      if (itemName === "fromAndToYear") {
        updatedKeywords = updatedKeywords.filter(
          (item) => item.type !== "fromYear" && item.type !== "toYear"
        );
      } else if (itemName === "fromYear") {
        console.log("from or to : ", itemName);
        updatedKeywords = updatedKeywords.filter(
          (item) => item.type !== "fromAndToYear" && item.type !== "toYear"
        );
      } else if (itemName === "toYear") {
        updatedKeywords = updatedKeywords.filter(
          (item) => item.type !== "fromAndToYear" && item.type !== "fromYear"
        );
      }

      if (keywordIndex !== -1) {
        // If 'keyword' already exists, update its value
        updatedKeywords[keywordIndex] = { type: itemName, value: keywordText };
        return updatedKeywords;
      } else {
        // if 'keyword' not exists
        return [...updatedKeywords, { type: itemName, value: keywordText }];
      }
    });
  };

  const handleRemoveKeyword = (type) => {
    setShowDropdown(false);
    setFromYearDropdown(false);
    setToYearDropdown(false);

    if (type === "keyword") {
      setWords("");
    } else if (type === "fromYear") {
      setFromYear("");
    } else if (type === "toYear") {
      setToYear("");
    } else if (type === "fromAndToYear") {
      setFromYear("");
      setToYear("");
    }
    setKeywords((prevKeywords) =>
      prevKeywords.filter((item) => item.type !== type)
    );
    console.log("startSearch calling from handleRemoveKeyword");
    startMomentSearch(type);
  };

  const startMomentSearch = (avoidType, sortVal) => {
    let searchQuery = {
      selector: {},
      page: 1,
      limit: 10,
      sorting: sortVal ? Number(sortVal) : momentSorting,
      loggedInUserId: userLogin?.id ? userLogin.id : null,
      user_email: userLogin?.email ? userLogin.email : "",
    };

    if (words && words !== "" && avoidType !== "keyword") {
      searchQuery.selector.search_keyword = words;
    }

    if (
      fromYear &&
      fromYear !== "" &&
      avoidType !== "fromYear" &&
      avoidType !== "fromAndToYear"
    ) {
      searchQuery.selector.fromYear = handleYears(fromYear);
      searchQuery.selector.fromYearEra = fromYearEra;
    }

    if (
      toYear &&
      toYear !== "" &&
      avoidType !== "toYear" &&
      avoidType !== "fromAndToYear"
    ) {
      searchQuery.selector.toYear = handleYears(toYear);
      searchQuery.selector.toYearEra = toYearEra;
    }

    dispatch(getMomentsForSearch(searchQuery, 1));
  };

  const handleYears = (year) => {
    let yearVal = year;
    if (year && year !== "") {
      if (year < 10) {
        yearVal = `000${year}`;
      } else if (year < 100) {
        yearVal = `00${year}`;
      } else if (year < 1000) {
        yearVal = `0${year}`;
      } else {
        yearVal = year.toString();
      }
    } else {
      yearVal = year;
    }
    return yearVal;
  };

  const logout = () => {
    setLoading(true);
    if (user) {
      setShow(true);

      setTimeout(() => {
        setLoading(false);
      }, 2000);
      removeLoginDataFromStorage();
      setUser("");
      setInitials("");
      setImageUrl("");
      dispatch(handleUserLogged(false));

      setTimeout(() => {
        setShow(false);
        navigate("/");
      }, 5000);
    }
  };

  const handleDropdownItem = (lifeId) => {
    navigate(`/${lifeId}`);
  };

  const handleSearchSuggestion = (input) => {
    dispatch(
      getSearchSuggestion({
        input: input,
        user_email: userLogin?.email ? userLogin.email : "",
      })
    );
  };

  const redirectToLandingPage = () => {
    const currentPath = window.location.pathname;
    if (currentPath === "/") {
      window.location.href = currentPath;
    }
  };

  const handleFromYearItem = (selectedYear) => {
    setFromYear(selectedYear);
  };

  const handleToYearItem = (selectedToYear) => {
    setToYear(selectedToYear);
  };

  const handleSortingDate = () => {
    setSortByDate((prevState) => !prevState);
  };

  const fromYearList = [
    {
      id: 1,
      year: "1750",
    },
    {
      id: 2,
      year: "1800",
    },
    {
      id: 3,
      year: "1850",
    },
    {
      id: 4,
      year: "1950",
    },
    {
      id: 5,
      year: "1975",
    },
  ];

  const toYearList = [
    {
      id: 1,
      year: "1800",
    },
    {
      id: 2,
      year: "1900",
    },
    {
      id: 3,
      year: "1990",
    },
    {
      id: 4,
      year: "2000",
    },
    {
      id: 5,
      year: "2020",
    },
  ];

  const sortMoments = (sortValue) => {
    startMomentSearch(null, sortValue);
  };

  const getSuggestionWhenClick = () => {
    if (showDropdown === false && !words.length > 0) {
      dispatch(
        getSearchSuggestion({
          count: 0,
          user_email: userLogin?.email ? userLogin.email : "",
        })
      );
    }
    setShowDropdown((prevState) => !prevState);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleYearEra = (evt, type) => {
    if (type === "from") {
      setFromYearEra(evt.target.value);
    } else {
      setToYearEra(evt.target.value);
    }
  };

  return (
    <div>
      <Navbar expand="lg" className="Navbar" fixed="top">
        <Container className="header-navbar-main">
          {/* Pop up */}
          <Modal className="popup-modal" show={show} onHide={handleClose}>
            <div className="popup-header">
              <div className="logo-icon">
                <img src={images.MoofIcon} alt="moofIcon" />
              </div>
            </div>

            <Modal.Body>
              <div className="title">
                {loading ? (
                  <Spinner
                    animation="border"
                    variant="dark"
                    style={{ width: "15px", height: "15px" }}
                  />
                ) : (
                  "Successfully Logout"
                )}
              </div>
            </Modal.Body>
          </Modal>

          <Nav.Link onClick={() => redirectToLandingPage()}>
            <Link to="/">
              <img className="logo" src={images.moofLogo} alt="logo"></img>
            </Link>
          </Nav.Link>

          <Nav.Link className="yellow-login-btn">
            <Link to="/login">
              {!user && (
                <div className="yellow-btn login-btn mobile-login-btn">
                  {" "}
                  Login
                </div>
              )}
            </Link>
          </Nav.Link>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto nav-container">
              <div>
                <div>
                  {showSearch && (
                    <Form>
                      <div className="select-form">
                        <div className="search-menu-btn" ref={inputDropdownRef}>
                          <div>
                            <input
                              id="searchText"
                              className={`search ${
                                showDropdown && !words.length > 0
                                  ? "expanded"
                                  : "search"
                              }`}
                              type="text"
                              placeholder="Search Lives and Moments"
                              value={words}
                              onChange={(e) => {
                                setShowDropdown(true);
                                setWords(e.target.value);
                                handleSearchSuggestion(e.target.value);
                              }}
                              onClick={() => {
                                getSuggestionWhenClick();
                              }}
                              autocomplete="one-time-code"
                            />
                          </div>
                          {showDropdown === true &&
                            searchSuggetion &&
                            searchSuggetion.length > 0 && (
                              <div className="search-dropdown-content">
                                {searchSuggetion.map((searchLife, index) => (
                                  <div className="d-flex dropdown-item menu-items"
                                    onClick={() =>
                                      handleDropdownItem(
                                        searchLife.verified_name
                                          ? searchLife.verified_name
                                          : searchLife.life_id
                                      )
                                    }
                                  >
                                    <div
                                      className="item"
                                      onClick={() =>
                                        handleDropdownItem(
                                          searchLife.verified_name
                                            ? searchLife.verified_name
                                            : searchLife.life_id
                                        )
                                      }
                                    >
                                      <div>
                                        <img
                                          src={searchLife.life_logo}
                                          className="search-dropdown-user"
                                          alt="lifeLogo"
                                        ></img>
                                      </div>
                                      <div className="search-dropdown-name">
                                        {searchLife.life_name.length > 25
                                          ? searchLife.life_name.slice(0, 25) +
                                            "..."
                                          : searchLife.life_name}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                                <Row>
                                  <Col>
                                    <Link
                                      to="/search"
                                      className="advance-search"
                                    >
                                      Advance Search
                                    </Link>
                                  </Col>
                                </Row>
                              </div>
                            )}
                        </div>
                        <div className="from-to-year">
                          <div ref={fromYearDropdownRef}>
                            <div className="input-select">
                              <input
                                type="number"
                                className={`datePicker ${
                                  showFromYearDropdown && fromYear === ""
                                    ? "expanded"
                                    : "datePicker"
                                }`}
                                placeholder="From Year"
                                value={fromYear}
                                maxLength="4"
                                onInput={(e) => {
                                  e.target.value = Math.max(
                                    0,
                                    parseInt(e.target.value)
                                  )
                                    .toString()
                                    .slice(0, 4);
                                  setFromYear(e.target.value);
                                }}
                                onClick={() => {
                                  setFromYearDropdown(
                                    (prevState) => !prevState
                                  );
                                }}
                              />
                              {/* <select className="search-input" 
                                onChange={(evt) => handleYearEra(evt, 'from')}
                                value={fromYearEra}
                              >
                                <option value="CE">CE</option>
                                <option value="BCE">BCE</option>
                              </select> */}
                            </div>

                            {showFromYearDropdown === true &&
                              fromYear === "" && (
                                <div className="from-year-dropdown-content">
                                  {fromYearList.map((fromyear, index) => (
                                    <div
                                      key={index}
                                      className="d-flex dropdown-item menu-items"
                                      onClick={() =>
                                        handleFromYearItem(fromyear.year)
                                      }
                                    >
                                      <div className="item">
                                        <Row>
                                          <div
                                            className="search-dropdown-name"
                                            onClick={() =>
                                              setFromYearDropdown(false)
                                            }
                                          >
                                            {fromyear.year}
                                          </div>
                                        </Row>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                          </div>

                          <div ref={toYearDropdownRef}>
                            <div className="input-select">
                              <input
                                type="number"
                                className={`datePicker ${
                                  showToYearDropdown && toYear === ""
                                    ? "expanded"
                                    : "datePicker"
                                }`}
                                placeholder="To Year"
                                value={toYear}
                                maxLength="4"
                                onInput={(e) => {
                                  e.target.value = Math.max(
                                    0,
                                    parseInt(e.target.value)
                                  )
                                    .toString()
                                    .slice(0, 4);
                                  setToYear(e.target.value);
                                }}
                                onClick={() => {
                                  setToYearDropdown((prevState) => !prevState);
                                }}
                              />
                              {/* <select className="search-input"
                                onChange={(evt) => handleYearEra(evt, 'to')} 
                                value={toYearEra}
                              >
                                <option value="CE">CE</option>
                                <option value="BCE">BCE</option>
                              </select> */}
                            </div>

                            {showToYearDropdown === true && toYear === "" && (
                              <div className="from-year-dropdown-content">
                                {toYearList.map((toyear, index) => (
                                  <div
                                    key={index}
                                    className="d-flex dropdown-item menu-items"
                                    onClick={() =>
                                      handleToYearItem(toyear.year)
                                    }
                                  >
                                    <div className="item">
                                      <Row>
                                        <Col
                                          className="search-dropdown-name"
                                          onClick={() =>
                                            setToYearDropdown(false)
                                          }
                                        >
                                          {toyear.year}
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                        <div></div>
                        <Link>
                          <div style={{ display: "flex" }}>
                            <button
                              className="search-btn"
                              onClick={handleSearch}
                              disabled={!words && !fromYear && !toYear}
                            ></button>
                            <div>
                              <div onClick={handleSortingDate}>
                                {sortByDate === false ? (
                                  <OverlayTrigger
                                    className="tooltip"
                                    placement="bottom"
                                    overlay={
                                      <Tooltip>
                                        Sort by Moment Date: Latest Moments
                                        First.
                                      </Tooltip>
                                    }
                                  >
                                    <img
                                      className="sort-image"
                                      src={images.sortAsc}
                                      alt="sortAsc"
                                      onClick={() => sortMoments(1)}
                                    ></img>
                                  </OverlayTrigger>
                                ) : (
                                  <OverlayTrigger
                                    className="tooltip"
                                    placement="bottom"
                                    overlay={
                                      <Tooltip>
                                        Sort by Moment Date: Oldest Moments
                                        First
                                      </Tooltip>
                                    }
                                  >
                                    <img
                                      className="sort-image"
                                      src={images.sortDec}
                                      alt="sortDec"
                                      onClick={() => sortMoments(-1)}
                                    ></img>
                                  </OverlayTrigger>
                                )}
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div>
                        <div className="keywords-container">
                          {keywords.map((keyword, index) => (
                            <button key={index} className="keyword-button">
                              {keyword.value}
                              <span
                                className="close-icon"
                                onClick={() =>
                                  handleRemoveKeyword(keyword.type)
                                }
                              >
                                &#x2716;
                              </span>
                            </button>
                          ))}
                        </div>
                      </div>
                    </Form>
                  )}
                </div>
              </div>

              {/* </Nav.Link> */}
            </Nav>
            <Nav className="nav-container">
              <Nav.Link className="right-nav link">
                {user ? (
                  <Link to="/user-settings">
                    <h2>
                      {user ? firstName : ""} {user ? lastName : ""}
                    </h2>
                  </Link>
                ) : (
                  <Link to="/login">
                    <div className="yellow-btn login-btn web-login-btn">
                      Login
                    </div>
                  </Link>
                )}
              </Nav.Link>
              <Nav.Link className="right-nav link">
                <h2>
                  <div className="dropdown link menu-btn">
                    {user ? (
                      <>
                        {imageUrl ? (
                          <div>
                            <img
                              src={imageUrl}
                              className="user-image"
                              alt="userImage"
                            ></img>
                          </div>
                        ) : (
                          <div
                            className="user-image-letter"
                            id="name"
                            style={{ background: themeColor || "#E0E0E0" }}
                          >
                            {initials}
                          </div>
                        )}

                        <div
                          className="dropdown-content"
                          style={{ marginLeft: "-80px" }}
                        >
                          <div className="d-flex dropdown-item menu-items">
                            <div className="item">
                              <Link to="/about-us" className="pages">
                                About Us
                              </Link>
                            </div>
                          </div>
                          <div className="d-flex dropdown-item menu-items">
                            <div className="item">
                              <Link to="/mooflife-concept" className="pages">
                                Mooflife Concept
                              </Link>
                            </div>
                          </div>
                          <div className="d-flex dropdown-item menu-items">
                            <div className="item">
                              <Link to="/contact-us" className="pages">
                                Contact Us
                              </Link>
                            </div>
                          </div>
                          <div className="d-flex dropdown-item menu-items">
                            <div className="item">
                              <Link to="/user-settings" className="pages">
                                User Settings
                              </Link>
                            </div>
                          </div>
                          <div className="d-flex dropdown-item menu-items" onClick={logout}>
                            <div className="item">
                              <span>
                                <img
                                  src={images.logout}
                                  width="20px"
                                  alt="logout"
                                />
                              </span>
                              <a href="#" onClick={logout} className="pages">
                                Logout
                              </a>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div>
                          <Link to="/login">
                            <img
                              className="user-image"
                              src={userimage}
                              alt="userImage"
                            ></img>
                          </Link>
                        </div>
                        <div
                          className="dropdown-content"
                          style={{ marginLeft: "-120px" }}
                        >
                          <div className="d-flex dropdown-item menu-items">
                            <div className="item">
                              <span>
                                <img
                                  src={images.login}
                                  width="20px"
                                  alt="login"
                                />
                              </span>
                              <Link className="link-tag1" to="/login">
                                Log In&nbsp;
                              </Link>{" "}
                              &nbsp;|&nbsp;
                              <Link className="link-tag2" to="/sign-up">
                                {" "}
                                &nbsp;Sign Up
                              </Link>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </h2>
              </Nav.Link>
            </Nav>
            <div className="footer-part-navigation">
              <Footer></Footer>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavigationBar;
