export const subscriptionPackages = [
    {
      id:1,
      plan: "Free",
      package_description : "for Lifetime",
      package_theme_color: "#BFBFBFB0",
      package_theme_dark_color: "#484848",
      package_background_color: "#F5F5F5",
    },
    {
      id:2,
      plan: "Plus",
      package_description : "Life saver",
      package_theme_color: "#CADFFF",
      package_theme_dark_color: "#3787FF",
      package_background_color: "#EDF3FE",
    },
    {
      id:3,
      plan: "Premium",
      package_description : "For Influencer, Small Businesses, Autobiographers, Academics.",
      package_theme_color: "#FFDECC",
      package_theme_dark_color: "#FF5900",
      package_background_color: "#FFF6F2",
    },
    {
      id:4,
      plan: "Elite",
      package_description : "For Public  figures, Enterprises, Diarists, History enthusiasts.",
      package_theme_color: "#F2DEAB",
      package_theme_dark_color: "#FFBF00",
      package_background_color: "#FFF8E7",
    }
  ]


