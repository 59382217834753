import { createRequest } from '../../utils/requestHandler';
import { getSearchResults,setSearchMoments, setSearchLives, setSearchLifePage, setSearchLifeLimitReached, setSearchMomentsPage, setSearchMomentsLimitReached } from '../slices/SearchSlice';
const mooflife_backend = process.env.REACT_APP_BACK_END_URL;

export const fetchSearchResults = (data) => {

  return async (dispatch) => {
    try { 
      const configs = {
          headers: {
              'Content-Type': 'application/json',
            },
      }
      const response = await createRequest('post', `${mooflife_backend}/life/search`, data, configs);

      if (response && response.data && response.data.success) {
        dispatch(getSearchResults(response.data.data))
        return response.data.success;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error while search:', error);
    }
  };
  };

  export const getMoreSearchMoments = (data, page) => {
    return async (dispatch) => {
      try { 
        const configs = {
            headers: {
                'Content-Type': 'application/json',
              },
        }
  
        const response = await createRequest('post', `${mooflife_backend}/moment/search`, data, configs)
        if (response && response.data && response.data.success && response.data.data) {
          if(response.data.data.length === 0){
            dispatch(setSearchMomentsLimitReached(true));
          }
          
          dispatch(setSearchMoments(response.data.data))
          
          if(page){
            dispatch(setSearchMomentsPage(page+1));
          }
          return response.data.success;
        } else {
          return false;
        }
        
      } catch (error) {
        console.error('Error while getting moments data:', error);
      }
    };
  };

  export const getMoreSearchlives = (data, page) => {
    return async (dispatch) => {
      try { 
        const configs = {
            headers: {
                'Content-Type': 'application/json',
              },
        }
  
        const response = await createRequest('post', `${mooflife_backend}/life/search-more`, data, configs)
        if (response && response.data && response.data.success && response.data.data) {
          if(response.data.data.length === 0){
            dispatch(setSearchLifeLimitReached(true));
          }
          
          dispatch(setSearchLives(response.data.data))
          
          if(page){
            dispatch(setSearchLifePage(page+1));
          }
          return response.data.success;
        } else {
          return false;
        }

      } catch (error) {
        console.error('Error while getting life data:', error);
      }
    };
  };

  export const resetLifeAndMomentSearchData = () => {
    return async (dispatch) => {
      try {
        dispatch(getSearchResults({ lives: [], moments: [] }));
      } catch (error) {
        console.error("Error resetting Life Moments:", error);
      }
    };
  };

  export const resetLifeAndMomentSearchPage = () => {
    return async (dispatch) => {
      try {
        dispatch(setSearchLifePage(2));
        dispatch(setSearchMomentsPage(2));
      } catch (error) {
        console.error("Error resetting page value:", error);
      }
    };
  };
