import {createSlice} from "@reduxjs/toolkit";

const commentsSlice = createSlice({
    name: "Comments",
    initialState:{
       commentsForMoment: [],
       page: 1,
       limitReached: false,
    },
    reducers:{
        setCommentsForMoments: (state, action) => {

            const newComments = action.payload;
        
            const updateComments = (comments) => {
                return comments.map(comment => {
                    const existingCommentIndex = state.commentsForMoment.findIndex(existingComment => existingComment._id === comment._id);
                    if (existingCommentIndex !== -1) {
                        // If the comment with the same _id already exists, update its content, like and dislike counts
                        const updatedComment = { ...state.commentsForMoment[existingCommentIndex], content: comment.content, 
                            like_count: comment.like_count, dislike_count: comment.dislike_count  };
        
                        // Recursively update replies
                        if (comment.replies && comment.replies.length > 0) {
                            updatedComment.replies = updateComments(comment.replies);
                        }
        
                        return updatedComment;
                    } else {
                        return comment;
                    }
                });
            };
    
            const updatedComments = updateComments(newComments);

            // Remove deleted comments from the Redux store
            const updatedCommentsIds = updatedComments.map(comment => comment._id);
            state.commentsForMoment = state.commentsForMoment.filter(existingComment => updatedCommentsIds.includes(existingComment._id));

            //Filter out existing comments and add updated comments
            state.commentsForMoment = [
                ...state.commentsForMoment.filter(existingComment => !newComments.some(newComment => newComment._id === existingComment._id)),
                ...updatedComments
            ];
        
        },

        resetCommentsForMoments: (state, action) => {
            state.commentsForMoment = []
        },

        setPage: (state, action) => {
            state.page = action.payload
        },

        setLimitReached: (state, action) => {
            state.limitReached = action.payload
        },

        insertNewComment: (state, action) => {
            state.commentsForMoment = [...state.commentsForMoment, action.payload];
        },
    }
})

export const {
    setCommentsForMoments,
    resetCommentsForMoments,
    setPage,
    setLimitReached,
    insertNewComment,
} = commentsSlice.actions;

export default commentsSlice.reducer;