import React from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { images } from "../utils/image";
import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MomentJS from 'moment';
import { registerNewUser, userLogin, setUserDetails } from "../store/action/userInteractionsAction";
import { getFromSessionStorage, setIntoSessionStorage } from "../utils/storageHandler";
import {countryList} from "../utils/CountryList.js";
import ("../styles/YourInterestsStyle.css");

const YourInterests = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [selectedItems, setSelectedItems] = useState([]);
  const [show, setShow] = useState(false);
  const [searchBar, setSearchBar] = useState("");
  const [interests, setInterests] = useState([]);
  const [loading, setLoading] = useState(false);

  //Show and hide modal
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const userDetails = useSelector(
    (state) => state.UserInteractions.newUserDetails
  );

  useEffect(() => {
    const accessToken = getFromSessionStorage('accessToken');
    if(accessToken){
      // Redirect to the home page if the user already loggedIn
      navigate("/");
    }else if (!userDetails.terms_and_condition) {
      // Redirect to the loggin page if the user hasn't completed the sign-up process
      navigate("/login");
    }
  }, []);

  function checkboxHandler(e) {
    let isSelected = e.target.checked;
    let value = e.target.value;

    if (isSelected) {
      if(selectedItems.includes("All")){
        setSelectedItems([value]);
      }
      else{
        setSelectedItems([...selectedItems, value]);
      }
    } else {
      setSelectedItems((prevData) => {
        return prevData.filter((country) => {
          return country !== value;
        });
      });
    }
  }

  function checkAllHandler() {
    const countryList = selectedItems;
    if (countryList.includes("All")) {
      countryList.splice(countryList.indexOf("All"), 1);
      setSelectedItems(countryList);
    } else {
      setSelectedItems(["All"]);
    }
  }

  function uncheckAllHandler() {
    setSelectedItems([]);
    setSearchBar("");
    handleClose();
  }

  const handleSaveClose = () => {
    if(selectedItems && selectedItems.length > 0){
      setIsChecked6(true)
    }
    setSearchBar("");
    handleClose();
  };

  // Filter the data
  const handleSearchChange = (event) => {
    setSearchBar(event.target.value);
  };
  
  const filteredData = countryList.filter((item) =>
    item.country.toLowerCase().startsWith(searchBar.toLowerCase())
  );
  
  const [isChecked, setIsChecked] = useState(false);
  const checkboxValue = "Entertainment & Sports";
  const [isChecked1, setIsChecked1] = useState(false);
  const checkboxValue1 = "Business";
  const [isChecked2, setIsChecked2] = useState(false);
  const checkboxValue2 = "Political & Governance";
  const [isChecked3, setIsChecked3] = useState(false);
  const checkboxValue3 = "Technology & Science";
  const [isChecked4, setIsChecked4] = useState(false);
  const checkboxValue4 = "People & Culture";
  const [isChecked5, setIsChecked5] = useState(false);
  const checkboxValue5 = "Sustainability";
  const [isChecked6, setIsChecked6] = useState(false);
  const checkboxValue6 = "Country & Region";

  const handleCheckBoxes = () => {
    setIsChecked(!isChecked);
  };

  const handleClickCheckBoxes = (value) => {
    let interestsArray = interests;
    if (!interestsArray.includes(value)) {
      interestsArray.push(value);
      setInterests(interestsArray);
    } else {
      interestsArray.splice(interestsArray.indexOf(value), 1);
    }
  };

  const handleSaveCountries = () => {};

  const handleCheckboxChange = (evt) => {
    setIsChecked(!isChecked);
  };

  const handleCheckboxChange1 = () => {
    setIsChecked1(!isChecked1);
  };

  const handleCheckboxChange2 = () => {
    setIsChecked2(!isChecked2);
  };

  const handleCheckboxChange3 = () => {
    setIsChecked3(!isChecked3);
  };

  const handleCheckboxChange4 = () => {
    setIsChecked4(!isChecked4);
  };

  const handleCheckboxChange5 = () => {
    setIsChecked5(!isChecked5);
  };

  const handleCheckboxChange6 = () => {
    if (selectedItems.length > 0) {
      setIsChecked6(!isChecked6);
    }
  };

  const nextStep = async () => {
    if (step === 1) {
      let user = { ...userDetails };
      let dataToRegister ={
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        password: user.password,
        country: user.country,
        interests: interests,
        selected_countries: selectedItems,
        theme_colour: themeColor,
      };

      dispatch(registerNewUser(dataToRegister))
        .then((res) => {
          if (res) {
            setStep(step + 1);
          }
        })
        .catch((error) => {
          console.error("Error in verification:", error);
        });
    }
  };

  const gotomoments = () => {
    setLoading(true);
    const loginData = {
      email: userDetails.email, 
      password: userDetails.password
    };
    dispatch(userLogin(loginData))
        .then((res) => {
          if (res && res.success && res.data.token) {
            const tokenExpireAt = MomentJS().add(10, 'hours');
              setIntoSessionStorage("accessToken", res.data.token);
              setIntoSessionStorage("userDetails", res.data.userDetails, true);
              setIntoSessionStorage("tokenExpireTime", tokenExpireAt);
              setIntoSessionStorage("interest", res.data.interest, true);
              setIntoSessionStorage("selected_countries", res.data?.selected_countries, true);
              setIntoSessionStorage("register_type", res.data.register_type);
              dispatch(setUserDetails({}));
              setLoading(false);
              navigate("/");
          }
        });
  };

  const [themeColor, setThemeColor] = useState("#D9D9D9");
  const [isSelected, setIsSelected] = useState(true);

  const handleThemeColor = (e) => {
    const selectedColor = e.target.getAttribute("value");
    selectedColor ? setIsSelected(true) : setIsSelected(false);
    setThemeColor(selectedColor);
  };

  return (
    <div>
      {step === 1 && (
        <>
          <div className="Your-Interests-main">
            <Card className="card-step-1">
              <div className="dots">
                <div className={`dot ${step === 1 ? "active" : ""}`}></div>
                <div className={`dot ${step === 2 ? "active" : ""}`}></div>
              </div>
              <div className="interests-title-set">
                <p className="title-2">Tell us about your interests</p>
                <p className="title-3">
                  This will allow us to tailor your experience, presenting the
                  moments closer to what you love.
                </p>

                <Row className="justify-content-md-center row1">
                  <div className="interest-container">
                    <div className="row">
                      <ul>
                        <li>
                          <input
                            type="checkbox"
                            id="myCheckbox1"
                            checked={isChecked}
                            onChange={handleCheckBoxes}
                            onClick={() =>
                              handleClickCheckBoxes("Entertainment & Sports")
                            }
                          />
                          <label for="myCheckbox1" >
                            <img style={{ backgroundImage: `url('${images.img1}')` }}></img>
                            <span class="image-text">{checkboxValue}</span>
                          </label>
                        </li>
                        <li>
                          <input
                            type="checkbox"
                            id="myCheckbox2"
                            checked={isChecked1}
                            onChange={handleCheckboxChange1}
                            // onChange={handleCheckBoxes}
                            onClick={() => handleClickCheckBoxes("Business")}
                          />
                          <label for="myCheckbox2" >
                            <img style={{ backgroundImage: `url('${images.img2}')` }}></img>
                            <span class="image-text">{checkboxValue1}</span>
                          </label>
                        </li>
                        <li>
                          <input
                            type="checkbox"
                            id="myCheckbox3"
                            checked={isChecked2}
                            onChange={handleCheckboxChange2}
                            onClick={() =>
                              handleClickCheckBoxes("Political & Governance")
                            }
                          />
                          <label for="myCheckbox3" >
                            <img style={{ backgroundImage: `url('${images.img3}')` }}></img>
                            <span class="image-text">{checkboxValue2}</span>
                          </label>
                        </li>
                        <li>
                          <input
                            type="checkbox"
                            id="myCheckbox4"
                            checked={isChecked3}
                            onChange={handleCheckboxChange3}
                            onClick={() =>
                              handleClickCheckBoxes("Technology & Science")
                            }
                          />
                          <label for="myCheckbox4" >
                            <img style={{ backgroundImage: `url('${images.img4}')` }}></img>
                            <span class="image-text">{checkboxValue3}</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Row>
                <Row className="justify-content-md-center row2">
                  <div className="interest-container">
                    <div className="row">
                      <ul>
                        <li>
                          <input
                            type="checkbox"
                            id="myCheckbox5"
                            checked={isChecked4}
                            onChange={handleCheckboxChange4}
                            onClick={() =>
                              handleClickCheckBoxes("People & Culture")
                            }
                          />
                          <label for="myCheckbox5" >
                            <img style={{ backgroundImage: `url('${images.img5}')` }}></img>
                            <span class="image-text">{checkboxValue4}</span>
                          </label>
                        </li>
                        <li>
                          <input
                            type="checkbox"
                            id="myCheckbox6"
                            checked={isChecked5}
                            onChange={handleCheckboxChange5}
                            onClick={() =>
                              handleClickCheckBoxes("Sustainability")
                            }
                          />
                          <label for="myCheckbox6" >
                            <img style={{ backgroundImage: `url('${images.img6}')` }}></img>
                            <span class="image-text">{checkboxValue5}</span>
                          </label>
                        </li>
                        <li>
                          <input
                            type="checkbox"
                            id="myCheckbox8"
                            checked={isChecked6}
                            onChange={handleCheckboxChange6}
                          />
                          <label for="myCheckbox8" onClick={handleShow} >
                            <img style={{ backgroundImage: `url('${images.img7}')` }}></img>
                            <span class="image-text">{checkboxValue6}</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Row>

                <div className="color-palette-main">
                  <div className="title">
                    Personalize Your Profile: Select a Color.
                  </div>
                  <Row className="color-palette">
                    <Col className="circle-line">
                      <span
                        className="circle"
                        style={{ background: "#D9D9D9" }}
                        value="#D9D9D9"
                        onClick={handleThemeColor}
                      ></span>
                      {isSelected && themeColor === "#D9D9D9" && (
                        <div className="line"></div>
                      )}
                    </Col>

                    <Col className="circle-line">
                      <span
                        className="circle"
                        style={{ background: "#F2B0B0" }}
                        value="#F2B0B0"
                        onClick={handleThemeColor}
                      ></span>
                      {isSelected && themeColor === "#F2B0B0" && (
                        <div className="line"></div>
                      )}
                    </Col>

                    <Col className="circle-line">
                      <span
                        className="circle"
                        style={{ background: "#EFD798" }}
                        value="#EFD798"
                        onClick={handleThemeColor}
                      ></span>
                      {isSelected && themeColor === "#EFD798" && (
                        <div className="line"></div>
                      )}
                    </Col>

                    <Col className="circle-line">
                      <span
                        className="circle"
                        style={{ background: "#B9E472" }}
                        value="#B9E472"
                        onClick={handleThemeColor}
                      ></span>
                      {isSelected && themeColor === "#B9E472" && (
                        <div className="line"></div>
                      )}
                    </Col>

                    <Col className="circle-line">
                      <span
                        className="circle"
                        style={{ background: "#76DAA4" }}
                        value="#76DAA4"
                        onClick={handleThemeColor}
                      ></span>
                      {isSelected && themeColor === "#76DAA4" && (
                        <div className="line"></div>
                      )}
                    </Col>

                    <Col className="circle-line">
                      <span
                        className="circle"
                        style={{ background: "#FCD970" }}
                        value="#FCD970"
                        onClick={handleThemeColor}
                      ></span>
                      {isSelected && themeColor === "#FCD970" && (
                        <div className="line"></div>
                      )}
                    </Col>

                    <Col className="circle-line">
                      <span
                        className="circle"
                        style={{ background: "#9BE7D9" }}
                        value="#9BE7D9"
                        onClick={handleThemeColor}
                      ></span>
                      {isSelected && themeColor === "#9BE7D9" && (
                        <div className="line"></div>
                      )}
                    </Col>

                    <Col className="circle-line">
                      <span
                        className="circle"
                        style={{ background: "#94C0E1" }}
                        value="#94C0E1"
                        onClick={handleThemeColor}
                      ></span>
                      {isSelected && themeColor === "#94C0E1" && (
                        <div className="line"></div>
                      )}
                    </Col>

                    <Col className="circle-line">
                      <span
                        className="circle"
                        style={{ background: "#CCA9E7" }}
                        value="#CCA9E7"
                        onClick={handleThemeColor}
                      ></span>
                      {isSelected && themeColor === "#CCA9E7" && (
                        <div className="line"></div>
                      )}
                    </Col>

                    <Col className="circle-line">
                      <span
                        className="circle"
                        style={{ background: "#E5B0D0" }}
                        value="#E5B0D0"
                        onClick={handleThemeColor}
                      ></span>
                      {isSelected && themeColor === "#E5B0D0" && (
                        <div className="line"></div>
                      )}
                    </Col>
                  </Row>
                </div>

                <button className="btn-next" onClick={nextStep}>
                  Next
                </button>

                <Modal
                  show={show}
                  onHide={handleClose}
                  className="your-interests-modal"
                >
                  <Modal.Body>
                    <Card className="card-1">
                      <Card.Body>
                        <Row>
                          <Col>
                            Country <span>All</span>
                          </Col>
                          <Col className="d-flex align-items-center justify-content-end ">
                            {" "}
                            <label>
                              <Form.Check
                                className="yellow-checkbox"
                                type="checkbox"
                                checked={selectedItems[0] === "All"}
                                onChange={checkAllHandler}
                              />
                            </label>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                    <Card className="card-2">
                      <input
                        className="search-row"
                        type="text"
                        placeholder="Search Countries"
                        style={{ paddingLeft: "50px" }}
                        value={searchBar}
                        onChange={handleSearchChange}
                      />
                    </Card>
                    <Card className="card-3">
                      <Row className="row">
                        {filteredData.map((item, index) => (
                          <div style={{ display: "flex" }} key={index}>
                            <Col>{item.country}</Col>
                            <Col className="d-flex align-items-center justify-content-end">
                              <Form.Check
                                className="yellow-checkbox"
                                type="checkbox"
                                checked={selectedItems.includes(item.country)}
                                value={item.country}
                                onChange={checkboxHandler}
                              />
                            </Col>
                          </div>
                        ))}
                      </Row>
                    </Card>
                  </Modal.Body>
                  <Modal.Footer>
                    <p className="clear">
                      {/* <button onClick={uncheckAllHandler}>Clear All</button> */}
                      <button
                        className="yellow-btn"
                        onClick={uncheckAllHandler}
                      >
                        Close
                      </button>
                      <button className="yellow-btn" onClick={handleSaveClose}>
                        Save & Close
                      </button>
                    </p>
                  </Modal.Footer>
                </Modal>
              </div>
            </Card>
          </div>
        </>
      )}

      {step === 2 && (
        <div className="Congratulations-main">
          <Card className="card-step-2">
            <Row>
              <Col sm={6} className="col-image">
                <img src={images.congratulation} width="100%"></img>
              </Col>
              <Col sm={6} className="col-details">
                <div className="step-2">
                  <div className="dots">
                    <div className={`dot ${step === 1 ? "active" : ""}`}></div>
                    <div className={`dot ${step === 2 ? "active" : ""}`}></div>
                  </div>

                  <p className="title-1">Congratulations !</p>
                  <p className="title-2">
                    You have successfully created your MoofLife Account.
                  </p>
                  <p className="title-3">
                    You can now follow and interact with your favorite lives and
                    their moments.
                  </p>
                  <button
                    className="yellow-btn start-btn"
                    onClick={() => gotomoments()}
                    disabled={loading === true}
                  >
                    {loading ? (
                      <Spinner
                        animation="border"
                        variant="light"
                        style={{ width: "15px", height: "15px" }}
                      />
                    ) : (
                      "Start MoofLife Journey"
                    )}
                  </button>
                </div>
              </Col>
            </Row>
          </Card>
        </div>
      )}
    </div>
  );
};

export default YourInterests;
