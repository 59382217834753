import { createSlice } from "@reduxjs/toolkit";

const MostViewedSlice = createSlice({
    name: "MostViewedMoments",
    initialState: {
        MostViewedMoments: [],
        MostViewedMomentsTile: [],
        Page: 1,
        limitReached: false,
    },
    reducers: {
        getMostViewedMoments: (state, action) => {
            state.MostViewedMoments = action.payload;
        },

        setMostViewedMoments: (state, action) => {
            action.payload.map(mostViewed => {
                if (!state.MostViewedMomentsTile.some(item => item._id === mostViewed._id)) {
                    state.MostViewedMomentsTile.push(mostViewed);
                }
            })
        },

        setPage: (state, action) => {
            state.Page = action.payload
        },

        setLimitReached: (state, action) => {
            state.limitReached = action.payload
        },

    }
})

export const { getMostViewedMoments , setMostViewedMoments, setPage, setLimitReached } = MostViewedSlice.actions;

export default MostViewedSlice.reducer;