import "../../styles/CooperatePagesStyle/CooprerateHeaderStyle.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { images } from "../../utils/image";

const CooperateHeader = () => {
  const [scrolled, setScrolled] = useState(false);

  //Get the active tab in Navbar
  const CustomLink = ({ to, children }) => {
    const location = useLocation();
    const isActive = location.pathname === to;

    return (
      <li className={isActive ? "active" : ""}>
        <Link to={to}>{children}</Link>
      </li>
    );
  };

  //Fix the Navbar when scrolling down
  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <div>
      <Navbar
        collapseOnSelect
        expand="lg"
        fixed="top"
        className={`bg-body-tertiary Navbar-main navbar-fixed-top ${
          scrolled ? "scrolled" : ""
        }`}
      >
        <Container>
          <Navbar.Brand href="/">
            <img className="header-logo" src={images.moofLifeLogoTran}></img>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              <Nav.Link>
                <CustomLink to="/about-us">About Us</CustomLink>
              </Nav.Link>
              <Nav.Link>
                <CustomLink to="/mooflife-concept">MoofLife Concept</CustomLink>
              </Nav.Link>
              <Nav.Link>
                <CustomLink to="/contact-us">Contact Us</CustomLink>
              </Nav.Link>
              <Nav.Link>
                <CustomLink to="/privacy-policy">Privacy Policy</CustomLink>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default CooperateHeader;
