import React from "react";
import "../../styles/LandingPage/LandingPageStyle.css";
import NavigationBar from "../LandingPage/NavigationBar";
import TopMoments from "./TopMoments";
import NewLives from "./NewLives";
import MostViewedMoments from "./MostViewedMoments";
import NewMoments from "./NewMoments";
import FamousBirthday from "./FamousBirthday";
import OnThisDay from "./OnThisDay";
import Info from "./Info";
import Footer from "./Footer";

const LandingPage = () => {
  return (
    <div className="landing-grid-main">
      <NavigationBar />
      <TopMoments />
      <NewLives />
      <MostViewedMoments />
      <NewMoments />
      <FamousBirthday />
      <OnThisDay />
      <Info />
      <Footer />
    </div>
  );
};

export default LandingPage;
