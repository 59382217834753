import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Form from "react-bootstrap/Form";
import { Modal, Carousel, Spinner } from "react-bootstrap";
import copy from "copy-to-clipboard";
import InfiniteScroll from "react-infinite-scroller";
import { useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { getMostViewMoments } from "../store/action/MostViewedMomentsAction";
import {
  getSelectedMoments,
  getPermissionsToUpdate,
  getAuthorizedEmails,
  deleteMoment,
  linkMoment, 
  checkIfAlreadyConnected,
  unlinkMoment,
} from "../store/action/MomentAction";
import {
  getSelectedLifeMoments,
  getMomentsStartingFromSpecificId,
} from "../store/action/LifeAction";
import "../styles/LandingMiddleStyle.css";
import "../styles/ShareIconStyle.css";
import "../styles/LifeHeaderStyle.css";
import "../styles/LifePageStyle.css";
import { images } from "../utils/image";
import Comments from "./Comments/Comments";
import { getFromSessionStorage } from "../utils/storageHandler";
import ReportLifeMoment from "./ReportLifeMoment";
import { createImpression } from "../store/action/ImpressionAction";
import { fetchImpressionSet } from "../store/action/ImpressionAction";
import { updateMomentInteractionCount } from "../store/action/MostViewedMomentsAction";
import VideoPlayer from "../components/VideoPlayer";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import {
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  WhatsappIcon,
  XIcon,
} from "react-share";
import NavigationBar from "../views/LifePage/LifeNavbar.jsx";
import LifeLeft from "./LifePageParts/LifeLeft";
import LifeRight from "./LifePageParts/LifeRight";
import LifeHeader from "./LifePageParts/LifeHeader";
import {
  fetchLife,
  resetLifeMoments,
  resetPage,
  resetLimitReached,
  resetLife,
  getLivesForUser
} from "../store/action/LifeAction";
import PopUpModal from "./PopUpModal";
import ConfirmationPopup from "./ConfirmationPopup";
import Flag from 'react-world-flags';
import { countryList } from '../utils/CountryList';
import { Helmet } from 'react-helmet-async';

const LifePage = ({ area }) => {
  //logged username first letter
  // const user = false;
  // var name = user;
  // var initials = name ? name.charAt(0) : "";
  const limit = 10;
  const selectedLife = useSelector((state) => state.Life.Life);

  const dispatch = useDispatch();
  const scrollableAreaRef = useRef(null);
  const textRef = useRef();
  const params = useParams();
  const prevMomentId = useRef(params.moment_id);
  const prevLifeId = useRef(params.life_id);
  let rightSideTopAd = null;
  const GeneralAds = useSelector((state) => state.General.Advertisements);
  const { life_id } = useParams();

  if (GeneralAds.length > 0) {
    GeneralAds.map((adItem) => {
      if (adItem.placement === "right_side_top") {
        rightSideTopAd = adItem.ad_url;
      }
    });
  }

  const isLifePage = params && params.life_id ? true : false;
  let isLifeStartingFromSpecificMoment =
    params && params.moment_id ? true : false;

  //check logged users
  const [userLogin, setUserLogin] = useState("");

  //copy text
  const [showTooltip, setTooltipShow] = useState(false);

  //Comment section
  const [commentOpen, setCommentOpen] = useState(false);
  const [showAllImages, setShowAllImages] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showImg, setShowImg] = useState(false);
  const [selectedMomentImages, setSelectedMomentImages] = useState([]);
  const [commentMoment, setCommentMoment] = useState("");
  const [showPopup, setShowPopUp] = useState(false);
  const [shareMoment, setShareMoment] = useState("");

  //Report Modal
  const [show, setShow] = useState(false);
  const [reportMomentId, setReportMomentId] = useState("");

  // Impression Modal
  const [showImpressionSet, setShowImpressionSet] = useState("");

  // moments
  const [showMore, setShowMore] = useState(false);
  const [currentMoment, setCurrentMoment] = useState("");

  //image privacy modal
  const [modalShow, setModalShow] = useState(false);

  //Share
  const [shareBoxVisible, setShareBoxVisible] = useState(false);

  //Impression visible
  const [impressionBoxVisible, setImpressionBoxVisible] = useState(false);

  const [sidebarTop, setSidebarTop] = useState(undefined);
  const [lifeLeftTop, setLifeLeftTop] = useState(undefined);
  const [expanded, setExpanded] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingLink, setLoadingLink] = useState(false);
  const [permissionGranted, setPermissionGrant] = useState(false);

  const scrollDiv = document.getElementById("scrollerDiv");
  const lifeLeftDiv = document.getElementById("lifeLeftDiv");

  const shareBoxRef = useRef(null);

  const [themeColor, setThemeColor] = useState("");
  const [initials, setInitials] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  //check logged users
  const [user, setUser] = useState("");
  const dropdownRef = useRef(null);
  const [showCreateDropdown, setShowCreateDropdown] = useState(false);
  const [livesForUser, setLivesForUser] = useState("");
  const [lifeData, setLifeData] = useState(null);
  const [momentIdForLink, setMomentIdForLink] = useState(null);
  const [lifeIdForLink, setLifeIdForLink] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const [confirmationPopupShow, setConfirmationPopupShow] = useState(false);
  const [momentIndex, setMomentIndex] = useState("");
  const [isMomentDelete, setIsMomentDelete] = useState(false);
  const [message, setMessage] = useState("");
  const [popupModalShow, setPopupModalShow] = useState(false);
  const [popupMessages, setPopupMessages] = useState({
    title: "",
    subtitle1: "",
    subtitle2: "",
    button1: "",
    button2: "",
  });

  const [unlinkId, setUnlinkId] = useState("");

  const [selectedLifeId, setSelectedLifeId] = useState(null);
  const [showLinkMomentPopup, setShowLinkMomentPopup] = useState(false);
  // const [isChecked, setIsChecked] = useState(false);
  const [showConfirmLink, setShowConfirmLink] = useState(false);
  const [mooflifeAdmin, setMooflifeAdmin] = useState(false);

  useEffect(() => {
    const userDetails = getFromSessionStorage("userDetails", true);
    setUserLogin(userDetails);

    dispatch(resetLife());
    dispatch(resetLifeMoments());
    dispatch(resetPage());
    dispatch(resetLimitReached());
    dispatch(fetchLife(life_id)).then((response) => {
      if (!response.data.success) {
        navigate("/404");
      }else {
        if(response && response.data && response.data.success){
          setLifeData(response.data.data);
          dispatch(
            getPermissionsToUpdate({
              lifeId: response.data.data?.life_id,
              userEmail: userDetails?.email,
            })
          ).then((permission) => {
            setPermissionGrant(permission);
          });
        }
      }
    });
    scrollableAreaRef.current.focus();
    const lifeRight = document
      .querySelector(".life-right-full")
      .getBoundingClientRect();
    const lifeLeft = document
      .getElementById("lifeLeftDiv")
      .getBoundingClientRect();
    setSidebarTop(lifeRight.top);
    setLifeLeftTop(lifeLeft.top);

    const themeColor = userDetails ? userDetails.theme_colour : "";
    setThemeColor(themeColor);
    const user = userDetails ? userDetails.first_name : "";
    setUser(user);
    const firstInitial = userDetails ? userDetails.first_name.charAt(0) : "";
    const lastInitial =
      userDetails && userDetails.last_name
        ? userDetails.last_name.charAt(0)
        : "";
    var initials = firstInitial + lastInitial;
    setInitials(initials);
    const imageUrl = userDetails ? userDetails.image_url : "";
    setImageUrl(imageUrl);
    if(userDetails){
      requestLivesForUser(userDetails);
      checkAuthorizedEmails(userDetails);
    }
   
  }, []);

  const checkAuthorizedEmails = async (userDetails) => {
    if(userDetails && userDetails.email){
      dispatch(await getAuthorizedEmails({userEmail: userDetails.email}))
      .then((res) => {
        if (res && res.success && res.data) {
          setMooflifeAdmin(res.data.result);
        } 
      });
    }
  };

  useEffect(() => {
    dispatch(resetLifeMoments());
    if (lifeData && lifeData._id) {
      loadItems();
    }
  }, [lifeData]);

  useEffect(() => {
    if (!sidebarTop) return;

    scrollDiv.addEventListener("scroll", isSticky);
    return () => {
      scrollDiv.removeEventListener("scroll", isSticky);
    };
  }, [sidebarTop]);

  useEffect(() => {
    if (!lifeLeftTop) return;

    scrollDiv.addEventListener("scroll", lifeleftSticky);
    return () => {
      scrollDiv.removeEventListener("scroll", lifeleftSticky);
    };
  }, [lifeLeftTop]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (shareBoxRef.current && !shareBoxRef.current.contains(event.target)) {
        setShareBoxVisible(false);
      }

      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowCreateDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const requestLivesForUser = async (userDetails) => {
    dispatch(await getLivesForUser({ email: userDetails.email })).then(
      (res) => {
        if (res.success && res.data) {
          setLivesForUser(res.data);
        }
      }
    );
  };

  const navigateMomentCreate = (lifeData) => {
    if (lifeData) {
      const lifeInState = { lifeData: lifeData };
      navigate("/create-moment", { state: lifeInState });
    }
  };

  const isSticky = () => {
    const sidebarEl = document.querySelector(".life-right-full");
    const scrollTop = scrollDiv.scrollTop; // Using scrollDiv.scrollTop instead of window.scrollY
    const scrollThreshold =
      sidebarTop + sidebarEl.offsetHeight / 2 - scrollDiv.clientHeight / 2;

    if (scrollTop >= scrollThreshold) {
      sidebarEl.classList.add("is-sticky");
      const remainingScroll = scrollTop - scrollThreshold;

      // Calculate the maximum top position dynamically based on the scrollDiv height
      const maxTop = scrollDiv.clientHeight / 3 - sidebarEl.offsetHeight / 1.1;

      // Calculate the top position to ensure visibility
      const topPosition = Math.min(remainingScroll, maxTop);
      sidebarEl.style.top = `${topPosition}px`;
    } else {
      sidebarEl.classList.remove("is-sticky");
      sidebarEl.style.top = "auto";
    }
  };

  const lifeleftSticky = () => {
    const scrollTop = scrollDiv.scrollTop;
    const scrollThreshold =
      lifeLeftTop + lifeLeftDiv.offsetHeight - scrollDiv.clientHeight;

    if (scrollTop >= scrollThreshold) {
      lifeLeftDiv.classList.add("is-sticky");
      const remainingScroll = scrollTop - scrollThreshold;

      // Calculate the maximum top position dynamically based on the scrollDiv height
      const maxTop = scrollDiv.clientHeight - lifeLeftDiv.offsetHeight;

      // Calculate the top position to ensure visibility
      const topPosition = Math.min(remainingScroll, maxTop);
      if (scrollDiv.clientHeight > 1055) {
        lifeLeftDiv.style.top = "80px";
      } else {
        lifeLeftDiv.style.top = `${topPosition}px`;
      }
    } else {
      lifeLeftDiv.classList.remove("is-sticky");
      lifeLeftDiv.style.top = "auto";
    }
  };

  const ImpressionSet = useSelector(
    (state) => state.ImpressionSet.impressionSetApi
  );

  useEffect(() => {
    if (params.moment_id) {
      if (params.moment_id !== prevMomentId.current) {
        window.location.reload();
      }

      prevMomentId.current = params.moment_id;
    }
  }, [params.moment_id]);

  useEffect(() => {
    if (params.life_id) {
      if (params.life_id !== prevLifeId.current) {
        window.location.reload();
      }

      prevLifeId.current = params.life_id;
    }
  }, [params.life_id]);

  // Moments
  const LifeMoments = useSelector((state) => state.Life.LifeMoments);
  const lifeMomentPage = useSelector((state) => state.Life.Page);
  const limitReached = useSelector((state) => state.Moments.limitReached);
  const lifeMomentLimitReached = useSelector(
    (state) => state.Life.limitReached
  );
  const momentSorting = useSelector((state) => state.Life.momentSorting);
  const selectedMoments = isLifePage ? LifeMoments : [];
  const isLimitReached = isLifePage ? lifeMomentLimitReached : limitReached;

  const userData = getFromSessionStorage("userDetails", true);

  const showReportModal = (_id) => {
    setReportMomentId(_id);
    setShow(true);
  };

  const openModal = (images, selectedIndex) => {
    setSelectedMomentImages(images);
    setSelectedImageIndex(selectedIndex);
    setShowImg(true);
  };

  const handleClose = () => {
    setShowImg(false);
    setShowPopUp(false);
  };

  const handleImpressionSet = (id) => {
    setShowImpressionSet(true);
    dispatch(fetchImpressionSet(id));
  };

  const handleCloseImpressionSet = () => {
    setShowImpressionSet(false);
  };

  const toggleShow = (id, action) => {
    setCurrentMoment(id);
    setShowMore(action === "less" ? false : true);
    dispatch(updateMomentInteractionCount({ moment_id: id }));
  };

  //Function to add text to clipboard
  const copyToClipboard = () => {
    let copyText = textRef.current.value;
    let isCopy = copy(copyText);
    if (isCopy) {
      console.log("Copied to Clipboard");
      setTooltipShow(true);

      setTimeout(() => {
        setTooltipShow(false);
      }, 3000);
    }
  };

  const loadItems = async () => {
    const userDetails = getFromSessionStorage("userDetails", true);
    const loggedInUserId = userDetails?.id ? userDetails.id : null;
    if (isLifePage && lifeData?._id) {
      if (isLifeStartingFromSpecificMoment) {
        dispatch(
          getMomentsStartingFromSpecificId(
            {
              //page: lifeMomentPage,
              momentId: params.moment_id,
              loggedInUserId: loggedInUserId,
              user_email: userDetails?.email ? userDetails.email : "",
              sorting: momentSorting ? momentSorting : -1,
            }
            // lifeMomentPage
          )
        );
      } else {
      if(lifeData&&lifeData._id){
        dispatch(
          getSelectedLifeMoments(
            {
              selector: { life: lifeData?._id },
              page: lifeMomentPage,
              limit: limit,
              loggedInUserId: loggedInUserId,
              sorting: momentSorting ? momentSorting : -1,
              life_id: lifeData ? lifeData.life_id : null,
              connectors: lifeData ? lifeData.connected_lives : [],
              user_email: userDetails?.email ? userDetails.email : ""
            },
            lifeMomentPage
          )
        );
      }
        
      }
    }
  };

  useEffect(() => {
    const userDetails = getFromSessionStorage("userDetails", true);
    setUserLogin(userDetails);
  }, []);

  const handleCommentOpen = (momentId) => {
    setCommentMoment(momentId);
    if (!userLogin) {
      setShowPopUp(true);
    }

    if (commentMoment === "" || commentMoment === momentId) {
      setCommentOpen(!commentOpen);
    }
  };

  //handle impressions
  const handleEmojiClick = (type, momentId) => {
    dispatch(
      createImpression({
        moment_id: momentId,
        loggedInUserId: userData?.id,
        impression_type: type,
        page: "life",
      })
    );
  };

  const totalFeedbacks = (reactionCounts) => {
    if (reactionCounts) {
      return Object.values(reactionCounts).reduce(
        (sum, count) => sum + count,
        0
      );
    }
  };

  const getTopReactions = (reactionCounts) => {
    // Create an array of objects with reaction type and count
    if (reactionCounts) {
      const reactionArray = Object.entries(reactionCounts).map(
        ([type, count]) => ({ type, count })
      );

      // Sort the array based on count in descending order
      reactionArray.sort((a, b) => b.count - a.count);

      // Get the top 3 reaction types
      const topReactions = reactionArray
        .filter((reaction) => reaction.count > 0)
        .slice(0, 3);

      // Return an array containing only the reaction types
      return topReactions.map((reaction) => reaction.type);
    }
  };

  const getEmojiAsset = (type) => {
    switch (type) {
      case "like":
        return images.LikeIcon;
      case "great":
        return images.GreatIcon;
      case "emotional":
        return images.EmotionalIcon;
      case "nostalgic":
        return images.NostalgicIcon;
      case "wish":
        return images.WishIcon;
      case "part":
        return images.PartOfTheMomentIcon;
      case "witnessed":
        return images.WitnessedIcon;
      default:
        return null;
    }
  };

  const getCountByType = (type, impressionSet) => {
    return impressionSet.filter(
      (impression) => impression.impression_type === type
    ).length;
  };

  const handleShareClick = (momentId) => {
    setShareMoment(momentId);
    if (shareMoment === "" || shareMoment === momentId) {
      setShareBoxVisible(!shareBoxVisible);
    }
  };

  const handleImpressionClick = () => {
    if (!userLogin) {
      setShowPopUp(true);
    } else setImpressionBoxVisible(!impressionBoxVisible);
  };

  const showPopupModal = () => {
    if (!userLogin) {
      setShowPopUp(true);
    } else {
      setShowPopUp(false);
    }
  };

  const currentUrl =
    window.location.protocol +
    "//" +
    window.location.hostname +
    (window.location.port ? ":" + window.location.port : "");

  //Life Right
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const [mostViewedMomentsList, setMostViewedMomentsList] = useState([]);
  const [momentsLoadCompleted, setMomentsLoadCompleted] = useState(false);

  const Life = useSelector((state) => state.Life.Life);
  const MostViewedMoments = useSelector(
    (state) => state.MostViewedMoments.MostViewedMoments
  );

  const rightSideTopAdRedirectUrl =
    Life && Life.ad_top_right_redirect_url
      ? Life.ad_top_right_redirect_url
      : "#";
  const rightSideBottomAdRedirectUrl =
    Life && Life.ad_bottom_right_redirect_url
      ? Life.ad_bottom_right_redirect_url
      : "#";

  useEffect(() => {
    dispatch(getMostViewMoments());
  }, []);

  useEffect(() => {
    const isDisplayAll = state ? state.fullyMostViewed : "false";
    if (MostViewedMoments.length > 0) {
      if (!isDisplayAll || isDisplayAll === "false") {
        if (MostViewedMoments.length >= 2) {
          setMostViewedMomentsList([
            MostViewedMoments[0],
            MostViewedMoments[1],
          ]);
        } else if (MostViewedMoments.length === 1) {
          setMostViewedMomentsList([MostViewedMoments[0]]);
        }
      } else {
        setMostViewedMomentsList(MostViewedMoments);
        setMomentsLoadCompleted(true);
      }
    }
  }, [MostViewedMoments]);

  const handleDropDown = async (isOpen, life, momentId) => {
    setLoading(true);
    if (isOpen && lifeData) {
      dispatch(
        await getPermissionsToUpdate({
          lifeId: lifeData.life_id,
          userEmail: userData.email,
        })
      ).then((permission) => {
        setPermissionGrant(permission);
        setLoading(false);
      });
    }

    if(isOpen && momentId && lifeData){
        const isMomentConnected = await dispatch(checkIfAlreadyConnected({momentId: momentId, lifeId: lifeData.life_id}));
        if(isMomentConnected && isMomentConnected.success){
          setIsConnected(isMomentConnected.data)
        }
    }
  };

  const updateMoment = (momentId) => {
    navigate(`/update-moment/${momentId}`);
  };


  const handleConfirmationPopupShow = (momentId) => {
    setPopupMessages({
      title: "Warning!",
      subtitle1: "Are you sure you want to delete this moment? ",
      subtitle2:
        "This action is irreversible and the image cannot be recovered once deleted.",
      button1: "Delete",
      button2: "Cancel",
    });
    setIsMomentDelete(true);
    setConfirmationPopupShow(true);
    setMomentIndex(momentId);
  };

  const handleDeleteMoment = (momentId) => {
    dispatch(
      deleteMoment({
        moment_id: momentId,
      })
    ).then(async (res) => {
      if (res && res.success) {
        //delete successfull
          setPopupModalShow(true);
        setIsMomentDelete(true);
        setMessage("Delete Successful");

        setTimeout(() => {
          setPopupModalShow(false);
        }, 3000);

      } else {
        //delete fail
        setPopupModalShow(true);
        setIsMomentDelete(true);
        setMessage("Delete Fail");

        setTimeout(() => {
          setPopupModalShow(false);
        }, 3000);
      }

    });
  };


  const handleLinkMomentPopupOpen = (momentId) => {
    if(momentId){
      setShowLinkMomentPopup(true);
      setMomentIdForLink(momentId);
    }
  };

  const handleLinkMomentPopupClose = () => {
    setShowLinkMomentPopup(false);
    setShowConfirmLink(false);
  };

  // const handleCheck = (id) => {
  //   setIsChecked((prevState) => ({
  //     ...prevState,
  //     [id]: !prevState[id],
  //   }));
  // };

  // const selectLinkMoment = () => {
  //   setShowConfirmLink(true);
  //   setShowLinkMomentPopup(false);
  // };

  const selectLifeForLink = (lifeId) => {
    setSelectedLifeId(lifeId);
    if(lifeId){
      setLifeIdForLink(lifeId);
      // handleCheck(lifeId);
    }
  };

  const handleLinkMoment = async () => {
    setLoadingLink(true);
    if(momentIdForLink && lifeIdForLink){
      const linkMomentRes = await dispatch(linkMoment({momentId: momentIdForLink, lifeId: lifeIdForLink}));
      if (linkMomentRes && linkMomentRes.success && linkMomentRes.data) {
        const message = linkMomentRes.data.message ? linkMomentRes.data.message : "Successful! Moment Connection Completed.";
          setMessage(message);
          setPopupModalShow(true);
          setLoadingLink(false);
          setShowLinkMomentPopup(false);
          setSelectedLifeId("");

          setTimeout(() => {
            setPopupModalShow(false);
          }, 3000);
      }else {
          let error = null;
          if(linkMomentRes.error){
            error = linkMomentRes.error
          }

          const errorMsg = error ? error : 'Failed to connect the Moment with selected Life!'

          setMessage(errorMsg);
          setPopupModalShow(true);
          setShowLinkMomentPopup(false);
          setLoadingLink(false);
          setTimeout(() => {
            setPopupModalShow(false);
          }, 3000);
      }
    }
  };

  const confirmUnlinkMoment = (id) => {
    setShowConfirmLink(true);
    setUnlinkId(id);

  }

  const handleUnLink = async(momentId) => {
    setLoadingLink(true);
    const unlinkRes = await dispatch(unlinkMoment({momentId: momentId, lifeId: lifeData.life_id}));
    if(unlinkRes && unlinkRes.success){
      const message =  unlinkRes.data;
      setMessage(message);
      setShowConfirmLink(false);
      setLoadingLink(false);
      setPopupModalShow(true);

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }else {
      let error = null;
      if(unlinkRes.error){
        error = unlinkRes.error;
      }
      const errorMsg = error ? error : 'Moment unlinking failed!';
      setMessage(errorMsg);
      setPopupModalShow(true);
      setShowConfirmLink(false);
      setLoadingLink(false);
      setTimeout(() => {
        setPopupModalShow(false);
      }, 3000);
    }
    
  };

  let LifeDescription =
    Life && Life.life_description ? Life.life_description : "";
  const descriptionContent = expanded
    ? LifeDescription
    : LifeDescription.slice(0, 300);
  const leftSideBottomAdRedirectUrl =
    Life && Life.ad_bottom_left_redirect_url
      ? Life.ad_bottom_left_redirect_url
      : "#";

  return (
    <div className="old-life">
      <Helmet>
      <meta name="google-adsense-account" content="ca-pub-9623894499006251"/>
      </Helmet>
      <div
        id="scrollerDiv"
        className="dummy-scroll"
        style={{
          height: "100vh",
          overflowY: "auto",
          overflowX: "hidden"
        }}
        ref={scrollableAreaRef}
        tabIndex={0}
      >
      <PopUpModal
          title={message}
          show={popupModalShow}
          setPopupModalShow={(bool) => setPopupModalShow(bool)}
          isMomentDelete={isMomentDelete}
        ></PopUpModal>

        <ConfirmationPopup
          title={popupMessages.title}
          subtitle1={popupMessages.subtitle1}
          subtitle2={popupMessages.subtitle2}
          button1={popupMessages.button1}
          button2={popupMessages.button2}
          confirmationPopupShow={confirmationPopupShow}
          setConfirmationPopupShow={(bool) => setConfirmationPopupShow(bool)}
          handleDeleteMoment={handleDeleteMoment}
          momentIndex={momentIndex}
          isMomentDelete={isMomentDelete}
          setIsMomentDelete={setIsMomentDelete}
          setPopupMessages={setPopupMessages}
        ></ConfirmationPopup> 

        {/* Header Part */}
        <NavigationBar />

        <InfiniteScroll
          pageStart={1}
          loadMore={loadItems}
          hasMore={!isLimitReached}
          loader={<p className="loading-word">Loading...</p>}
          useWindow={false}
          initialLoad={true}
        >
          <div className="life-main">
            {userLogin && (
              <ReportLifeMoment
                title="Report Moment"
                subtitle="Why do you want to report this moment?"
                lifeMomentId={reportMomentId}
                show={show}
                setShow={(bool) => setShow(bool)}
              ></ReportLifeMoment>
            )}
            <div className="life-header">
              <Row>
                <Col className="left-row">
                  {/* Header part */}
                  <LifeHeader />

                  <Container>
                    <Row>
                      {/* life left */}
                      <Col
                        sm={4}
                        className="page-left"
                        style={{ paddingLeft: "0px" }}
                      >
                        <LifeLeft />
                      </Col>

                      {/* life middle */}
                      <Col
                        sm={8}
                        className="page-middle"
                        style={{ padding: "0px" }}
                      >
                        {/* Middle Part */}
                        <div>
                          {area === "landingPage" && rightSideTopAd !== null ? (
                            <div className="middle-img-part">
                              <img
                                src={rightSideTopAd}
                                alt="Lading Page Top Ad"
                                style={{ marginTop: "50px" }}
                              />

                              <div className="ad-word">Ad</div>
                            </div>
                          ) : area === "lifePage" &&
                            selectedLife.ad_space_top_right !== null ? (
                            <div className="middle-img-part">
                              <img
                                src={selectedLife.ad_space_top_right}
                                alt="Life Page Top Ad"
                              />

                              <div className="ad-word">Ad</div>
                            </div>
                          ) : (
                            <div className="middle-img-part">
                              <img
                                src={images.googleAdd1}
                                alt="Default Ad"
                                style={{ marginTop: "0px" }}
                              />

                              <div className="ad-word">Ad</div>
                            </div>
                          )}
                        </div>

                        <div>
                          <div className="create-container">
                            {userLogin ? (
                              imageUrl ? (
                                <div>
                                  <img
                                    src={imageUrl}
                                    className="user-image"
                                  ></img>
                                </div>
                              ) : (
                                <div
                                  className="user-image-letter"
                                  id="name"
                                  style={{
                                    background: themeColor || "#E0E0E0",
                                  }}
                                >
                                  {initials}
                                </div>
                              )
                            ) : (
                              <div>
                                <img src={images.UserImage} />
                              </div>
                            )}

                            <div className="dropdown" ref={dropdownRef}>
                              <div
                                className="create-div"
                                type="text"
                                readonly
                                onClick={() =>
                                  setShowCreateDropdown(
                                    (prevState) => !prevState
                                  )
                                }
                              >
                                Create a new Life Moment
                              </div>
                              {showCreateDropdown && (
                                <div className="dropdown-content">
                                  {(permissionGranted || mooflifeAdmin) && (
                                    <div className="select-life-btn"
                                    onClick={() =>
                                      navigateMomentCreate(selectedLife)
                                    }
                                  >
                                    Create a Moment on {selectedLife.life_name}
                                  </div>
                                  )}
                                  
                                  <p className="dropdown-topic">
                                    {userLogin ? (
                                      livesForUser.length < 0 ? (
                                        <>You do not have a Life profile to create a Moment..</>
                                      ):(
                                        <>Select a life to create moments...</>
                                      )
                                      
                                    ):(
                                      <>You need to Login to create a Life Moment</>
                                    )}
                                  </p>
                                  {livesForUser &&
                                    livesForUser.length > 0 &&
                                    livesForUser.map((life) => {
                                      return (
                                        <div className="dropdown-item">
                                          <img
                                            src={
                                              life.life_logo
                                                ? life.life_logo
                                                : images.UserImage
                                            }
                                            className="create-dropdown-img"
                                          ></img>
                                          <div>
                                            <div
                                              className="life-name"
                                              onClick={() =>
                                                navigateMomentCreate(life)
                                              }
                                            >
                                              {life.life_name}
                                            </div>
                                            <div className="life-tag">
                                              {life.life_title
                                                ? life.life_title.length > 30 ? life.life_title.slice(0, 30) + "..." : life.life_title
                                                : ""}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}

                                  {userLogin ? (
                                    <div className="dropdown-item">
                                      {
                                        mooflifeAdmin && 
                                        <>
                                          <i
                                            class="bi bi-plus-circle-fill create-dropdown-img"
                                            style={{
                                              color: "#DFEAFF",
                                              fontSize: "40px",
                                            }}
                                          ></i>
                                          <div>
                                            <div className="new-life-link">
                                              <Link to="/select-package">
                                                Create a new Life Profile
                                              </Link>
                                            </div>
                                          </div>
                                        </>
                                      }        
                                    </div>
                                  ) : (
                                    <div className="dropdown-item">
                                      <div>
                                        <img
                                          src={images.UserImage}
                                          className="create-dropdown-img"
                                        ></img>
                                      </div>
                                      <div>
                                        <div>
                                          <Link
                                            className="link-tag"
                                            to="/login"
                                          >
                                            Log In
                                          </Link>{" "}
                                          |&nbsp;
                                          <Link
                                            className="link-tag"
                                            to="/sign-up"
                                          >
                                            Sign Up
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div
                          className="middle-main"
                          style={{ borderRadius: "13px" }}
                        >
                          <div className="moments-container">
                            {/* users */}
                            {selectedMoments && selectedMoments.length > 0 ? (
                              <>
                                {selectedMoments.map((moment, index) => {
                                  const { life } = moment;
                                  return (
                                    <Card className="card" key={index}>
                                      <Card.Body>
                                        <Row className="life">
                                          <Col className="life-logo" sm={1}>
                                            <Link to={`/${life.verified_name?life.verified_name:life.life_id}`}>
                                              <img src={life.life_logo}></img>
                                            </Link>
                                          </Col>
                                          <Col className="life-content">
                                            <h6 className="title-1">
                                              <Link to={`/${life.verified_name?life.verified_name:life.life_id}`}>
                                                {life.life_name}
                                                <span>
                                                  <img
                                                    className="verified-icon"
                                                    src={images.VerifiedIcon}
                                                  ></img>
                                                </span>
                                              </Link>
                                            </h6>
                                            <h6 className="title-2">
                                              {moment.moment_date} {moment.era && moment.era === 'BCE' ? 'BCE' : ''}
                                            </h6>
                                          </Col>
                                          <Col className="moment-category" sm={3}>
                                            {moment.world_moment === true ? (
                                              <span
                                                className="category-word"
                                                style={{
                                                  background: "#FFF1C6",
                                                }}
                                              >
                                                World Moment
                                              </span>
                                            ) : moment.national_moment.length >
                                              0 ? (
                                              <span
                                                className="category-word"
                                                style={{
                                                  background: "#D6E4FF",
                                                }}
                                              >
                                                National Moment
                                              </span>
                                            ) : moment.top_moment === true ? (
                                              <span
                                                className="category-word"
                                                style={{
                                                  background: "#D6E4FF",
                                                }}
                                              >
                                                Top Moment
                                              </span>
                                            ) : moment.sustainability_moment ===
                                              true ? (
                                              <span
                                                className="category-word"
                                                style={{
                                                  background: "#DBFFDE",
                                                }}
                                              >
                                                Sustainability
                                              </span>
                                            ) : null}
                                            {moment.world_moment === true ? (
                                              <img
                                                src={images.GlobalMoment}
                                                alt="World Moment"
                                              />
                                            ) : moment.national_moment.length > 0 ? (
                                              moment.national_moment.slice(0,5).map((countryName, index) => {
                                                const countryCode = countryList.find(
                                                  country => country.country === countryName
                                                )
          
                                                if(countryCode) {
                                                  return (
                                                    <Flag key={index} code={countryCode.code} />
                                                  )
                                                }})
                                            ) : (
                                              moment.top_moment === true && (
                                                <img
                                                  src={images.TopMoment}
                                                  alt="Top Moment"
                                                />
                                              )
                                            )}
                                            {moment.sustainability_moment ===
                                              true && (
                                              <img
                                                src={
                                                  images.SustainabilityMoment
                                                }
                                                alt="Sustainability Moment"
                                              />
                                            )}
                                          </Col>
                                        </Row>
                                        <Row className="moment">
                                          <Col className="moment-logo" sm={1}>
                                            {userLogin ? (
                                              <Dropdown
                                                onToggle={(isOpen) =>
                                                  handleDropDown(
                                                    isOpen,
                                                    moment.life,
                                                    moment._id
                                                  )
                                                }
                                              >
                                                <Dropdown.Toggle id="dropdown-basic">
                                                  <img
                                                    src={images.MoofIcon}
                                                  ></img>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                  {/* <Dropdown.Item>
                                          Edit Moment
                                        </Dropdown.Item> */}
                                                {loading ? (
                                                  <Spinner
                                                    animation="border"
                                                    variant="dark"
                                                    style={{
                                                      width: "15px",
                                                      height: "15px",
                                                      textAlign: "center",
                                                      margin: "auto",
                                                      display: "flex",
                                                      justifyContent: "center",
                                                    }}
                                                  />
                                                ): !permissionGranted ? (
                                                    <Dropdown.Item
                                                      onClick={() =>
                                                        showReportModal(
                                                          moment._id
                                                        )
                                                      }
                                                      key="report-moment"
                                                    >
                                                      Report Moment
                                                    </Dropdown.Item>
                                                  ) : (
                                                    <>
                                                      <Dropdown.Item
                                                        onClick={() =>
                                                          updateMoment(
                                                            moment._id
                                                          )
                                                        }
                                                        key="update-moment"
                                                      >
                                                        Update Moment
                                                      </Dropdown.Item>
                                                      <Dropdown.Item
                                                        // onClick={() =>
                                                        //   handleDeleteMoment(moment._id)
                                                        // }
                                                        onClick={() =>
                                                          handleConfirmationPopupShow(
                                                            moment._id
                                                          )
                                                        }
                                                      >
                                                        Delete Moment
                                                      </Dropdown.Item>
                                                      {
                                                        isConnected && (permissionGranted || mooflifeAdmin)?
                                                          <Dropdown.Item
                                                            onClick={() =>
                                                              // handleUnLink(moment._id)
                                                              confirmUnlinkMoment(moment._id)
                                                            }
                                                          >
                                                            Disconnect from Life
                                                          </Dropdown.Item>
                                                        :
                                                          <Dropdown.Item
                                                            onClick={() =>
                                                              handleLinkMomentPopupOpen(moment._id)
                                                            }
                                                          >
                                                            Connect to my Life
                                                          </Dropdown.Item>
                                                      }
                                                    </>
                                                  )}
                                                </Dropdown.Menu>
                                              </Dropdown>
                                            ) : (
                                              <img
                                                src={images.MoofIcon}
                                                onClick={showPopupModal}
                                              ></img>
                                            )}
                                          </Col>
                                          <Col className="moment-title">
                                            <Row>
                                              <Col>
                                                <h4 className="title-1">
                                                  {moment.moment_title}
                                                </h4>
                                              </Col>
                                              <Col
                                                sm={1}
                                                style={{ textAlign: "right" }}
                                              >
                                                {moment.userImpression && (
                                                  <div>
                                                    <img
                                                      src={getEmojiAsset(
                                                        moment.userImpression
                                                      )}
                                                      width="20px"
                                                    />
                                                  </div>
                                                )}
                                              </Col>
                                            </Row>
                                            <div className="title-2">
                                              {moment.moment_type.map(
                                                (type, index) => (
                                                  <>| {type} </>
                                                )
                                              )}
                                              {moment.moment_type.length >
                                                0 && <span>|</span>}
                                            </div>
                                            <h4 className="title-2"></h4>
                                          </Col>
                                        </Row>
                                        <Card.Text>
                                          <div class="moment-content">
                                            <p>
                                              {showMore &&
                                              currentMoment === moment._id
                                                ? moment.moment_details
                                                : moment.moment_details.slice(
                                                    0,
                                                    1500
                                                  )}
                                              {moment.link_descriptions}
                                              {moment.moment_details.length >
                                                1500 &&
                                                (showMore &&
                                                currentMoment === moment._id ? (
                                                  <span
                                                    className="seemore"
                                                    onClick={() =>
                                                      toggleShow(
                                                        moment._id,
                                                        "less"
                                                      )
                                                    }
                                                  >
                                                    see less...
                                                  </span>
                                                ) : (
                                                  <span
                                                    className="seemore"
                                                    onClick={() =>
                                                      toggleShow(
                                                        moment._id,
                                                        "more"
                                                      )
                                                    }
                                                  >
                                                    see more...
                                                  </span>
                                                ))}
                                            </p>
                                            <p className="link-hashtag">
                                              {moment.external_link_title && (
                                                <>External Reference :&nbsp;</>
                                              )}
                                              <a
                                                target="_blank"
                                                href={moment.external_link}
                                              >
                                                {moment.external_link_title}
                                              </a>
                                              {moment.hashtags.map(
                                                (hashtag) => (
                                                  <span>#{hashtag}</span>
                                                )
                                              )}
                                            </p>

                                            {moment.images.length > 0 && (
                                                    <div
                                                    className="image-gallery"
                                                    style={{
                                                      display: "grid",
                                                      gridTemplateColumns:
                                                        moment.images.length === 1
                                                          ? "1fr"
                                                          : moment.images.length === 2
                                                          ? "repeat(2, 1fr)"
                                                          : "repeat(2, 1fr)",
                                                      gridAutoRows: "auto",
                                                      height: moment.images && (moment.images.length === 2 || moment.images.length === 3) ? "200px" : moment.images && moment.images.length === 1 ? "500px" : "400px"
                                                    }}
                                                  >
                                                    {moment.images.length === 3 ? (
                                                      moment.images
                                                        .slice(0, 2)
                                                        .map((image, imageIndex) => (
                                                          <div
                                                            onClick={() =>
                                                              openModal(
                                                                moment.images,
                                                                imageIndex
                                                              )
                                                            }
                                                            key={imageIndex}
                                                            className={`image-wrapper${
                                                              imageIndex === 0
                                                                ? " full-width"
                                                                : ""
                                                            }`}
                                                            style={{
                                                              height: "100%",
                                                              width: "100%",
                                                              display: "flex",
                                                              justifyContent: "center",
                                                              alignItems: "center",
                                                              margin: "auto",
                                                            }}
                                                          >
                                                            {image.media_type ===
                                                              "image" ||
                                                            !image.media_type ? (
                                                              <img
                                                                src={image.url}
                                                                alt={`Image ${
                                                                  imageIndex + 1
                                                                }`}
                                                                style={{
                                                                  maxWidth: "100%",
                                                                  maxHeight: "100%",
                                                              }}
                                                              />
                                                            ) : (
                                                              image.media_type ===
                                                                "video" && (
                                                                <VideoPlayer source={image.url}/>
                                                              )
                                                            )}
                                                            {imageIndex === 1 &&
                                                              moment.images.length >
                                                                2 &&
                                                              !showAllImages && (
                                                                <div
                                                                  className="more-indicator"
                                                                  onClick={() =>
                                                                    openModal(
                                                                      moment.images,
                                                                      imageIndex
                                                                    )
                                                                  }
                                                                >
                                                                  <i class="bi bi-image"></i>
                                                                </div>
                                                              )}
                                                          </div>
                                                        ))
                                                    ):(
                                                      moment.images
                                                        .slice(0, 4)
                                                        .map((image, imageIndex) => (
                                                          <div
                                                            onClick={() =>
                                                              openModal(
                                                                moment.images,
                                                                imageIndex
                                                              )
                                                            }
                                                            key={imageIndex}
                                                            className={`image-wrapper${
                                                              imageIndex === 0
                                                                ? " full-width"
                                                                : ""
                                                            }`}
                                                            style={{
                                                              height: "100%",
                                                              width: "100%",
                                                              display: "flex",
                                                              justifyContent: "center",
                                                              alignItems: "center",
                                                              margin: "auto",
                                                            }}
                                                          >
                                                            {image.media_type ===
                                                              "image" ||
                                                            !image.media_type ? (
                                                              <img
                                                                src={image.url}
                                                                alt={`Image ${
                                                                  imageIndex + 1
                                                                }`}
                                                                style={{
                                                                  maxWidth: "100%",
                                                                  maxHeight: "100%",
                                                              }}
                                                              />
                                                            ) : (
                                                              image.media_type ===
                                                                "video" && (
                                                                <VideoPlayer source={image.url}/>
                                                              )
                                                            )}
                                                            {imageIndex === 3 &&
                                                              moment.images.length >
                                                                4 &&
                                                              !showAllImages && (
                                                                <div
                                                                  className="more-indicator"
                                                                  onClick={() =>
                                                                    openModal(
                                                                      moment.images,
                                                                      imageIndex
                                                                    )
                                                                  }
                                                                >
                                                                  +{" "}
                                                                  {moment.images
                                                                    .length - 4}
                                                                </div>
                                                              )}
                                                          </div>
                                                        ))
                                                    )}
                                                    
                                                  </div>
                                            )}
                                      
                                          </div>
                                        </Card.Text>
                                        <hr></hr>
                                        <Row className="icons-row">
                                          <Col>
                                            <div>
                                              <div></div>
                                            </div>
                                            <div className="icons">
                                              {moment.reaction_counts > 0 && (
                                                <p>
                                                  {totalFeedbacks(
                                                    moment.reaction_counts
                                                  )}
                                                </p>
                                              )}

                                              <div
                                                class="feed"
                                                onClick={handleImpressionClick}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex !important",
                                                  }}
                                                >
                                                  {totalFeedbacks(
                                                    moment.reaction_counts
                                                  ) === 0 ? (
                                                    <div>
                                                      <img
                                                        src={
                                                          images.ImpressionsIcon
                                                        }
                                                        alt="No Feedback"
                                                        width="20"
                                                        height="20"
                                                        style={{
                                                          marginLeft: "10px",
                                                          marginTop: "-10px",
                                                        }}
                                                      />{" "}
                                                    </div>
                                                  ) : (
                                                    <div
                                                      className="top3-emojis-container"
                                                      style={{
                                                        marginLeft: "5px",
                                                      }}
                                                    >
                                                      {getTopReactions(
                                                        moment.reaction_counts
                                                      ).map((emoji) => (
                                                        <div key={emoji}>
                                                          <img
                                                            src={getEmojiAsset(
                                                              emoji
                                                            )}
                                                            alt={emoji}
                                                            width="20"
                                                            height="20"
                                                          />
                                                        </div>
                                                      ))}
                                                    </div>
                                                  )}
                                                </div>
                                                <a class="like-btn">
                                                  {userLogin &&
                                                    impressionBoxVisible && (
                                                      <div
                                                        class="reaction-box"
                                                      >
                                                        {(!moment.impression_types || moment.impression_types.like === true) && (
                                                        <div
                                                          class="reaction-icon"
                                                          onClick={() =>
                                                            handleEmojiClick(
                                                              "like",
                                                              moment._id
                                                            )
                                                          }
                                                        >
                                                          <label>
                                                            Like this Moment
                                                          </label>
                                                          <img
                                                            src={
                                                              images.LikeIcon
                                                            }
                                                          ></img>
                                                        </div>
                                                        )}

                                                        {(!moment.impression_types || moment.impression_types.great === true) && (
                                                        <div
                                                          class="reaction-icon"
                                                          onClick={() =>
                                                            handleEmojiClick(
                                                              "great",
                                                              moment._id
                                                            )
                                                          }
                                                        >
                                                          <label>
                                                            Great Moment
                                                          </label>
                                                          <img
                                                            src={
                                                              images.GreatIcon
                                                            }
                                                          ></img>
                                                        </div>
                                                        )}

                                                        {(!moment.impression_types || moment.impression_types.emotional === true) && (
                                                        <div
                                                          class="reaction-icon"
                                                          onClick={() =>
                                                            handleEmojiClick(
                                                              "emotional",
                                                              moment._id
                                                            )
                                                          }
                                                        >
                                                          <label>
                                                            Emotional Moment
                                                          </label>
                                                          <img
                                                            src={
                                                              images.EmotionalIcon
                                                            }
                                                          ></img>
                                                        </div>
                                                        )}

                                                        {(!moment.impression_types || moment.impression_types.nostalgic === true) && (
                                                        <div
                                                          class="reaction-icon"
                                                          onClick={() =>
                                                            handleEmojiClick(
                                                              "nostalgic",
                                                              moment._id
                                                            )
                                                          }
                                                        >
                                                          <label>
                                                            Nostalgic Moment
                                                          </label>
                                                          <img
                                                            src={
                                                              images.NostalgicIcon
                                                            }
                                                          ></img>
                                                        </div>
                                                        )}

                                                        {(!moment.impression_types || moment.impression_types.wish === true) && (
                                                        <div
                                                          class="reaction-icon"
                                                          onClick={() =>
                                                            handleEmojiClick(
                                                              "wish",
                                                              moment._id
                                                            )
                                                          }
                                                        >
                                                          <label>
                                                            Wish I could be
                                                            there
                                                          </label>
                                                          <img
                                                            src={
                                                              images.WishIcon
                                                            }
                                                          ></img>
                                                        </div>
                                                        )}

                                                        {(!moment.impression_types || moment.impression_types.part === true) && (
                                                        <div
                                                          class="reaction-icon"
                                                          onClick={() =>
                                                            handleEmojiClick(
                                                              "part",
                                                              moment._id
                                                            )
                                                          }
                                                        >
                                                          <label>
                                                            Was part of this
                                                            Moment
                                                          </label>
                                                          <img
                                                            src={
                                                              images.PartOfTheMomentIcon
                                                            }
                                                          />
                                                        </div>
                                                        )}

                                                        {(!moment.impression_types || moment.impression_types.witnessed === true) && (
                                                        <div
                                                          class="reaction-icon"
                                                          onClick={() =>
                                                            handleEmojiClick(
                                                              "witnessed",
                                                              moment._id
                                                            )
                                                          }
                                                        >
                                                          <label>
                                                            Witnessed this
                                                            Moment
                                                          </label>
                                                          <img
                                                            src={
                                                              images.WitnessedIcon
                                                            }
                                                          ></img>
                                                        </div>
                                                        )}
                                                      </div>
                                                    )}
                                                </a>
                                              </div>
                                              {/* <img className="icons-image" src={impressions}></img> */}
                                              <p
                                                className="impression-word"
                                                onClick={() =>
                                                  handleImpressionSet(
                                                    moment._id
                                                  )
                                                }
                                                style={{
                                                  marginLeft:
                                                    totalFeedbacks(
                                                      moment.reaction_counts
                                                    ) > 0
                                                      ? "0px"
                                                      : "10px",
                                                }}
                                              >
                                                Like
                                              </p>
                                            </div>
                                          </Col>
                                          {moment.commentable === true && (
                                            <Col>
                                            <div
                                              className="icons comment-icon"
                                              onClick={() =>
                                                handleCommentOpen(moment._id)
                                              }
                                            >
                                              {moment.number_of_comments >
                                                0 && (
                                                <p>
                                                  {moment.number_of_comments}
                                                </p>
                                              )}

                                              <img
                                                className="icons-image"
                                                src={images.CommentIcon}
                                                style={{ marginTop: "2px" }}
                                              ></img>
                                              <p className="comments-word">
                                                Comment
                                              </p>
                                            </div>
                                          </Col>
                                          )}
                                          
                                          {moment.shareable === true && (
                                            <Col>
                                            {showTooltip === true && (
                                              <div className="copy-message">
                                                Link copied
                                              </div>
                                            )}
                                            <div className="icons share-icon">
                                              <div class="share-feed">
                                                <a
                                                  class="share-btn"
                                                  onClick={() =>
                                                    handleShareClick(moment._id)
                                                  }
                                                >
                                                  <img
                                                    className="icons-image"
                                                    src={images.ShareIcon}
                                                  ></img>
                                                  {shareBoxVisible &&
                                                    shareMoment ===
                                                      moment._id && (
                                                      <div
                                                        class="share-box"
                                                        ref={shareBoxRef}
                                                      >
                                                        <div class="share-icon">
                                                          <label>
                                                            Telegram
                                                          </label>
                                                          <TelegramShareButton
                                                            url={`${currentUrl}/${life.verified_name?life.verified_name:life.life_id}/${moment._id}`}
                                                            title={
                                                              `${life.life_name} | ${moment.moment_date} ${moment.era && moment.era === 'BCE' ? 'BCE' : ''} : ${moment.moment_title}`
                                                            }
                                                          >
                                                            <TelegramIcon
                                                              size={32}
                                                              round
                                                              className="icon"
                                                            />
                                                          </TelegramShareButton>
                                                        </div>
                                                        <div class="share-icon">
                                                          <label>
                                                            Linkedin
                                                          </label>
                                                          <LinkedinShareButton
                                                            url={`${currentUrl}/${life.verified_name?life.verified_name:life.life_id}/${moment._id}`}
                                                            title={
                                                              `${life.life_name} | ${moment.moment_date} ${moment.era && moment.era === 'BCE' ? 'BCE' : ''} : ${moment.moment_title}`
                                                            }
                                                            source="MoofLife"
                                                          >
                                                            <LinkedinIcon
                                                              size={32}
                                                              round
                                                              className="icon"
                                                            />
                                                          </LinkedinShareButton>
                                                        </div>
                                                        <div class="share-icon">
                                                          <label>
                                                            Whatsapp
                                                          </label>
                                                          <WhatsappShareButton
                                                            url={`${currentUrl}/${life.verified_name?life.verified_name:life.life_id}/${moment._id}`}
                                                            title={
                                                              `${life.life_name} | ${moment.moment_date} ${moment.era && moment.era === 'BCE' ? 'BCE' : ''} : ${moment.moment_title}`
                                                            }
                                                            separator=": "
                                                          >
                                                            <WhatsappIcon
                                                              size={32}
                                                              round
                                                              className="icon"
                                                            />
                                                          </WhatsappShareButton>
                                                        </div>
                                                        <div class="share-icon">
                                                          <label>
                                                            Facebook
                                                          </label>
                                                          <FacebookShareButton
                                                            url={`${currentUrl}/${life.verified_name?life.verified_name:life.life_id}/${moment._id}`}
                                                          >
                                                            <FacebookIcon
                                                              size={32}
                                                              round
                                                              className="icon"
                                                            />
                                                          </FacebookShareButton>
                                                        </div>
                                                        <div class="share-icon">
                                                          <label>Mask</label>
                                                          <TwitterShareButton
                                                            url={`${currentUrl}/${life.verified_name?life.verified_name:life.life_id}/${moment._id}`}
                                                            title={
                                                              `${life.life_name} | ${moment.moment_date} ${moment.era && moment.era === 'BCE' ? 'BCE' : ''} : ${moment.moment_title}`
                                                            }
                                                          >
                                                            <XIcon
                                                              size={32}
                                                              round
                                                              className="icon"
                                                            />
                                                          </TwitterShareButton>
                                                        </div>
                                                        <div class="share-icon icon-copy">
                                                          <div class="hover-text">
                                                            {/* <!-- Copy Text --> */}
                                                            <div className="copy-text d-flex">
                                                              <input
                                                                value={`${currentUrl}/${life.verified_name?life.verified_name:life.life_id}/${moment._id}`}
                                                                disabled
                                                                type="text"
                                                                ref={textRef}
                                                              />
                                                            </div>
                                                          </div>
                                                          <img
                                                            src={
                                                              images.CopyIcon
                                                            }
                                                            onClick={
                                                              copyToClipboard
                                                            }
                                                            className="icon"
                                                          ></img>
                                                        </div>
                                                      </div>
                                                    )}
                                                </a>
                                              </div>
                                              <p
                                                className="share-word"
                                                onClick={() =>
                                                  handleShareClick(moment._id)
                                                }
                                              >
                                                Share
                                              </p>
                                            </div>
                                          </Col>
                                          )}
                                          
                                        </Row>
                                        {userLogin
                                          ? commentOpen &&
                                            commentMoment === moment._id && (
                                              <Comments
                                                currentUserId={
                                                  userData && userData.id
                                                    ? userData.id
                                                    : null
                                                }
                                                momentId={moment._id}
                                                area="lifePage"
                                              ></Comments>
                                            )
                                          : commentOpen &&
                                            commentMoment === moment._id && (
                                              <Comments
                                                momentId={moment._id}
                                              ></Comments>
                                            )}
                                      </Card.Body>
                                    </Card>
                                  );
                                })}
                              </>
                            ) : (
                              <p></p>
                            )}
                          </div>

                          {/* Image Modal */}
                          <Modal
                            id="imageshw"
                            size="lg"
                            className="modal-image"
                            show={showImg}
                            onHide={handleClose}
                            centered
                            style={{ maxWidth: "100%", margin: "0 auto" }}
                          >
                            <Modal.Header closeButton></Modal.Header>
                            <Modal.Body>
                              <Carousel
                                className="carousel"
                                activeIndex={selectedImageIndex}
                                onSelect={(selectedIndex, e) =>
                                  setSelectedImageIndex(selectedIndex)
                                }
                              >
                                {selectedMomentImages.map(
                                  (image, imageIndex) => (
                                    <Carousel.Item key={imageIndex}>
                                      <div>
                                        <div className="title-1">
                                          {image.title}
                                        </div>
                                        {image.media_type === "image" ||
                                        !image.media_type ? (
                                          <div className="image-div">
                                            <Zoom>
                                              <img
                                                className="modal-moment-img"
                                                src={image.url}
                                                alt={`Image ${imageIndex + 1}`}
                                              />
                                            </Zoom>
                                          </div>
                                        ) : (
                                          image.media_type === "video" && (
                                            <VideoPlayer source={image.url} />
                                          )
                                        )}

                                        <Row>
                                          <Row>
                                          {image.source && (
                                            <p className="title-2">
                                              Source : <span>{image.source}</span>
                                            </p>
                                          )}
                                          </Row>
                                          <Row>
                                            {image.credit && (
                                              <p className="title-2">
                                                Credit :{" "}
                                                <span>{image.credit}</span>
                                              </p>
                                            )}
                                          </Row>
                                          <Row className="title-2">
                                            <div className="image-copyright">
                                              This content may be subject to copyright.
                                              <span
                                                className="image-copyright-link"
                                                onClick={() =>
                                                  setModalShow(true)
                                                }
                                              >
                                                Refer privacy policy
                                              </span>
                                            </div>
                                          </Row>
                                        </Row>
                                      </div>
                                    </Carousel.Item>
                                  )
                                )}
                              </Carousel>
                            </Modal.Body>
                          </Modal>

                          {/* Impressions Set */}
                          <Modal
                            size="lg"
                            show={showImpressionSet}
                            onHide={handleCloseImpressionSet}
                            backdrop="static"
                            keyboard={false}
                            className="impressions-set-main"
                          >
                            <Modal.Header closeButton>
                              <Modal.Title className="title">
                                Impressions
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <Tabs
                                defaultActiveKey="All"
                                id="uncontrolled-tab-example"
                                className="mb-3 tabs"
                              >
                                {[
                                  { emoji_type: "All", title: "All" },
                                  {
                                    emoji_type: "part",
                                    title: "angry",
                                    icon: images.PartOfTheMomentIcon,
                                  },
                                  {
                                    emoji_type: "like",
                                    title: "like",
                                    icon: images.LikeIcon,
                                  },
                                  {
                                    emoji_type: "great",
                                    title: "great",
                                    icon: images.GreatIcon,
                                  },
                                  {
                                    emoji_type: "emotional",
                                    title: "emotional",
                                    icon: images.EmotionalIcon,
                                  },
                                  {
                                    emoji_type: "witnessed",
                                    title: "witnessed",
                                    icon: images.WitnessedIcon,
                                  },
                                  {
                                    emoji_type: "nostalgic",
                                    title: "nostalgic",
                                    icon: images.NostalgicIcon,
                                  },
                                  {
                                    emoji_type: "wish",
                                    title: "wish",
                                    icon: images.WishIcon,
                                  },
                                ]
                                  .filter((tab) => {
                                    if (tab.emoji_type === "All") {
                                      return true; // Always show the "All" tab
                                    } else {
                                      const count = getCountByType(
                                        tab.emoji_type,
                                        ImpressionSet
                                      );
                                      return count > 0; // Show the tab only if the count is greater than 0
                                    }
                                  })
                                  .map((tab, index) => (
                                    <Tab
                                      key={index}
                                      eventKey={tab.emoji_type}
                                      title={
                                        <>
                                          {tab.emoji_type === "All" ? (
                                            "All"
                                          ) : (
                                            <img
                                              src={tab.icon}
                                              width="20px"
                                              style={{ padding: "0px" }}
                                              alt={`${tab.emoji_type} tab icon`}
                                            ></img>
                                          )}

                                          <span>
                                            {tab.emoji_type === "All"
                                              ? ImpressionSet.length
                                              : getCountByType(
                                                  tab.emoji_type,
                                                  ImpressionSet
                                                )}
                                          </span>
                                        </>
                                      }
                                    >
                                      {tab.emoji_type === "All"
                                        ? ImpressionSet.map(
                                            (impressionSet, index) => (
                                              <div
                                                className="impression-row"
                                                key={index}
                                              >
                                                <div>
                                                  {impressionSet.user
                                                    ?.image_url ? (
                                                    <div>
                                                      <img
                                                        src={
                                                          impressionSet.user
                                                            ?.image_url
                                                        }
                                                        className="profile-icon"
                                                      ></img>
                                                    </div>
                                                  ) : (
                                                    <div
                                                      className="user-image-letter"
                                                      id="name"
                                                      style={{
                                                        background:
                                                          impressionSet.user
                                                            ?.theme_colour ||
                                                          "#D9D9D9",
                                                      }}
                                                    >
                                                      {impressionSet.user?.first_name?.charAt(
                                                        0
                                                      )}
                                                      {impressionSet.user &&
                                                      impressionSet.user
                                                        .last_name
                                                        ? impressionSet.user.last_name.charAt(
                                                            0
                                                          )
                                                        : ""}
                                                    </div>
                                                  )}
                                                  <img
                                                    className="emoji-icon"
                                                    src={getEmojiAsset(
                                                      impressionSet?.impression_type
                                                    )}
                                                    alt={`${impressionSet?.impression_type} emoji`}
                                                  ></img>
                                                </div>
                                                <div className="profile-name">
                                                  {`${
                                                    impressionSet.user
                                                      ?.first_name
                                                  } ${
                                                    impressionSet.user
                                                      ?.last_name
                                                      ? impressionSet.user
                                                          ?.last_name
                                                      : ""
                                                  }`}
                                                </div>
                                              </div>
                                            )
                                          )
                                        : ImpressionSet.filter(
                                            (impression) =>
                                              impression.impression_type ===
                                              tab.emoji_type
                                          ).map((impressionSet, index) => (
                                            <div
                                              className="impression-row"
                                              key={index}
                                            >
                                              <div>
                                                {impressionSet.user === "" ? (
                                                  <img
                                                    className="user-image"
                                                    src={images.UserImage}
                                                  ></img>
                                                ) : (
                                                  <>
                                                    {impressionSet.user
                                                      ?.image_url ? (
                                                      <div>
                                                        <img
                                                          src={
                                                            impressionSet.user
                                                              ?.image_url
                                                          }
                                                          className="profile-icon"
                                                        ></img>
                                                      </div>
                                                    ) : (
                                                      <div
                                                        className="user-image-letter"
                                                        id="name"
                                                        style={{
                                                          background:
                                                            impressionSet.user
                                                              ?.theme_colour ||
                                                            "#D9D9D9",
                                                        }}
                                                      >
                                                        {impressionSet.user?.first_name?.charAt(
                                                          0
                                                        )}

                                                        {impressionSet.user &&
                                                        impressionSet.user
                                                          .last_name
                                                          ? impressionSet.user.last_name.charAt(
                                                              0
                                                            )
                                                          : ""}
                                                      </div>
                                                    )}
                                                  </>
                                                )}
                                                <img
                                                  className="emoji-icon"
                                                  src={getEmojiAsset(
                                                    impressionSet?.impression_type
                                                  )}
                                                  alt={`${impressionSet?.impression_type} emoji`}
                                                ></img>
                                              </div>
                                              <div className="profile-name">
                                                {`${
                                                  impressionSet.user?.first_name
                                                } ${
                                                  impressionSet.user?.last_name
                                                    ? impressionSet.user
                                                        .last_name
                                                    : ""
                                                }`}
                                              </div>
                                            </div>
                                          ))}
                                    </Tab>
                                  ))}
                              </Tabs>
                            </Modal.Body>
                          </Modal>

                          {/* Pop up */}
                          <Modal
                            className="popup-modal"
                            show={showPopup}
                            onHide={handleClose}
                          >
                            <div className="popup-header">
                              <div className="close-icon" onClick={handleClose}>
                                <img src={images.closeIcon}></img>
                              </div>
                              <div className="logo-icon">
                                <img src={images.MoofIcon} />
                              </div>
                            </div>

                            <Modal.Body className="modal-body">
                              Please Login / Sign-up to comment or like this
                              moment <br></br>
                              <Link to="/login">
                                <button className="yellow-btn">Login</button>
                              </Link>
                              <Link to="/sign-up">
                                <button className="yellow-btn">Sign Up </button>
                              </Link>
                            </Modal.Body>
                          </Modal>

                          {/* Link Moment Modal */}
                          <Modal
                            className="link-moment-modal"
                            show={showLinkMomentPopup}
                            backdrop="static"
                            centered
                          >
                            <Modal.Header className="title">
                              Connect Moment to my Life
                            </Modal.Header>
                            <div className="modal-body">
                              {livesForUser &&
                                livesForUser.length > 0 &&
                                livesForUser.map((life) => {
                                  return (
                                    <div className="card"
                                    onClick={() => selectLifeForLink(life.life_id) }
                                    style={{ border: selectedLifeId === life.life_id ? "2px solid #FFC000" : "" }}
                                    >
                                      <div className="life-set">
                                        <div className="d-flex gap-3 align-items-center">
                                          <Zoom>
                                            <img
                                              src={
                                                life.life_logo
                                                  ? life.life_logo
                                                  : images.UserImage
                                              }
                                              className="create-dropdown-img"
                                            ></img>
                                          </Zoom>

                                          <div>
                                            <div className="life-name">
                                              {life.life_name}
                                            </div>
                                            <div className="life-tag">
                                              {life.life_tag
                                                ? life.life_tag
                                                : ""}
                                            </div>
                                          </div>
                                        </div>
                                        {/* <div>
                                          <Form.Check
                                            type="radio"
                                            className="yellow-switch"
                                            id="shareable"
                                            checked={isChecked[life.life_id]}
                                            onClick={() => selectLifeForLink(life.life_id)}
                                          />
                                        </div> */}
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                            <Modal.Footer>
                              <div className="btn-set">
                                <button
                                  className="yellow-btn"
                                  onClick={handleLinkMoment}
                                  disabled={loadingLink || !selectedLifeId}
                                >
                                   {loadingLink ? (
                                      <Spinner
                                        animation="border"
                                        variant="light"
                                        style={{
                                          width: "15px",
                                          height: "15px",
                                        }}
                                      />
                                    ) : (
                                      "Connect"
                                    )}
                                </button>
                                <button
                                  className="yellow-btn"
                                  onClick={handleLinkMomentPopupClose}
                                  disabled={loadingLink}
                                >
                                  Cancel
                                </button>
                              </div>
                            </Modal.Footer>
                          </Modal>

                          {/* Confirm Unlink Moment Modal */}
                          <Modal
                            className="popup-modal confirmation-modal"
                            show={showConfirmLink}
                            onHide={handleClose}
                            backdrop="static"
                            centered
                          >
                            <div className="popup-header">
                              <div className="logo-icon">
                                <img src={images.MoofIcon} />
                              </div>
                            </div>

                            <Modal.Body className="modal-body">
                              <div className="title">Confirmation</div>
                              <div className="subtitle1">
                                Are you sure you want to disconnect this moment ?
                              </div>
                            </Modal.Body>

                            <div className="btn-set">
                              <button
                                className="btn1"
                                onClick={() => handleUnLink(unlinkId)}
                              >
                                   {loadingLink ? (
                                      <Spinner
                                        animation="border"
                                        variant="light"
                                        style={{
                                          width: "15px",
                                          height: "15px",
                                        }}
                                      />
                                    ) : (
                                      "Yes"
                                    )}
                              </button>
                              <button
                                className="btn2"
                                onClick={() => handleLinkMomentPopupClose()}
                                disabled={loadingLink}
                              >
                                No
                              </button>
                            </div>
                          </Modal>

                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Col>

                {/* life right */}
                <Col sm={3} className="page-right life-right-full">
                  <LifeRight />
                </Col>
              </Row>
            </div>
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default LifePage;
