import React, { useEffect, useRef, useState } from "react";
import "../../styles/TopMomentTileStyle.css";
import "../../styles/ShareIconStyle.css";
import NavigationBar from "../../components/NavigationBar";
import InfiniteScroll from "react-infinite-scroller";
import { getTopMomentsTile } from "../../store/action/TopMomentsAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Card } from "react-bootstrap";
import VideoPlayer from "../../components/VideoPlayer";
import { Link } from "react-router-dom";
import { images } from "../../utils/image";
import Dropdown from "react-bootstrap/Dropdown";
import copy from "copy-to-clipboard";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import {
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
  XIcon,
} from "react-share";

const TopMomentTile = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.toLocaleString("en-US", { month: "long" });
  const currentDay = currentDate.toLocaleString("en-US", { day: "2-digit" });
  const dispatch = useDispatch();
  const TopMoments = useSelector((state) => state.TopMoments.TopMomentsTile);

  const limit = 9;
  const limitReached = useSelector((state) => state.TopMoments.limitReached);
  const Page = useSelector((state) => state.TopMoments.Page);
  const isLimitReached = limitReached;

  const [shareBoxVisible, setShareBoxVisible] = useState();
  const [shareMoment, setShareMoment] = useState("");
  const textRef = useRef();
  const shareBoxRef = useRef();
  const [tooltipState, setTooltipState] = useState({});

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (shareBoxRef.current && !shareBoxRef.current.contains(event.target)) {
        setShareBoxVisible(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const momentDate = moment(currentDate).format("YYYY-MM-DD");
    dispatch(
      getTopMomentsTile(
        {
          date: momentDate,
          page: 1,
          limit: 9,
        },
        1
      )
    );
  }, []);

  const currentUrl =
    window.location.protocol +
    "//" +
    window.location.hostname +
    (window.location.port ? ":" + window.location.port : "");

  const loadItems = async () => {
    const momentDate = moment(currentDate).format("YYYY-MM-DD");
    dispatch(
      getTopMomentsTile(
        {
          date: momentDate,
          page: Page,
          limit: limit,
        },
        Page
      )
    );
  };

  const isLargeTile = (index) => {
    // pattern - 1, 6, 11, 16, 21
    // 6 / 5 remaining 1
    if (TopMoments[index]?.images[0]?.url && index % 5 === 1) {
      return true;
    } else if (
      index % 5 === 2 &&
      !TopMoments[index - 1]?.images[0]?.url &&
      TopMoments[index]?.images[0]?.url
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleShareClick = (momentId) => {
    setShareMoment(momentId);
    setShareBoxVisible(true);
  };

  const copyToClipboard = (id, topMoment) => {
    let copyText = textRef.current.value;
    let isCopy = copy(copyText);
    if (id === topMoment._id && isCopy) {
      toggleTooltip(id, true);

      setTimeout(() => {
        toggleTooltip(id, false);
      }, 3000);
    }
  };

  const toggleTooltip = (momentId, value) => {
    setTooltipState((prevState) => ({
      ...prevState,
      [momentId]: value,
    }));
  };

  return (
    <div>
      <div
        id="scrollerDiv"
        className="dummy-scroll"
        style={{
          height: "100vh",
          overflow: "auto",
        }}
        tabIndex={0}
      >
        <NavigationBar />

        <InfiniteScroll
          pageStart={1}
          loadMore={loadItems}
          hasMore={!isLimitReached}
          loader={
            TopMoments.length >= limit && !isLimitReached ? (
              <p
                className="loading-word-landing"
                style={{ marginLeft: "20px" }}
              >
                Loading...
              </p>
            ) : null
          }
          useWindow={false}
          initialLoad={false}
        >
          <div className="tile-view-main">
            <div className="grid">
              <div className="tile date-tile">
                <div className="date-card">
                  <div className="img-container">
                    <Card className="info-card">
                      <div className="top-date-month">
                        <Card.Text className="date">{currentDay}</Card.Text>
                        <Card.Text className="month">{currentMonth}</Card.Text>
                      </div>

                      <Card.Text className="topic">
                        Moments in History
                      </Card.Text>
                    </Card>
                  </div>
                </div>
              </div>
              {TopMoments.map((topMoment, index) => (
                <div
                  key={topMoment._id}
                  className={`tile ${isLargeTile(index) ? "large" : ""}`}
                >
                  <Dropdown>
                    <Dropdown.Toggle>
                      <div className="moof-icon">
                        <img src={images.MoofIcon} className="moof-icon"/>
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown-menu">
                      <Dropdown.Item
                        onClick={() => handleShareClick(topMoment._id)}
                      >
                        <img src={images.ShareIcon}></img>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  {tooltipState[topMoment._id] && (
                    <div className="copy-message ">Link copied</div>
                  )}
                  <div className="icons share-icon">
                    <div class="share-feed">
                      <a
                        class="share-btn"
                        onClick={() => handleShareClick(topMoment._id)}
                      >
                        {shareBoxVisible && shareMoment === topMoment._id && (
                          <div class="share-box" ref={shareBoxRef}>
                            <div class="share-icon">
                              <label>Telegram</label>
                              <TelegramShareButton
                                url={`${currentUrl}/${topMoment.life.verified_name?topMoment.life.verified_name:topMoment.life.life_id}/${topMoment._id}`}
                                title={moment.moment_title}
                              >
                                <TelegramIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </TelegramShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Linkedin</label>
                              <LinkedinShareButton
                                url={`${currentUrl}/${topMoment.life.verified_name?topMoment.life.verified_name:topMoment.life.life_id}/${topMoment._id}`}
                                title={moment.moment_title}
                                source="MoofLife"
                              >
                                <LinkedinIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </LinkedinShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Whatsapp</label>
                              <WhatsappShareButton
                                url={`${currentUrl}/${topMoment.life.verified_name?topMoment.life.verified_name:topMoment.life.life_id}/${topMoment._id}`}
                                title={moment.moment_title}
                                separator=":: "
                              >
                                <WhatsappIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </WhatsappShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Facebook</label>
                              <FacebookShareButton
                                url={`${currentUrl}/${topMoment.life.verified_name?topMoment.life.verified_name:topMoment.life.life_id}/${topMoment._id}`}
                              >
                                <FacebookIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </FacebookShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Mask</label>
                              <TwitterShareButton
                                url={`${currentUrl}/${topMoment.life.verified_name?topMoment.life.verified_name:topMoment.life.life_id}/${topMoment._id}`}
                                title={moment.moment_title}
                              >
                                <XIcon size={32} round className="icon" />
                              </TwitterShareButton>
                            </div>
                            <div class="share-icon icon-copy">
                              <div class="hover-text">
                                {/* <!-- Copy Text --> */}
                                <div className="copy-text d-flex">
                                  <input
                                    value={`${currentUrl}/${topMoment.life.verified_name?topMoment.life.verified_name:topMoment.life.life_id}/${topMoment._id}`}
                                    disabled
                                    type="text"
                                    ref={textRef}
                                  />
                                </div>
                              </div>
                              <img
                                src={images.CopyIcon}
                                onClick={() =>
                                  copyToClipboard(topMoment._id, topMoment)
                                }
                                className="icon"
                              ></img>
                            </div>
                          </div>
                        )}
                      </a>
                    </div>
                  </div>

                  <Link
                    to={`/${topMoment.life.verified_name?topMoment.life.verified_name:topMoment?.life?.life_id}/${topMoment._id}`}
                    target="_blank"
                  >
                    {topMoment.images.length > 0 &&
                      (topMoment.images[0]?.media_type === "image" ? (
                        <img src={topMoment.images[0].url} className="moment-img"/>
                      ) : (
                        <div>
                          <VideoPlayer
                            className="videoFrame"
                            source={topMoment.images[0].url}
                          />
                        </div>
                      ))}

                    <div className="title">
                      <div className="p">
                        <div className="life-logo-name">
                          <img
                            src={topMoment.life?.life_logo}
                            className="img"
                          ></img>
                          <div className="moment-date">
                            {topMoment.moment_date}
                          </div>
                        </div>
                        {topMoment.moment_title.length > 90
                          ? topMoment.moment_title.slice(0, 90) + "..."
                          : topMoment.moment_title}
                      </div>
                    </div>

                    {topMoment.images.length < 1 && (
                      <div className="para">
                        <div>
                          {topMoment.moment_details.length > 150
                            ? topMoment.moment_details.slice(0, 150) + "..."
                            : topMoment.moment_details}
                        </div>
                      </div>
                    )}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default TopMomentTile;
