import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import "../../styles/LifeLeftStyle.css";
import "../../styles/LifePage/SearchPageStyle.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getLifeMomemntsForSearch,
  getLifeMomentsForSearch,
} from "../../store/action/LifeAction";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { images } from "../../utils/image";
import { getMomentsForSearch } from "../../store/action/MomentAction";

const SearchPage = () => {
  const limit = 10;
  const dispatch = useDispatch();
  const [fromYear, setFromYear] = useState("");
  const [toYear, setToYear] = useState("");
  const [words, setWords] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [fromYearEra, setFromYearEra] = useState("CE");
  const [toYearEra, setToYearEra] = useState("CE");
  const [sortByDate, setSortByDate] = useState(false);

  const momentSorting = useSelector((state) => state.Life.momentSorting);
  const selectedLife = useSelector((state) => state.Life.Life);
  const Life = useSelector((state) => state.Life.Life);

  const handleAddKeywords = () => {
    // From Year - To Year
    if (fromYear !== "" && toYear !== "") {
      const keywordText = `${fromYear} - ${toYear}`;
      setSearchKeywords("fromAndToYear", keywordText);
    } else {
      // From - Present
      if (fromYear !== "") {
        const keywordText = `${fromYear} - Present`;
        setSearchKeywords("fromYear", keywordText);
      }

      // Past - ToYear
      if (toYear !== "") {
        const keywordText = `Past - ${toYear}`;
        setSearchKeywords("toYear", keywordText);
      }
    }

    // Word
    if (words !== "") {
      const keywordText = `${words}`;
      setSearchKeywords("keyword", keywordText);
    }

    startSearch();
  };

  const setSearchKeywords = (itemName, keywordText) => {
    setKeywords((prevKeywords) => {
      const keywordIndex = prevKeywords.findIndex(
        (item) => item.type === itemName
      );
      let updatedKeywords = [...prevKeywords];

      if (keywordText === "") {
        updatedKeywords = updatedKeywords.filter(
          (item) => item.type !== itemName
        );
      }

      if (itemName === "fromAndToYear") {
        updatedKeywords = updatedKeywords.filter(
          (item) => item.type !== "fromYear" && item.type !== "toYear"
        );
      } else if (itemName === "fromYear") {
        updatedKeywords = updatedKeywords.filter(
          (item) => item.type !== "fromAndToYear" && item.type !== "toYear"
        );
      } else if (itemName === "toYear") {
        updatedKeywords = updatedKeywords.filter(
          (item) => item.type !== "fromAndToYear" && item.type !== "fromYear"
        );
      }

      if (keywordIndex !== -1) {
        // If 'keyword' already exists, update its value
        updatedKeywords[keywordIndex] = { type: itemName, value: keywordText };
        return updatedKeywords;
      } else {
        // if 'keyword' not exists
        return [...updatedKeywords, { type: itemName, value: keywordText }];
      }
    });
  };

  const handleRemoveKeyword = (type) => {
    if (type === "keyword") {
      setWords("");
    } else if (type === "fromYear") {
      setFromYear("");
    } else if (type === "toYear") {
      setToYear("");
    } else if (type === "fromAndToYear") {
      setFromYear("");
      setToYear("");
    }
    setKeywords((prevKeywords) =>
      prevKeywords.filter((item) => item.type !== type)
    );
    startSearch(type);
  };

  const startSearch = async (avoidType) => {
    const query = {
      selector: {
        life: selectedLife._id,
      },
      page: 1,
      limit: limit,
      life_id: selectedLife ? selectedLife.life_id : null,
      connectors: selectedLife ? selectedLife.connected_lives : [],
    };

    if (words && words !== "" && avoidType !== "keyword") {
      query.selector.search_keyword = words;
    }

    if (
      fromYear &&
      fromYear !== "" &&
      avoidType !== "fromYear" &&
      avoidType !== "fromAndToYear"
    ) {
      query.selector.fromYear = handleYears(fromYear);
      query.selector.fromYearEra = fromYearEra;
    }

    if (
      toYear &&
      toYear !== "" &&
      avoidType !== "toYear" &&
      avoidType !== "fromAndToYear"
    ) {
      query.selector.toYear = handleYears(toYear);
      query.selector.toYearEra = toYearEra;
    }

    dispatch(getLifeMomemntsForSearch(query, 1));
  };

  const pressEnterButton = (e) => {
    if (e.key === "Enter") {
      handleAddKeywords();
    }
  };

  const handleSortingDate = () => {
    setSortByDate((prevState) => !prevState);
  };

  const sortMoments = (sortValue) => {
    startMomentSearch(null, sortValue);
  };

  const startMomentSearch = (avoidType, sortVal) => {
    let searchQuery = {
      selector: {
        life: selectedLife._id,
      },
      page: 1,
      limit: 10,
      sorting: sortVal ? Number(sortVal) : momentSorting,
      life_id: selectedLife ? selectedLife.life_id : null,
      connectors: selectedLife ? selectedLife.connected_lives : [],
    };

    if (words && words !== "" && avoidType !== "keyword") {
      searchQuery.selector.search_keyword = words;
    }

    if (
      fromYear &&
      fromYear !== "" &&
      avoidType !== "fromYear" &&
      avoidType !== "fromAndToYear"
    ) {
      searchQuery.selector.fromYear = handleYears(fromYear);
      searchQuery.selector.fromYearEra = fromYearEra;
    }

    if (
      toYear &&
      toYear !== "" &&
      avoidType !== "toYear" &&
      avoidType !== "fromAndToYear"
    ) {
      searchQuery.selector.toYear = handleYears(toYear);
      searchQuery.selector.toYearEra = toYearEra;
    }

    dispatch(getLifeMomentsForSearch(searchQuery, 1));
  };

  const handleYears = (year) => {
    let yearVal = year;
    if (year && year !== "") {
      if (year < 1000) {
        yearVal = `0${year}`;
      } else if (year <= 100) {
        yearVal = `00${year}`;
      } else if (year <= 10) {
        yearVal = `000${year}`;
      } else {
        yearVal = year;
      }
    } else {
      yearVal = year;
    }
    return yearVal;
  };

  const handleYearEra = (evt, type) => {
    if (type === "from") {
      setFromYearEra(evt.target.value);
    } else {
      setToYearEra(evt.target.value);
    }
  };

  return (
    <div className="life-search-main">
      <div className="quick-search">
        <Card className="card">
          <div className="form">
            <div className="search-row">
              <div className="search-col">
                <input
                  className="search-moment"
                  type="text"
                  placeholder={`Search Moments of ${Life.life_name}`}
                  value={words}
                  onChange={(e) => setWords(e.target.value)}
                  onKeyPress={pressEnterButton}
                />
              </div>

              <div className="fromYear" sm={6}>
                <input
                  type="text"
                  placeholder="From Year"
                  value={fromYear}
                  maxLength="4"
                  onChange={(e) => setFromYear(e.target.value)}
                  onKeyPress={pressEnterButton}
                />
                {/* <select
                  className="search-input"
                  onChange={(evt) => handleYearEra(evt, "from")}
                  value={fromYearEra}
                >
                  <option value="CE">CE</option>
                  <option value="BCE">BCE</option>
                </select> */}
              </div>
              <div className="toYear" sm={6}>
                <input
                  type="text"
                  placeholder="To Year"
                  value={toYear}
                  maxLength="4"
                  onChange={(e) => setToYear(e.target.value)}
                  onKeyPress={pressEnterButton}
                />
                {/* <select
                  className="search-input"
                  onChange={(evt) => handleYearEra(evt, "to")}
                  value={toYearEra}
                >
                  <option value="CE">CE</option>
                  <option value="BCE">BCE</option>
                </select> */}
              </div>

              <div className="d-flex align-items-center gap-2">
                <div
                  className="search-button"
                  onClick={() => handleAddKeywords()}
                >
                  Search
                </div>
                <div>
                  <div onClick={handleSortingDate}>
                    {sortByDate === false ? (
                      <OverlayTrigger
                        className="tooltip"
                        placement="bottom"
                        overlay={
                          <Tooltip>
                            Sort by Moment Date: Latest Moments First.
                          </Tooltip>
                        }
                      >
                        <img
                          className="sort-image"
                          src={images.sortAsc}
                          alt="sortAsc"
                          onClick={() => sortMoments(1)}
                          style={{ marginTop: "3px" }}
                        ></img>
                      </OverlayTrigger>
                    ) : (
                      <OverlayTrigger
                        className="tooltip"
                        placement="bottom"
                        overlay={
                          <Tooltip>
                            Sort by Moment Date: Oldest Moments First
                          </Tooltip>
                        }
                      >
                        <img
                          className="sort-image"
                          src={images.sortDec}
                          alt="sortDec"
                          onClick={() => sortMoments(-1)}
                          style={{ marginTop: "3px" }}
                        ></img>
                      </OverlayTrigger>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="checkbox-set">
              <div className="d-flex gap-2 align-items-center">
                <Form.Check className="yellow-checkbox" type="checkbox" />
                <label>Show All</label>
              </div>
              <div className="d-flex gap-2 align-items-center">
                <Form.Check className="yellow-checkbox" type="checkbox" />
                <label>World Moment</label>
              </div>
              <div className="d-flex gap-2 align-items-center">
                <Form.Check className="yellow-checkbox" type="checkbox" />
                <label>National Moment</label>
              </div>
              <div className="d-flex gap-2 align-items-center">
                <Form.Check className="yellow-checkbox" type="checkbox" />
                <label>Top Moment</label>
              </div>
              <div className="d-flex gap-2 align-items-center">
                <Form.Check className="yellow-checkbox" type="checkbox" />
                <label>Sustainability Moment</label>
              </div>
            </div> */}

            <Row>
              <div className="keywords-container">
                {keywords.map((keyword, index) => (
                  <button key={index} className="keyword-button">
                    {keyword.value}
                    <span
                      className="close-icon"
                      onClick={() => handleRemoveKeyword(keyword.type)}
                    >
                      &#x2716;
                    </span>
                  </button>
                ))}
              </div>
            </Row>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default SearchPage;
