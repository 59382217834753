import axios from 'axios';
import MomentJs from 'moment';
import { getFromSessionStorage, removeLoginDataFromStorage } from './storageHandler';

export const createRequest = (method, url, body = null, configs = {}) => {

  let tokenExpired = false;
  const expireTime = getFromSessionStorage('tokenExpireTime');
  if(expireTime){
    tokenExpired = MomentJs().isAfter(MomentJs(expireTime));
  }

  if(!tokenExpired){
    const requestConfig = {
      method,
      url,
      data: body,
      ...configs,
    };
  
    return axios(requestConfig)
      .then(response => {
        return response;
      })
      .catch(error => {
        console.error('Error in createRequest:', error);
        return error.response;
        //throw error;
    });
  }else {
    removeLoginDataFromStorage();
    window.location.href = window.location.origin + '/login';
  }
}