import { createSlice } from "@reduxjs/toolkit";

const FamousBdaysSlice = createSlice({
    name: "FamousBdays",
    initialState: {
        FamousBdays: []
    },
    reducers: {
        getFamousBdays: (state, action) => {
            state.FamousBdays = action.payload
        },
    }
})

export const { getFamousBdays } = FamousBdaysSlice.actions;

export default FamousBdaysSlice.reducer;