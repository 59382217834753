import React, { useEffect, useState } from "react";
import "../../styles/CommonStyle.css";
import "../../styles/AccountAdministrationStyle.css";
import "react-international-phone/style.css";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { images } from "../../utils/image";
import { useDispatch } from "react-redux";
import { addLifeManager, getLifeManagers, deleteLifeManager, getLifeByAccountManagerEmail, addLifeToLifeManager, enableDissableLifeManager, removeLifeFromLifeManager } from "../../store/action/AccountAdministrationAction"
import { getFromSessionStorage } from "../../utils/storageHandler";
const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const AccountAdministration = () => {
  const dispatch = useDispatch();

  const [modalShow, setModalShow] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isLifeChecked, setIsLifeChecked] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showLifeDelete, setShowLifeDelete] = useState(false);
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLifePage, setCurrentLifePage] = useState(1);
  const [newLifeAdminEmail, setNewLifeAdminEmail] = useState("");
  const [userLogin, setUserLogin] = useState("");
  const [currentData, setCurrentData] = useState({ id: null, type: null });
  const [LifeAdministrators, setLifeAdministrators] = useState([]);
  const [ManageLife, setManageLife] = useState([]);
  const [changeLifeAdmin, setChangeLifeAdmin] = useState(false);
  const [newLifeAdminData, setNewLifeAdminData] = useState({ id: null, lifeId: null, lifeManagerEmail: null, prevLifeManagerEmail: null, account_manager_email: null });
  const [newLifeAdmins, setNewLifeAdmins] = useState([]);
  const [lifeAdminResponse, setLifeAdminResponse] = useState(null);
  const [isAddNewLifeAdmin, setIsAddNewLifeAdmin] = useState(false);
  const [status, setStatus] = useState("Pending");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const[isCheckboxCheck , setIsCheckboxCheck] = useState(false);
  const [emailMessage, setErrorMessage] = useState("");


  //Checkbox enable & disable 

  useEffect(() => {
    setIsChecked(LifeAdministrators.length > 0 && LifeAdministrators.map(lifeadmin => lifeadmin.enabled));
    setIsLifeChecked(ManageLife.map(life => life.enable));


  }, []);

  useEffect(() => {
    const userDetails = getFromSessionStorage("userDetails", true);
    if(userDetails){
      setUserLogin(userDetails);
      fetchLifeManagers(userDetails.email);
      fetchLifeByAccountManagerEmail(userDetails.email);
    }
    

  }, []);

  const fetchLifeManagers = async (account_manager_email) => {

    dispatch(
      await getLifeManagers({ account_manager_email }

      )
    )
      .then((res) => {
        if (res) {
          const result = res.data
          setLifeAdministrators(result)
          const adminList = result.filter(lifeadmin => lifeadmin.enabled === true && lifeadmin.acknowledge === "yes");
          setNewLifeAdmins(adminList);

        }
      })
      .catch((error) => {
        console.error("Error in fetch life managers:", error);
      });
  };

  const fetchLifeByAccountManagerEmail = async (account_manager_email) => {

    dispatch(
      await getLifeByAccountManagerEmail({ account_manager_email }

      )
    )
      .then((res) => {
        if (res) {
          setManageLife(res.data)
        }
      })
      .catch((error) => {
        console.error("Error in fetch life managers:", error);
      });
  };

  const handleShow = (id, type) => {
    setModalShow(true);
    setCurrentData({ id, type });
  };

  const handleClose = () => {
    setModalShow(false);
  };

  const handleYesBtn = () => {
    if (currentData.type === 'LifeAdministrator') {
      handleToggle(currentData.id);
    } else {
      handleLifeToggle(currentData.id);
    }
  };

  const handleNoBtn = () => {
    handleClose();
  };

  const handleToggle = async(id) => {
    dispatch(
      await enableDissableLifeManager({
        id: id, status: !isChecked[id]
      })
    )
      .then((res) => {
        if (res) {
          fetchLifeManagers(userLogin.email)
          fetchLifeByAccountManagerEmail(userLogin.email)
          
        }
      })
      .catch((error) => {
        
        console.error("Error in change life admin:", error);
      });

    setIsChecked(prevState => ({ ...prevState, [id]: !prevState[id] }));
    handleClose();
  };

  const handleLifeToggle = (id) => {
    setIsLifeChecked(prevState => ({ ...prevState, [id]: !prevState[id] }));
    handleClose();
  };


  const handleAdmins = (evt, email, life_id) => {
    const { value } = evt.target;
    const selectedOption = evt.target.options[evt.target.selectedIndex];
    const life_manager_id = selectedOption.dataset.life_manager_id
    const data = {
      id: life_manager_id, lifeId: life_id, lifeManagerEmail: value, prevLifeManagerEmail: email ? email : null, account_manager_email: userLogin.email
    }

    setNewLifeAdminData(data);
    setChangeLifeAdmin(true);
  };

  const handleLifeAdminChange = async () => {
    if(newLifeAdminData.lifeManagerEmail === "None"){
      dispatch(
        await removeLifeFromLifeManager({
          newLifeAdminData
        })
      )
        .then((res) => {
          if (res) {
            fetchLifeByAccountManagerEmail(userLogin.email)
            setChangeLifeAdmin(false);
          }
        })
        .catch((error) => {
          setChangeLifeAdmin(false);
          console.error("Error in change life admin:", error);
        });
    }else{
      dispatch(
        await addLifeToLifeManager({
          newLifeAdminData
        })
      )
        .then((res) => {
          if (res) {
            fetchLifeByAccountManagerEmail(userLogin.email)
            setChangeLifeAdmin(false);
          }
        })
        .catch((error) => {
          setChangeLifeAdmin(false);
          console.error("Error in change life admin:", error);
        });
    }
   

  };

  const closeLifeAdminChange = () => {
    fetchLifeManagers(userLogin.email)
    setChangeLifeAdmin(false);
  };


  //Add New Life Administrators

  const handleAddNew = () => {
    setIsAddNewLifeAdmin((prevState => (!prevState)))
  }

  //pagination set

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const startLifeIndex = (currentLifePage - 1) * itemsPerPage;
  const endLifeIndex = startLifeIndex + itemsPerPage;

  const handleDelete = () => {
    setShowDelete((prevState) => !prevState);
  };

  const handleLifeDelete = () => {
    setShowLifeDelete((prevState) => !prevState);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleLifeNextPage = () => {
    setCurrentLifePage((prevPage) => prevPage + 1);
  };

  const handleLifePrevPage = () => {
    setCurrentLifePage(currentLifePage - 1);
  };

  const handleValueChange = (evt) => {
    if (!emailPattern.test(evt.target.value)) {
      setErrorMessage("Type valid email address");
    } else {
      setErrorMessage("");
    }
    
    const { value } = evt.target;
    setNewLifeAdminEmail(value);
    console.log(newLifeAdminEmail);
  };

  const handleAddNewLifeManager = async () => {
    if (newLifeAdminEmail !== "") {
      dispatch(
        await addLifeManager({
          life_manager_email: newLifeAdminEmail,
          account_manager_email: userLogin.email,
        })
      )
        .then((res) => {
          if (res) {
            fetchLifeManagers(userLogin.email)
            if(res.success){
              setLifeAdminResponse("We have sent an invitation")
              setStatus("Success")
            }else{
              if(res.data.code === 404){
                setLifeAdminResponse("User not Found.Please inform him/her to create an account")
                setStatus("Reject")
              }else if(res.data.code === 401){
                setLifeAdminResponse("Before send invitation delete his/her request")
                setStatus("Reject")
              }else if(res.data.code === 403){
                setLifeAdminResponse("Account manager email can't add as a life manager")
                setStatus("Reject")
              }else{
                setLifeAdminResponse("Already added")
                setStatus("Pending")
              }
            }

          }
        })
        .catch((error) => {
          console.error("Error in reset password:", error);
        });
    } else {
      setErrorMessage("missing data"); 
    }
  };

  const handleLifeManagerDelete = async (id) => {
    if (id) {
      dispatch(
        await deleteLifeManager({
          id
        })
      )
        .then((res) => {
          if (res) {
            fetchLifeManagers(userLogin.email)
            fetchLifeByAccountManagerEmail(userLogin.email)
          }
          setShowConfirmation(false);
          setDeleteId("");
        })
        .catch((error) => {
          console.error("Error in reset password:", error);
        });
    } else {
      console.log("missing data"); 
    }
  };

  const handleCheck = () => {
    setIsCheckboxCheck((prevState) => !prevState);
  }

  const handleShowConfirmation = (id) => {
    setShowConfirmation(true);
    setDeleteId(id);
  }

  const handleConfirmationClose = () => {
    setShowConfirmation(false);
  }

  return (
    <div>
      <div className="favorites-main Life-Administrators">
        <div className="title">Life Administrators</div>

        <button className="add-new-btn" onClick={handleAddNew}>
          Add New Life Administrator{" "}
          <span>
            <img
              src={images.addAdmin}
              style={{ marginLeft: "20px" }}
            />
          </span>
        </button>

        {isAddNewLifeAdmin && (
          <div className="add-new-admin">
            <div className="d-flex gap-2 w-50">
              <input className="form-input" placeholder="jhon@gmail.com" onChange={handleValueChange}/>
            </div>
            {emailMessage && (
              <small >
                <div className="error-message" style={{paddingTop: "10px"}}>
                  <span>
                    <i class="bi bi-exclamation-circle"></i>
                  </span>
                  {emailMessage}
                </div>
              </small>
            )}

            <div className="check-word">
              <Form.Check
                className="yellow-checkbox"
                type="checkbox"
                id="topMoment"
                checked={isCheckboxCheck}
                onClick={handleCheck}
              />
              When add life administrators your account details (name and email)
              will be shared with the receiver.
            </div>

            <button className="yellow-btn" onClick={handleAddNewLifeManager} disabled={!isCheckboxCheck}>Add</button>

            {status && (
              <div
                className="status-message"
                style={{
                  color: status === "Pending" ? "blue" : status === "Success" ? "Green" : "red",
                }}
              >
                {lifeAdminResponse}
              </div>
            )}
          </div>
        )}

        <div className="main-content">
          <Table hover borderless className="table" responsive>
            <thead className="table-header">
              <tr>
                <th>Email</th>
                <th>Name</th>
                <th>Enabled</th>
                <th>Acknowledged</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {LifeAdministrators && LifeAdministrators.length > 0 && LifeAdministrators.slice(startIndex, endIndex).map(
                (admin, index) => (
                  <tr key={index}>
                    <>
                      <td>{admin.life_manager_email}</td>
                      <td> {" "} {admin.life_manager_first_name}{" "} {admin.life_manager_last_name}{" "} </td>
                      <td>
                        <span>
                          <input
                            type="checkbox"
                            id={admin.id}
                            className="checkbox"
                            checked={isChecked[admin.id] !== undefined ? isChecked[admin.id] : admin.enabled}
                            onChange={() => { isChecked[admin.id] ? handleShow(admin.id, "LifeAdministrator") : handleToggle(admin.id); }}
                          />
                          <label htmlFor={admin.id} className="toggle">
                            <span>
                              <div>Yes</div>
                              <div>No</div>
                            </span>
                          </label>
                        </span>
                      </td>
                      <td>{admin.acknowledge}</td>
                    </>
                    {showDelete && (
                      <td>
                        <i class="bi bi-trash3-fill" onClick={() => {
                          // handleLifeManagerDelete(admin.id);
                          handleShowConfirmation(admin.id)
                        }}></i>
                      </td>
                    )}
                  </tr>
                )
              )}
            </tbody>
          </Table>

          <div className="pagination-set">
            {LifeAdministrators && LifeAdministrators.length > itemsPerPage &&
              (LifeAdministrators.length > endIndex ? (
                <>
                  {currentPage > 1 && (
                    <div onClick={handlePrevPage}>
                      <i class="bi bi-caret-left-fill"></i>
                    </div>
                  )}
                  <div className="page-num">
                    <div>{currentPage}</div>
                  </div>
                  <span className="page-num"> to </span>
                  <div className="page-num" onClick={handleNextPage}>
                    {currentPage + 1}
                  </div>
                  <div onClick={handleNextPage}>
                    <i class="bi bi-caret-right-fill"></i>
                  </div>
                </>
              ) : (
                <div className="page-num" onClick={handlePrevPage}>
                  Back
                </div>
              ))}
          </div>

          <div className="delete-word" onClick={handleDelete}>
            Delete Life Administrator
          </div>
        </div>
      </div>

      <div className="favorites-main Manage-Life" style={{ marginTop: "10px" }}>
        <div className="title">Manage Life</div>

        <button className="add-new-btn">Add New Life</button>

        <div className="main-content">
          <Table hover borderless className="table" responsive>
            <thead className="table-header">
              <tr>
                <th>Life Name</th>
                {/* <th>Enabled</th> */}
                <th>Life Adm 1</th>
                <th>Life Adm 2</th>
                <th>Subscription</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {ManageLife && ManageLife.slice(startLifeIndex, endLifeIndex).map(
                (life, index) => (
                  <tr key={index}>
                    <>
                      <td>
                        <Link to={`/${life.verified_name?life.verified_name:life.life_id}`}>{life.life_name}</Link>
                      </td>
                      {/* <td>
                        <span>
                          <input
                            type="checkbox"
                            id={life.life_name}
                            className="checkbox"
                            checked={isLifeChecked[life.life_id] !== undefined ? isLifeChecked[life.life_id] : life.enable}
                            onChange={() => { isLifeChecked[life.life_id] ? handleShow(life.life_id) : handleLifeToggle(life.life_id); }}
                          />
                          <label htmlFor={life.life_name} className="toggle">
                            <span>
                              <div>Yes</div>
                              <div>No</div>
                            </span>
                          </label>
                        </span>
                      </td> */}
                      <td>
                        <select className="select-option" onChange={(evt) => handleAdmins(evt, life.life_manager_email[0]?.email, life.life_id)}>
                          {life.life_manager_email[0]?.firstName ? (
                            <option value={null}>None</option>
                          ) : (
                            <option value={null} selected={true}>None</option>
                          )}

                          {newLifeAdmins.map((lifeadmin, index) => (

                            <option key={lifeadmin.id} data-life_manager_id={lifeadmin.id} value={lifeadmin.life_manager_email} selected={lifeadmin.life_manager_email === life.life_manager_email[0]?.email}>
                              {lifeadmin.life_manager_first_name} {lifeadmin.life_manager_last_name}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td>
                          <select className="select-option" onChange={(evt) => handleAdmins(evt, life.life_manager_email[1]?.email, life.life_id)}>
                          {life.life_manager_email[1]?.firstName ? (
                            <option value={null}>None</option>
                          ) : (
                            <option value={null} selected={true}>None</option>
                          )}
                          {newLifeAdmins.map((lifeadmin, index) => (
                            <option key={lifeadmin.id} data-life_manager_id={lifeadmin.id} value={lifeadmin.life_manager_email} selected={lifeadmin.life_manager_email === life.life_manager_email[1]?.email}>
                            {lifeadmin.life_manager_first_name} {lifeadmin.life_manager_last_name}
                          </option>
                          ))}
                        </select>
                      </td>

                      <td>{life.current_subscription}</td>
                    </>
                    {showLifeDelete && (
                      <td>
                        <i class="bi bi-trash3-fill"></i>
                      </td>
                    )}
                  </tr>
                )
              )}
            </tbody>
          </Table>

          <div className="pagination-set">
            {ManageLife && ManageLife.length > itemsPerPage &&
              (ManageLife.length > endLifeIndex ? (
                <>
                  {currentLifePage > 1 && (
                    <div onClick={handleLifePrevPage}>
                      <i class="bi bi-caret-left-fill"></i>
                    </div>
                  )}
                  <div className="page-num">
                    <div>{currentLifePage}</div>
                  </div>
                  <span className="page-num"> to </span>
                  <div className="page-num" onClick={handleLifeNextPage}>
                    {currentLifePage + 1}
                  </div>
                  <div onClick={handleLifeNextPage}>
                    <i class="bi bi-caret-right-fill"></i>
                  </div>
                </>
              ) : (
                <div className="page-num" onClick={handleLifePrevPage}>
                  Back
                </div>
              ))}
          </div>

          {/* <div className="delete-word" onClick={handleLifeDelete}>
            Delete Life
          </div> */}
        </div>

        {/* <div className="button-row">
          <button className="yellow-btn">Edit</button>

          <button className="yellow-btn">Cancel</button>
        </div> */}
      </div>

      {modalShow && (
        <Modal
          show={handleShow}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          className="account-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="modal-topic">Confimation</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-content">
            {currentData.type == "LifeAdministrator" ? (
              <div>Remove from Life Administrator</div>
            ) : (
              <div>Remove from Life</div>
            )}
            <div className="modal-btn-set">
              <button className="yellow-btn" onClick={handleYesBtn}>
                Yes
              </button>
              <button className="yellow-btn" onClick={handleNoBtn}>
                No
              </button>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      )}

      {changeLifeAdmin && (
        <Modal
          show={changeLifeAdmin}
          onHide={closeLifeAdminChange}
          backdrop="static"
          keyboard={false}
          className="account-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="modal-topic">Confimation</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-content">
            <div>Change the Life Admin</div>
            <div className="modal-btn-set">
              <button className="yellow-btn" onClick={handleLifeAdminChange}>
                Yes
              </button>
              <button className="yellow-btn" onClick={closeLifeAdminChange}>
                No
              </button>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      )}

      {/* delete life administrator confirmation modal */}
      <Modal
        className="popup-modal confirmation-modal"
        show={showConfirmation}
        onHide={handleClose}
        backdrop="static"
        centered
      >
        <div className="popup-header">
          <div className="logo-icon">
            <img src={images.MoofIcon} />
          </div>
        </div>

        <Modal.Body className="modal-body">
          <div className="title">Confirmation</div>
          <div className="subtitle1">Do you want to delete this Life Administrator ?</div>
        </Modal.Body>

          <div className="btn-set">
            <button className="btn1" onClick={() => handleLifeManagerDelete(deleteId)}>
              Yes
            </button>
            <button className="btn2" onClick={handleConfirmationClose}>
              No
            </button>
          </div>
      </Modal>
    </div>
  );
};

export default AccountAdministration;
