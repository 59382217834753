import { createSlice } from "@reduxjs/toolkit";

const FollowerSlice = createSlice({
  name: "Follower",
  initialState: {
    followerList: [],
    page: 1,
  },
  reducers: {
    followerList: (state, action) => {
      action.payload.map((follower) => {
        if (
          !state.followerList.some((item) => item.life_id === follower.life_id)
        ) {
          state.followerList.push(follower);
        }
      });
    },
    deleteFollower: (state, action) => {
      const lifeIdToRemove = action.payload;
      state.followerList = state.followerList.filter(
        (item) => item.life_id !== lifeIdToRemove
      );
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
  },
});

export const { followerList, setPage, deleteFollower } = FollowerSlice.actions;

export default FollowerSlice.reducer;
