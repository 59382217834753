import React from "react";
import NavigationBar from "../LandingPage/NavigationBar";

const LifeNavbar = () => {
  return (
    <div>
      <NavigationBar />
    </div>
  );
};

export default LifeNavbar;
