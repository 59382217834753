import React, { useEffect } from "react";
import "../../styles/LandingPage/MostViewedMomentsStyle.css";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { getMostViewMoments } from "../../store/action/MostViewedMomentsAction";
import { images } from "../../utils/image";
import { Link } from "react-router-dom";

const MostViewedMoments = () => {
  const dispatch = useDispatch();
  const MostViewedMoments = useSelector(
    (state) => state.MostViewedMoments.MostViewedMoments
  );

  useEffect(() => {
    dispatch(getMostViewMoments());
  }, [dispatch]);

  // Define beforeChange and afterChange functions
  const beforeChange = function (currentSlide, nextSlide) {};

  const afterChange = function (currentSlide) {};

  const isInfinite = MostViewedMoments.length <= 3;
  // Define settings object
  const settings = {
    dots: false,
    infinite: isInfinite ? false : true,
    speed: 0,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 578,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    prevArrow: <button onClick={beforeChange}></button>,
    nextArrow: <button onClick={afterChange}></button>,
  };

  return (
    <>
      {MostViewedMoments.length > 0 && (
        <div className="most-view-moments-main">
          <div className="most-view-content">
            <div className="moments-details">
              <div className="most-view-title">
                Most Viewed <span>Moments</span>
              </div>

              <Slider {...settings}>
                {MostViewedMoments.map((mostView, index) => {
                  if (
                    mostView.images &&
                    mostView.images.length > 0 &&
                    mostView.images[0] &&
                    mostView.images[0].media_type === "image"
                  ) {
                    return (
                      <div className="most-view-card" key={index}>
                        <div className="image">
                          <img
                            src={mostView.images[0].url}
                            alt="most-view-image"
                          />
                        </div>

                        <div className="details">
                          <hr className="hr-line"></hr>
                          <div className="life-name">
                            <Link
                              to={`/${
                                mostView.life.verified_name
                                  ? mostView.life.verified_name
                                  : mostView.life.life_id
                              }`}
                            >
                              {mostView.life.life_name.length > 30
                                ? mostView.life.life_name.slice(0, 30) + "..."
                                : mostView.life.life_name}{" "}
                              : <span>{mostView.moment_date}</span>
                            </Link>
                          </div>
                          <Link
                            to={`/${
                              mostView?.life.verified_name ||
                              mostView?.life.life_id
                            }/${mostView._id}`}
                            target="_blank"
                          >
                            <div className="moment-title">
                              {mostView.moment_title.length > 80
                                ? mostView.moment_title.slice(0, 80) + "..."
                                : mostView.moment_title}
                            </div>
                          </Link>

                          <div className="moment-desc">
                            {mostView.moment_details.length > 150
                              ? mostView.moment_details.slice(0, 150) + "..."
                              : mostView.moment_details}
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </Slider>
            </div>

            <div className="google-ads">
              <img src={images.ad1} />
              <img src={images.ad2} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MostViewedMoments;
