export const options = [
    { value: "Political", label: "Political" },
    { value: "Sustainability", label: "Sustainability" },
    { value: "Economy", label: "Economy" },
    { value: "Business", label: "Business" },
    { value: "Entertainment", label: "Entertainment" },
    { value: "Music", label: "Music" },
    { value: "Sports", label: "Sports" },
    { value: "Technology", label: "Technology" },
    { value: "Science", label: "Science" },
    { value: "Health", label: "Health" },
    { value: "Social", label: "Social" },
    { value: "Natural disaster", label: "Natural disaster" },
    { value: "War", label: "War" },
    { value: "Movements", label: "Movements" },
    { value: "Terrorism", label: "Terrorism" },
    { value: "Travel", label: "Travel" },
    { value: "Transportation", label: "Transportation" },
    { value: "Religion", label: "Religion" },
    { value: "Construction", label: "Construction" },
    { value: "Architecture", label: "Architecture" },
  ];