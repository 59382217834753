import React, { useState, useEffect } from "react";
import "../../styles/SearchStyle.css";
import "../../styles/CommonStyle.css";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useDispatch, useSelector } from "react-redux";
import { getMoreSearchMoments } from "../../store/action/SearchAction";
import { useNavigate } from "react-router-dom";
import { getFromSessionStorage } from "../../utils/storageHandler.js";

const SearchMoment = (props) => {
  const data = props.searchData;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchResults = useSelector((state) => state.searchResults.searchData);
  const searchMomentsPage = useSelector(
    (state) => state.searchResults.searchMomentPage
  );
  const [userLogin, setUserLogin] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const totalItems = searchResults.moments.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  useEffect(() => {
    const userDetails = getFromSessionStorage("userDetails", true);
    setUserLogin(userDetails);
  }, []);

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
    getNextPageMoments();
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getNextPageMoments = async () => {
    dispatch(
      await getMoreSearchMoments(
        {
          keyword: data.keyword,
          fromYear: handleYears(data.fromYear),
          fromYearEra: data.fromYearEra,
          toYearEra: handleYears(data.toYearEra),
          toYear: data.toYear,
          page: searchMomentsPage,
          limit: itemsPerPage,
          user_email: userLogin?.email?userLogin.email:"",
          sorting: -1
        },
        searchMomentsPage
      )
    )
  };

  const handleYears = (year) => {
    let yearVal = year;
    if (year && year !== "") {
      if (year < 10) {
        yearVal = `000${year}`;
      } else if (year < 100) {
        yearVal = `00${year}`;
      } else if (year < 1000) {
        yearVal = `0${year}`;
      } else {
        yearVal = year.toString();
      }
    } else {
      yearVal = year;
    }
    return yearVal;
  };

  const goToMoments = (_id,life_id) => {
    navigate(`/${life_id}/${_id}`);
    
  };

  return (
    <div>
      <div className="search-moment-main">
        <h2 className="title">Moments</h2>
        {searchResults.moments
          .slice(startIndex, endIndex)
          .map((searchMoment, index) => (
            <Card
              key={index}
              className="search-moment-card"
              onClick={() => goToMoments(searchMoment._id, searchMoment.life.verified_name?searchMoment.life.verified_name:searchMoment.life.life_id)}
            >
              <Row>
                <Col sm={1}>
                  <img
                    className="moment-logo"
                    src={searchMoment.life.life_logo}
                  ></img>
                </Col>
                <Col className="details-col">
                  <div className="life-name title-highlight">{searchMoment.life.life_name}</div>
                  <div className="moment-date">{searchMoment.moment_date}</div>
                  <div className="moment-title title-highlight">
                    {searchMoment.moment_title}
                  </div>
                </Col>
                <Col sm={4} style={{ textAlign: "right" }}>
                  {searchMoment.images.length > 0 ? (
                    <img
                      className="search-moment-image"
                      src={searchMoment.images[0].url}
                    ></img>
                  ) : (
                    <div></div>
                  )}
                </Col>
              </Row>
            </Card>
          ))}
        
        <div className="pagination-buttons">
          <button
            className="pagination-btn"
            onClick={handlePrev}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <button
            className="pagination-btn"
            onClick={handleNext}
            disabled={totalPages === currentPage}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchMoment;
